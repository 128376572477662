import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../components/auth-provider";
import { loadAgents } from "../../data-functions/agent-api";
import { loadSponsors } from "../../data-functions/system-data_api";
import { AgentsDict } from "../../types/agents";
import { SponsorsDict } from "../../types/sponsors";
import {
  CampaignsDict,
  CandidateDoc,
  Candidate,
  CANDIDATE_STATUS_LIST,
  CandidateStatus,
  VERIFICATION_EXPIRY_HOURS,
} from "../../types/recruiting";
import { TitledPage } from "../../components/titled-page";
import {
  Box,
  Button,
  CardContent,
  LinearProgress,
  Typography,
  Card,
  Dialog,
  DialogContent,
  Grid,
  TextField,
  Divider,
  MenuItem,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Stack,
} from "@mui/material";
import { parse } from "papaparse";
import {
  CSV_TEST_SCORE_EMAIL_FIELD,
  parseTestScore,
  CSV_CANDIDATE_MAPPING,
  CSV_CANDIDATE_MAPPING_DATE_PROPERTIES,
  CSV_CANDIDATE_MAPPING_BOOLEAN_PROPERTIES,
  CSV_CANDIDATE_MAPPING_COUNTRY_PROPERTIES,
} from "../../utils/importers/importerHOPS_candidates";
import {
  generateConfirmationCode,
  loadCampaigns,
  loadCandidates,
  saveCandidate,
} from "../../data-functions/recruiting_api";
import { Filter } from "../../types/commons";
import {
  findCountry,
  formatDateToddMMYYYY,
  parseCSVDate,
  setNestedProperty,
} from "../../utils/utils";
import { sendEmailVerificationEmail } from "../../utils/candidate-utils";

// Define import types
type ImportType = "candidates" | "language_tests" | null;

type ImportOptions = {
  setToInvited: boolean;
  status: CandidateStatus;
  scorePercent: number;
  fileHasOrders: boolean;
  addOrder: boolean;
  setAsVerified: boolean;
  sendConfirmationCode: boolean;
};

// Add this type for import statistics
type ImportStats = {
  total: number;
  current: number;
  missingCandidates: string[];
  duplicateCandidates: string[];
  failedSends: string[];
};

const RecruitingImportPage: React.FC = () => {
  // Add state for import type selection
  const [importType, setImportType] = useState<ImportType>(null);

  const [sponsors, setSponsors] = useState<SponsorsDict>({});
  const [selectedSponsor, setSelectedSponsor] = useState<string>("");
  const [agents, setAgents] = useState<AgentsDict>({});
  const [selectedAgent, setSelectedAgent] = useState<string>("");
  const [campaigns, setCampaigns] = useState<CampaignsDict>({});
  const [selectedCampaign, setSelectedCampaign] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const [showProgress, setShowProgress] = useState(false);
  const [importOptions, setImportOptions] = useState<ImportOptions>({
    setToInvited: true,
    status: "new",
    scorePercent: 45,
    fileHasOrders: true,
    addOrder: true,
    setAsVerified: false,
    sendConfirmationCode: false,
  });

  // const [testDate, setTestDate] = useState<string>("");
  // const [testDateResult, setTestDateResult] = useState<string>("");

  const [errors, setErrors] = useState<{
    file: string;
    sponsor: string;
    agent: string;
    campaign: string;
  }>({ file: "", sponsor: "", agent: "", campaign: "" });

  // Update the progress state to use the new type
  const [progress, setProgress] = useState<ImportStats>({
    total: 0,
    current: 0,
    missingCandidates: [],
    duplicateCandidates: [],
    failedSends: [],
  });

  const [importComplete, setImportComplete] = useState(false);

  const { currentUser } = useContext(AuthContext)!;

  const fetchAgents = async () => {
    const agentsDict = await loadAgents(currentUser?.appUser);

    if (agentsDict) {
      setAgents(agentsDict);
    } else {
      setAgents({});
    }
  };

  const fetchSponsors = async () => {
    const sponsors = await loadSponsors(currentUser?.appUser);
    if (sponsors) {
      setSponsors(sponsors);
    } else {
      setSponsors({});
    }
  };

  const fetchCampaigns = async () => {
    const filter: Filter = {};
    if (!currentUser?.appUser?.agentId && selectedAgent) {
      filter["agentId"] = { value: selectedAgent, operator: "==" };
    }
    if (!currentUser?.appUser?.sponsorId && selectedSponsor) {
      filter["sponsorId"] = { value: selectedSponsor, operator: "==" };
    }

    const campaigns = await loadCampaigns(currentUser?.appUser, filter);
    if (campaigns) {
      setCampaigns(campaigns);
    }
  };

  useEffect(() => {
    if (currentUser?.appUser?.sponsorId) {
      setSelectedSponsor(currentUser.appUser.sponsorId);
    }
    if (currentUser?.appUser?.agentId) {
      setSelectedAgent(currentUser.appUser.agentId);
    }
    fetchSponsors();
    fetchAgents();
  }, [currentUser]);

  useEffect(() => {
    fetchCampaigns();
  }, [selectedSponsor, selectedAgent]);

  const validate = async () => {
    let isValid = true;
    const newErrors = { file: "", agent: "", sponsor: "", campaign: "" };

    if (!file) {
      isValid = false;
      newErrors.file = "Please select a file";
    }

    if (!selectedAgent) {
      isValid = false;
      newErrors.agent = "Please select an agent";
    }

    if (!selectedSponsor) {
      isValid = false;
      newErrors.sponsor = "Please select a sponsor";
    }

    if (!selectedCampaign || selectedCampaign === "no_campaign") {
      isValid = false;
      newErrors.campaign = "Please select a campaign";
    }

    setErrors(newErrors);

    return isValid;
  };

  const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setImportOptions({ ...importOptions, [e.target.name]: e.target.checked });
  };

  const handleStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setImportOptions({ ...importOptions, status: e.target.value as CandidateStatus });
  };

  const onInputComboChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.name) {
      case "agentId":
        setSelectedAgent(event.target.value);
        break;
      case "sponsorId":
        setSelectedSponsor(event.target.value);
        break;
      case "campaignId":
        setSelectedCampaign(event.target.value);
        break;
      case "status":
        setImportOptions({ ...importOptions, status: event.target.value as CandidateStatus });
        break;
    }
  };

  const handleCloseDialog = () => {
    setShowProgress(false);
    setImportComplete(false);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
    }
  };

  const handleImportTests = async () => {
    const isValid = await validate();
    if (!isValid) {
      return;
    } else {
      setErrors({ ...errors, agent: "", sponsor: "" });
    }

    if (file) {
      setShowProgress(true);
      setImportComplete(false);
      try {
        const text: string = await file.text();

        const results = parse(text, {
          header: true,
          delimiter: ";",
          skipEmptyLines: "greedy",
        });

        setProgress({
          current: 0,
          total: results.data.length,
          missingCandidates: [],
          duplicateCandidates: [],
          failedSends: [],
        });

        for (let row of results.data) {
          if (typeof row === "object" && row !== null) {
            const dataRow = row as Record<string, string>;
            const email = dataRow[CSV_TEST_SCORE_EMAIL_FIELD];

            const newCandidates = await loadCandidates(currentUser?.appUser, selectedCampaign, {
              email: { value: email, operator: "==" },
            });

            if (newCandidates && Object.keys(newCandidates).length === 1) {
              const candidateDoc: CandidateDoc = {
                id: Object.keys(newCandidates)[0],
                candidate: newCandidates[Object.keys(newCandidates)[0]],
              };
              const testScore = parseTestScore(dataRow);

              //   if (!testScore.scorePercentage && testScore.score && testScore.maxScore) {
              //     testScore.scorePercentage = (testScore.score / testScore.maxScore) * 100;
              //   }
              candidateDoc.candidate.testScore = testScore;
              if (importOptions.setToInvited && testScore.verdict === "pass") {
                candidateDoc.candidate.status = "invited";
              }

              await saveCandidate(currentUser?.appUser, candidateDoc);
              console.log("candidate: ", candidateDoc.candidate);
            } else {
              setProgress((prev) => ({
                ...prev,
                missingCandidates: [...prev.missingCandidates, email],
              }));
              console.log("missing candidate: ", email);
            }
          }

          setProgress((prev) => ({ ...prev, current: prev.current + 1 }));
        }
        setImportComplete(true);
      } catch (error) {
        console.error("Error reading CSV file:", error);
        alert("Error reading CSV file. Please make sure it is a valid CSV file.");
        setImportComplete(true);
      } finally {
        console.log("------- import complete -------");
      }
    }
  };

  const handleImportCandidates = async () => {
    const isValid = await validate();
    if (!isValid) {
      return;
    } else {
      setErrors({ ...errors, agent: "", sponsor: "" });
    }

    if (file) {
      setShowProgress(true);
      setImportComplete(false);
      try {
        const text: string = await file.text();

        const results = parse(text, {
          header: true,
          delimiter: ";",
          skipEmptyLines: "greedy",
        });

        setProgress({
          current: 0,
          total: results.data.length,
          missingCandidates: [],
          duplicateCandidates: [],
          failedSends: [],
        });

        for (let row of results.data) {
          if (typeof row === "object" && row !== null) {
            const dataRow = row as Record<string, string>;

            // Create a new candidate from CSV data
            const newCandidate: Candidate = {
              name: "",
              surname: "",
              email: "",
              phone: "",
              sponsorId: selectedSponsor,
              agentId: selectedAgent,
              campaignId: selectedCampaign,
              status: importOptions.status,
            };

            const csvKeys = Object.keys(dataRow);
            csvKeys.forEach((key) => {
              key = key.trim();
              const csvValue = dataRow[key] ? dataRow[key].trim() : "";

              // map standard fields
              const propertyName = CSV_CANDIDATE_MAPPING[key];
              if (propertyName) {
                setNestedProperty(newCandidate, propertyName, csvValue);
              }

              const booleanPropertyName = CSV_CANDIDATE_MAPPING_BOOLEAN_PROPERTIES[key];
              if (booleanPropertyName) {
                const booleanValue = csvValue.toLowerCase() === "yes" ? true : false;
                setNestedProperty(newCandidate, booleanPropertyName, booleanValue);
              }

              const datePropertyName = CSV_CANDIDATE_MAPPING_DATE_PROPERTIES[key];
              if (datePropertyName) {
                const date = parseCSVDate(dataRow[key]);
                setNestedProperty(newCandidate, datePropertyName, formatDateToddMMYYYY(date, "-"));
              }

              const countryPropertyName = CSV_CANDIDATE_MAPPING_COUNTRY_PROPERTIES[key];
              if (countryPropertyName) {
                const country = findCountry(dataRow[key].trim());
                if (country) {
                  setNestedProperty(newCandidate, countryPropertyName, country);
                }
              }
            });

            // Only import if we have the minimum required fields
            if (newCandidate.name && newCandidate.surname && newCandidate.email) {
              const cs = await loadCandidates(currentUser?.appUser, selectedCampaign, {
                email: { value: newCandidate.email, operator: "==" },
              });

              if (cs && Object.keys(cs).length === 0) {
                if (newCandidate.has_passport === undefined) {
                  newCandidate.has_passport = !!newCandidate.passportId;
                }

                // Apply verification if option is selected
                if (importOptions.setAsVerified) {
                  const now = new Date();
                  newCandidate.verification = {
                    verificationCode: Math.floor(100000 + Math.random() * 900000).toString(),
                    expiryDate: new Date(
                      now.getTime() + VERIFICATION_EXPIRY_HOURS * 60 * 60 * 1000
                    ).toISOString(),
                    verifiedOn: now.toISOString(),
                  };
                }

                // Set up confirmation code if option is selected and status is invited
                if (importOptions.sendConfirmationCode && importOptions.status === "invited") {
                  if (!newCandidate.verification) {
                    newCandidate.verification = {
                      verificationCode: Math.floor(100000 + Math.random() * 900000).toString(),
                      expiryDate: new Date(
                        Date.now() + VERIFICATION_EXPIRY_HOURS * 60 * 60 * 1000
                      ).toISOString(),
                    };
                  }
                  newCandidate.verification.confirmationCode = await generateConfirmationCode(
                    selectedCampaign
                  );
                  newCandidate.verification.confirmationSent = Date.now();
                }

                const candidateDoc: CandidateDoc = {
                  id: "", // Empty ID for new candidates
                  candidate: newCandidate,
                };

                const savedCandidate = await saveCandidate(
                  currentUser?.appUser,
                  candidateDoc,
                  true
                );
                if (savedCandidate) {
                  if (importOptions.sendConfirmationCode && importOptions.status === "invited") {
                    const sendResult = await sendEmailVerificationEmail(
                      [savedCandidate.id],
                      "candidate_confirmation"
                    );
                    if (sendResult.status !== 200) {
                      setProgress((prev) => ({
                        ...prev,
                        failedSends: [...prev.failedSends, savedCandidate.id],
                      }));
                    }
                  }
                }
              } else {
                // Add to duplicates list
                setProgress((prev) => ({
                  ...prev,
                  duplicateCandidates: [...prev.duplicateCandidates, newCandidate.email],
                }));
                console.log("Skipped candidate due to duplicate email:", newCandidate.email);
              }
            } else {
              const identifier = dataRow.email || dataRow.name || `Row ${progress.current + 1}`;
              setProgress((prev) => ({
                ...prev,
                missingCandidates: [...prev.missingCandidates, identifier],
              }));
              console.log("Skipped candidate due to missing required fields:", dataRow);
            }
          }

          setProgress((prev) => ({ ...prev, current: prev.current + 1 }));
        }
        setImportComplete(true);
      } catch (error) {
        console.error("Error reading CSV file:", error);
        alert("Error reading CSV file. Please make sure it is a valid CSV file.");
        setImportComplete(true);
      } finally {
        console.log("------- import complete -------");
      }
    }
  };

  // Reset import type to go back to selection screen
  const handleResetImportType = () => {
    setImportType(null);
    setFile(null);
    setErrors({ file: "", sponsor: "", agent: "", campaign: "" });
  };

  return (
    <TitledPage title="Import Recruiting Data">
      <Box maxWidth="md" margin="auto" sx={{}}>
        <Dialog open={showProgress} fullWidth maxWidth="sm">
          <DialogContent>
            <Box sx={{ width: "100%", textAlign: "center", py: 3 }}>
              {!importComplete ? (
                <>
                  <Typography variant="h6" gutterBottom>
                    Importing Data...
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Processing record {progress.current} of {progress.total}
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={(progress.current / progress.total) * 100}
                    sx={{ mt: 2 }}
                  />
                </>
              ) : (
                <>
                  <Typography variant="h6" gutterBottom color="success.main">
                    Import Complete
                  </Typography>
                  <Box sx={{ mt: 2, mb: 3 }}>
                    <Typography variant="body1" gutterBottom>
                      Total records processed: {progress.total}
                    </Typography>
                    {progress.missingCandidates.length > 0 && (
                      <Box sx={{ mt: 2 }}>
                        <Typography variant="body1" color="error" gutterBottom>
                          Missing candidates ({progress.missingCandidates.length}):
                        </Typography>
                        <Box
                          sx={{
                            maxHeight: 150,
                            overflow: "auto",
                            bgcolor: "#f5f5f5",
                            p: 1,
                            borderRadius: 1,
                          }}
                        >
                          {progress.missingCandidates.map((candidate, index) => (
                            <Typography key={index} variant="body2" gutterBottom>
                              {candidate}
                            </Typography>
                          ))}
                        </Box>
                      </Box>
                    )}
                    {progress.duplicateCandidates.length > 0 && (
                      <Box sx={{ mt: 2 }}>
                        <Typography variant="body1" color="warning.main" gutterBottom>
                          Duplicate candidates ({progress.duplicateCandidates.length}):
                        </Typography>
                        <Box
                          sx={{
                            maxHeight: 150,
                            overflow: "auto",
                            bgcolor: "#f5f5f5",
                            p: 1,
                            borderRadius: 1,
                          }}
                        >
                          {progress.duplicateCandidates.map((candidate, index) => (
                            <Typography key={index} variant="body2" gutterBottom>
                              {candidate}
                            </Typography>
                          ))}
                        </Box>
                      </Box>
                    )}
                  </Box>
                  <Button variant="contained" onClick={handleCloseDialog} sx={{ mt: 2 }}>
                    Close
                  </Button>
                </>
              )}
            </Box>
          </DialogContent>
        </Dialog>

        {importType === null ? (
          // Import type selection screen
          <Box sx={{ textAlign: "center", py: 4 }}>
            <Typography variant="h5" gutterBottom sx={{ mb: 4 }}>
              Select Import Type
            </Typography>
            <Stack direction="row" spacing={4} justifyContent="center" sx={{ mb: 4 }}>
              <Button
                variant="contained"
                size="large"
                onClick={() => setImportType("candidates")}
                sx={{ minWidth: 200, py: 2 }}
              >
                Import Candidates
              </Button>
              <Button
                variant="contained"
                size="large"
                onClick={() => setImportType("language_tests")}
                sx={{ minWidth: 200, py: 2 }}
              >
                Import Language Tests
              </Button>
            </Stack>
          </Box>
        ) : (
          // Import form based on selected type
          <Card sx={{ padding: "2rem", border: "solid 1px #DFDFDF" }} variant="outlined">
            <CardContent sx={{ padding: 0 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
                <Typography variant="h6">
                  {importType === "candidates"
                    ? "Import Candidates"
                    : "Import Language Test Results"}
                </Typography>
                <Button variant="outlined" size="small" onClick={handleResetImportType}>
                  Change Import Type
                </Button>
              </Box>

              <Grid container gap={1}>
                <Grid item xs={12}>
                  <TextField
                    type="file"
                    error={!!errors.file}
                    helperText={errors.file}
                    id="outlined-basic"
                    label="Import file"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ accept: ".csv" }}
                    onChange={handleFileChange}
                  />
                </Grid>
                {currentUser && currentUser.appUser && !currentUser.appUser.sponsorId && (
                  <React.Fragment>
                    <Grid item xs={12}>
                      <Divider textAlign="left">Assign a sponsor</Divider>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={"sponsorId"}
                        label={"Sponsor"}
                        select
                        error={!!errors.sponsor}
                        helperText={errors.sponsor}
                        onChange={onInputComboChange}
                        value={selectedSponsor || ""}
                        required
                        fullWidth
                        margin={"dense"}
                      >
                        <MenuItem value="" key="no_sponsor">
                          <Typography fontStyle={"italic"}>no sponsor selected</Typography>
                        </MenuItem>
                        {Object.entries(sponsors).map(([sponsorId, sponsor]) => {
                          return (
                            <MenuItem value={sponsorId} key={sponsorId}>
                              {sponsor.name}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Grid>
                  </React.Fragment>
                )}

                {currentUser && currentUser.appUser && !currentUser.appUser.agentId && (
                  <React.Fragment>
                    <Grid item xs={12}>
                      <Divider textAlign="left">Assign an agent</Divider>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={"agentId"}
                        label={"Agent"}
                        select
                        error={!!errors.agent}
                        helperText={errors.agent}
                        onChange={onInputComboChange}
                        value={selectedAgent || ""}
                        required
                        fullWidth
                        margin={"dense"}
                      >
                        <MenuItem value="" key="no_agent">
                          <Typography fontStyle={"italic"}>no agent selected</Typography>
                        </MenuItem>
                        {Object.entries(agents).map(([agentId, agent]) => {
                          return (
                            <MenuItem value={agentId} key={agentId}>
                              {agent.company}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Grid>
                  </React.Fragment>
                )}
                <Grid item xs={12}>
                  <Divider textAlign="left">Assign a campaign</Divider>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name={"campaignId"}
                    label={"Campaign"}
                    select
                    error={!!errors.campaign}
                    helperText={errors.campaign}
                    onChange={onInputComboChange}
                    value={selectedCampaign || ""}
                    required
                    fullWidth
                    margin={"dense"}
                  >
                    <MenuItem value="" key="no_campaign">
                      <Typography fontStyle={"italic"}>no campaign selected</Typography>
                    </MenuItem>
                    {Object.entries(campaigns)
                      .sort((a, b) => a[1].name.localeCompare(b[1].name))
                      .map(([campaignId, campaign]) => {
                        return (
                          <MenuItem value={campaignId} key={campaignId}>
                            {campaign.name}
                          </MenuItem>
                        );
                      })}
                  </TextField>
                </Grid>

                {importType === "language_tests" ? (
                  // Language test specific options
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={importOptions.setToInvited}
                          onChange={handleOptionChange}
                          name="setToInvited"
                          disabled={showProgress}
                        />
                      }
                      label="Automatically set invitation status for successful candidates"
                    />
                  </Grid>
                ) : (
                  // Candidate import specific options
                  <Grid item xs={12}>
                    <Divider textAlign="left">Candidate Options</Divider>
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                      <Grid item xs={12} md={3}>
                        <TextField
                          name="status"
                          label="Initial Status"
                          select
                          value={importOptions.status}
                          onChange={handleStatusChange}
                          fullWidth
                          margin="dense"
                        >
                          {CANDIDATE_STATUS_LIST.map((statusOption) => (
                            <MenuItem key={statusOption.key} value={statusOption.key}>
                              {statusOption.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={importOptions.setAsVerified}
                              onChange={handleOptionChange}
                              name="setAsVerified"
                              disabled={showProgress}
                            />
                          }
                          label="Automatically set candidates as verified"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={importOptions.sendConfirmationCode}
                              onChange={handleOptionChange}
                              name="sendConfirmationCode"
                              disabled={showProgress || importOptions.status !== "invited"}
                            />
                          }
                          label="Automatically generate and send confirmation code"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                {importType === "language_tests" && (
                  <Grid item xs={12} md={3} sx={{ paddingLeft: 3 }}>
                    <TextField
                      name={"score_percent"}
                      label={"Score Threshold"}
                      type="number"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }}
                      onChange={onInputComboChange}
                      value={importOptions.scorePercent || ""}
                    />
                  </Grid>
                )}

                <Grid item xs={12} sx={{ paddingTop: 2, textAlign: "center" }}>
                  <Button
                    variant="contained"
                    onClick={
                      importType === "language_tests" ? handleImportTests : handleImportCandidates
                    }
                  >
                    {importType === "language_tests" ? "Import Test Results" : "Import Candidates"}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}
      </Box>
    </TitledPage>
  );
};

export default RecruitingImportPage;
