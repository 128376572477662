import { Button, Grid, Typography, CircularProgress, Box } from "@mui/material";
import { Alert, DialogActions } from "@mui/material";
import { useContext, useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

import { AuthContext } from "../../components/auth-provider";
import { CandidatesDict, VERIFICATION_EXPIRY_HOURS } from "../../types/recruiting";
import { Snackbar } from "@mui/material";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { generateConfirmationCode, saveCandidate } from "../../data-functions/recruiting_api";
import { getServerTime } from "../../data-functions/common-api";
import { sendEmailVerificationEmail } from "../../utils/candidate-utils";

interface ResetVerificationDialogProps {
  candidates: CandidatesDict;
  open: boolean;
  onClose: () => void;
}

const SendConfirmationCodeDialog = ({
  candidates: initialCandidates,
  open,
  onClose,
}: ResetVerificationDialogProps) => {
  const [openSnackError, setOpenSnackError] = useState(false);
  const [candidates, setCandidates] = useState<CandidatesDict>({});
  const [sendEmail, setSendEmail] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [progress, setProgress] = useState(0);
  const [failedSends, setFailedSends] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { currentUser } = useContext(AuthContext)!;

  useEffect(() => {
    const newCandidates = { ...initialCandidates };
    setActivePage(1);
    setSendEmail(true);
    setCandidates(newCandidates);
  }, [initialCandidates]);

  const handleCloseSnack = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackError(false);
  };

  const handleClose = () => {
    onClose();
  };

  const SendConfirmationCode = async () => {
    setIsLoading(true);
    try {
      setProgress(0);
      setActivePage(2);
      setFailedSends([]);
      const candidateIds = Object.keys(candidates);
      const numCandidates = candidateIds.length;
      let candidateIdToMail: string[] = [];

      const serverTime = await getServerTime();

      for (let i = 0; i < numCandidates; i++) {
        const candidateId = candidateIds[i];
        const candidate = candidates[candidateId];
        console.log("candidate: ", candidate);
        if (candidate && candidate.campaignId) {
          if (!candidate.verification) {
            console.log("creating verification object");
            candidate.verification = {
              verificationCode: "",
              expiryDate: "",
            };
          }

          if (!candidate.verification.verificationCode) {
            console.log("generating verification code");
            candidate.verification.verificationCode = uuidv4();
            candidate.verification.expiryDate = new Date(
              serverTime.timestamp + VERIFICATION_EXPIRY_HOURS * 60 * 60 * 1000
            ).toISOString();
          }

          console.log("candidate.verification: ", candidate.verification.confirmationCode);
          if (!candidate.verification.confirmationCode) {
            console.log("generating confirmation code");
            candidate.verification.confirmationCode = await generateConfirmationCode(
              candidate.campaignId || ""
            );
          }

          await saveCandidate(currentUser?.appUser, { id: candidateId, candidate: candidate });

          const sendResult = await sendEmailVerificationEmail(
            [candidateId],
            "candidate_confirmation"
          );
          console.log("message send result: ", sendResult);
          if (sendResult.status !== 200) {
            candidateIdToMail.push(candidateIds[i]);
          }
          setProgress(i + 1);
        }
        setFailedSends(candidateIdToMail);

        // if (candidateIdToMail.length > 0) {
        //   console.log("sending email");
        //   sendEmailVerificationEmail(candidateIdToMail, "candidate_confirmation").then(
        //     (response) => {
        //       setActivePage(3);
        //     },
        //     (error) => {
        //       setActivePage(3);
        //     }
        //   );
        // }
      }
    } catch (error) {
      setOpenSnackError(true);
    } finally {
      setIsLoading(false);
      setActivePage(3);
    }
  };

  const getActivePage = () => {
    switch (activePage) {
      case 1:
        return (
          <Grid container gap={2}>
            <Grid item xs={12}>
              <Typography fontWeight="bold">
                {candidates && Object.keys(candidates).length > 0
                  ? Object.keys(candidates).length + " "
                  : "No "}
                candidates will be sent confirmation codes.
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="primary"
                disabled={Object.keys(candidates).length === 0 || isLoading}
                sx={{ marginTop: "16px" }}
                onClick={SendConfirmationCode}
              >
                {isLoading ? "Sending..." : "Send confirmation codes"}
              </Button>
            </Grid>
            <Grid item xs={4} />
          </Grid>
        );
      case 2:
        return (
          <Grid container gap={2}>
            <Grid item xs={12}>
              <CircularProgress />
              <Typography>
                Sending confirmation codes {progress} of {Object.keys(candidates).length}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {/* <CircularProgress variant="determinate" value={progress} /> */}
            </Grid>
          </Grid>
        );

      case 3:
        return (
          <Grid container gap={2}>
            <Grid item xs={12}>
              <Typography>
                {"An email with the confirmation code has been sent to the candidates"}.
                {failedSends.length > 0 && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="body1" color="error" gutterBottom>
                      Failed to send to candidates ({failedSends.length}):
                    </Typography>
                    <Box
                      sx={{
                        maxHeight: 150,
                        overflow: "auto",
                        bgcolor: "#f5f5f5",
                        p: 1,
                        borderRadius: 1,
                      }}
                    >
                      {failedSends.map((candidateId) => (
                        <Typography key={"ci-" + candidateId} variant="body2" gutterBottom>
                          {candidates[candidateId].email || "No email found"}
                        </Typography>
                      ))}
                    </Box>
                  </Box>
                )}
              </Typography>
            </Grid>
          </Grid>
        );
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"lg"}>
      <DialogTitle>Send Confirmation Codes</DialogTitle>
      <DialogContent sx={{ minHeight: "100px", width: "700px" }}>
        {getActivePage()}

        <Snackbar open={openSnackError} autoHideDuration={1000} onClose={handleCloseSnack}>
          <Alert onClose={handleCloseSnack} severity="error" sx={{ width: "100%" }}>
            The server encountered an error.
          </Alert>
        </Snackbar>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SendConfirmationCodeDialog;
