const ru = {
  // ------------ General variables ------------
  radio_yes: "Да",
  radio_no: "Нет",
  radio_undefined: "Пожалуйста, выберите хотя бы один вариант!",
  travel_reason_work: "Работа",
  travel_reason_tourism: "Туризм",
  travel_reason_study: "Обучение",
  travel_reason_transit: "Транзит",

  // ------------ Relatives relationship ------------
  relative_reason_none: "выберите одну опцию",
  relative_reason_adoped_child: "Усыновленный ребенок",
  relative_reason_brother: "Брат",
  relative_reason_brother_in_law: "Шурин",
  relative_reason_child: "Ребенок",
  relative_reason_child_spouce: "Невестка / Зять",
  relative_reason_daughter: "Дочь",
  relative_reason_daughter_in_law: "Невестка",
  relative_reason_father: "Отец",
  relative_reason_father_in_law: "Свекор",
  relative_reason_grandchild: "Внук",
  relative_reason_grandparent: "Бабушка / Дедушка",
  relative_reason_husband: "Муж",
  relative_reason_mother: "Мать",
  relative_reason_mother_in_law: "Свекровь",
  relative_reason_parent: "Родитель",
  relative_reason_sister: "Сестра",
  relative_reason_sister_in_law: "Золовка",
  relative_reason_son: "Сын",
  relative_reason_son_in_law: "Зять",
  relative_reason_step_child: "Приемный ребенок",
  relative_reason_step_parents: "Приемные родители",
  relative_reason_step_sibling: "Приемный брат / сестра",
  relative_reason_unmarried_partner: "Неженатый партнер",
  relative_reason_wife: "Жена",

  // ------------ Relatives Type of Stay ------------
  typeofstay_none: "выберите одну опцию",
  typeofstay_temporary: "Временная виза",
  typeofstay_permanently: "С постоянным пребыванием",
  typeofstay_other: "Нет визы и нет постоянного пребывания",
  typeofstay_cannotcontact: "Не могу связаться с ними",

  // ------------ Steps ------------
  step_0: "Начало",
  step_1: "Общая информация",
  step_2: "Семейное положение",
  step_3: "Поездки",
  step_4: "Дополнительная информация",
  step_5: "Конец",

  step_text_finished: "Все данные заполнены - вы готовы!",
  button_next: "Следующая",
  button_back: "Предыдущая",
  button_finish: "Завершить",

  // ------------ Dividers ------------
  divider_general_information: "Общая информация",
  divider_birth_information: "Информация о рождении",
  divider_passport_information: "Паспортная информация",
  divider_residency_information: "Информация о месте жительства",
  divider_phone_information: "Телефон",
  //
  divider_marital_status: "Семейное положение",
  divider_children: "Дети",
  divider_partner_details: "Данные о партнере",
  divider_parents: "Данные о родителях",
  divider_mother_details: "Данные о матери",
  divider_father_details: "Данные об отце",
  divider_immergency_contact: "Контакт в случае чрезвычайной ситуации",
  divider_additional_information: "Дополнительная информация",
  divider_relatives_UK: "Родственники в Великобритании",
  //
  divider_uk_status: "Информация о Великобритании",
  divider_travel_UK: "Поездки в Великобританию",
  divider_travel_abroad: "Заграничные поездки",
  divider_final_provisions: "Заключительные положения",
  divider_data_privacy: "Защита личных данных",
  divider_sufficient_funds: "Достаточные средства",

  // ------------- Errors -------------
  email_error: "Электронная почта обязательна!",

  // ------------- Welcome Page -------------
  welcome_title: "Работа в Великобритании с визой",
  welcome_hint1:
    "Пожалуйста, укажите ответы на вопросы латинскими буквами. Вопросы, отмеченные звездочкой (*), обязательны для заполнения.",
  welcome_hint2:
    "Наша услуга БЕСПЛАТНАЯ! Пожалуйста, не платите никому! Сбор за визу составляет 298 британских фунтов стерлингов (приблизительно 363 EUR/ 386 USD), а также сбор TLS (в некоторых странах), который оплачивается карточкой Visa или Mastercard непосредственно в Визовом центре Великобритании UKVI. У нас нет агентов! Заявки подаются напрямую!",

  // ------------- General Information Page -------------
  name: "Имя",
  name_error: "Имя обязательно и должно содержать только буквы!",
  surname: "Фамилия",
  surname_error: "Фамилия обязательна и должна содержать только буквы!",
  maiden_name: "Девичья фамилия (если есть)",
  maiden_name_error: "Девичья фамилия должна содержать только буквы!",
  gender: "Пол",
  gender_error: "Пол обязателен!",
  sex_male: "Мужской",
  sex_female: "Женский",
  sex_diverse: "Другой",
  birth_date: "Дата рождения",
  birth_date_error: "Требуется дата рождения, которая должна быть как минимум 17 лет назад.",
  birth_place: "Место рождения (город)",
  birth_place_eror: "Место рождения обязательно!",
  pass_id: "Номер паспорта",
  pass_id_error: "Номер паспорта обязателен и должен содержать только буквы и цифры!",
  pass_issuing_place: "Город выдачи",
  pass_issuing_place_error: "Место выдачи обязательно и должно содержать только буквы!",
  pass_issue_date: "Дата выдачи",
  pass_issue_date_error: "Дата выдачи паспорта обязательна и должна быть в прошлом.",
  pass_expiry_date: "Дата окончания",
  pass_expiry_date_error: "Дата окончания паспорта обязательна и должна быть в будущем!",
  biometric_pass_error: "Пожалуйста, выберите тип вашего паспорта!",
  full_address: "Домашний адрес (улица, дом, город)",
  residency_street: "Домашний адрес (улица, дом, этаж, квартира)",
  residency_street_error: "Домашний адрес обязателен!",
  residency_city: "Домашний адрес (город/посёлок)",
  residency_city_error: "Домашний адрес обязателен и должен содержать только буквы!",
  residency_province: "Область / Регион",
  residency_province_error: "Область должна содержать только буквы!",
  residency_zip: "Почтовый индекс",
  residency_zip_error: "Почтовый индекс обязателен!",
  residency_date_since: "С какого времени вы проживаете по этому адресу?",
  residency_date_since_error: "Дата заселения обязательна и должна быть в прошлом!",
  pass_biometric: "У вас есть биометрический паспорт?",
  nationality: "Гражданство",
  nationality_error: "Гражданство обязательно!",
  additional_nationality: "У вас есть другое гражданство?",
  additional_nationality_error: "",
  birth_country: "Страна рождения",
  birth_country_error: "Страна рождения обязательна!",
  pass_issuing_country: "Страна выдачи",
  pass_issuing_country_error: "Страна выдачи обязательна!",
  residency_country: "Страна проживания",
  residency_country_error: "Страна проживания обязательна!",
  ownership_own: "Моя собственность",
  ownership_rent: "Арендовано",
  ownership_other: "Другое",
  residency_ownership: "Это ваше жилье?",
  residency_ownership_error: "Пожалуйста, выберите соответствующий статус для этого жилья!",
  residency_ownership_other: "Уточните, пожалуйста",
  residency_ownership_other_error: "Пожалуйста, укажите ваше отношение к жилью латинскими буквами!",
  phone: "Номер телефона",
  phone_error: "Номер телефона обязателен и должен содержать только цифры!",

  // ------------- Family Information Page -------------
  marital_status: "Семейное положение",
  marital_status_error: "Семейное положение обязательно!",
  marital_status_single: "Не женат (не замужем)",
  marital_status_married: "Женат (замужем)",
  marital_status_divorced: "Разведен (разведена)",
  has_children: "У вас есть дети?",
  has_children_error: "Данные хотя бы об одном ребенке обязательны!",
  child_0_name: "Имя первого ребенка",
  child_1_name: "Имя второго ребенка",
  child_2_name: "Имя третьего ребенка",
  child_3_name: "Имя четвертого ребенка",
  child_0_surname: "Фамилия первого ребенка",
  child_1_surname: "Фамилия второго ребенка",
  child_2_surname: "Фамилия третьего ребенка",
  child_3_surname: "Фамилия четвертого ребенка",
  child_0_birthday: "Дата рождения первого ребенка",
  child_1_birthday: "Дата рождения второго ребенка",
  child_2_birthday: "Дата рождения третьего ребенка",
  child_3_birthday: "Дата рождения четвертого ребенка",
  child_0_lives_home: "Живет ли с вами",
  child_1_lives_home: "Живет ли с вами",
  child_2_lives_home: "Живет ли с вами",
  child_3_lives_home: "Живет ли с вами",
  //
  child_0_name_error: "Имя ребенка обязательно и должно содержать только буквы!",
  child_1_name_error: "Имя ребенка обязательно и должно содержать только буквы!",
  child_2_name_error: "Имя ребенка обязательно и должно содержать только буквы!",
  child_3_name_error: "Имя ребенка обязательно и должно содержать только буквы!",
  child_0_surname_error: "Фамилия ребенка обязательна и должна содержать только буквы!",
  child_1_surname_error: "Фамилия ребенка обязательна и должна содержать только буквы!",
  child_2_surname_error: "Фамилия ребенка обязательна и должна содержать только буквы!",
  child_3_surname_error: "Фамилия ребенка обязательна и должна содержать только буквы!",
  child_0_birthday_error: "Необходимо указать дату рождения ребенка!",
  child_1_birthday_error: "Необходимо указать дату рождения ребенка!",
  child_2_birthday_error: "Необходимо указать дату рождения ребенка!",
  child_3_birthday_error: "Необходимо указать дату рождения ребенка!",
  //
  partner_name: "Имя",
  partner_name_error: "Имя обязательно и должно содержать только буквы!",
  partner_surname: "Фамилия",
  partner_surname_error: "Фамилия обязательна и должна содержать только буквы!",
  partner_nationality: "Гражданство",
  partner_nationality_error: "Гражданство обязательно!",
  partner_birthday: "Дата рождения",
  partner_birthday_error: "Требуется дата рождения, которая должна быть как минимум 17 лет назад.",
  partner_birth_place: "Место рождения (город)",
  partner_birth_place_error: "Место рождения обязательно!",
  partner_birth_country: "Страна рождения",
  partner_birth_country_error: "Страна рождения обязательна!",
  partner_livesHome: "Партнер живет с вами?",
  partner_livesHome_error: "Пожалуйста, выберите подходящий вариант!",
  //
  parents_unknown: "Не могу предоставить информацию о своих родителях",
  //
  mother_name: "Имя",
  mother_name_error: "Имя обязательно и должно содержать только буквы!",
  mother_surname: "Фамилия",
  mother_surname_error: "Фамилия обязательна и должна содержать только буквы!",
  mother_nationality: "Гражданство",
  mother_nationality_error: "Гражданство обязательно!",
  mother_birthday: "Дата рождения",
  mother_birthday_error: "Требуется дата рождения, которая должна быть как минимум 17 лет назад.",
  mother_birthPlace: "Место рождения (город)",
  mother_birthPlace_error: "Место рождения обязательно!",
  mother_birthCountry: "Страна рождения",
  mother_birthCountry_error: "Страна рождения обязательна!",
  //
  father_name: "Имя",
  father_name_error: "Имя обязательно и должно содержать только буквы!",
  father_surname: "Фамилия",
  father_surname_error: "Фамилия обязательна и должна содержать только буквы!",
  father_nationality: "Гражданство",
  father_nationality_error: "Гражданство обязательно!",
  father_birthday: "Дата рождения",
  father_birthday_error: "Требуется дата рождения, которая должна быть как минимум 17 лет назад.",
  father_birthPlace: "Место рождения (город)",
  father_birthPlace_error: "Место рождения обязательно!",
  father_birthCountry: "Страна рождения",
  father_birthCountry_error: "Страна рождения обязательна!",
  //
  immergency_contact_name: "Имя контактного лица",
  immergency_contact_name_error:
    "Имя контактного лица обязательно и должно содержать только буквы!",
  immergency_contact_phone: "Номер телефона",
  immergency_contact_phone_error: "Номер телефона обязателен и должен содержать только цифры!",
  immergency_contact_relation: "Как вы связаны с этим контактом?",
  immergency_contact_relation_error: "Отношение обязательно!",
  immergency_contact_relation_relatives: "Родственник",
  immergency_contact_relation_parent: "Родитель",
  immergency_contact_relation_friend: "Друг",
  //
  children_travelling: "Дети будут путешествовать с вами?",
  //
  has_relatives_in_UK: "Имеете ли родственников в Великобритании?",
  has_relatives_in_UK_error: "Требуется указать информация хотя бы одного родственника!",

  relative_0_name: "Имя первого родственника",
  relative_1_name: "Имя второго родственника",
  relative_2_name: "Имя третьего родственника",
  relative_3_name: "Имя четвертого родственника",

  relative_0_surname: "Фамилия первого родственника",
  relative_1_surname: "Фамилия второго родственника",
  relative_2_surname: "Фамилия третьего родственника",
  relative_3_surname: "Фамилия четвертого родственника",

  relative_0_nationality: "Национальность первого родственника",
  relative_1_nationality: "Национальность второго родственника",
  relative_2_nationality: "Национальность третьего родственника",
  relative_3_nationality: "Национальность четвертого родственника",

  relative_0_relation: "Ваша связь с первым родственником",
  relative_1_relation: "Ваша связь со вторым родственником",
  relative_2_relation: "Ваша связь с третьим родственником",
  relative_3_relation: "Ваша связь с четвертым родственником",

  relative_0_typeofstay: "Статус пребывания первого родственника",
  relative_1_typeofstay: "Статус пребывания второго родственника",
  relative_2_typeofstay: "Статус пребывания третьего родственника",
  relative_3_typeofstay: "Статус пребывания четвертого родственника",

  relative_0_passportNumber: "Номер паспорта первого родственника",
  relative_1_passportNumber: "Номер паспорта второго родственника",
  relative_2_passportNumber: "Номер паспорта третьего родственника",
  relative_3_passportNumber: "Номер паспорта четвертого родственника",

  relative_0_note: "Подробный описание ситуации",
  relative_1_note: "Подробный описание ситуации",
  relative_2_note: "Подробный описание ситуации",
  relative_3_note: "Подробный описание ситуации",

  relative_0_name_error: "Имя обязательно и должно содержать только буквы!",
  relative_1_name_error: "Имя обязательно и должно содержать только буквы!",
  relative_2_name_error: "Имя обязательно и должно содержать только буквы!",
  relative_3_name_error: "Имя обязательно и должно содержать только буквы!",

  relative_0_surname_error: "Фамилия обязательна и должна содержать только буквы!",
  relative_1_surname_error: "Фамилия обязательна и должна содержать только буквы!",
  relative_2_surname_error: "Фамилия обязательна и должна содержать только буквы!",
  relative_3_surname_error: "Фамилия обязательна и должна содержать только буквы!",

  relative_0_nationality_error: "Гражданство обязательно!",
  relative_1_nationality_error: "Гражданство обязательно!",
  relative_2_nationality_error: "Гражданство обязательно!",
  relative_3_nationality_error: "Гражданство обязательно!",

  relative_0_relation_error: "Выберите свою связь с родственником!",
  relative_1_relation_error: "Выберите свою связь с родственником!",
  relative_2_relation_error: "Выберите свою связь с родственником!",
  relative_3_relation_error: "Выберите свою связь с родственником!",

  relative_0_typeofstay_error: "Выберите статус пребывания!",
  relative_1_typeofstay_error: "Выберите статус пребывания!",
  relative_2_typeofstay_error: "Выберите статус пребывания!",
  relative_3_typeofstay_error: "Выберите статус пребывания!",

  relative_0_passportNumber_error: "Номер паспорта обязателен!",
  relative_1_passportNumber_error: "Номер паспорта обязателен!",
  relative_2_passportNumber_error: "Номер паспорта обязателен!",
  relative_3_passportNumber_error: "Номер паспорта обязателен!",

  relative_0_note_error: "Подробный описание обязателен!",
  relative_1_note_error: "Подробный описание обязателен!",
  relative_2_note_error: "Подробный описание обязателен!",
  relative_3_note_error: "Подробный описание обязателен!",

  // ------------- Travel Information Page -------------
  UK_insurance: "Имеете ли национальный страховой номер в Великобритании?",
  UK_insurance_number: "Национальный страховой номер",
  UK_insurance_number_error: "Пожалуйста, введите ваш национальный страховой номер!",
  visited_UK_doctor: "Посещали ли вы врача в Великобритании?",
  granted_UK_visa: "Выдавалась ли вам виза в Великобританию?",
  refused_visa: "Отказывали ли вам виза в Великобританию ранее?",
  refused_entry: "Вам отказывали во въезде в Великобританию?",
  has_worked_illegally: "Работали ли вы нелегально в Великобритании?",
  required_to_leave_UK: "Были ли вы депортированы из Великобритании?",
  has_applied_for_visa:
    "Подавали ли вы заявление на статус или пребывание в Великобритании за последние 10 лет?",
  //
  has_travelled_to_UK: "Вы ранее ездили в Великобританию?",
  has_travelled_to_UK_error: "Необходимо указать хотя бы одну поездку!",
  travel_uk_hint: "Укажите ваши последние 3 поездки в Великобританию.",
  //
  traveluk_0_from: "Дата начала первой поездки",
  traveluk_1_from: "Дата начала второй поездки",
  traveluk_2_from: "Дата начала третьей поездки",
  traveluk_3_from: "Дата начала четвёртой поездки",

  traveluk_0_to: "Дата окончания первой поездки",
  traveluk_1_to: "Дата окончания второй поездки",
  traveluk_2_to: "Дата окончания третьей поездки",
  traveluk_3_to: "Дата окончания четвёртой поездки",

  traveluk_0_reason: "Цель первой поездки",
  traveluk_1_reason: "Цель второй поездки",
  traveluk_2_reason: "Цель третьей поездки",
  traveluk_3_reason: "Цель четвёртой поездки",

  traveluk_0_from_error: "Необходимо указать дату начала вашего пребывания в Великобритании!",
  traveluk_1_from_error: "Необходимо указать дату начала вашего пребывания в Великобритании!",
  traveluk_2_from_error: "Необходимо указать дату начала вашего пребывания в Великобритании!",
  traveluk_3_from_error: "Необходимо указать дату начала вашего пребывания в Великобритании!",

  traveluk_0_to_error: "Необходимо указать дату окончания вашего пребывания в Великобритании!",
  traveluk_1_to_error: "Необходимо указать дату окончания вашего пребывания в Великобритании!",
  traveluk_2_to_error: "Необходимо указать дату окончания вашего пребывания в Великобритании!",
  traveluk_3_to_error: "Необходимо указать дату окончания вашего пребывания в Великобритании!",

  traveluk_0_reason_error: "Необходимо указать цель вашего пребывания в Великобритании!",
  traveluk_1_reason_error: "Необходимо указать цель вашего пребывания в Великобритании!",
  traveluk_2_reason_error: "Необходимо указать цель вашего пребывания в Великобритании!",
  traveluk_3_reason_error: "Необходимо указать цель вашего пребывания в Великобритании!",
  //
  refused_visa_abroad: "Вам отказывали визу в какую-либо страну?",
  deported_abroad: "Вы были депортированы из какой-либо страны?",
  deported_abroad_details:
    "Если вы были депортированы из другой страны, пожалуйста, укажите подробности",
  deported_abroad_details_error:
    'Если вы ответили "да" на предыдущий вопрос, необходимо предоставить подробную информацию!',

  //
  has_travelled_abroad:
    "Вы путешествовали в какие-либо страны, кроме Великобритании, за последние 10 лет?",
  has_travelled_abroad_error: "Необходимо указать хотя бы одну поездку!",
  destination_0: "Первая страна",
  destination_1: "Вторая страна",
  destination_2: "Третья страна",
  destination_3: "Четвертая страна",
  destination_4: "Пятая страна",
  travelabroad_0_from: "Дата начала первой поездки",
  travelabroad_1_from: "Дата начала второй поездки",
  travelabroad_2_from: "Дата начала третьей поездки",
  travelabroad_3_from: "Дата начала четвертой поездки",
  travelabroad_4_from: "Дата начала пятой поездки",
  travelabroad_0_to: "Дата окончания первой поездки",
  travelabroad_1_to: "Дата окончания второй поездки",
  travelabroad_2_to: "Дата окончания третьей поездки",
  travelabroad_3_to: "Дата окончания четвертой поездки",
  travelabroad_4_to: "Дата окончания пятой поездки",

  travelabroad_0_reason: "Причина первой поездки",
  travelabroad_1_reason: "Причина второй поездки",
  travelabroad_2_reason: "Причина третьей поездки",
  travelabroad_3_reason: "Причина четвертой поездки",
  travelabroad_4_reason: "Причина пятой поездки",

  destination_0_error:
    "Необходимо указать страну, в которой вы пребывали! Для Великобритании, пожалуйста, используйте предыдущий раздел.",
  destination_1_error:
    "Необходимо указать страну, в которой вы пребывали! Для Великобритании, пожалуйста, используйте предыдущий раздел.",
  destination_2_error:
    "Необходимо указать страну, в которой вы пребывали! Для Великобритании, пожалуйста, используйте предыдущий раздел.",
  destination_3_error:
    "Необходимо указать страну, в которой вы пребывали! Для Великобритании, пожалуйста, используйте предыдущий раздел.",
  destination_4_error:
    "Необходимо указать страну, в которой вы пребывали! Для Великобритании, пожалуйста, используйте предыдущий раздел.",
  travelabroad_0_from_error: "Необходимо указать дату начала вашего пребывания в прошлом!",
  travelabroad_1_from_error: "Необходимо указать дату начала вашего пребывания в прошлом!",
  travelabroad_2_from_error: "Необходимо указать дату начала вашего пребывания в прошлом!",
  travelabroad_3_from_error: "Необходимо указать дату начала вашего пребывания в прошлом!",
  travelabroad_4_from_error: "Необходимо указать дату начала вашего пребывания в прошлом!",
  travelabroad_0_to_error: "Необходимо указать дату окончания вашего пребывания в прошлом!",
  travelabroad_1_to_error: "Необходимо указать дату окончания вашего пребывания в прошлом!",
  travelabroad_2_to_error: "Необходимо указать дату окончания вашего пребывания в прошлом!",
  travelabroad_3_to_error: "Необходимо указать дату окончания вашего пребывания в прошлом!",
  travelabroad_4_to_error: "Необходимо указать дату окончания вашего пребывания в прошлом!",
  travelabroad_0_reason_error: "Необходимо указать причину вашего пребывания за границей!",
  travelabroad_1_reason_error: "Необходимо указать причину вашего пребывания за границей!",
  travelabroad_2_reason_error: "Необходимо указать причину вашего пребывания за границей!",
  travelabroad_3_reason_error: "Необходимо указать причину вашего пребывания за границей!",
  travelabroad_4_reason_error: "Необходимо указать причину вашего пребывания за границей!",

  // ------------- Additional Information Page -------------
  convictions: "Были ли вы осуждены? (включая нарушения правил дорожного движения)",
  war_crimes: "Участвовали ли вы в военных конфликтах?",
  terrorist_org_member: "Участвовали/поддерживали ли вы террористические организации?",
  not_good_character:
    "Участвовали ли вы в деятельности, которая может создать негативное впечатление о вас?",
  application_additional_information:
    "Желаете ли предоставить дополнительную информацию в рамках этой заявки?",
  application_additional_information_error: "Пожалуйста, используйте только латинские буквы",
  work_experience: "Пожалуйста, опишите ваш опыт работы",
  work_experience_error: "Пожалуйста, используйте только латинские буквы",
  has_health_issues: "У вас есть проблемы со здоровьем/аллергии? (Пожалуйста, опишите)",
  has_health_issues_error: "Пожалуйста, используйте только латинские буквы",
  english_level: "Уровень владения английским языком",
  english_level_error: "Пожалуйста, укажите ваш уровень владения английским языком!",
  language_level_none: "Отсутствует",
  language_level_beginner: "Начальный",
  language_level_intermediate: "Средний",
  language_level_advanced: "Продвинутый",
  has_driving_license: "Имеете ли вы водительское удостоверение?",
  has_driving_license_error: "Пожалуйста, укажите, есть ли у вас водительское удостоверение!",
  //
  privacy_hint:
    "Почему нам нужны ваши личные данные и как мы их обрабатываем? " +
    "Hops Labour Solutions является администратором ваших личных данных " +
    "и обрабатывает их в соответствии с Общим регламентом по защите персональных данных (GDPR). " +
    "Сбор ваших личных данных необходим в связи с вашей кандидатурой " +
    "на участие в наших программах. Здесь вы можете прочитать подробную информацию " +
    "о нашей Политике конфиденциальности: ",
  privacy_policy:
    "Подтверждаю, что я подробно ознакомлен/а, понимаю " +
    "и соглашаюсь с политикой конфиденциальности",
  privacy_policy_error:
    "Пожалуйста, примите политику конфиденциальности, " +
    "чтобы мы могли продолжить работу с вашей кандидатурой!",
  //
  sufficient_funds:
    "Я заявляю, что у меня достаточно средств, чтобы оплатить тест на туберкулез, визу и расходы на проезд в Великобританию и обратно.",
  sufficient_funds_error: "Пожалуйста, подтвердите наличие средств!",

  // ------------- Candidate Registration Page -------------
  register_finished_title: "Спасибо, что предоставили нам свои данные.",
  register_welcome_title: "Сезонная работа в Великобритании",
  register_welcome_hint1:
    "Добро пожаловать на страницу регистрации компании {sponsor}. Здесь вы можете подать заявку на участие в Программе сезонных рабочих в Великобритании .",
  register_welcome_hint2:
    "Пожалуйста, будьте готовы указать свое имя, фамилию, номер загранпаспорта, адрес электронной почты и номер телефона.",
  register_disclaimer_text:
    "Трудоустройство по программе сезонных рабочих – абсолютно бесплатное. Берегитесь мошенников и коммерческих компаний, которые предлагают трудоустройство за деньги. Если вы кому-то платите за помощь с вашим заявлением, вы потеряете свой шанс участвовать в программе сезонных рабочих.",
  register_register_title: "Сезонная работа в Великобритании",
  register_register_hint1:
    "Вы можете здесь зарегистрироваться. Укажите, пожалуйста, свои имя и фамилию, свой адрес электронной почты и свой номер телефона. " +
    "Используйте только английские буквы.",

  register_finished_email:
    "Мы отправили вам ссылку для подтверждения вашей электронной почты! " +
    "Чтобы завершить процесс регистрации, пожалуйста, нажмите на ссылку в письме.",
  register_finished_hint1:
    "У вас есть {hours} часов на подтверждения вашего адреса электронной почты! " +
    "Без подтверждения ваша регистрация будет удалена.",
  register_finished_hint2: `Если вы не получили наше письмо, проверьте папку "Спам".`,

  register_campaign_invalid_title: "Предоставленная вам ссылка на регистрацию недействительна!",
  register_campaign_expired_title:
    "Срок действия этой кампании уже истек, и она больше не принимает регистрации!",

  register_registration_confirmed_title: "Спосибо! Ваша регистрация подтверждена!",
  register_registration_confirmed_email: "Вы получите электронное письмо с подтверждением.",
  register_registration_confirmed_email_2:
    "Пожалуйста, возьмите это письмо с собой на собеседование!",
  register_regitration_already_confirmed_title: "Ваша регистрация уже подтверждена!",

  register_candidate_invalid_title: "Предоставленная вам ссылка на регистрацию недействительна!",
  register_confirmation_expired_title:
    "Срок действия этой ссылки уже истек! Вам необходимо пройти новую регистрацию!",
  register_empty_title: "Работа в Великобритании с визой",
  register_resend_confirmation_button: "Повторная отправка письма с подтверждением",

  register_upload_passport_title: "Загрузите фотографию",
  register_upload_passport_instructions:
    "Пожалуйста, загрузите свою четкую фотографию в паспортном формате на светлом фоне.",
  register_upload_passport_instructions2:
    "Загрузите фотографию в формате jpeg или jpg, максимальный размер 5MB.",

  button_register: "Зарегистрироваться",
  register_upload_passport_button: "Выберите изображение",
  email: "Электронная почта",
  has_passport: "У вас есть паспорт?",
  passportId: "Номер паспорта",
  passportId_error: "Пожалуйста, введите номер действительного паспорта",
  region: "Область",
  region_error: "Пожалуйста, выберите область",
  no_region: "Выберите область",

  email_suggestion: "Использовать {email} вместо",
  email_provider_url:
    "Пожалуйста, используйте адрес электронной почты от провайдера {emailProviderUrl}",

  // ------------- Interview Questions Page -------------
  qid_0032: "Знаете ли вы русский язык?",
  qid_0032_error: "Пожалуйста, укажите, знаете ли вы русский язык!",
  qid_0033: "Если да, то на каком уровне?",
  qid_0033_error: "Пожалуйста, укажите, на каком уровне вы знаете русский язык!",
  qid_0033_01: "Базовый",
  qid_0033_02: "Средний",
  qid_0033_03: "Продвинутый",
  qid_0029: "Знаете ли вы английский язык?",
  qid_0029_error: "Пожалуйста, укажите, знаете ли вы английский язык!",
  qid_0030: "Если да, то на каком уровне?",
  qid_0030_error: "Пожалуйста, укажите, на каком уровне вы знаете английский язык!",
  qid_0030_01: "Базовый",
  qid_0030_02: "Средний",
  qid_0030_03: "Продвинутый",
  qid_0026: "Работали ли вы в Великобритании ранее?",
  qid_0026_error: "Пожалуйста, укажите, работали ли вы в Великобритании ранее!",
  qid_0027: "Если да, то через какую компанию?",
  qid_0027_error: "Пожалуйста, укажите, через какую компанию вы работали в Великобритании!",
  qid_0027_00: "Ничего",
  qid_0027_01: "HOPS Group",
  qid_0027_02: "ProForce",
  qid_0027_03: "Fruitful",
  qid_0027_04: "Concordia",
  qid_0027_05: "AgriHR",
  qid_0027_06: "Другое",
  qid_0028: "Если да, то в каком году?",
  qid_0028_error: "Пожалуйста, укажите, в каком году вы работали в Великобритании!",
  qid_0031: "Имеете ли вы высшее образование?",
  qid_0031_error: "Пожалуйста, укажите, имеете ли вы высшее образование!",
  qid_0006: "Имеете ли вы водительское удостоверение?",
  qid_0006_error: "Пожалуйста, укажите, имеете ли вы водительское удостоверение!",
  qid_0007: "Имеете ли вы лицензию на управление транспортным средством?",
  qid_0007_error:
    "Пожалуйста, укажите, имеете ли вы лицензию на управление транспортным средством!",
  qid_0007_01: "Трактор",
  qid_0007_02: "Фронтальный погрузчик",
  qid_0007_03: "Спецтехника",
  qid_0034: "Согласны ли вы на съемку во время собеседования?",
  qid_0034_error: "Пожалуйста, укажите, согласны ли вы на съемку во время собеседования!",

  // ------------- country names -------------
  ISO3166_2_: "Выберите страну",
  ISO3166_2_AU: "Австралия",
  ISO3166_2_AT: "Австрия",
  ISO3166_2_AZ: "Азербайджан",
  ISO3166_2_AX: "Аландские острова",
  ISO3166_2_AL: "Албания",
  ISO3166_2_DZ: "Алжир",
  ISO3166_2_AS: "Американское Самоа",
  ISO3166_2_AI: "Ангилья",
  ISO3166_2_AO: "Ангола",
  ISO3166_2_AD: "Андорра",
  ISO3166_2_AQ: "Антарктика",
  ISO3166_2_AG: "Антигуа и Барбуда",
  ISO3166_2_AR: "Аргентина",
  ISO3166_2_AM: "Армения",
  ISO3166_2_AW: "Аруба",
  ISO3166_2_AF: "Афганистан",
  ISO3166_2_BS: "Багамские Острова",
  ISO3166_2_BD: "Бангладеш",
  ISO3166_2_BB: "Барбадос",
  ISO3166_2_BH: "Бахрейн",
  ISO3166_2_BZ: "Белиз",
  ISO3166_2_BY: "Беларусь",
  ISO3166_2_BE: "Бельгия",
  ISO3166_2_BJ: "Бенин",
  ISO3166_2_BM: "Бермуды",
  ISO3166_2_BG: "Болгария",
  ISO3166_2_BO: "Боливия",
  ISO3166_2_BQ: "Бонайре, Синт-Эстатиус и Саба",
  ISO3166_2_BA: "Босния и Герцеговина",
  ISO3166_2_BW: "Ботсвана",
  ISO3166_2_BR: "Бразилия",
  ISO3166_2_IO: "Британская Территория в Индийском Океане",
  ISO3166_2_BN: "Бруней",
  ISO3166_2_BF: "Буркина-Фасо",
  ISO3166_2_BI: "Бурунди",
  ISO3166_2_BT: "Бутан",
  ISO3166_2_VU: "Вануату",
  ISO3166_2_VA: "Ватикан",
  ISO3166_2_GB: "Великобритания",
  ISO3166_2_HU: "Венгрия",
  ISO3166_2_VE: "Венесуэла",
  ISO3166_2_VG: "Виргинские Острова (Великобритания)",
  ISO3166_2_VI: "Виргинские Острова (США)",
  ISO3166_2_UM: "Внешние малые острова США",
  ISO3166_2_TL: "Восточный Тимор",
  ISO3166_2_VN: "Вьетнам",
  ISO3166_2_GA: "Габон",
  ISO3166_2_HT: "Гаити",
  ISO3166_2_GY: "Гайана",
  ISO3166_2_GM: "Гамбия",
  ISO3166_2_GH: "Гана",
  ISO3166_2_GP: "Гваделупа",
  ISO3166_2_GT: "Гватемала",
  ISO3166_2_GF: "Гвиана",
  ISO3166_2_GN: "Гвинея",
  ISO3166_2_GW: "Гвинея-Бисау",
  ISO3166_2_DE: "Германия",
  ISO3166_2_GG: "Гернси",
  ISO3166_2_GI: "Гибралтар",
  ISO3166_2_HN: "Гондурас",
  ISO3166_2_HK: "Гонконг",
  ISO3166_2_PS: "Государство Палестина",
  ISO3166_2_GD: "Гренада",
  ISO3166_2_GL: "Гренландия",
  ISO3166_2_GR: "Греция",
  ISO3166_2_GE: "Грузия",
  ISO3166_2_GU: "Гуам",
  ISO3166_2_DK: "Дания",
  ISO3166_2_CD: "ДР Конго",
  ISO3166_2_JE: "Джерси",
  ISO3166_2_DJ: "Джибути",
  ISO3166_2_DM: "Доминика",
  ISO3166_2_DO: "Доминиканская Республика",
  ISO3166_2_EG: "Египет",
  ISO3166_2_ZM: "Замбия",
  ISO3166_2_ZW: "Зимбабве",
  ISO3166_2_IL: "Израиль",
  ISO3166_2_IN: "Индия",
  ISO3166_2_ID: "Индонезия",
  ISO3166_2_JO: "Иордания",
  ISO3166_2_IQ: "Ирак",
  ISO3166_2_IR: "Иран",
  ISO3166_2_IE: "Ирландия",
  ISO3166_2_IS: "Исландия",
  ISO3166_2_ES: "Испания",
  ISO3166_2_IT: "Италия",
  ISO3166_2_YE: "Йемен",
  ISO3166_2_CV: "Кабо-Верде",
  ISO3166_2_KZ: "Казахстан",
  ISO3166_2_KH: "Камбоджа",
  ISO3166_2_CM: "Камерун",
  ISO3166_2_CA: "Канада",
  ISO3166_2_QA: "Катар",
  ISO3166_2_KE: "Кения",
  ISO3166_2_CY: "Кипр",
  ISO3166_2_KG: "Кыргызстан",
  ISO3166_2_KI: "Кирибати",
  ISO3166_2_TW: "Китайская Республика",
  ISO3166_2_CN: "Китай",
  ISO3166_2_KP: "КНДР",
  ISO3166_2_CC: "Кокосовые острова",
  ISO3166_2_CO: "Колумбия",
  ISO3166_2_KM: "Коморы",
  ISO3166_2_CR: "Коста-Рика",
  ISO3166_2_CI: "Кот-д’Ивуар",
  ISO3166_2_CU: "Куба",
  ISO3166_2_KW: "Кувейт",
  ISO3166_2_CW: "Кюрасао",
  ISO3166_2_LA: "Лаос",
  ISO3166_2_LV: "Латвия",
  ISO3166_2_LS: "Лесото",
  ISO3166_2_LR: "Либерия",
  ISO3166_2_LB: "Ливан",
  ISO3166_2_LY: "Ливия",
  ISO3166_2_LT: "Литва",
  ISO3166_2_LI: "Лихтенштейн",
  ISO3166_2_LU: "Люксембург",
  ISO3166_2_MU: "Маврикий",
  ISO3166_2_MR: "Мавритания",
  ISO3166_2_MG: "Мадагаскар",
  ISO3166_2_YT: "Майотта",
  ISO3166_2_MO: "Макао",
  ISO3166_2_MK: "Северная Македония",
  ISO3166_2_MW: "Малави",
  ISO3166_2_MY: "Малайзия",
  ISO3166_2_ML: "Мали",
  ISO3166_2_MV: "Мальдивы",
  ISO3166_2_MT: "Мальта",
  ISO3166_2_MA: "Марокко",
  ISO3166_2_MQ: "Мартиника",
  ISO3166_2_MH: "Маршалловы Острова",
  ISO3166_2_MX: "Мексика",
  ISO3166_2_FM: "Микронезия",
  ISO3166_2_MZ: "Мозамбик",
  ISO3166_2_MD: "Молдова",
  ISO3166_2_MC: "Монако",
  ISO3166_2_MN: "Монголия",
  ISO3166_2_MS: "Монтсеррат",
  ISO3166_2_MM: "Мьянма",
  ISO3166_2_NA: "Намибия",
  ISO3166_2_NR: "Науру",
  ISO3166_2_NP: "Непал",
  ISO3166_2_NE: "Нигер",
  ISO3166_2_NG: "Нигерия",
  ISO3166_2_NL: "Нидерланды",
  ISO3166_2_NI: "Никарагуа",
  ISO3166_2_NU: "Ниуэ",
  ISO3166_2_NZ: "Новая Зеландия",
  ISO3166_2_NC: "Новая Каледония",
  ISO3166_2_NO: "Норвегия",
  ISO3166_2_AE: "ОАЭ",
  ISO3166_2_OM: "Оман",
  ISO3166_2_BV: "Остров Буве",
  ISO3166_2_IM: "Остров Мэн",
  ISO3166_2_NF: "Остров Норфолк",
  ISO3166_2_CX: "Остров Рождества",
  ISO3166_2_KY: "Острова Кайман",
  ISO3166_2_CK: "Острова Кука",
  ISO3166_2_PN: "Острова Питкэрн",
  ISO3166_2_SH: "Остров Святой Елены",
  ISO3166_2_PK: "Пакистан",
  ISO3166_2_PW: "Палау",
  ISO3166_2_PA: "Панама",
  ISO3166_2_PG: "Папуа — Новая Гвинея",
  ISO3166_2_PY: "Парагвай",
  ISO3166_2_PE: "Перу",
  ISO3166_2_PL: "Польша",
  ISO3166_2_PT: "Португалия",
  ISO3166_2_PR: "Пуэрто-Рико",
  ISO3166_2_CG: "Республика Конго",
  ISO3166_2_KR: "Республика Корея",
  ISO3166_2_RE: "Реюньон",
  ISO3166_2_RU: "Россия",
  ISO3166_2_RW: "Руанда",
  ISO3166_2_RO: "Румыния",
  ISO3166_2_EH: "САДР",
  ISO3166_2_SV: "Сальвадор",
  ISO3166_2_WS: "Самоа",
  ISO3166_2_SM: "Сан-Марино",
  ISO3166_2_ST: "Сан-Томе и Принсипи",
  ISO3166_2_SA: "Саудовская Аравия",
  ISO3166_2_MP: "Северные Марианские Острова",
  ISO3166_2_SC: "Сейшельские Острова",
  ISO3166_2_BL: "Сен-Бартелеми",
  ISO3166_2_MF: "Сен-Мартен",
  ISO3166_2_PM: "Сен-Пьер и Микелон",
  ISO3166_2_SN: "Сенегал",
  ISO3166_2_VC: "Сент-Винсент и Гренадины",
  ISO3166_2_KN: "Сент-Китс и Невис",
  ISO3166_2_LC: "Сент-Люсия",
  ISO3166_2_RS: "Сербия",
  ISO3166_2_SG: "Сингапур",
  ISO3166_2_SX: "Синт-Мартен",
  ISO3166_2_SY: "Сирия",
  ISO3166_2_SK: "Словакия",
  ISO3166_2_SI: "Словения",
  ISO3166_2_SB: "Соломоновы Острова",
  ISO3166_2_SO: "Сомали",
  ISO3166_2_SD: "Судан",
  ISO3166_2_SR: "Суринам",
  ISO3166_2_US: "США",
  ISO3166_2_SL: "Сьерра-Леоне",
  ISO3166_2_TJ: "Таджикистан",
  ISO3166_2_TH: "Таиланд",
  ISO3166_2_TZ: "Танзания",
  ISO3166_2_TC: "Теркс и Кайкос",
  ISO3166_2_TG: "Того",
  ISO3166_2_TK: "Токелау",
  ISO3166_2_TO: "Тонга",
  ISO3166_2_TT: "Тринидад и Тобаго",
  ISO3166_2_TV: "Тувалу",
  ISO3166_2_TN: "Тунис",
  ISO3166_2_TM: "Туркменистан",
  ISO3166_2_TR: "Турция",
  ISO3166_2_UG: "Уганда",
  ISO3166_2_UZ: "Узбекистан",
  ISO3166_2_UA: "Украина",
  ISO3166_2_WF: "Уоллис и Футуна",
  ISO3166_2_UY: "Уругвай",
  ISO3166_2_FO: "Фарерские острова",
  ISO3166_2_FJ: "Фиджи",
  ISO3166_2_PH: "Филиппины",
  ISO3166_2_FI: "Финляндия",
  ISO3166_2_FK: "Фолклендские острова",
  ISO3166_2_FR: "Франция",
  ISO3166_2_PF: "Французская Полинезия",
  ISO3166_2_TF: "Французские Южные и Антарктические территории",
  ISO3166_2_HM: "Херд и Макдональд",
  ISO3166_2_HR: "Хорватия",
  ISO3166_2_CF: "ЦАР",
  ISO3166_2_TD: "Чад",
  ISO3166_2_ME: "Черногория",
  ISO3166_2_CZ: "Чехия",
  ISO3166_2_CL: "Чили",
  ISO3166_2_CH: "Швейцария",
  ISO3166_2_SE: "Швеция",
  ISO3166_2_SJ: "Шпицберген и Ян-Майен",
  ISO3166_2_LK: "Шри-Ланка",
  ISO3166_2_EC: "Эквадор",
  ISO3166_2_GQ: "Экваториальная Гвинея",
  ISO3166_2_ER: "Эритрея",
  ISO3166_2_SZ: "Эсватини",
  ISO3166_2_EE: "Эстония",
  ISO3166_2_ET: "Эфиопия",
  ISO3166_2_ZA: "ЮАР",
  ISO3166_2_GS: "Южная Георгия и Южные Сандвичевы Острова",
  ISO3166_2_SS: "Южный Судан",
  ISO3166_2_JM: "Ямайка",
  ISO3166_2_JP: "Япония",
  ISO3166_2_XK: "Косово",
};

export default ru;
