import { Autocomplete, TextField, Box } from "@mui/material";
import { COUNTRIES_FULL, SelectCountryProps } from "../types/country-codes";
import { EMPTY_COUNTRY } from "../types/commons";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";

const COUNTRIES = [{ code: "", label: "Select a country" }, ...COUNTRIES_FULL];

export const SelectCountryTranslated: React.FC<SelectCountryProps> = ({
  id,
  value,
  label,
  error,
  helperText,
  onChange,
  sx,
  required,
}) => {
  const intl = useIntl();

  return (
    <Autocomplete
      sx={sx}
      id={id}
      value={value && value.code ? value : EMPTY_COUNTRY}
      onChange={onChange}
      //
      options={COUNTRIES}
      autoHighlight
      disableClearable
      getOptionLabel={(option) => {
        return option.code
          ? intl.formatMessage({ id: "ISO3166_2_" + option.code, defaultMessage: option.label })
          : "";
      }}
      isOptionEqualToValue={(option, value) => option.code === value.code}
      //
      renderOption={(props, option) => (
        <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            src={option.code && `https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={option.code && `https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          />
          {<FormattedMessage id={"ISO3166_2_" + option.code} defaultMessage={option.label} />}{" "}
          {option.code && "(" + option.code + ")"}
        </Box>
      )}
      //
      renderInput={(params) => (
        <TextField
          {...params}
          name={id}
          label={<FormattedMessage id={id} defaultMessage={label} />}
          error={error}
          helperText={
            helperText && <FormattedMessage id={id + "_error"} defaultMessage={helperText} />
          }
          required={required}
          InputLabelProps={{ shrink: true }}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};
