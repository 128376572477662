import { ServerTime } from "../types/commons";
import { BASE_FUNCTIONS_URL } from "../types/firebase";

export const getServerTime = async () => {
  const apiKey = process.env.REACT_APP_FUNCTIONS_API_KEY || "";
  const response = await fetch(BASE_FUNCTIONS_URL + "/public/server-time", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-API-Key": apiKey,
    },
  });

  const data: ServerTime = await response.json();
  return data;
};
