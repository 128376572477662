import React from "react";
import {
  Grid,
  Divider,
  MenuItem,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from "@mui/material";

import { RadioYesNo } from "../../../components/radio-button-yes-no";

import { Applicant, ApplicantErrors } from "../../../types/applicants";
import { EMPTY_COUNTRY } from "../../../types/commons";
import { Country } from "../../../types/commons";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CustomTextField } from "../../../components/custom-textfield";
import { FormattedMessage } from "react-intl";
import { ordinalNumbers } from "../../../utils/utils";
import { NUM_CHILDREN } from "../../../types/persons";
import { NUM_RELATIVES, RELATIVE_RELATION } from "../../../types/relatives";
import { Circle, CircleOutlined } from "@mui/icons-material";
import { SelectCountryTranslated } from "../../../components/select-country";

interface PageFamilyInformationProps {
  applicant: Applicant;
  errors: ApplicantErrors;
  disableChildren: boolean;
  disableRelatives: boolean;
  disableParents: boolean;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onCountryChange: (event: any, newValue: Country | null) => void;
  onPersonInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPersonCountryChange: (event: any, newValue: Country | null) => void;
  onInputChildrenChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onInputRelativesChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onRelativeNationalityChange: (event: any, newValue: Country | null) => void;
}
export const PageFamilyInformation: React.FC<PageFamilyInformationProps> = ({
  applicant,
  errors,
  disableChildren,
  disableRelatives,
  disableParents,
  onInputChange,
  onCountryChange,
  onPersonInputChange,
  onPersonCountryChange,
  onInputChildrenChange,
  onInputRelativesChange,
  onRelativeNationalityChange,
}) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container spacing={2} padding={2}>
      <Grid item xs={12}>
        <Divider textAlign="left">
          <FormattedMessage id="divider_marital_status" defaultMessage="Marital Status" />
        </Divider>
      </Grid>

      <Grid item xs={12} md={4}>
        <CustomTextField
          name="marital_status"
          label="Marital Status"
          select
          error={!!errors.marital_status}
          helperText={errors.marital_status}
          value={applicant.marital_status ?? ""}
          onChange={onInputChange}
          required
          autoFocus
          fullWidth
        >
          <MenuItem value="Single">
            <FormattedMessage id="marital_status_single" defaultMessage="Single" />
          </MenuItem>
          <MenuItem value="Married">
            <FormattedMessage id="marital_status_married" defaultMessage="Married" />
          </MenuItem>
          <MenuItem value="Divorced">
            <FormattedMessage id="marital_status_divorsed" defaultMessage="Divorsed" />
          </MenuItem>
        </CustomTextField>
      </Grid>

      {/* partner's details */}

      {applicant.marital_status === "Married" && (
        <React.Fragment>
          <Grid item xs={12}>
            <Divider textAlign="left">
              <FormattedMessage id="divider_partner_details" defaultMessage="Partner's Details" />
            </Divider>
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomTextField
              name="partner_name"
              label="Partner's name"
              error={!!errors.partner?.name}
              helperText={errors.partner && errors.partner.name}
              type="text"
              value={applicant && applicant.partner ? applicant.partner.name : ""}
              onChange={onPersonInputChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomTextField
              name="partner_surname"
              label="Partner's surname"
              error={!!errors.partner?.surname}
              helperText={errors.partner && errors.partner.surname}
              type="text"
              value={applicant && applicant.partner ? applicant.partner.surname : ""}
              onChange={onPersonInputChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectCountryTranslated
              id="partner_nationality"
              label="Partner's nationality"
              error={!!errors.partner?.nationality}
              helperText={errors.partner && errors.partner.nationality}
              value={
                applicant &&
                applicant.partner &&
                applicant.partner.nationality &&
                applicant.partner.nationality.code
                  ? applicant.partner.nationality
                  : EMPTY_COUNTRY
              }
              onChange={onPersonCountryChange}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomTextField
              name="partner_birthday"
              label="Partner's date of birth"
              error={!!errors.partner?.birthday}
              helperText={errors.partner && errors.partner?.birthday}
              type="date"
              value={
                applicant && applicant.partner && applicant.partner
                  ? applicant.partner.birthday
                  : ""
              }
              onChange={onPersonInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={10} md={6}>
            {/* Lives home */}
            <RadioYesNo
              id="partner_livesHome"
              value={applicant && applicant.partner ? applicant.partner.livesHome : true}
              label="Does your partner live with you?"
              error={!!errors.partner?.livesHome}
              helperText={errors.partner && errors.partner.livesHome}
              onChange={onPersonInputChange}
            />
          </Grid>
        </React.Fragment>
      )}

      <Grid item xs={12}>
        <Divider textAlign="left">
          <FormattedMessage id="divider_children" defaultMessage="Children" />
        </Divider>
      </Grid>

      <Grid item xs={10} md={8}>
        {/* Has children */}
        <RadioYesNo
          id="has_children"
          value={applicant.has_children}
          label="Do you have children?"
          error={!!errors.has_children}
          helperText={errors.has_children}
          onChange={onInputChildrenChange}
        />
      </Grid>

      <Grid item xs={2} md={4} />

      {/* children details */}
      {!disableChildren &&
        ordinalNumbers(NUM_CHILDREN).map((value, i) => {
          return (
            <React.Fragment key={"child_" + i}>
              <Grid item xs={12} md={4}>
                <CustomTextField
                  name={"child_" + i + "_name"}
                  label={value + " child's name"}
                  error={!!(errors.children && errors.children[i] && errors.children[i].name)}
                  helperText={errors.children && errors.children[i] && errors.children[i].name}
                  disabled={disableChildren}
                  type="text"
                  value={applicant.children && applicant.children[i] && applicant.children[i].name}
                  onChange={onInputChildrenChange}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <CustomTextField
                  name={"child_" + i + "_surname"}
                  label={value + " child's family name"}
                  error={!!(errors.children && errors.children[i] && errors.children[i].surname)}
                  helperText={errors.children && errors.children[i] && errors.children[i].surname}
                  disabled={disableChildren}
                  type="text"
                  value={
                    applicant.children && applicant.children[i] && applicant.children[i].surname
                  }
                  onChange={onInputChildrenChange}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <CustomTextField
                  id={"child_" + i + "_birthday"}
                  name={"child_" + i + "_birthday"}
                  label={value + " child's date of birth"}
                  error={!!(errors.children && errors.children[i] && errors.children[i].birthday)}
                  helperText={errors.children && errors.children[i] && errors.children[i].birthday}
                  type="date"
                  value={
                    applicant.children && applicant.children[i] && applicant.children[i].birthday
                  }
                  onChange={onInputChildrenChange}
                  disabled={disableChildren}
                  fullWidth
                />
              </Grid>
              {smallScreen && i < 3 && (
                <Grid item xs={10}>
                  <Divider light></Divider>
                </Grid>
              )}
            </React.Fragment>
          );
        })}

      {/* parent's details */}

      <Grid item xs={12}>
        <Divider textAlign="left">
          <FormattedMessage id="divider_parents" defaultMessage="Parents" />
        </Divider>
      </Grid>
      <Grid item xs={12}>
        <FormControl error={!!errors.parents_unknown}>
          <FormControlLabel
            control={
              <Checkbox
                name="parents_unknown"
                size="medium"
                checkedIcon={<Circle />}
                icon={<CircleOutlined />}
                onChange={onInputChange}
                checked={applicant.parents_unknown || false}
              />
            }
            label={
              <FormattedMessage
                id="parents_unknown"
                defaultMessage={"I do not have my parents' details"}
              />
            }
          />
          <FormHelperText>
            {errors.parents_unknown && (
              <FormattedMessage id="privacy_policy_error" defaultMessage={errors.parents_unknown} />
            )}
          </FormHelperText>
        </FormControl>
      </Grid>

      {!disableParents && (
        <React.Fragment>
          {/* mother's details */}
          <Grid item xs={12}>
            <Divider textAlign="left">
              <FormattedMessage id="divider_mother_details" defaultMessage="Mother's Details" />
            </Divider>
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomTextField
              name="mother_name"
              label="Mother's name"
              error={!!errors.mother?.name}
              helperText={errors.mother && errors.mother.name}
              type="text"
              value={applicant && applicant.mother ? applicant.mother.name : ""}
              onChange={onPersonInputChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomTextField
              name="mother_surname"
              label="Mother's surname"
              error={!!errors.mother?.surname}
              helperText={errors.mother && errors.mother.surname}
              type="text"
              value={applicant && applicant.mother ? applicant.mother.surname : ""}
              onChange={onPersonInputChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectCountryTranslated
              id="mother_nationality"
              label="Mother's nationality"
              error={!!errors.mother?.nationality}
              helperText={errors.mother && errors.mother.nationality}
              value={
                applicant &&
                applicant.mother &&
                applicant.mother.nationality &&
                applicant.mother.nationality.code
                  ? applicant.mother.nationality
                  : EMPTY_COUNTRY
              }
              onChange={onPersonCountryChange}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomTextField
              name="mother_birthday"
              label="Mother's date of birth"
              error={!!errors.mother?.birthday}
              helperText={errors.mother && errors.mother.birthday}
              type="date"
              value={applicant && applicant.mother ? applicant.mother.birthday : ""}
              onChange={onPersonInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomTextField
              name="mother_birthPlace"
              label="Mother's place of birth"
              error={!!errors.mother?.birthPlace}
              helperText={errors.mother && errors.mother.birthPlace}
              type="text"
              value={applicant && applicant.mother ? applicant.mother.birthPlace : ""}
              onChange={onPersonInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectCountryTranslated
              id="mother_birthCountry"
              label="Mother's country of birth"
              error={!!errors.mother?.birthCountry}
              helperText={errors.mother && errors.mother?.birthCountry}
              value={
                applicant && applicant.mother?.birthCountry && applicant.mother?.birthCountry.code
                  ? applicant.mother?.birthCountry
                  : EMPTY_COUNTRY
              }
              onChange={onPersonCountryChange}
            />
          </Grid>
          {/* father's details */}
          <Grid item xs={12}>
            <Divider textAlign="left">
              <FormattedMessage id="divider_father_details" defaultMessage="Father's Details" />
            </Divider>
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomTextField
              name="father_name"
              label="Father's name"
              error={!!errors.father?.name}
              helperText={errors.father && errors.father.name}
              type="text"
              value={applicant && applicant.father ? applicant.father.name : ""}
              onChange={onPersonInputChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomTextField
              name="father_surname"
              label="Father's surname"
              error={!!errors.father?.surname}
              helperText={errors.father && errors.father.surname}
              type="text"
              value={applicant && applicant.father ? applicant.father.surname : ""}
              onChange={onPersonInputChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectCountryTranslated
              id="father_nationality"
              label="Father's nationality"
              error={!!errors.father?.nationality}
              helperText={errors.father && errors.father.nationality}
              value={
                applicant &&
                applicant.father &&
                applicant.father.nationality &&
                applicant.father &&
                applicant.father.nationality.code &&
                applicant.father
                  ? applicant.father.nationality
                  : EMPTY_COUNTRY
              }
              onChange={onPersonCountryChange}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomTextField
              name="father_birthday"
              label="Father's date of birth"
              error={!!errors.father?.birthday}
              helperText={errors.father && errors.father.birthday}
              type="date"
              value={applicant && applicant.father ? applicant.father.birthday : ""}
              onChange={onPersonInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomTextField
              name="father_birthPlace"
              label="Father's place of birth"
              error={!!errors.father?.birthPlace}
              helperText={errors.father && errors.father.birthPlace}
              type="text"
              value={applicant && applicant.father ? applicant.father.birthPlace : ""}
              onChange={onPersonInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectCountryTranslated
              id="father_birthCountry"
              label="Father's country of birth"
              error={!!errors.father?.birthCountry}
              helperText={errors.father && errors.father.birthCountry}
              value={
                applicant &&
                applicant.father &&
                applicant.father.birthCountry &&
                applicant.father.birthCountry.code
                  ? applicant.father.birthCountry
                  : EMPTY_COUNTRY
              }
              onChange={onPersonCountryChange}
            />
          </Grid>
        </React.Fragment>
      )}

      <Grid item xs={12}>
        <Divider textAlign="left">
          <FormattedMessage id="divider_immergency_contact" defaultMessage="Immergency contact" />
        </Divider>
      </Grid>

      <Grid item xs={12} md={6}>
        <CustomTextField
          name="immergency_contact_name"
          label="Immergency contact's FULL name"
          error={!!(errors.immergency_contact && errors.immergency_contact.name)}
          helperText={errors.immergency_contact && errors.immergency_contact.name}
          type="text"
          value={
            applicant && applicant.immergency_contact && applicant.immergency_contact.name
              ? applicant.immergency_contact.name
              : ""
          }
          onChange={onInputChange}
          fullWidth
          required
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <CustomTextField
          name="immergency_contact_phone"
          label="Immergency contact's phone number"
          error={!!(errors.immergency_contact && errors.immergency_contact.phone)}
          helperText={errors.immergency_contact && errors.immergency_contact.phone}
          type="tel"
          value={
            applicant && applicant.immergency_contact && applicant.immergency_contact.phone
              ? applicant.immergency_contact.phone
              : ""
          }
          onChange={onInputChange}
          fullWidth
          required
        />
      </Grid>

      <Grid item xs={10} md={6}>
        <CustomTextField
          name="immergency_contact_relation"
          label="What is your realtion to your contact"
          select
          error={!!(errors.immergency_contact && errors.immergency_contact.relation)}
          helperText={errors.immergency_contact && errors.immergency_contact.relation}
          value={
            applicant && applicant.immergency_contact && applicant.immergency_contact.relation
              ? applicant.immergency_contact.relation
              : ""
          }
          onChange={onInputChange}
          required
          fullWidth
        >
          <MenuItem sx={{ fontStyle: "italic" }} value="">
            <FormattedMessage
              id="immergency_contact_relation_none"
              defaultMessage="none / choose an option"
            />
          </MenuItem>
          <MenuItem value="Relatives">
            <FormattedMessage
              id="immergency_contact_relation_relatives"
              defaultMessage="Relatives"
            />
          </MenuItem>
          <MenuItem value="Parent">
            <FormattedMessage id="immergency_contact_relation_parent" defaultMessage="Parent" />
          </MenuItem>
          <MenuItem value="Friend">
            <FormattedMessage id="immergency_contact_relation_friend" defaultMessage="Friend" />
          </MenuItem>
        </CustomTextField>
      </Grid>

      <Grid item xs={12}>
        <Divider textAlign="left">
          <FormattedMessage
            id="divider_additional_information"
            defaultMessage="Additional information"
          />
        </Divider>
      </Grid>

      <Grid item xs={12}>
        <RadioYesNo
          id="children_travelling"
          value={applicant.children_travelling}
          label="Are children travelling with you?"
          error={!!errors.children_travelling}
          helperText={errors.children_travelling}
          onChange={onInputChange}
        />
      </Grid>

      {/* --------- Relatives In UK ------------- */}
      <Grid item xs={12}>
        <Divider textAlign="left">
          <FormattedMessage id="divider_relatives_UK" defaultMessage="Relatives in UK" />
        </Divider>
      </Grid>

      <Grid item xs={10} md={8}>
        {/* Has relatives */}
        <RadioYesNo
          id="has_relatives_in_UK"
          value={applicant.has_relatives_in_UK}
          label="Do you have relatives in UK?"
          error={!!errors.has_relatives_in_UK}
          helperText={errors.has_relatives_in_UK}
          onChange={onInputRelativesChange}
        />
      </Grid>

      <Grid item xs={2} md={4} />

      {/* relatives details */}
      {!disableRelatives &&
        ordinalNumbers(NUM_RELATIVES).map((value, i) => {
          return (
            <React.Fragment key={"relative_" + i}>
              <Grid item xs={12} md={4}>
                <CustomTextField
                  name={"relative_" + i + "_name"}
                  label={value + " relative's name"}
                  error={
                    !!(errors.relatives_UK && errors.relatives_UK[i] && errors.relatives_UK[i].name)
                  }
                  helperText={
                    errors.relatives_UK && errors.relatives_UK[i] && errors.relatives_UK[i].name
                  }
                  type="text"
                  value={
                    applicant.relatives_UK &&
                    applicant.relatives_UK[i] &&
                    applicant.relatives_UK[i].name
                  }
                  onChange={onInputRelativesChange}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <CustomTextField
                  name={"relative_" + i + "_surname"}
                  label={value + " relative's family name"}
                  error={
                    !!(
                      errors.relatives_UK &&
                      errors.relatives_UK[i] &&
                      errors.relatives_UK[i].surname
                    )
                  }
                  helperText={
                    errors.relatives_UK && errors.relatives_UK[i] && errors.relatives_UK[i].surname
                  }
                  type="text"
                  value={
                    applicant.relatives_UK &&
                    applicant.relatives_UK[i] &&
                    applicant.relatives_UK[i].surname
                  }
                  onChange={onInputRelativesChange}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <SelectCountryTranslated
                  id={"relative_" + i + "_nationality"}
                  label={value + " relative's nationality"}
                  error={
                    !!(
                      errors.relatives_UK &&
                      errors.relatives_UK[i] &&
                      errors.relatives_UK[i].nationality
                    )
                  }
                  helperText={
                    errors.relatives_UK &&
                    errors.relatives_UK[i] &&
                    errors.relatives_UK[i].nationality
                  }
                  value={
                    applicant && applicant.relatives_UK && applicant.relatives_UK[i]
                      ? applicant.relatives_UK[i].nationality
                      : EMPTY_COUNTRY
                  }
                  onChange={onRelativeNationalityChange}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <CustomTextField
                  name={"relative_" + i + "_relation"}
                  label={"Relationship with the " + value + " relative"}
                  select
                  error={
                    !!(
                      errors.relatives_UK &&
                      errors.relatives_UK[i] &&
                      errors.relatives_UK[i].relation
                    )
                  }
                  helperText={
                    errors.relatives_UK && errors.relatives_UK[i] && errors.relatives_UK[i].relation
                  }
                  value={
                    (applicant.relatives_UK &&
                      applicant.relatives_UK[i] &&
                      applicant.relatives_UK[i].relation) ||
                    ""
                  }
                  onChange={onInputRelativesChange}
                  required
                  fullWidth
                >
                  <MenuItem sx={{ fontStyle: "italic" }} value="" key={"key_" + i + "_none"}>
                    <FormattedMessage
                      id="relative_reason_none"
                      defaultMessage="none / choose an option"
                    />
                  </MenuItem>
                  {Object.keys(RELATIVE_RELATION).map((key) => {
                    return (
                      <MenuItem value={key} key={"key_" + i + "_" + key}>
                        <FormattedMessage
                          id={"relative_reason_" + key}
                          defaultMessage={RELATIVE_RELATION[key]}
                        />
                      </MenuItem>
                    );
                  })}
                </CustomTextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  name={"relative_" + i + "_typeofstay"}
                  label={value + " relative's permission to stay in the UK"}
                  select
                  error={
                    !!(
                      errors.relatives_UK &&
                      errors.relatives_UK[i] &&
                      errors.relatives_UK[i].typeOfStay
                    )
                  }
                  helperText={
                    errors.relatives_UK &&
                    errors.relatives_UK[i] &&
                    errors.relatives_UK[i].typeOfStay
                  }
                  value={
                    (applicant.relatives_UK &&
                      applicant.relatives_UK[i] &&
                      applicant.relatives_UK[i].typeOfStay) ||
                    ""
                  }
                  onChange={onInputRelativesChange}
                  required
                  fullWidth
                >
                  <MenuItem sx={{ fontStyle: "italic" }} value="">
                    <FormattedMessage
                      id="relative_reason_none"
                      defaultMessage="none / choose an option"
                    />
                  </MenuItem>
                  <MenuItem value="temporaryVisa">
                    <FormattedMessage
                      id="typeofstay_temporary"
                      defaultMessage="They have a temporary visa"
                    />
                  </MenuItem>
                  <MenuItem value="permanentResident">
                    <FormattedMessage
                      id="typeofstay_permanently"
                      defaultMessage="They are in the UK permanently"
                    />
                  </MenuItem>
                  <MenuItem value="other">
                    <FormattedMessage
                      id="typeofstay_other"
                      defaultMessage="They do not have a visa and are not in the UK permanently"
                    />
                  </MenuItem>
                  <MenuItem value="cannotContact">
                    <FormattedMessage
                      id="typeofstay_cannotcontact"
                      defaultMessage="I cannot contact my relative"
                    />
                  </MenuItem>
                </CustomTextField>
              </Grid>
              {applicant.relatives_UK &&
                applicant.relatives_UK[i] &&
                ["temporaryVisa", "permanentResident"].includes(
                  applicant.relatives_UK[i].typeOfStay
                ) && (
                  <Grid item xs={12} md={6}>
                    <CustomTextField
                      name={"relative_" + i + "_passportNumber"}
                      label={value + " relative's passport number"}
                      error={
                        !!(
                          errors.relatives_UK &&
                          errors.relatives_UK[i] &&
                          errors.relatives_UK[i].passportNumber
                        )
                      }
                      helperText={
                        errors.relatives_UK &&
                        errors.relatives_UK[i] &&
                        errors.relatives_UK[i].passportNumber
                      }
                      value={applicant.relatives_UK[i].passportNumber}
                      onChange={onInputRelativesChange}
                      fullWidth
                    />
                  </Grid>
                )}
              {applicant.relatives_UK &&
                applicant.relatives_UK[i] &&
                ["other", "cannotContact"].includes(applicant.relatives_UK[i].typeOfStay) && (
                  <Grid item xs={12}>
                    <CustomTextField
                      name={"relative_" + i + "_note"}
                      label={"Please give a detailed description of the situation"}
                      error={
                        !!(
                          errors.relatives_UK &&
                          errors.relatives_UK[i] &&
                          errors.relatives_UK[i].note
                        )
                      }
                      helperText={
                        errors.relatives_UK && errors.relatives_UK[i] && errors.relatives_UK[i].note
                      }
                      value={applicant.relatives_UK[i].note}
                      onChange={onInputRelativesChange}
                      fullWidth
                      multiline
                      rows={3}
                    />
                  </Grid>
                )}
              {i < NUM_RELATIVES - 1 && (
                <Grid item xs={10}>
                  <Divider light></Divider>
                </Grid>
              )}
            </React.Fragment>
          );
        })}
    </Grid>
  );
};
