import { Timestamp } from "firebase/firestore";
import { Address, Country } from "../types/commons";
import { Applicant } from "../types/applicants";
import { COUNTRIES_3L_TO_2L, COUNTRIES_FULL } from "../types/country-codes";

const ORDINAL_NUMBERS = [
  "First",
  "Second",
  "Third",
  "Fourth",
  "Fifth",
  "Sixth",
  "Seventh",
  "Eighth",
  "Ninth",
  "Tenth",
  "Eleventh",
  "Twelfth",
  "Thirteenth",
  "Fourteenth",
  "Fifteenth",
  "Sixteenth",
  "Seventeenth",
  "Eighteenth",
  "Nineteenth",
  "Twentieth",
];

export const BASE_URL = "https://visauk.realizon.tech";

export function isObject(value: any): boolean {
  return value && typeof value === "object" && !Array.isArray(value);
}

export function isArray(value: any): boolean {
  return value && typeof value === "object" && Array.isArray(value);
}

export function ordinalNumbers(count: number) {
  return ORDINAL_NUMBERS.slice(0, count);
}

export const isNameValid = (name: string | undefined): boolean => {
  return name ? /^[A-Za-zäÄöÖüÜ -]+$/.test(name) && name.length > 0 : false;
};

export const isStreetAddressValid = (name: string | undefined): boolean => {
  return name ? /^[0-9A-Za-zäÄöÖüÜ. -]+$/.test(name) : false;
};

export const containsNonLatinCharacters = (text: string | undefined | null) => {
  // This regex matches any character that is not a Latin letter, number, or punctuation
  const nonLatinRegex = /[^\p{Script=Latin}\p{Punctuation}\p{Number}\s]/gu;

  //console.log("containsNonLatinCharacters: ", text, "; " + nonLatinRegex.test(text || ""));
  return text && nonLatinRegex.test(text);
};

export const isCityNameValid = (city: string | undefined): boolean => {
  return city ? /^[A-Za-zäÄöÖüÜ -]+$/.test(city) : false;
};

export const isPhoneValid = (phone: string | undefined): boolean => {
  return phone ? /^[0-9]+$/.test(phone) : false;
};

export const isEMailValid = (email: string | undefined): boolean => {
  return email ? /\S+@\S+\.\S+/.test(email) : false;
};

export const isConfirmationCodeValid = (code: string | undefined): boolean => {
  return code ? /^[A-Z0-9]{1,6}$/.test(code) : false;
};

export const fbTimestampToDate = (ts: Timestamp | undefined) => {
  return ts ? ts.toDate().toISOString().split("T")[0] : "";
};

export const fbTimestampToDateTime = (ts: Timestamp | undefined) => {
  return ts ? ts.toDate().toISOString() : "";
};

export function isDateInThePast(dateString: string | undefined, numYears?: number) {
  if (dateString === undefined) {
    return false;
  }
  const currentDate = new Date();
  currentDate.setHours(23, 59, 59, 999); // Set to end of day
  const inputDate = new Date(dateString);

  // Calculate the difference in milliseconds between the current date and the input date
  const timeDifference = currentDate.getTime() - inputDate.getTime();

  // Convert the time difference to years
  const yearsDifference = timeDifference / (1000 * 60 * 60 * 24 * 365);

  // Check if the difference is exactly 10 years
  return numYears ? yearsDifference >= numYears : currentDate > inputDate;
}

export function isDateInTheFuture(dateString: string, numYears?: number) {
  const currentDate = new Date();
  const inputDate = new Date(dateString);

  // Calculate the difference in milliseconds between the current date and the input date
  const timeDifference = inputDate.getTime() - currentDate.getTime();

  // Convert the time difference to years
  const yearsDifference = timeDifference / (1000 * 60 * 60 * 24 * 365);

  // Check if the difference is exactly 10 years
  return numYears ? yearsDifference >= numYears : inputDate > currentDate;
}

export function isDateEarlier(firstDate: string, secondDate: string): boolean {
  const date1 = new Date(firstDate);
  const date2 = new Date(secondDate);

  return date1 < date2;
}

export function getExpiryDate(expiry_days: number): Date {
  const date = new Date();
  date.setDate(date.getDate() + expiry_days);
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59);
  date.setMilliseconds(999);
  return date;
}

export function formatDateStringToddMMYYYY(date: string): string {
  if (!date) return "";
  const startDate = new Date(date);
  const day = String(startDate.getDate()).padStart(2, "0");
  const month = String(startDate.getMonth() + 1).padStart(2, "0"); // January is 0!
  const year = startDate.getFullYear();

  return `${day}.${month}.${year}`;
}

export function formatDateToddMMYYYY(date: Date, delimiter: string = "."): string {
  if (!date) return "";
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
  const year = date.getFullYear();

  console.log("date: ", date, day, month, year);
  console.log("formatDate_ddMMYYYY: ", date, day, month, year);
  return `${day}${delimiter}${month}${delimiter}${year}`;
}

export function formatDateToYYYYMMdd(date: Date, delimiter: string = "."): string {
  if (!date) return "";
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
  const year = date.getFullYear();

  console.log("date: ", date, day, month, year);
  console.log("formatDate_ddMMYYYY: ", date, day, month, year);
  return `${year}${delimiter}${month}${delimiter}${day}`;
}

export const getDefaultExpiryDate = (daysOffset = 2) => {
  return new Date(new Date().setDate(new Date().getDate() + daysOffset))
    .toISOString()
    .split("T")[0];
};

export const parseCSVDate = (date: string) => {
  const [day, month, year] = date.split(".").map(Number);
  let fullYear = 0;
  if (year < 100) {
    fullYear = year < 50 ? 2000 + year : 1900 + year;
  } else {
    fullYear = year;
  }

  const newDate = new Date(fullYear, month - 1, day, 10, 10, 10);
  console.log(`parsing date: ${date} -> (${day}, ${month}, ${fullYear}) -> ${newDate}`);
  return newDate;
};

export function generatePassword(
  length: number,
  allowUpperCase: boolean = true,
  allowSymbols: boolean = true
): string {
  const numbers = "0123456789";
  const uppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";
  const symbols = "!@#$%^&*()_+~`|}{[]:;?><,./-=";
  let all = numbers + lowercaseLetters;
  if (allowUpperCase) all += uppercaseLetters;
  if (allowSymbols) all += symbols;

  // make sure, there is at least one number
  let password = "";
  password += numbers[Math.floor(Math.random() * numbers.length)];

  // make sure, there is at least one letter
  if (allowUpperCase) {
    password += uppercaseLetters[Math.floor(Math.random() * uppercaseLetters.length)];
  } else {
    password += lowercaseLetters[Math.floor(Math.random() * lowercaseLetters.length)];
  }

  // make sure, there is at least one symbol, if allowed
  if (allowSymbols) {
    password += symbols[Math.floor(Math.random() * symbols.length)];
  }

  for (let i = password.length, n = all.length; i < length; ++i) {
    password += all[Math.floor(Math.random() * n)];
  }

  // Shuffle the password to ensure randomness
  password = password
    .split("")
    .sort(() => 0.5 - Math.random())
    .join("");

  return password;
}

export function copyToClipboard(
  text: string,
  onfulfilled?: ((value: void) => void | PromiseLike<void>) | null | undefined,
  onrejected?: ((reason: any) => void) | null | undefined
) {
  //TODO: implent the promise and pass callback functions
  navigator.clipboard.writeText(text).then(onfulfilled, onrejected);
}

export const fullAdress = (address: Address): string => {
  let result: string = "";
  if (address) {
    result = address.street;
    if (address.street2) {
      result += (result.length > 0 ? ", " : "") + address.street2;
    }
    if (address.city) {
      result += result.length > 0 ? ", " : "";
      result += (address.zip ? address.zip + " " : "") + address.city;
    }
    if (address.country) {
      result += (result.length > 0 ? ", " : "") + address.country.label;
    }
  }
  return result;
};

export const applicantFullAdress = (applicant: Applicant): string => {
  let result: string = "";
  if (applicant) {
    result = applicant.residency_street || "";
    if (applicant.residency_city) {
      result += result.length > 0 ? ", " : "";
      result +=
        (applicant.residency_zip ? applicant.residency_zip + " " : "") + applicant.residency_city;
    }
    if (applicant.residency_country) {
      result += (result.length > 0 ? ", " : "") + applicant.residency_country.label;
    }
  }
  return result;
};

export const findCountry = (country_code: string): Country | null => {
  let code: string;

  if (country_code.length === 3) {
    code = COUNTRIES_3L_TO_2L[country_code];
  } else {
    code = country_code;
  }

  if (!code) return null;

  let country = COUNTRIES_FULL.find((c) => c.code === code) || null;
  if (country) {
    country = { ...country };
  }
  return country;
};

export function chunkArray<T>(array: T[], chunkSize: number): T[][] {
  const results = [];
  while (array.length) {
    results.push(array.splice(0, chunkSize));
  }
  return results;
}

export const urlAppendCacheBuster = (url: string): string => {
  if (url.indexOf("?") === -1) {
    url = url + "?";
  } else url = url + "&";

  url = url + "c=" + Date.now();

  return url;
};

export const setNestedProperty = (obj: any, path: string, value: any) => {
  const keys = path.split(".");
  let current = obj;

  for (let i = 0; i < keys.length - 1; i++) {
    if (!current[keys[i]]) {
      current[keys[i]] = {}; // Create the nested object if it doesn't exist
    }
    current = current[keys[i]];
  }

  current[keys[keys.length - 1]] = value;
};
