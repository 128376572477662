import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { WorkOrdersDict } from "../../types/orders";
import { useEffect, useState } from "react";
import { isDateInThePast } from "../../utils/utils";
import { ErrorOutlineOutlined, InfoOutlined } from "@mui/icons-material";

export interface WOBatchUpdateProps {
  allowClose: boolean;
}

export interface WOBatchUpdateResult {
  status: "OK" | "FAILED";
  message?: string;
}

interface WorkOrdersBatchUpdateDialogProps {
  open: boolean;
  onClose: () => void;
  onBatchUpdate: (values: WOBatchUpdateProps) => void;
  workOrders: WorkOrdersDict;
  season: string;
  updateResult: WOBatchUpdateResult | null;
}

const WorkOrdersBatchUpdateDialog = ({
  open,
  onClose,
  onBatchUpdate,
  workOrders,
  season,
  updateResult,
}: WorkOrdersBatchUpdateDialogProps) => {
  const [numToClose, setNumToClose] = useState<number>(0);
  const [inProgress, setInProgress] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      let count = 0;
      console.log("workOder count: " + Object.keys(workOrders).length);
      Object.entries(workOrders).forEach(([workOrderId, workOrder]) => {
        if (
          workOrder.status === "open" &&
          isDateInThePast(workOrder.end_date) &&
          workOrder.seasonId === season
        ) {
          count++;
        }
      });
      setNumToClose(count);
      setInProgress(false);
    }
  }, [open]);

  useEffect(() => {
    if (updateResult) {
      setInProgress(false);
    }
  }, [updateResult]);

  const handleBatchUpdate = async () => {
    // if (!validateInput()) {
    //   return;
    // }

    setInProgress(true);
    onBatchUpdate({ allowClose: true });
  };

  const handleClose = () => {
    onClose();
  };

  const getDialogContents = () => {
    if (updateResult === null) {
      if (inProgress) {
        return (
          <>
            <CircularProgress sx={{ marginRight: "16px" }} /> Closing work orders...
          </>
        );
      } else {
        if (numToClose > 0) {
          return (
            <Typography variant="body1">
              You are about to close {numToClose} work orders. Do you want to proceed?
            </Typography>
          );
        } else {
          return <Typography variant="body1">No work orders need to be closed.</Typography>;
        }
      }
    } else {
      if (updateResult.status === "OK") {
        return (
          <>
            <InfoOutlined sx={{ marginRight: "8px", color: "#669C35" }} />
            <Typography variant="body1" color={"#669C35"} fontWeight={"bold"}>
              The work orders have been successfully updated.
            </Typography>
          </>
        );
      } else
        return (
          <>
            <ErrorOutlineOutlined sx={{ marginRight: "8px", color: "#E32400" }} />
            <Typography variant="body1" color={"#E32400"} fontWeight={"bold"}>
              The work orders could not be updated!
            </Typography>
          </>
        );
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"sm"} fullWidth>
      <DialogTitle>Batch Update Applications</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "16px",
            minHeight: "48px",
          }}
        >
          {getDialogContents()}
        </Box>
      </DialogContent>
      <DialogActions>
        {!updateResult && numToClose > 0 && (
          <Button disabled={inProgress} onClick={handleBatchUpdate}>
            Update
          </Button>
        )}
        <Button disabled={inProgress} onClick={handleClose} color="primary">
          {updateResult || numToClose === 0 ? "Close" : "Cancel"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WorkOrdersBatchUpdateDialog;
