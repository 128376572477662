import React, { useState } from "react";
import { Tooltip, IconButton, Menu, MenuItem, TableCell, Checkbox } from "@mui/material";
import { MilitaryTech as ChangeStatus } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";
import Slideshow from "@mui/icons-material/Slideshow";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import { VisaApplication } from "../../types/visa-application";
import { isDateInThePast } from "../../utils/utils";
import { VirtualPresentationInstance } from "../../types/virtual-presentations";
import { APPLICATION_STATUS } from "../../types/visa-application";

interface ApplicationRowProps {
  index: number;
  applicationId: string;
  application: VisaApplication;
  getApplicantFullName: (applicantId: string) => string;
  handleStatusChange: (applicationId: string, status: string) => void;
  handleEditApplication: (applicationId: string) => void;
  handleSendLink: (applicationId: string) => void;
  handleAssignVirtualPresentation: (applicationId: string) => void;
  handleDelete: (applicationId: string | null) => void;
  isSelected: (applicationId: string) => boolean;
  getOrderName: (orderId: string) => string;
  displayVPStatusIcon: (vpInstance: VirtualPresentationInstance | null | undefined) => JSX.Element;
  displayStatusDescription: (status: string) => string;
  displayStatusLabel: (status: string) => string;
  displayHasDrivingLicense: (applicantId: string) => JSX.Element;
  handleCheckRow: (event: React.ChangeEvent<HTMLInputElement>, applicationId: string) => void;
}

const ApplicationRow = ({
  index,
  applicationId,
  application,
  getApplicantFullName,
  handleStatusChange,
  handleEditApplication,
  handleSendLink,
  handleAssignVirtualPresentation,
  handleDelete,
  isSelected,
  getOrderName,
  displayVPStatusIcon,
  displayStatusDescription,
  displayStatusLabel,
  displayHasDrivingLicense,
  handleCheckRow,
}: ApplicationRowProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (status: string) => {
    handleStatusChange(applicationId, status);
    handleCloseMenu();
  };

  const isItemSelected = isSelected(applicationId);
  const labelId = `enhanced-table-checkbox-${index}`;

  return (
    <>
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          checked={isItemSelected}
          inputProps={{
            "aria-labelledby": labelId,
          }}
          onChange={(event) => handleCheckRow(event, applicationId)}
        />
      </TableCell>
      <TableCell scope="row" size="small">
        {getApplicantFullName(application?.applicantId)}
      </TableCell>
      <TableCell size="small">{getOrderName(application?.workOrderId)}</TableCell>

      <TableCell
        size="small"
        sx={
          isDateInThePast(application.dataForm_expiry_date) && !application.privacy_policy_accepted
            ? { color: "red" }
            : { color: "black" }
        }
      >
        {application.dataForm_expiry_date || "no"}
      </TableCell>
      <TableCell size="small">
        {application.privacy_policy_accepted && <CheckIcon sx={{ color: "green" }} />}
      </TableCell>

      <TableCell size="small">{displayVPStatusIcon(application.virtualPresentation)}</TableCell>

      <TableCell size="small">{displayHasDrivingLicense(application.applicantId)}</TableCell>

      <TableCell size="small">
        <Tooltip title={displayStatusDescription(application.status)}>
          <div>{displayStatusLabel(application.status)}</div>
        </Tooltip>
      </TableCell>
      <TableCell align="right" style={{ width: 140 }} size="small">
        <Tooltip title="Edit application">
          <IconButton
            size="small"
            onClick={() => {
              handleEditApplication(applicationId);
            }}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Send Link">
          <IconButton size="small" onClick={() => handleSendLink(applicationId || "")}>
            <SendIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Virtual Presentation">
          <IconButton
            size="small"
            onClick={() => handleAssignVirtualPresentation(applicationId || "")}
          >
            <Slideshow />
          </IconButton>
        </Tooltip>
        <Tooltip title="Change Status">
          <IconButton size="small" onClick={handleOpenMenu}>
            <ChangeStatus />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(applicationId || null);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
          {APPLICATION_STATUS.map((status) => (
            <MenuItem
              key={applicationId + "-" + status.key}
              id={applicationId + "-" + status.key}
              onClick={() => handleMenuItemClick(status.key)}
            >
              {status.label}
            </MenuItem>
          ))}
        </Menu>
      </TableCell>
    </>
  );
};

export default ApplicationRow;
