import {
  CollectionReference,
  Query,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { Filter } from "../types/commons";
import { AppUser } from "../types/users";
import {
  VirtualPresentation,
  VirtualPresentationDoc,
  VirtualPresentationSlide,
  VirtualPresentationsDict,
} from "../types/virtual-presentations";
import db, { storage } from "../types/firebase";
import { applyFilter } from "../utils/db-utils";
import { deleteObject, getDownloadURL, ref, uploadString } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { urlAppendCacheBuster } from "../utils/utils";
import _ from "lodash";
import { getServerTime } from "./common-api";

export const isPresentationAssigned = async (presentationId: string) => {
  const presentationsRef = query(
    collection(db, "applications"),
    where("virtualvirtualPresentation.virtualPresentationId", "==", presentationId),
    limit(3)
  );

  const presentationsSnapshot = await getDocs(presentationsRef);

  return presentationsSnapshot.size > 0;
};

export const loadVirtualPresentation = async (presentationId: string) => {
  const presentationRef = doc(db, "presentations", presentationId);
  const presentationSnapshot = await getDoc(presentationRef);

  if (presentationSnapshot.exists()) {
    const presentation = presentationSnapshot.data() as VirtualPresentation;
    return presentation;
  } else {
    return null;
  }
};

export const loadVirtualPresentations = async (
  user: AppUser | null | undefined,
  filter: Filter = {},
  cLimit: number = 0
) => {
  console.log("api call -> loadVirtualPresentations");
  const sponsorId = user && user.sponsorId ? user.sponsorId : "";
  //for the time being, we are not using agentId. presentations are only for sponsors

  let presentationsRef: Query | CollectionReference;
  let presentationsSnapshot;
  const presentationsDict: VirtualPresentationsDict = {};

  if (sponsorId) {
    presentationsRef = query(collection(db, "presentations"), where("sponsorId", "==", sponsorId));
  } else {
    presentationsRef = collection(db, "presentations");
  }

  presentationsRef = applyFilter(presentationsRef, filter, cLimit);

  presentationsSnapshot = await getDocs(presentationsRef);

  presentationsSnapshot.forEach((doc) => {
    const presentation = doc.data() as VirtualPresentation;
    presentationsDict[doc.id] = presentation;
  });

  return presentationsDict;
};

export const deleteSlideImages = async (slide: VirtualPresentationSlide) => {
  if (slide.storageRef) {
    const oldImageRef = ref(storage, slide.storageRef);
    await deleteObject(oldImageRef);
  }
  if (slide.thumbnailRef) {
    const oldThumbnailRef = ref(storage, slide.thumbnailRef);
    await deleteObject(oldThumbnailRef);
  }
};

export const deletePresentation = async (presentation: VirtualPresentationDoc) => {
  if (!presentation.id) {
    return;
  }

  // delete all images from storage
  presentation.presentation.slides.forEach(async (slide) => {
    deleteSlideImages(slide);
  });
  return deleteDoc(doc(db, "presentations", presentation.id));
};

export const uploadSlideImages = async (presentation: VirtualPresentationDoc) => {
  if (presentation && presentation.presentation.slides) {
    console.log("api call -> uploadSlideImages");
    const newPresentation: VirtualPresentationDoc = _.cloneDeep(presentation);
    const slides = newPresentation.presentation.slides;

    // Step 1: Check all slides with local urls and delete them on the server
    for (const slide of slides) {
      if (slide.localImage) {
        if (slide.storageRef) {
          const oldImageRef = ref(storage, slide.storageRef);
          await deleteObject(oldImageRef);

          if (slide.thumbnailRef) {
            const oldThumbnailRef = ref(storage, slide.thumbnailRef);
            await deleteObject(oldThumbnailRef);
          }
        }

        // Step 2: Upload the image to Firebase Storage
        const slideId = uuidv4();
        const storageRef = ref(
          storage,
          "virtual-presentations/" + presentation.id + "/" + slideId + "." + slide.localExtension ||
            "jpg"
        );
        // Define metadata with Cache-Control
        const metadata = {
          cacheControl: "public, max-age=31536000", // 1 year
        };

        await uploadString(storageRef, slide.localImage, "data_url", metadata);
        slide.storageRef = storageRef.fullPath;

        const downloadURL: string = await getDownloadURL(storageRef);
        slide.url = urlAppendCacheBuster(downloadURL);

        if (slide.localThumbnail) {
          const thumbnailRef = ref(
            storage,
            "virtual-presentations/" +
              presentation.id +
              "/thumbnails/t-" +
              slideId +
              "." +
              slide.localExtension || "jpg"
          );

          await uploadString(thumbnailRef, slide.localThumbnail, "data_url", metadata);
          slide.thumbnailRef = thumbnailRef.fullPath;
          const thumbnailURL: string = await getDownloadURL(thumbnailRef);
          slide.thumbnail = urlAppendCacheBuster(thumbnailURL);
        }

        // delete localImage and localThumbnail from the newSlide object
        delete slide.localImage;
        delete slide.localThumbnail;
      }
    }

    return newPresentation;
  } else return presentation;
};

export const savePresentation = async (
  user: AppUser | null | undefined,
  presentation: VirtualPresentationDoc,
  createIfNoId: boolean = false
) => {
  if (!user) {
    return null;
  }
  let vpDoc = _.cloneDeep(presentation);
  const serverTime = await getServerTime();

  // if the document does not exist yet, create it first,
  // as we need the id for storing the slide images
  if (!vpDoc.id && createIfNoId) {
    console.log("new presentation, creating document.... ");
    vpDoc.presentation.createdAt = serverTime.iso;
    vpDoc.presentation.createdBy = user.id;
    vpDoc.id = (
      await addDoc(collection(db, "presentations"), { name: vpDoc.presentation.name })
    ).id;
    console.log("new presentation, creating document: " + vpDoc.id);
  }

  if (vpDoc.id) {
    // Upload images
    vpDoc = await uploadSlideImages(vpDoc);

    // Update the document
    const docRef = doc(db, "presentations", vpDoc.id);
    const presentationSnapshot = await getDoc(docRef);
    // Update the document
    if (presentationSnapshot) {
      if (presentation.id) {
        // set update time and user only if the presentation is not new.
        // otherwise the created time and user are already set above
        vpDoc.presentation.updatedAt = serverTime.iso;
        vpDoc.presentation.updatedBy = user.id;
      }
      await setDoc(docRef, vpDoc.presentation);
      console.log("updated presentation:", vpDoc.id);
    } else return null;
  } else {
    return null;
  }
  return vpDoc;
};
