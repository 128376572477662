import { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  MenuItem,
  Paper,
  TextField,
  Tooltip,
} from "@mui/material";
import { Card, CardActions, CardContent, CardHeader, Grid } from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { CheckBox, Delete as DeleteIcon, InfoOutlined as InfoIcon } from "@mui/icons-material";
// import { MuiColorInput, MuiColorInputColors, matchIsValidColor } from "mui-color-input";

import {
  AGENT_TITLES,
  AgentDoc,
  AgentErrors,
  AgentsDict,
  EMPTY_AGENT_CONTACT,
} from "../../types/agents";
import { EMPTY_AGENT, EMPTY_AGENT_ERRORS } from "../../types/agents";

import { isEMailValid, isPhoneValid } from "../../utils/utils";

import { AddressInput } from "../../components/address-input";
import { Country, EMPTY_COUNTRY, EMPTY_ADDRESS } from "../../types/commons";
import { TitledPage } from "../../components/titled-page";
import { AuthContext } from "../../components/auth-provider";
import { deleteAgent, loadAgents, saveAgent } from "../../data-functions/agent-api";
import { agentFullName } from "../../utils/applicant-utils";
import { SponsorsDict } from "../../types/sponsors";
import { loadSponsors } from "../../data-functions/system-data_api";
import _, { isEqual } from "lodash";
import { hasRole } from "../../utils/user-utils";
import { DocumentHistoryDialog } from "../dialogs/dialogDocumentHistory";

const AgentsData: React.FC = () => {
  const [agents, setAgents] = useState<AgentsDict>({});
  const [selectedAgent, setSelectedAgent] = useState<AgentDoc | null>(null);
  const [searchValue, setSearchValue] = useState<string>("");
  const [sponsors, setSponsors] = useState<SponsorsDict>({});

  const [openHistory, setOpenHistory] = useState(false);
  const [historyDoc, setHistoryDoc] = useState<string>("");

  const [addVisaContact, setAddVisaContact] = useState(false);

  const { currentUser } = useContext(AuthContext)!;

  const [errors, setErrors] = useState<AgentErrors>({ ...EMPTY_AGENT_ERRORS });

  const fetchAgents = async () => {
    const agentsDict: AgentsDict = await loadAgents(currentUser?.appUser);
    setAgents(agentsDict);
  };

  const fetchSponsors = async () => {
    const sponsorsDict: SponsorsDict = await loadSponsors(currentUser?.appUser);
    setSponsors(sponsorsDict);
  };

  useEffect(() => {
    fetchSponsors();
    fetchAgents();
  }, []);

  useEffect(() => {
    setSelectedAgent(null);
    fetchSponsors();
    fetchAgents();
  }, [currentUser]);

  const handleAddAgent = async () => {
    const newAgent = _.cloneDeep(EMPTY_AGENT);
    if (newAgent.contacts && newAgent.contacts.length > 0) {
      newAgent.contacts[0].type = "visa";
    }
    setErrors(_.cloneDeep(EMPTY_AGENT_ERRORS));
    newAgent.sponsorId = currentUser?.appUser?.sponsorId ?? newAgent.sponsorId;

    setAddVisaContact(false);
    setSelectedAgent({ id: "", agent: newAgent });
  };

  const handleSaveAgent = async () => {
    if (!selectedAgent) return;

    if (!validate()) {
      return;
    }

    const selectedAgentId = selectedAgent && selectedAgent?.id ? selectedAgent.id : "";

    console.log("selected id: " + selectedAgentId);
    console.log("selected agent: ");
    console.log(selectedAgent?.agent);

    const newId = await saveAgent(currentUser?.appUser, selectedAgent, true);
    selectedAgent.id = newId;

    const newAgents = _.cloneDeep(agents);
    newAgents[newId] = _.cloneDeep(selectedAgent.agent);
    setAgents(newAgents);

    console.log("new agents");
    console.log(newAgents);
  };

  const handleDelete = async (agentId: string) => {
    if (!agentId) return;

    if (window.confirm("Are you sure you want to delete this agent?")) {
      await deleteAgent(agentId);

      const newAgents = _.cloneDeep(agents);
      delete newAgents[agentId];
      if (selectedAgent?.id === agentId) {
        setSelectedAgent(null);
      }
      setAgents(newAgents);
    }
  };

  const handleSelectAgent = (agentId: string) => {
    if (agentId) {
      const agent = agents[agentId];
      if (agent) {
        if (selectedAgent && selectedAgent.id !== agentId) {
          const sAgent = selectedAgent.id ? agents[selectedAgent.id] : null;

          if (!isEqual(sAgent, selectedAgent.agent)) {
            const conf = window.confirm(
              "The selected agent has been changed! Press OK if you want to discard the changes."
            );
            if (!conf) {
              return;
            }
          }
        }

        console.log("selected agent: ", agentId);
        console.log("agent: ", agent);
        console.log("errors: ", errors);
        setAddVisaContact(agent.contacts && agent.contacts.length > 0);
        setSelectedAgent({ id: agentId, agent: { ...agent } });
      }
    }
  };

  const onInputSponsorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (selectedAgent) {
      const newAgent: AgentDoc = _.cloneDeep(selectedAgent);
      newAgent.agent.sponsorId = value;
      setSelectedAgent(newAgent);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("selected agent: ");
    console.log(selectedAgent);
    //    if (event.target.name === "expiry_date") {
    if (selectedAgent) {
      const newAgent: AgentDoc = _.cloneDeep(selectedAgent);
      newAgent.agent = { ...newAgent.agent, [event.target.name]: event.target.value };
      setSelectedAgent(newAgent);
    }
  };

  const handleContactInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("contact input change");
    console.log("target: " + event.target.name);
    console.log("new value: " + event.target.value);

    if (selectedAgent) {
      const newAgent: AgentDoc = { ...selectedAgent };
      const keys = event.target.name.split("_");
      const index = parseInt(keys[1] || "0");
      const field = keys[2];
      if (!newAgent.agent.contacts || newAgent.agent.contacts.length === 0) {
        newAgent.agent.contacts = [{ ...EMPTY_AGENT_CONTACT }];
      }
      newAgent.agent.contacts[index] = {
        ...newAgent.agent.contacts[index],
        [field]: event.target.value,
      };
      setSelectedAgent(newAgent);
    }
  };

  const handleAddressInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("address input change");
    console.log("target: " + event.target.name);
    console.log("new value: " + event.target.value);

    if (selectedAgent) {
      const newAgent: AgentDoc = _.cloneDeep(selectedAgent);

      if (!newAgent.agent.address) {
        newAgent.agent.address = { ...EMPTY_ADDRESS };
      }
      newAgent.agent.address = {
        ...newAgent.agent.address,
        [event.target.name]: event.target.value,
      };
      console.log(newAgent);
      setSelectedAgent(newAgent);
    }
  };

  const handleVisaContactAddressInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("address input change");
    console.log("target: " + event.target.name);
    console.log("new value: " + event.target.value);

    if (selectedAgent) {
      const newAgent: AgentDoc = _.cloneDeep(selectedAgent);

      if (!newAgent.agent.contacts || newAgent.agent.contacts.length === 0) {
        newAgent.agent.contacts = [{ ...EMPTY_AGENT_CONTACT }];
      }

      newAgent.agent.contacts[0].address = {
        ...newAgent.agent.contacts[0].address,
        [event.target.name]: event.target.value,
      };
      console.log(newAgent);
      setSelectedAgent(newAgent);
    }
  };

  const handleHideForCosChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedAgent) {
      const newAgent: AgentDoc = { ...selectedAgent };
      newAgent.agent.hideForCos = event.target.checked;
      setSelectedAgent(newAgent);
    }
  };

  const handleAddVisaContact = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("selected agent: ", selectedAgent);
    if (event.target.checked && selectedAgent && !selectedAgent.agent.contacts) {
      const newAgent: AgentDoc = _.cloneDeep(selectedAgent);
      newAgent.agent.contacts = [_.cloneDeep(EMPTY_AGENT_CONTACT)];
      newAgent.agent.contacts[0].type = "visa";
      console.log("new agent: ", newAgent);
      setSelectedAgent(newAgent);
    }
    console.log("add visa contact: ", event.target.checked);
    setAddVisaContact(event.target.checked);
  };

  const handleAddressCountryChange = (event: any, newValue: Country | null) => {
    if (selectedAgent) {
      const newAgent: AgentDoc = _.cloneDeep(selectedAgent);
      if (!newAgent.agent.address) {
        newAgent.agent.address = { ...EMPTY_ADDRESS };
      }
      newAgent.agent.address.country = newValue ?? { ...EMPTY_COUNTRY };
      setSelectedAgent(newAgent);
    }
  };

  const handleVisaContactAddressCountryChange = (event: any, newValue: Country | null) => {
    if (selectedAgent) {
      const newAgent: AgentDoc = _.cloneDeep(selectedAgent);
      if (!newAgent.agent.contacts || newAgent.agent.contacts.length === 0) {
        newAgent.agent.contacts = [_.cloneDeep(EMPTY_AGENT_CONTACT)];
      }
      newAgent.agent.contacts[0].address.country = newValue ?? { ...EMPTY_COUNTRY };
      setSelectedAgent(newAgent);
    }
  };
  const validate = () => {
    const newErrors = _.cloneDeep(EMPTY_AGENT_ERRORS);
    console.log("errors: ", newErrors);

    let isValid = true;
    if (!selectedAgent) return true;

    const agent = selectedAgent.agent;

    if (!agent.sponsorId) {
      newErrors.sponsorId = "Please, select a sponsor!";
      isValid = false;
    }

    if (agent.name.length < 3) {
      newErrors.name = "Please, enter a valid name for this agent!";
      isValid = false;
    }

    if (agent.surname.length < 3) {
      newErrors.surname = "Please, enter a valid name for this agent!";
      isValid = false;
    }

    if (agent.company.length < 3) {
      newErrors.company = "Please, enter a valid company name for this agent!";
      isValid = false;
    }

    if (!isEMailValid(agent.email)) {
      newErrors.email = "Please, enter a valid email addres, for this agent!";
      isValid = false;
    }
    if (agent.contacts && agent.contacts.length > 0) {
      const contact = agent.contacts[0];
      if (!(contact.email && isEMailValid(contact.email))) {
        newErrors.contacts[0].email = "Please, enter a valid email addres, for this agent!";
        isValid = false;
      }
      if (!(contact.phone && isPhoneValid(contact.phone))) {
        newErrors.contacts[0].phone = "Please, enter a valid phone number, for this agent!";
        isValid = false;
      }
      if (contact.name.length < 3) {
        newErrors.contacts[0].name = "Please, enter a valid name, for this agent!";
        isValid = false;
      }
      if (contact.surname.length < 3) {
        newErrors.contacts[0].surname = "Please, enter a valid name, for this agent!";
        isValid = false;
      }
      if (!contact.address.country) {
        newErrors.contacts[0].address.country = "Please, select a country, for this agent!";
        isValid = false;
      }
      if (!contact.address.city) {
        newErrors.contacts[0].address.city = "Please, enter a valid city, for this agent!";
        isValid = false;
      }
      if (!contact.address.street) {
        newErrors.contacts[0].address.street = "Please, enter a valid street, for this agent!";
        isValid = false;
      }
      if (!contact.address.zip) {
        newErrors.contacts[0].address.zip = "Please, enter a valid zip code, for this agent!";
        isValid = false;
      }
    }
    setErrors(newErrors);
    console.log("errors: ", newErrors);
    console.log("is valid: " + isValid);
    return isValid;
  };

  const handleShowHistory = async (documentId: string) => {
    setHistoryDoc(documentId);
    setOpenHistory(true);
  };

  // const handleColorChange = (newValue: string, colors: MuiColorInputColors) => {
  //   if (matchIsValidColor(newValue) && selectedAgent) {
  //     const newAgent: Agent = {
  //       ...selectedAgent,
  //       color: newValue,
  //     };
  //     setSelectedAgent(newAgent);
  //   } // boolean
  // };

  /* ------------ Search functions -------------- */
  const filteredAgents = Object.entries(agents).filter(
    ([agentId, agent]) =>
      searchValue.length < 3 ||
      agent.name.toLowerCase().includes(searchValue.toLowerCase()) ||
      agent.surname.toLowerCase().includes(searchValue.toLowerCase()) ||
      agent.company.toLowerCase().includes(searchValue.toLowerCase()) ||
      agent.email?.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <TitledPage title="Agents">
      <Box>
        <Box display={"flex"} sx={{ gap: 2 }}>
          <Button variant="outlined" color="primary" size="small" onClick={handleAddAgent}>
            Add New agent
          </Button>
          {hasRole(currentUser?.appUser, ["admin"]) && (
            <>
              <DocumentHistoryDialog
                open={openHistory}
                onClose={() => setOpenHistory(false)}
                documentId={historyDoc}
              />
              <Tooltip title="History">
                <span>
                  <IconButton
                    color="primary"
                    edge={"end"}
                    disabled={!selectedAgent}
                    onClick={() => handleShowHistory(selectedAgent?.id || "")}
                  >
                    <InfoIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Divider
                orientation="vertical"
                flexItem
                sx={{ marginTop: "8px", marginBottom: "4px" }}
              />
            </>
          )}
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search..."
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            sx={{ minWidth: "300px" }}
          />
        </Box>

        <Box sx={{ display: "flex", flexDirection: "row", marginTop: "1rem" }}>
          <Box
            sx={{
              flexGrow: 1,
              flexBasis: "0",
              width: "42%",
              marginRight: "8px",
            }}
          >
            <TableContainer component={Paper} sx={{ minHeight: "300px" }}>
              <Table sx={{ width: "100%" }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell component="th">Company</TableCell>
                    <TableCell component="th">Name</TableCell>
                    <TableCell component="th" align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredAgents.map(([agentId, agent]) => {
                    return (
                      <TableRow
                        key={agentId}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        onClick={() => handleSelectAgent(agentId)}
                        className={selectedAgent?.id === agentId ? "selected" : ""}
                      >
                        <TableCell scope="row" size="small">
                          {agent.company}
                        </TableCell>
                        <TableCell scope="row" size="small">
                          {agentFullName(agent)}
                        </TableCell>
                        <TableCell align="right" style={{ width: 100 }} size="small">
                          <Tooltip title="Delete">
                            <IconButton size="small" onClick={() => handleDelete(agentId || "")}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          {/* ------------- Agent Details ------------- */}
          <Box sx={{ width: "58%" }}>
            {selectedAgent && (
              <Card>
                <CardHeader title={"Agent Details"} />
                <CardContent>
                  <Grid container spacing={2} padding={2}>
                    {currentUser && currentUser.appUser && !currentUser.appUser.sponsorId && (
                      <Grid item xs={12} md={8}>
                        <TextField
                          error={!!errors.sponsorId}
                          helperText={errors.sponsorId}
                          name={"sponsorId"}
                          label={"Sponsor"}
                          select
                          onChange={onInputSponsorChange}
                          value={
                            (selectedAgent &&
                              selectedAgent.agent &&
                              selectedAgent.agent.sponsorId) ||
                            ""
                          }
                          required
                          margin={"dense"}
                          fullWidth
                        >
                          <MenuItem value={""} key={"no_sponsor"}>
                            {"Please, select a sponsor..."}
                          </MenuItem>
                          {Object.entries(sponsors).map(([sponsorId, sponsor]) => {
                            return (
                              <MenuItem value={sponsorId} key={sponsorId}>
                                {sponsor.name}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </Grid>
                    )}
                    <Grid item xs={12} md={12}>
                      <TextField
                        error={!!errors.company}
                        helperText={errors.company}
                        autoFocus
                        margin="dense"
                        name="company"
                        label="Company Name"
                        type="text"
                        fullWidth
                        value={selectedAgent && selectedAgent.agent.company}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <TextField
                        name="title"
                        label="Title"
                        select
                        margin="dense"
                        fullWidth
                        error={!!errors.title}
                        helperText={errors.title}
                        value={(selectedAgent && selectedAgent.agent.title) || ""}
                        onChange={handleInputChange}
                        required
                      >
                        {Object.entries(AGENT_TITLES).map(([key, value]) => {
                          return (
                            <MenuItem value={key} key={`title-menu-${key}`}>
                              {value}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Grid>

                    <Grid item xs={12} md={5}>
                      <TextField
                        error={!!errors.name}
                        helperText={errors.name}
                        margin="dense"
                        name="name"
                        label="Name"
                        type="text"
                        fullWidth
                        value={selectedAgent && selectedAgent.agent.name}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <TextField
                        error={!!errors.surname}
                        helperText={errors.surname}
                        margin="dense"
                        name="surname"
                        label="Family Name"
                        type="text"
                        fullWidth
                        value={selectedAgent && selectedAgent.agent.surname}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextField
                        error={!!errors.email}
                        helperText={errors.email}
                        margin="dense"
                        name="email"
                        label="Email"
                        type="text"
                        fullWidth
                        value={(selectedAgent && selectedAgent.agent.email) || ""}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        error={!!errors.phone}
                        helperText={errors.phone}
                        margin="dense"
                        name="phone"
                        label="Phone"
                        type="text"
                        fullWidth
                        value={(selectedAgent && selectedAgent.agent.phone) || ""}
                        onChange={handleInputChange}
                      />
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <AddressInput
                        address={
                          selectedAgent && selectedAgent.agent.address
                            ? selectedAgent.agent.address
                            : { ...EMPTY_ADDRESS }
                        }
                        errors={errors.address}
                        onInputChange={handleAddressInputChange}
                        onCountryChange={handleAddressCountryChange}
                        secondStreetLine={true}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedAgent?.agent.hideForCos || false}
                            onChange={handleHideForCosChange}
                            name="hideForCos"
                            value={selectedAgent?.agent.hideForCos || false}
                          />
                        }
                        label="Hide contact data on the COS application"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={addVisaContact}
                            onChange={handleAddVisaContact}
                            name="add-visa-contact"
                          />
                        }
                        label="Add a Visa Contact"
                      />
                    </Grid>

                    {addVisaContact && (
                      <>
                        <Grid item xs={12}>
                          <Divider>Visa Contact</Divider>
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <TextField
                            name="contacts_0_title"
                            label="Title"
                            select
                            margin="dense"
                            fullWidth
                            error={!!errors.contacts[0]?.title}
                            helperText={errors.contacts[0]?.title}
                            value={(selectedAgent && selectedAgent.agent?.contacts[0]?.title) || ""}
                            onChange={handleContactInputChange}
                            required
                          >
                            {Object.entries(AGENT_TITLES).map(([key, value]) => {
                              return (
                                <MenuItem value={key} key={`title-menu-${key}`}>
                                  {value}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} md={5}>
                          <TextField
                            error={!!errors.contacts[0]?.name}
                            helperText={errors.contacts[0]?.name}
                            margin="dense"
                            name="contacts_0_name"
                            label="Name"
                            type="text"
                            fullWidth
                            value={
                              (selectedAgent?.agent?.contacts &&
                                selectedAgent.agent.contacts[0]?.name) ||
                              ""
                            }
                            onChange={handleContactInputChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={5}>
                          <TextField
                            error={!!errors.contacts[0]?.surname}
                            helperText={errors.contacts[0]?.surname}
                            margin="dense"
                            name="contacts_0_surname"
                            label="Family Name"
                            type="text"
                            fullWidth
                            value={
                              (selectedAgent?.agent?.contacts &&
                                selectedAgent.agent.contacts[0]?.surname) ||
                              ""
                            }
                            onChange={handleContactInputChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <TextField
                            error={!!errors.contacts[0]?.email}
                            helperText={errors.contacts[0]?.email}
                            margin="dense"
                            name="contacts_0_email"
                            label="Email"
                            type="text"
                            fullWidth
                            value={
                              (selectedAgent?.agent?.contacts &&
                                selectedAgent.agent.contacts[0]?.email) ||
                              ""
                            }
                            onChange={handleContactInputChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            error={!!errors.contacts[0]?.phone}
                            helperText={errors.contacts[0]?.phone}
                            margin="dense"
                            name="contacts_0_phone"
                            label="Phone"
                            type="text"
                            fullWidth
                            value={
                              (selectedAgent?.agent?.contacts &&
                                selectedAgent.agent.contacts[0]?.phone) ||
                              ""
                            }
                            onChange={handleContactInputChange}
                          />
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <AddressInput
                            address={
                              selectedAgent?.agent?.contacts &&
                              selectedAgent.agent.contacts[0]?.address
                                ? selectedAgent.agent.contacts[0]?.address
                                : { ...EMPTY_ADDRESS }
                            }
                            errors={errors.contacts[0]?.address}
                            onInputChange={handleVisaContactAddressInputChange}
                            onCountryChange={handleVisaContactAddressCountryChange}
                            secondStreetLine={true}
                          />
                        </Grid>
                      </>
                    )}
                    {/* <Grid item xs={12} md={6}>
                    <MuiColorInput
                      error={!!errors.color}
                      helperText={errors.color}
                      margin="dense"
                      name="color"
                      label="Agent's main color"
                      fullWidth
                      value={(selectedAgent && selectedAgent.color) || "#00ff00"}
                      onChange={handleColorChange}
                      format="hex"
                    />
                  </Grid> */}
                  </Grid>
                </CardContent>
                <CardActions sx={{ padding: 2 }}>
                  <Box flexGrow={1}></Box>
                  <Button variant="contained" onClick={handleSaveAgent} sx={{ mr: 2 }}>
                    Save
                  </Button>
                </CardActions>
              </Card>
            )}
          </Box>
        </Box>
      </Box>
    </TitledPage>
  );
};

export default AgentsData;
