import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { ProtectedRoute } from "./components/protected-route";
import { AuthProvider } from "./components/auth-provider";

import { ThemeProvider } from "@mui/material/styles";

import theme from "./theme";

import Header from "./components/header";
import ImportApplicantsPage from "./pages/visa-applications/pageImportApplicants";
import ApplicantsPage from "./pages/visa-applications/pageApplicants";
import IbcMainPage from "./pages/general/pageGWFMain";
import { Questionnaire } from "./pages/questionnaire/questionnaire";
import LoginPage from "./pages/general/pageLogin";
import SponsorsData from "./pages/system-data/pageSponsors";
import EmployersData from "./pages/system-data/pageEmployers";
import ApplicationsPage from "./pages/visa-applications/pageApplications";
//import { APIProvider } from "@vis.gl/react-google-maps";
import { RegisterCandidate } from "./pages/recruiting/pageRegisterCandidate";
import RecruitingCampaigns from "./pages/recruiting/pageCampaigns";
import PageInterviews from "./pages/recruiting/pageInterviews";
import CandidatesPage from "./pages/recruiting/pageCandidates";
import PageWorkOrders from "./pages/visa-applications/pageWorkOrders";
import AgentsData from "./pages/system-data/pageAgents";
import { VirtualPresentationPlay } from "./pages/virtual-presentation/virtual-presentation";
import PageUsers from "./pages/system-data/pageUsers";
import VirtualPresentationsPage from "./pages/virtual-presentation/pageVirtualPresentations";
import ApplicationWrapper from "./components/application-wrapper";
import SeasonsData from "./pages/system-data/pageSeasons";
import { VerifyRegistrationPage } from "./pages/recruiting/pageVerifyRegistration";
import RecruitingImportPage from "./pages/recruiting/pageImportRecruitingData";

// load it from a real time config, or from an environment variable
//const API_KEY = "AIzaSyByPGqcdRMzObYHkz_PjbIHJQR1tAHblW4";

const App: React.FC = () => {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <Router>
          <ApplicationWrapper>
            <Header />
            <Routes>
              <Route
                path="/questionnaire/:qid"
                element={
                  //<APIProvider apiKey={API_KEY} language="en">
                  <Questionnaire />
                  //</APIProvider>
                }
              />
              <Route path="/finishSignUp" element={<LoginPage mode="finishsignup" />} />

              <Route path="/register/:cid" element={<RegisterCandidate />} />
              <Route path="/verify-registration/:cid" element={<VerifyRegistrationPage />} />

              <Route path="/virtual-presentation/:pid" element={<VirtualPresentationPlay />} />
              <Route
                path="/import-applicants"
                element={
                  <ProtectedRoute allowedRoles={["admin", "operator"]}>
                    <ImportApplicantsPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/campaigns"
                element={
                  <ProtectedRoute allowedRoles={["admin", "recruiter"]}>
                    <RecruitingCampaigns />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/candidates"
                element={
                  <ProtectedRoute allowedRoles={["admin", "operator", "recruiter"]}>
                    <CandidatesPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/interviews"
                element={
                  <ProtectedRoute allowedRoles={["admin", "recruiter"]}>
                    <PageInterviews />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/recruiting-import"
                element={
                  <ProtectedRoute allowedRoles={["admin", "recruiter"]}>
                    <RecruitingImportPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/applicants/:applicantId?"
                element={
                  <ProtectedRoute allowedRoles={["admin", "operator"]}>
                    <ApplicantsPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/applications"
                element={
                  <ProtectedRoute allowedRoles={["admin", "operator"]}>
                    <ApplicationsPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/orders"
                element={
                  <ProtectedRoute allowedRoles={["admin", "operator"]}>
                    <PageWorkOrders />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/presentations"
                element={
                  <ProtectedRoute allowedRoles={["admin", "operator"]}>
                    <VirtualPresentationsPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/employers"
                element={
                  <ProtectedRoute allowedRoles={["admin", "operator"]}>
                    <EmployersData />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/sponsors"
                element={
                  <ProtectedRoute allowedRoles={["admin", "operator"]}>
                    <SponsorsData />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/agents"
                element={
                  <ProtectedRoute allowedRoles={["admin", "operator"]}>
                    <AgentsData />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/seasons"
                element={
                  <ProtectedRoute allowedRoles={["admin"]}>
                    <SeasonsData />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/users"
                element={
                  <ProtectedRoute allowedRoles={["admin"]}>
                    <PageUsers />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/"
                element={
                  <ProtectedRoute allowedRoles={[]}>
                    <IbcMainPage />
                  </ProtectedRoute>
                }
              />
              <Route path="/login" element={<LoginPage mode="login" />} />
            </Routes>
          </ApplicationWrapper>
        </Router>
      </ThemeProvider>
    </AuthProvider>
  );
};

export default App;
