import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { TableComponents } from "react-virtuoso";

export type TableComponentsContextType = {
  selectedId: string;
  handleSelect: (id: string) => void;
};

export const VirtuosoTableComponents: TableComponents<[string, any]> = {
  Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props: any) => (
    <Table {...props} style={{ borderCollapse: "separate", tableLayout: "fixed" }} />
  ),
  TableHead: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableHead {...props} ref={ref} />
  )),
  TableRow: ({ item: _item, context, ...props }) => {
    const { selectedId, handleSelect } = context as TableComponentsContextType;
    const [id] = _item;
    return (
      <TableRow
        key={id}
        className={selectedId === id ? "selected" : ""}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
        onClick={() => handleSelect(id)}
        {...props}
      />
    );
  },
  TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
  TableFoot: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableFooter {...props} ref={ref} />
  )),
};
