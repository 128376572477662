import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  TextField,
  Box,
  Card,
  CardContent,
  Grid,
  FormControlLabel,
  Checkbox,
  Divider,
  MenuItem,
  Typography,
  LinearProgress,
  Dialog,
  DialogContent,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";

import { parse } from "papaparse";
import { Applicant, ApplicantDoc } from "../../types/applicants";
import {
  APPLICATION_MAPPING,
  CSV_APPLICANT_MAPPING,
  CSV_MAPPING_BOOLEAN_PROPERTIES,
  CSV_MAPPING_COUNTRY_PROPERTIES,
  CSV_MAPPING_DATE_PROPERTIES,
  parseChildrenFromCSV,
  parseTravelAbroad,
  parseTravelToUK,
} from "../../utils/importers/importerHOPS_applicants";
import { TitledPage } from "../../components/titled-page";
import {
  DEFAULT_WORK_ORDER_DURATION,
  EmployersDict,
  SeasonsDict,
  WorkOrder,
  WorkOrderDoc,
  WorkOrdersDict,
} from "../../types/orders";
import { AuthContext } from "../../components/auth-provider";
import { AgentsDict } from "../../types/agents";
import { loadAgents } from "../../data-functions/agent-api";
import { loadSeasons, loadWorkOrders, storeWorkOrder } from "../../data-functions/orders-api";
import { loadEmployers, loadSponsors } from "../../data-functions/system-data_api";
import { SponsorsDict } from "../../types/sponsors";
import {
  findCountry,
  formatDateToddMMYYYY,
  formatDateToYYYYMMdd,
  parseCSVDate,
  setNestedProperty,
} from "../../utils/utils";
import { APPLICATION_STATUS, VisaApplication } from "../../types/visa-application";
import { existingApplicants, storeApplicant } from "../../data-functions/applicants-api";
import {
  deleteApplications,
  loadApplications,
  storeApplication,
  updateApplication,
} from "../../data-functions/applications-api";

type ImportOptions = {
  addApplication: boolean;
  status: string;
  fileHasOrders: boolean;
  addOrder: boolean;
  existingApplication: "replace" | "ignore";
};

// Add this type for import statistics
type ImportStats = {
  total: number;
  current: number;
  newApplicants: number;
  applications: number;
  missingEmployers: string[];
  ignoredApplications: number;
  replacedApplications: number;
  alternateStartDates: string[];
};

const ImportApplicantsPage: React.FC = () => {
  const [sponsors, setSponsors] = useState<SponsorsDict>({});
  const [selectedSponsor, setSelectedSponsor] = useState<string>("");
  const [agents, setAgents] = useState<AgentsDict>({});
  const [employers, setEmployers] = useState<EmployersDict>({});
  const [selectedAgent, setSelectedAgent] = useState<string>("");
  const [seasons, setSeasons] = useState<SeasonsDict>({});
  const [file, setFile] = useState<File | null>(null);
  const [showProgress, setShowProgress] = useState(false);
  const [importOptions, setImportOptions] = useState<ImportOptions>({
    addApplication: true,
    status: "imported",
    fileHasOrders: true,
    addOrder: true,
    existingApplication: "ignore",
  });

  // const [testDate, setTestDate] = useState<string>("");
  // const [testDateResult, setTestDateResult] = useState<string>("");

  const [errors, setErrors] = useState<{
    sponsor: string;
    agent: string;
  }>({ sponsor: "", agent: "" });

  // Update the progress state to use the new type
  const [progress, setProgress] = useState<ImportStats>({
    total: 0,
    current: 0,
    newApplicants: 0,
    applications: 0,
    missingEmployers: [],
    ignoredApplications: 0,
    replacedApplications: 0,
    alternateStartDates: [],
  });

  const [importComplete, setImportComplete] = useState(false);

  const { currentUser } = useContext(AuthContext)!;

  const fetchAgents = async () => {
    const agentsDict = await loadAgents(currentUser?.appUser);

    if (agentsDict) {
      setAgents(agentsDict);
    } else {
      setAgents({});
    }
  };

  const fetchEmployers = async () => {
    const employers = await loadEmployers(currentUser?.appUser);
    if (employers) {
      setEmployers(employers);
    } else {
      setEmployers({});
    }
  };

  const fetchSponsors = async () => {
    const sponsors = await loadSponsors(currentUser?.appUser);
    if (sponsors) {
      setSponsors(sponsors);
    } else {
      setSponsors({});
    }
  };

  const fetchSeasons = async () => {
    const seasonsDict: SeasonsDict = await loadSeasons(currentUser?.appUser);
    setSeasons(seasonsDict);
  };

  useEffect(() => {
    if (currentUser?.appUser?.sponsorId) {
      setSelectedSponsor(currentUser.appUser.sponsorId);
    }
    fetchSponsors();
    fetchAgents();
    fetchEmployers();
    fetchSeasons();
  }, [currentUser]);

  // const testDateParsing = async () => {
  //   if (file) {
  //     const text: string = await file.text();
  //     const results = parse(text, {
  //       header: true,
  //       delimiter: ";",
  //       skipEmptyLines: "greedy",
  //     });

  //     console.log("------- starting import -------");

  //     for (let row of results.data) {
  //       console.log("------- next row -------");
  //       const woBeginKey = APPLICATION_MAPPING["order_start_date"];

  //       console.log("parsing row: ", row);
  //       if (typeof row === "object" && row !== null) {
  //         const dataRow = row as Record<string, string>;
  //         const orderBeginDateString = dataRow[woBeginKey].trim();

  //         const orderBeginDate = parseCSVDate(orderBeginDateString);
  //         console.log("parsed date: ", orderBeginDate);
  //       }
  //     }
  //   }
  // };

  // const handleTestDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setTestDate(e.target.value);
  // };

  const mapCsvRowToApplicant = async (row: Record<string, string>) => {
    const applicant: Partial<Applicant> = {
      name: "",
      surname: "",
      agentId: selectedAgent,
      sponsorId: currentUser?.appUser?.sponsorId || selectedSponsor,
    };

    const csvKeys = Object.keys(row);
    csvKeys.forEach((key) => {
      key = key.trim();
      const csvValue = row[key] ? row[key].trim() : "";
      const propertyName = CSV_APPLICANT_MAPPING[key];
      if (propertyName) {
        if (propertyName.toLowerCase() === "gender") {
          applicant.gender = csvValue.slice(0, 1).toUpperCase();
        } else {
          setNestedProperty(applicant, propertyName, csvValue);
        }
      }

      const booleanPropertyName = CSV_MAPPING_BOOLEAN_PROPERTIES[key];
      if (booleanPropertyName) {
        const booleanValue = csvValue.toLowerCase() === "yes" ? true : false;
        setNestedProperty(applicant, booleanPropertyName, booleanValue);
      }

      const datePropertyName = CSV_MAPPING_DATE_PROPERTIES[key];
      if (datePropertyName) {
        const date = parseCSVDate(row[key]);
        setNestedProperty(applicant, datePropertyName, formatDateToYYYYMMdd(date, "-"));
      }

      const countryPropertyName = CSV_MAPPING_COUNTRY_PROPERTIES[key];
      if (countryPropertyName) {
        const country = findCountry(row[key].trim());
        if (country) {
          setNestedProperty(applicant, countryPropertyName, country);
        }
      }
    });

    if (applicant.has_children) {
      const children = parseChildrenFromCSV("child", row);
      applicant.children = children;
    }

    if (applicant.has_travelled_to_UK) {
      const travelToUK = parseTravelToUK(row);
      applicant.travel_details_UK = travelToUK;
    }

    if (applicant.has_travelled_abroad) {
      const travelAbroad = parseTravelAbroad(row);
      applicant.travel_details_abroad = travelAbroad;
    }

    console.log("applicant: ", applicant);

    let appl: ApplicantDoc | null = { id: "", applicant: applicant as Applicant };
    if (!applicant.email) {
      return appl;
    } else {
      applicant.email = applicant.email.toLowerCase();
    }

    let newApplicantId: string | null = "";
    const existingApplicant = await existingApplicants(currentUser?.appUser, [applicant.email]);
    if (existingApplicant && existingApplicant.length > 0) {
      console.log("existing applicant found");
      newApplicantId = existingApplicant[0].id;
      console.log("existing applicant: ", newApplicantId);
    } else {
      console.log("new applicant found");
      newApplicantId = await storeApplicant(currentUser?.appUser, appl, true);
      setProgress((prev) => ({ ...prev, newApplicants: prev.newApplicants + 1 }));
    }

    if (newApplicantId) {
      appl.id = newApplicantId;
    }
    return appl;
  };

  const findSeasonId = (year: number): string | undefined => {
    return Object.entries(seasons).find(([id, season]) => {
      return new Date(season.start_date).getFullYear() === year;
    })?.[0];
  };

  const findWorkOrder = (row: Record<string, string>, workOrders: WorkOrdersDict) => {
    const oderKey = APPLICATION_MAPPING["order"];
    const orderNo = row[oderKey].trim();
    let workOrderId: string | undefined;
    if (orderNo) {
      workOrderId = Object.keys(workOrders).find((key) => {
        // console.log("wo: ", workOrders[key].name.toLowerCase());

        return workOrders[key].name.toLowerCase().indexOf("_order " + orderNo + "_") > -1;
      });
    }
    return workOrderId;
  };

  const parseWorkOrder = (row: Record<string, string>) => {
    const employerId = parseEmployer(row) || "";
    if (!employerId) {
      setProgress((prev) => ({
        ...prev,
        missingEmployers: [...prev.missingEmployers, row[APPLICATION_MAPPING["employer"]]],
      }));
      return null;
    }

    const oderKey = APPLICATION_MAPPING["order"];
    const woBeginKey = APPLICATION_MAPPING["order_start_date"];

    const orderNo = row[oderKey].trim();

    const orderBeginDateString = row[woBeginKey].trim();

    const orderBeginDate = parseCSVDate(orderBeginDateString);

    const seasonId = findSeasonId(orderBeginDate.getFullYear());
    console.log("found season: ", seasonId ? seasons[seasonId] : "none");

    const woName =
      employers[employerId].name + "_Order " + orderNo + "_" + formatDateToddMMYYYY(orderBeginDate);

    const workOrder: WorkOrder = {
      name: woName,
      start_date: orderBeginDate.toISOString().slice(0, 10),
      employerId: employerId,
      seasonId: seasonId || "",
      sponsorId: selectedSponsor,
      agentId: selectedAgent,
      status: "open",
      demand: 0,
    };

    console.log("new work order: ", workOrder);

    orderBeginDate.setDate(orderBeginDate.getDate() + DEFAULT_WORK_ORDER_DURATION);
    workOrder.end_date = orderBeginDate.toISOString().slice(0, 10);

    return workOrder;
  };

  const createWorkOrder = async (row: Record<string, string>) => {
    const workOrder = parseWorkOrder(row);
    if (!workOrder) {
      return null;
    }

    let newWO = await storeWorkOrder(currentUser?.appUser, { id: "", workOrder }, true);
    if (!newWO) {
      newWO = { id: "", workOrder };
    }
    return newWO;
  };

  const parseEmployer = (row: Record<string, string>) => {
    const employerKey = APPLICATION_MAPPING["employer"];
    const employerName = row[employerKey].trim();
    let employerId: string | undefined;
    if (employerName) {
      employerId = Object.keys(employers).find(
        (key) => employers[key].name.toLowerCase() === employerName.toLowerCase()
      );
    }

    console.log(`parsed employer id ${employerId} for "${employerName}"`);
    return employerId;
  };

  const validate = async () => {
    let isValid = true;

    if (!selectedAgent) {
      isValid = false;
      setErrors({ ...errors, agent: "Please select an agent" });
    }

    if (!selectedSponsor) {
      isValid = false;
      setErrors({ ...errors, sponsor: "Please select a sponsor" });
    }

    return isValid;
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
    }
  };

  const handleToggleChange = (event: React.MouseEvent<HTMLElement>, newValue: string) => {
    const key = (event.target as Element).id;

    if (key && importOptions && (newValue === "replace" || newValue === "ignore")) {
      setImportOptions({ ...importOptions, existingApplication: newValue });
    }
  };

  const addApplication = async (
    applicantId: string,
    workOrderId: string,
    seasonId: string,
    workOrders: WorkOrdersDict,
    startDate: string = ""
  ) => {
    const application: VisaApplication = {
      applicantId: applicantId,
      type: { key: "seasonal", label: "Seasonal Worker" },
      status: importOptions.status || "new",
      seasonId,
      workOrderId: workOrderId || "",
      sponsorId: selectedSponsor,
      agentId: selectedAgent,
    };

    if (startDate && workOrders && startDate !== workOrders[workOrderId].start_date) {
      console.log("found alternate start date: ", startDate);
      console.log("work order start date: ", workOrders[workOrderId].start_date);
      application.alternate_start_date = startDate;
    }

    console.log("application: ", application);
    const newAppl = await storeApplication(currentUser?.appUser, { id: "", application }, true);
    if (newAppl) {
      setProgress((prev) => ({ ...prev, applications: prev.applications + 1 }));
      console.log("new application stored successfully");
    } else {
      console.log("application could not be stored");
    }
  };

  const replaceApplication = async (
    applicationId: string,
    applicantId: string,
    workOrderId: string,
    seasonId: string,
    workOrders: WorkOrdersDict,
    startDate: string
  ) => {
    await deleteApplications([applicationId]);
    await addApplication(applicantId, workOrderId, seasonId, workOrders, startDate);
    setProgress((prev) => ({
      ...prev,
      replacedApplications: prev.replacedApplications + 1,
    }));
  };

  const handleUpload = async () => {
    const isValid = await validate();
    if (!isValid) {
      return;
    } else {
      setErrors({ ...errors, agent: "", sponsor: "" });
    }

    if (file) {
      setShowProgress(true);
      setImportComplete(false);
      try {
        const text: string = await file.text();
        const results = parse(text, {
          header: true,
          delimiter: ";",
          skipEmptyLines: "greedy",
        });

        setProgress({
          current: 0,
          total: results.data.length,
          newApplicants: 0,
          applications: 0,
          missingEmployers: [],
          ignoredApplications: 0,
          replacedApplications: 0,
          alternateStartDates: [],
        });

        let workOrders: WorkOrdersDict = {};
        if (importOptions.addApplication) {
          workOrders = await loadWorkOrders(currentUser?.appUser, true);
        }

        console.log("------- starting import -------");

        for (let row of results.data) {
          console.log("------- next row -------");

          console.log("parsing row: ", row);
          if (typeof row === "object" && row !== null) {
            const dataRow = row as Record<string, string>;
            const applicant = await mapCsvRowToApplicant(dataRow);
            console.log("parsed applicant: ", applicant);
            if (applicant && applicant.id) {
              let workOrderId = "";
              let startDate = "";
              let wo: WorkOrderDoc | null = null;
              let seasonId = "";

              // ----------  Handling Work Orders ----------

              if (importOptions.addApplication) {
                console.log("finding work order");
                workOrderId = findWorkOrder(dataRow, workOrders) || "";
                console.log("found work order (id): ", workOrderId);

                if (!workOrderId) {
                  if (importOptions.addOrder) {
                    console.log("creating work order");
                    wo = await createWorkOrder(dataRow);

                    if (wo) {
                      console.log("new work order id: ", wo.id);
                      if (wo.id) {
                        workOrders[wo.id] = wo.workOrder;
                        workOrderId = wo.id;
                      }
                      seasonId = wo.workOrder.seasonId;
                      startDate = wo.workOrder.start_date;
                    } else {
                      continue;
                    }
                  } else {
                    console.log("No work order found for applicant");
                  }
                } else {
                  seasonId = workOrders[workOrderId].seasonId;
                  const sd = parseCSVDate(dataRow[APPLICATION_MAPPING["order_start_date"]]);
                  startDate = sd.toISOString().slice(0, 10);
                  wo = { id: workOrderId, workOrder: workOrders[workOrderId] };
                  console.log("found season: ", seasonId ? seasons[seasonId] : "none");
                }

                // ----------  Handling Applications ----------

                // If the work order id is not found, we need cannot create an application
                if (workOrderId) {
                  // Check if the applicant has an existing application
                  const existingApplications = await loadApplications(currentUser?.appUser, {
                    applicantId: applicant.id,
                  });
                  console.log("existing application: ", existingApplications);
                  // If the applicant has no existing application, create a new one
                  if (Object.keys(existingApplications).length === 0) {
                    addApplication(applicant.id, workOrderId, seasonId, workOrders, startDate);
                  } else {
                    // Check if the existing application has the same work order id
                    console.log("multiple existing applications found");
                    console.log("checking for an application with the same work order id");
                    const sameApplication = Object.keys(existingApplications).find((key) => {
                      return existingApplications[key].workOrderId === workOrderId;
                    });
                    console.log("same application: ", sameApplication);
                    if (!sameApplication) {
                      console.log("no application with the same work order id found");
                      console.log("checking for an application within the same season");
                      // Find all applications with the same season id
                      const differentWorkOrderApplications = Object.keys(
                        existingApplications
                      ).filter(
                        (key) => existingApplications[key].seasonId === wo?.workOrder.seasonId
                      );
                      console.log(
                        "different work order applications: ",
                        differentWorkOrderApplications
                      );

                      if (differentWorkOrderApplications.length !== 0) {
                        console.log("Application exists but in a different work order");
                        console.log(
                          "application already exists: ",
                          differentWorkOrderApplications[0]
                        );
                        if (importOptions.existingApplication === "replace") {
                          console.log("replacing the application");
                          replaceApplication(
                            differentWorkOrderApplications[0],
                            applicant.id,
                            workOrderId,
                            seasonId,
                            workOrders,
                            startDate
                          );
                        } else {
                          console.log("ignoring the application");
                          setProgress((prev) => ({
                            ...prev,
                            ignoredApplications: prev.ignoredApplications + 1,
                          }));
                        }
                      } else {
                        // Create a new application for the current work order and season
                        addApplication(applicant.id, workOrderId, seasonId, workOrders, startDate);
                      }
                    } else {
                      console.log("application with the same work order id found");
                      const existingApplication = existingApplications[sameApplication];
                      if (
                        workOrders[workOrderId].start_date !== startDate &&
                        existingApplication.alternate_start_date !== startDate
                      ) {
                        console.log("alternate start date found");
                        console.log("existing application: ", existingApplication);
                        console.log("work order: ", workOrders[workOrderId]);
                        console.log("start date: ", startDate);
                        await updateApplication(currentUser?.appUser, sameApplication, {
                          alternate_start_date: startDate,
                          status: importOptions.status,
                        });
                        setProgress((prev) => ({
                          ...prev,
                          alternateStartDates: [
                            ...prev.alternateStartDates,
                            applicant.applicant.email + " - " + startDate,
                          ],
                        }));
                      } else {
                        console.log("starting date is the same as the existing application");
                      }
                    }
                  }
                }
              } else {
                setProgress((prev) => ({ ...prev, applications: prev.applications + 1 }));
              }
            }
            setProgress((prev) => ({ ...prev, current: prev.current + 1 }));
          }
        }
        setImportComplete(true);
      } finally {
        console.log("------- import complete -------");
      }
    }
  };

  const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setImportOptions({ ...importOptions, [e.target.name]: e.target.checked });
  };

  const onInputComboChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.name) {
      case "agentId":
        setSelectedAgent(event.target.value);
        break;
      case "sponsorId":
        setSelectedSponsor(event.target.value);
        break;
      case "status":
        setImportOptions({ ...importOptions, status: event.target.value });
        break;
    }
  };

  const handleCloseDialog = () => {
    setShowProgress(false);
    setImportComplete(false);
  };

  return (
    <TitledPage title="Import Applicants">
      <Box maxWidth="md" margin="auto" sx={{}}>
        <Dialog open={showProgress} fullWidth maxWidth="sm">
          <DialogContent>
            <Box sx={{ width: "100%", textAlign: "center", py: 3 }}>
              {!importComplete ? (
                <>
                  <Typography variant="h6" gutterBottom>
                    Importing Data...
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Processing record {progress.current} of {progress.total}
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={(progress.current / progress.total) * 100}
                    sx={{ mt: 2 }}
                  />
                </>
              ) : (
                <>
                  <Typography variant="h6" gutterBottom color="success.main">
                    Import Complete
                  </Typography>
                  <Box sx={{ mt: 2, mb: 3 }}>
                    <Typography variant="body1" gutterBottom>
                      Total records processed: {progress.total}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      New applicants added: {progress.newApplicants}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Applications ignored: {progress.ignoredApplications}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Applications replaced: {progress.replacedApplications}
                    </Typography>
                    {importOptions.addApplication && (
                      <Typography variant="body1" gutterBottom>
                        Applications created: {progress.applications}
                      </Typography>
                    )}
                    {progress.missingEmployers.length > 0 && (
                      <Box sx={{ mt: 2 }}>
                        <Typography variant="body1" color="error" gutterBottom>
                          Missing employers ({progress.missingEmployers.length}):
                        </Typography>
                        <Box
                          sx={{
                            maxHeight: 150,
                            overflow: "auto",
                            bgcolor: "#f5f5f5",
                            p: 1,
                            borderRadius: 1,
                          }}
                        >
                          {progress.missingEmployers.map((employer, index) => (
                            <Typography key={index} variant="body2" gutterBottom>
                              • {employer}
                            </Typography>
                          ))}
                        </Box>
                      </Box>
                    )}
                    {progress.alternateStartDates.length > 0 && (
                      <Box sx={{ mt: 2 }}>
                        <Typography variant="body1" color="error" gutterBottom>
                          Alternate start dates ({progress.alternateStartDates.length}):
                        </Typography>
                        <Box
                          sx={{
                            maxHeight: 150,
                            overflow: "auto",
                            bgcolor: "#f5f5f5",
                            p: 1,
                            borderRadius: 1,
                          }}
                        >
                          {progress.alternateStartDates.map((date, index) => (
                            <Typography key={index} variant="body2" gutterBottom>
                              • {date}
                            </Typography>
                          ))}
                        </Box>
                      </Box>
                    )}
                  </Box>
                  <Button variant="contained" onClick={handleCloseDialog} sx={{ mt: 2 }}>
                    Close
                  </Button>
                </>
              )}
            </Box>
          </DialogContent>
        </Dialog>
        <Card sx={{ padding: "2rem", border: "solid 1px #DFDFDF" }} variant="outlined">
          <CardContent sx={{ padding: 0 }}>
            <Grid container gap={1}>
              <Grid item xs={12}>
                <TextField
                  type="file"
                  id="outlined-basic"
                  label="Import file"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  onChange={handleFileChange}
                />
              </Grid>
              {currentUser && currentUser.appUser && !currentUser.appUser.sponsorId && (
                <React.Fragment>
                  <Grid item xs={12}>
                    <Divider textAlign="left">Assign a sponsor</Divider>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name={"sponsorId"}
                      label={"Sponsor"}
                      select
                      error={!!errors.sponsor}
                      helperText={errors.sponsor}
                      onChange={onInputComboChange}
                      value={selectedSponsor || ""}
                      required
                      fullWidth
                      margin={"dense"}
                    >
                      <MenuItem value="" key="no_sponsor">
                        <Typography fontStyle={"italic"}>no sponsor selected</Typography>
                      </MenuItem>
                      {Object.entries(sponsors).map(([sponsorId, sponsor]) => {
                        return (
                          <MenuItem value={sponsorId} key={sponsorId}>
                            {sponsor.name}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                </React.Fragment>
              )}

              {currentUser && currentUser.appUser && !currentUser.appUser.agentId && (
                <React.Fragment>
                  <Grid item xs={12}>
                    <Divider textAlign="left">Assign an agent</Divider>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name={"agentId"}
                      label={"Agent"}
                      select
                      error={!!errors.agent}
                      helperText={errors.agent}
                      onChange={onInputComboChange}
                      value={selectedAgent || ""}
                      required
                      fullWidth
                      margin={"dense"}
                    >
                      <MenuItem value="" key="no_agent">
                        <Typography fontStyle={"italic"}>no agent selected</Typography>
                      </MenuItem>
                      {Object.entries(agents).map(([agentId, agent]) => {
                        return (
                          <MenuItem value={agentId} key={agentId}>
                            {agent.company}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                </React.Fragment>
              )}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={importOptions.addApplication}
                      onChange={handleOptionChange}
                      name="addApplication"
                      disabled={showProgress || !file}
                    />
                  }
                  label="Automatically create an application for each applicant"
                />
              </Grid>
              <Grid item xs={12} md={3} sx={{ paddingLeft: 3 }}>
                <TextField
                  name={"status"}
                  label={"Application status"}
                  select
                  onChange={onInputComboChange}
                  value={importOptions.status || ""}
                  required
                  fullWidth
                  disabled={!importOptions.addApplication}
                  margin={"dense"}
                >
                  {Object.entries(APPLICATION_STATUS).map(([key, vtype]) => {
                    return (
                      <MenuItem value={vtype.key} key={key}>
                        {vtype.label}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  sx={{ paddingLeft: 3 }}
                  control={
                    <Checkbox
                      checked={importOptions.addOrder}
                      onChange={handleOptionChange}
                      name="addOrder"
                      disabled={showProgress || !file || !importOptions.addApplication}
                    />
                  }
                  label="Automatically create work orders if not present"
                />
              </Grid>
              <Grid item xs={12}>
                <ToggleButtonGroup
                  id="duplicate_applications"
                  value={importOptions.existingApplication || "ignore"}
                  exclusive
                  onChange={handleToggleChange}
                  aria-label="Campaign Status"
                  sx={{
                    marginTop: "8px",
                    ml: 3,
                  }}
                >
                  <ToggleButton
                    id="duplicate_applications_ignore"
                    value="ignore"
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: (theme) => theme.palette.primary.main,
                        color: (theme) => theme.palette.primary.contrastText,
                        "&:hover": {
                          backgroundColor: (theme) => theme.palette.primary.dark,
                        },
                      },
                    }}
                  >
                    Ignore
                  </ToggleButton>
                  <ToggleButton
                    id="duplicate_applications_replace"
                    value="replace"
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: (theme) => theme.palette.primary.main,
                        color: (theme) => theme.palette.primary.contrastText,
                        "&:hover": {
                          backgroundColor: (theme) => theme.palette.primary.dark,
                        },
                      },
                    }}
                  >
                    Replace
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>

              <Grid item xs={12} sx={{ paddingTop: 2 }}>
                <Button variant="contained" onClick={handleUpload}>
                  Import the applicants
                </Button>
              </Grid>

              {/* <Grid item xs={4} sx={{ paddingTop: 2 }}>
                <TextField
                  name="test"
                  label="Test"
                  fullWidth
                  margin="dense"
                  onChange={handleTestDateChange}
                />
              </Grid>
              <Grid item xs={3} sx={{ paddingTop: 2 }}>
                <Button variant="contained" onClick={testDateParsing}>
                  Test Date
                </Button>
              </Grid>
              <Grid item xs={5} sx={{ paddingTop: 2 }}>
                <Typography variant="body1" gutterBottom>
                  {testDateResult}
                </Typography>
              </Grid> */}
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </TitledPage>
  );
};

export default ImportApplicantsPage;
