import React, { useState, useEffect, useMemo, useContext } from "react";

import {
  Button,
  Checkbox,
  Chip,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  LinearProgress,
  DialogActions,
  CircularProgress,
} from "@mui/material";

import { Tooltip } from "@mui/material";
import { Box, Card, CardHeader, CardContent } from "@mui/material";
import {
  Delete as DeleteIcon,
  AssignmentInd,
  BadgeOutlined,
  Save as SaveIcon,
  ToggleOff,
  ToggleOn,
  Input as InputIcon,
  InfoOutlined as InfoIcon,
  ErrorOutline,
  Restore,
  MilitaryTech,
  Refresh,
  Calculate,
  Mail,
} from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import AddCandidateDialog from "../dialogs/dialogCandidateSettings";

import {
  Candidate,
  CandidateDoc,
  CandidatesDict,
  CampaignsDict,
  CandidateVerificaion,
  RegistrationInfosDict,
  RegistrationInfo,
} from "../../types/recruiting";
import { TitledPage } from "../../components/titled-page";
import {
  calculateSurveyScore,
  getCandidateStatusChip,
  getCandidateStatusText,
} from "../../utils/candidate-utils";
import { HeadCells, Order, defaultNumberComparator, getComparator } from "../../types/tables";
import { EnhancedTableHeadContents } from "../../components/enhanced-table";
import ConvertCandidatesDialog from "../dialogs/dialogConvertCandidates";
import { AuthContext } from "../../components/auth-provider";
import {
  deleteCandiateRegistrationInfo,
  deleteCandidate,
  loadCampaigns,
  loadCandidate,
  loadCandidates,
  loadRegistrationInfos,
  saveCandidate,
} from "../../data-functions/recruiting_api";
import { generateCandidatesCSV } from "../../utils/exporters/exporters";
import { TableVirtuoso } from "react-virtuoso";
import { VirtuosoTableComponents } from "../../components/enhanced-virtuoso-table";
import { useNavigate } from "react-router-dom";
import { LogEntry } from "../../types/commons";
import { logEvent } from "../../utils/db-utils";
import { DocumentHistoryDialog } from "../dialogs/dialogDocumentHistory";
import { hasRole } from "../../utils/user-utils";
import { isDateInThePast } from "../../utils/utils";
import ResetVerificationDialog from "../dialogs/dialogResetVerification";
import { DISQUALIFY_NUM_VALUE, Question, QuestionChoice } from "../../types/surveys";
import {
  ADVANCED_FORM_QUESTIONS,
  INTERVIEW_QUESTIONS,
  RUSSIAN_QUESTIONS,
} from "./interview-questions";
import { deleteObject, ref } from "firebase/storage";
import db, { storage } from "../../types/firebase";
import { doc, getDoc } from "firebase/firestore";
import SendConfirmationCodeDialog from "../dialogs/dialogSendConfirmationCode";
import { parse } from "papaparse";
import duplicateDocumentsFile from "../../ressources/get_duplicate_documents.csv";

const headCells: HeadCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    sortable: true,
  },
  {
    id: "surname",
    numeric: false,
    disablePadding: false,
    label: "Surname",
    sortable: true,
  },
  {
    id: "has_passport",
    numeric: false,
    disablePadding: false,
    label: "Pass",
    sortable: true,
    tooltip: "Has passport?",
    width: "30px",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    sortable: true,
    width: "100px",
  },
  {
    id: "surveyScore",
    numeric: false,
    disablePadding: false,
    label: "",
    tooltip: "Score",
    icon: <MilitaryTech />,
    sortable: true,
    align: "center",
    width: "50px",
  },
  {
    id: "verified",
    numeric: false,
    disablePadding: true,
    label: "",
    sortable: true,
    width: "30px",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "",
    sortable: false,
    align: "right",
    width: "110px",
  },
];

const CandidatesPage: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [candidates, setCandidates] = useState<CandidatesDict>({});
  const [candidateRegistrationInfo, setCandidateRegistrationInfo] =
    useState<RegistrationInfo | null>(null);
  const [registrationInfos, setRegistrationInfos] = useState<RegistrationInfosDict>({});
  const [selectedCandidate, setSelectedCandidate] = useState<CandidateDoc | null>(null);
  const [editCandidate, setEditdCandidate] = useState<CandidateDoc | null>(null);
  const [searchValue, setSearchValue] = useState<string>("");
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>("");
  const [campaigns, setCampaigns] = useState<CampaignsDict>({});
  const [selectedCampaign, setCampaign] = useState<string>("");
  const [showOpenCampaigns, setShowOpenCampaigns] = useState<boolean>(true);

  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<string>("name");
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [convertCandidates, setConvertCandidates] = useState<CandidatesDict>({});
  const [openConvertDialog, setConverDialogOpen] = useState(false);

  const [openHistory, setOpenHistory] = useState(false);
  const [historyDoc, setHistoryDoc] = useState<string>("");

  const [openResetVerificationDialog, setOpenResetVerificationDialog] = useState(false);
  const [resetVerificationCandidates, setResetVerificationCandidates] = useState<CandidatesDict>(
    {}
  );

  const [openSendConfirmationDialog, setOpenSendConfirmationDialog] = useState(false);
  const [sendConfirmationCandidates, setSendConfirmationCandidates] = useState<CandidatesDict>({});

  const [passportDialog, setPassportDialog] = useState<{ open: boolean; imageUrl: string }>({
    open: false,
    imageUrl: "",
  });

  const [surveyQuestions, setSurveyQuestions] = useState<Question[]>([]);

  const [loading, setLoading] = useState(false);

  const { currentUser } = useContext(AuthContext)!;
  const navigate = useNavigate();

  // Add new state for menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  // Add new state for progress dialog
  const [progressDialog, setProgressDialog] = useState<{
    open: boolean;
    current: number;
    total: number;
    action: "delete" | "progress" | null;
  }>({
    open: false,
    current: 0,
    total: 0,
    action: null,
  });

  // Add handlers for menu
  const handleMoreClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreClose = () => {
    setAnchorEl(null);
  };

  const fetchCandidates = async (campaignId: string) => {
    setCandidates({});
    setLoading(true);
    let candidatesDict: CandidatesDict;
    let regInfosDict: RegistrationInfosDict;

    const limit = 20000;

    if (campaignId === "all_campaigns") {
      candidatesDict = await loadCandidates(currentUser?.appUser, "all_campaigns");
      regInfosDict = await loadRegistrationInfos(currentUser?.appUser, "all_campaigns");
    } else if (campaignId === "no_campaign") {
      candidatesDict = await loadCandidates(currentUser?.appUser, "no_campaign", undefined, limit);
      regInfosDict = await loadRegistrationInfos(currentUser?.appUser, "no_campaign");
    } else {
      console.log("fetching candidates for campaign: ", campaignId);
      candidatesDict = await loadCandidates(currentUser?.appUser, campaignId);
      regInfosDict = await loadRegistrationInfos(currentUser?.appUser, campaignId);
    }

    setCandidates(candidatesDict);
    setRegistrationInfos(regInfosDict);
    setLoading(false);
  };

  const fetchCampaigns = async () => {
    const campaignsDict: CampaignsDict = await loadCampaigns(currentUser?.appUser);

    setCampaigns(campaignsDict);

    const fCampaigns = sortAndFilterCampaigns(campaignsDict, true);
    if (fCampaigns.length > 0) {
      setCampaign(fCampaigns[0][0]);
    } else setCampaign("all_campaigns");
  };

  const fetchSurveyQuestions = async () => {
    // Create a Map to maintain order and handle duplicates
    const questionsMap = new Map<string, any>();

    // Add questions in order of priority (later arrays won't override earlier ones)
    [RUSSIAN_QUESTIONS, ADVANCED_FORM_QUESTIONS, INTERVIEW_QUESTIONS].forEach((questionSet) => {
      questionSet.forEach((question) => {
        if (!questionsMap.has(question.id) && question.type !== "placeholder") {
          questionsMap.set(question.id, question);
        }
      });
    });

    // Convert Map back to object
    const questions: Question[] = Array.from(questionsMap.values());
    setSurveyQuestions(questions);
  };

  useEffect(() => {
    fetchSurveyQuestions();
    fetchCampaigns();
    setShowOpenCampaigns(true);
    //    fetchCandidates();
  }, []);

  useEffect(() => {
    setSelected([]);
    setCampaign("");
    setSearchValue("");
    setShowOpenCampaigns(true);
    setOpenSendConfirmationDialog(false);
    setOpenHistory(false);
    setOpenResetVerificationDialog(false);
    setConverDialogOpen(false);
    setSelectedCandidate(null);
    fetchCampaigns();
  }, [currentUser]);

  useEffect(() => {
    if (selectedCampaign) {
      setDebouncedSearchValue("");
      fetchCandidates(selectedCampaign);
    }
  }, [selectedCampaign]);

  useEffect(() => {
    if (editCandidate) {
      handleClickOpen(true);
    }
  }, [editCandidate]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchValue(searchValue);
    }, 500);

    return () => clearTimeout(timer);
  }, [searchValue]);

  const handleClickOpen = (edit: boolean = false) => {
    setEditMode(edit && !!editCandidate);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditdCandidate(null);
    // fetchCandidates(selectedCampaign);
  };

  const handleSaveCandidate = async (candidate: CandidateDoc) => {
    const appDoc = await saveCandidate(currentUser?.appUser, { ...candidate }, true);
    if (appDoc) {
      const newCandidates = { ...candidates };
      newCandidates[appDoc.id] = appDoc.candidate;

      setCandidates(newCandidates);
      if (selectedCandidate?.id === appDoc.id) {
        setSelectedCandidate(appDoc);
      }
    } else {
      console.log("error saving candidate");
    }
  };

  //const handleExport = async () => {
  // const q = query(collection(db, "candidates"), where("application.status", "==", "new"));
  // const querySnapshot = await getDocs(q);
  // const candidates = querySnapshot.docs
  //   .map((doc) => doc.data() as Candidate)
  //   .filter((candidate) => candidate.application && candidate.application.dataFormCompleted);
  // // Convert candidates data to CSV format:
  // const csvData = addHOPSCosCandidates([], candidates);
  // const curentDate = new Date().toISOString().split("T")[0];
  // // Create a blob from the CSV data and download:
  // const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
  // const url = window.URL.createObjectURL(blob);
  // const a = document.createElement("a");
  // a.style.display = "none";
  // a.href = url;
  // a.download = "candidates " + curentDate + ".csv"; // specify the file name
  // document.body.appendChild(a);
  // a.click();
  // document.body.removeChild(a);
  // window.URL.revokeObjectURL(url);
  //};

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = visibleRows.map(([id, candidate]) => id);
      setSelected(newSelected);
    } else setSelected([]);
  };

  const handleCheckRow = (event: React.ChangeEvent<HTMLInputElement>, candidateId: string) => {
    // setChecked(event.target.checked);
    const selectedIndex = selected.indexOf(candidateId);
    const newSelected: string[] = [...selected];

    if (selectedIndex === -1) {
      newSelected.push(candidateId);
    } else {
      newSelected.splice(selectedIndex, 1);
    }

    setSelected(newSelected);
  };

  const isSelected = (id: string) => selected.includes(id);

  const doDeleteCandidate = async (candidateId: string) => {
    const candidate = candidates[candidateId];
    if (candidate.imageUrl) {
      const storageRef = ref(storage, candidate.imageUrl);
      deleteObject(storageRef).then(() => {
        console.log("deleted image");
      });
    }
    deleteCandidate(candidateId).then(() => {
      deleteCandiateRegistrationInfo(candidateId).then(() => {
        console.log("deleted registration info");
      });
      const newCandidates = { ...candidates };
      delete newCandidates[candidateId];
      if (selectedCandidate && selectedCandidate.id === candidateId) {
        setSelectedCandidate(null);
      }

      if (selected.indexOf(candidateId) >= 0) {
        const newSelected = [...selected];
        newSelected.splice(selected.indexOf(candidateId), 1);
        setSelected(newSelected);
      }
      setCandidates(newCandidates);
    });
  };

  const handleDelete = async (candidateId: string) => {
    if (!candidateId) return;

    if (window.confirm("Are you sure you want to delete this candidate?")) {
      doDeleteCandidate(candidateId);
    }
  };

  const handleSelectCandidate = async (candidateId: string) => {
    console.log("loading candidate: ", candidateId);
    let loadedCandidate = await loadCandidate(candidateId);
    const candRegInfo = Object.values(registrationInfos).find(
      (regInfo) => regInfo.candidateId === candidateId
    );

    if (candRegInfo) {
      setCandidateRegistrationInfo({ ...candRegInfo });
    } else {
      setCandidateRegistrationInfo(null);
    }

    if (loadedCandidate) {
      setSelectedCandidate(loadedCandidate);
    }
  };

  const handleEditCandidate = async (candidate: CandidateDoc) => {
    if (candidate) {
      setEditdCandidate(candidate);
    }
  };

  const handleSelectCampaign = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValue("");
    setCampaign(value);
    setSelectedCandidate(null);
    if (value !== "all_campaigns") setSelected([]);
  };

  const refreshCandidates = async () => {
    setSelectedCandidate(null);
    setSelected([]);
    setSearchValue("");
    setLoading(true);
    await fetchCandidates(selectedCampaign);
    setLoading(false);
  };

  const getCampaignName = (candidate: Candidate) => {
    if (candidate && candidate.campaignId && campaigns[candidate.campaignId]) {
      return campaigns[candidate.campaignId].name;
    } else {
      return "n/a";
    }
  };

  const handleToggleShowopenCampaigns = () => {
    console.log("toggle show opened campaigns");
    const newSO = !showOpenCampaigns;
    if (newSO && selectedCampaign && campaigns[selectedCampaign]) {
      if (campaigns[selectedCampaign].status === "closed") {
        const fCampaigns = sortAndFilterCampaigns(campaigns, newSO);
        if (fCampaigns.length > 0) {
          setCampaign(fCampaigns[0][0]);
        } else setCampaign("all_campaigns");
        setSelectedCandidate(null);
        setSelected([]);
      }
    }
    setShowOpenCampaigns(newSO);
  };

  const handleShowHistory = async (documentId: string) => {
    setHistoryDoc(documentId);
    setOpenHistory(true);
  };

  const handleResetVerifications = async () => {
    const newCandidates: CandidatesDict = {};
    for (const candidateId of selected) {
      const candidate = candidates[candidateId];
      if (candidate.verification && !candidate.verification.verifiedOn) {
        newCandidates[candidateId] = { ...candidate };
      }
    }
    setResetVerificationCandidates(newCandidates);
    setOpenResetVerificationDialog(true);
  };

  const handleSendConfirmationCodes = async () => {
    const newCandidates: CandidatesDict = {};
    for (const candidateId of selected) {
      newCandidates[candidateId] = { ...candidates[candidateId] };
    }

    setSendConfirmationCandidates(newCandidates);
    setOpenSendConfirmationDialog(true);
  };

  const getAnswerValue = (candidate: Candidate, question: Question) => {
    if (candidate.surveyAnswers) {
      const value = candidate.surveyAnswers[question.id];
      if (typeof value === "string") {
        switch (value) {
          case "true":
            return "Yes";
          case "false":
            return "No";

          default:
            return value;
        }
      } else return "";
    } else return "";
  };

  const getAnswerScore = (candidate: Candidate, question: Question) => {
    if (question.type === "select_multiple") {
      if (
        question.score &&
        question.score["_no_answer_"] &&
        candidate.surveyAnswers &&
        question.options
      ) {
        for (const option of question.options) {
          const value = candidate.surveyAnswers[option.id];
          if (value) return "";
        }
        const score = question.score["_no_answer_"];
        return score === "disqualify" ? "DQ" : score || "0";
      } else return "";
    }

    if (!question.score) {
      return "";
    }

    const value = candidate.surveyAnswers && candidate.surveyAnswers[question.id];

    if (question.score && question.score["_any_answer_"] && value) {
      const score = question.score["_any_answer_"];
      return score === "disqualify" ? "DQ" : score || "0";
    }

    if (question.score && question.score["_no_answer_"] && !value) {
      const score = question.score["_no_answer_"];
      return score === "disqualify" ? "DQ" : score || "0";
    }

    if (value && typeof value === "string") {
      const score = question.score[value];
      return score === "disqualify" ? "DQ" : score || "0";
    }

    return "";
  };

  const getOptionScore = (candidate: Candidate, question: Question, option: QuestionChoice) => {
    if (!question.score) {
      return "";
    }

    if (
      candidate.surveyAnswers &&
      candidate.surveyAnswers[question.id] &&
      typeof candidate.surveyAnswers[question.id] === "object"
    ) {
      const questionAnswer =
        candidate.surveyAnswers && (candidate.surveyAnswers[question.id] as Record<string, string>);
      const value = questionAnswer && questionAnswer[option.id];

      if (question.score && question.score["_any_answer_"] && value) {
        const score = question.score["_any_answer_"];
        return score === "disqualify" ? "DQ" : score || "0";
      }

      if (value && typeof value === "string") {
        const score = value === "true" ? question.score[option.id] : "0";
        return score === "disqualify" ? "DQ" : score || "0";
      }
    }

    return "";
  };

  const getAnswerOptionValue = (
    candidate: Candidate,
    question: Question,
    option: QuestionChoice
  ) => {
    if (candidate.surveyAnswers) {
      const value = candidate.surveyAnswers[question.id];
      if (value && typeof value === "object" && value[option.id]) {
        switch (value[option.id]) {
          case "true":
            return "Yes";
          case "false":
            return "No";
          default:
            return value[option.id];
        }
      } else return "-";
    }
  };

  const getAnswersTable = (candidate: Candidate) => {
    if (!candidate.surveyAnswers) return <></>;

    return (
      <Table sx={{ width: "100%", marginTop: "8px" }} size="small" aria-label="questions table">
        <TableHead>
          <TableRow id="table-header-row">
            <TableCell component="th">Question</TableCell>
            <TableCell component="th" align="left">
              Answer
            </TableCell>
            <TableCell component="th" align="right">
              <Tooltip title="Score">
                <MilitaryTech />
              </Tooltip>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {surveyQuestions.map((question) => (
            <React.Fragment key={"r-" + question.id}>
              <TableRow id={"question-" + question.id} key={"question-" + question.id}>
                <TableCell>{question.label}</TableCell>
                <TableCell align="left">{getAnswerValue(candidate, question)}</TableCell>
                <TableCell align="right">{getAnswerScore(candidate, question)}</TableCell>
              </TableRow>
              {question.type === "select_multiple" &&
                question.options &&
                question.options.map((option) => (
                  <TableRow id={"option-" + option.id} key={"option-" + option.id}>
                    <TableCell id={option.id} align="left" sx={{ paddingLeft: "2rem" }}>
                      {option.label}
                    </TableCell>
                    <TableCell align="left">
                      {getAnswerOptionValue(candidate, question, option)}
                    </TableCell>
                    <TableCell align="right">
                      {getOptionScore(candidate, question, option)}
                    </TableCell>
                  </TableRow>
                ))}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    );
  };

  const getCampaignChip = (campaignId: string) => {
    const campaign = campaigns[campaignId];

    switch (campaign.status) {
      case "open":
        return <Chip label="Open" color="success" size="small" />;

      case "closed_registration":
        return <Chip label="Registration Closed" color="warning" size="small" />;

      case "closed":
        return <Chip label="Closed" color="error" size="small" />;

      default:
        return <Chip label="New" color="primary" variant="outlined" size="small" />;
    }
  };

  const getHasPassport = (candidate: Candidate) => {
    if (!candidate) return "-";

    if (candidate.has_passport) {
      return "Yes";
    } else {
      if (candidate.campaignId && campaigns[candidate.campaignId].disqualifyIfNoPassport) {
        return (
          <Typography component="span" color="error" fontWeight={"bold"}>
            No
          </Typography>
        );
      } else {
        return "No";
      }
    }
  };

  const displayCandidateDetails = (selectedCandidate: CandidateDoc | null) => {
    if (!selectedCandidate) {
      return;
    }

    let verificationExired = false;
    if (
      selectedCandidate.candidate.verification &&
      selectedCandidate.candidate.verification.expiryDate &&
      !selectedCandidate.candidate.verification.verifiedOn
    ) {
      verificationExired = isDateInThePast(selectedCandidate.candidate.verification.expiryDate);
    }

    return (
      <Box sx={{ paddingBottom: "16px" }}>
        <Card>
          <CardHeader
            title={selectedCandidate.candidate.name + " " + selectedCandidate.candidate.surname}
          />
          <CardContent>
            <Grid container spacing={1} padding={1}>
              <Grid item xs={12}>
                <Typography>
                  <Typography component="span" sx={{ fontWeight: "bold", fontSize: "0.825rem" }}>
                    Campaign:
                  </Typography>{" "}
                  {getCampaignName(selectedCandidate.candidate)}
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography>
                  <Typography component="span" sx={{ fontWeight: "bold", fontSize: "0.825rem" }}>
                    Name:
                  </Typography>{" "}
                  {selectedCandidate.candidate.name}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography>
                  <Typography component="span" sx={{ fontWeight: "bold", fontSize: "0.825rem" }}>
                    Family Name:
                  </Typography>{" "}
                  {selectedCandidate.candidate.surname}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography>
                  <Typography component="span" sx={{ fontWeight: "bold", fontSize: "0.825rem" }}>
                    EMail:
                  </Typography>{" "}
                  {selectedCandidate.candidate.email}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography>
                  <Typography component="span" sx={{ fontWeight: "bold", fontSize: "0.825rem" }}>
                    Phone:
                  </Typography>{" "}
                  {selectedCandidate.candidate.phone}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box display="flex" alignItems="end">
                  <Typography
                    component="span"
                    sx={{ fontWeight: "bold", fontSize: "0.825rem", marginRight: "4px" }}
                  >
                    Has passport:
                  </Typography>
                  {getHasPassport(selectedCandidate.candidate)}
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography>
                  <Typography component="span" sx={{ fontWeight: "bold", fontSize: "0.825rem" }}>
                    Passport ID:
                  </Typography>{" "}
                  {selectedCandidate.candidate.passportId}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography component="span" sx={{ fontWeight: "bold", fontSize: "0.825rem" }}>
                  Status:{" "}
                </Typography>
                {getCandidateStatusChip(selectedCandidate.candidate.status)}
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography component="span" sx={{ fontWeight: "bold", fontSize: "0.825rem" }}>
                  IP Address:{" "}
                </Typography>
                {candidateRegistrationInfo?.ip4Address ||
                  candidateRegistrationInfo?.ip6Address ||
                  "-"}
              </Grid>

              {verificationExired && (
                <React.Fragment>
                  <Grid item xs={12}>
                    <Typography>
                      <Typography
                        component="span"
                        sx={{ fontWeight: "bold", fontSize: "0.825rem" }}
                      >
                        Verification expired on:{" "}
                      </Typography>
                      <Typography component="span" color="red">
                        {selectedCandidate.candidate.verification?.expiryDate}
                      </Typography>
                    </Typography>
                  </Grid>
                </React.Fragment>
              )}
              {!verificationExired && (
                <React.Fragment>
                  <Grid item xs={12}>
                    <Typography>
                      <Typography
                        component="span"
                        sx={{ fontWeight: "bold", fontSize: "0.825rem" }}
                      >
                        Confirmation code:{" "}
                      </Typography>
                      {selectedCandidate.candidate.verification?.confirmationCode || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      <Typography
                        component="span"
                        sx={{ fontWeight: "bold", fontSize: "0.825rem" }}
                      >
                        Interviewed by:{" "}
                      </Typography>
                      {selectedCandidate.candidate.interviewer || "n/a"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      <Typography
                        component="span"
                        sx={{ fontWeight: "bold", fontSize: "0.825rem" }}
                      >
                        Interviewer comments:{" "}
                      </Typography>{" "}
                      {selectedCandidate.candidate.note || "-"}
                    </Typography>
                  </Grid>
                </React.Fragment>
              )}
              <Grid item xs={12}>
                <Divider />
                <Typography>
                  <Typography component="span" sx={{ fontWeight: "bold", fontSize: "0.825rem" }}>
                    Survey Score:{" "}
                  </Typography>{" "}
                  {selectedCandidate.candidate.surveyScore
                    ? selectedCandidate.candidate.surveyScore === DISQUALIFY_NUM_VALUE
                      ? "DQ"
                      : selectedCandidate.candidate.surveyScore
                    : "-"}
                </Typography>
              </Grid>
            </Grid>

            {selectedCandidate.candidate.surveyAnswers &&
              getAnswersTable(selectedCandidate.candidate)}
          </CardContent>
        </Card>
      </Box>
    );
  };

  const handlePassportDialogClose = () => {
    setPassportDialog({ open: false, imageUrl: "" });
  };

  const getPassportCell = (candidate: Candidate) => {
    if (candidate.has_passport) {
      if (candidate.imageUrl) {
        return (
          <>
            <IconButton
              onClick={() => setPassportDialog({ open: true, imageUrl: candidate.imageUrl! })}
              size="small"
            >
              <BadgeOutlined color="success" />
            </IconButton>
          </>
        );
      } else {
        return <BadgeOutlined sx={{ paddingLeft: "4px" }} />;
      }
    } else {
      return "";
    }
  };

  /* ------------ Search functions -------------- */
  const filteredCandidates = useMemo(
    () =>
      Object.entries(candidates).filter(([candidateId, candidate]) => {
        try {
          return (
            (debouncedSearchValue.length < 3 ||
              candidate.name.toLowerCase().includes(debouncedSearchValue.toLowerCase()) ||
              candidate.surname.toLowerCase().includes(debouncedSearchValue.toLowerCase()) ||
              candidate.email?.toLowerCase().includes(debouncedSearchValue.toLowerCase()) ||
              getCandidateStatusText(candidate.status)
                .toLowerCase()
                .includes(debouncedSearchValue.toLowerCase())) &&
            (["all_campaigns", "no_campaign"].includes(selectedCampaign) ||
              (candidate.campaignId && candidate.campaignId === selectedCampaign))
          );
        } catch (err) {
          console.error("filter exception:");
          console.log(candidate);
          return false;
        }
      }),
    [debouncedSearchValue, candidates, selectedCampaign]
  );

  const visibleRows = useMemo(
    () =>
      filteredCandidates
        .slice()
        .sort(
          getComparator(
            order,
            orderBy as keyof Candidate,
            orderBy === "surveyScore" ? defaultNumberComparator : undefined
          )
        ),
    [order, orderBy, filteredCandidates]
  );

  const sortAndFilterCampaigns = (fCampaigns: CampaignsDict, showOpen: boolean) => {
    return Object.entries(fCampaigns)
      .filter(([campaignId, campaign]) => {
        return !showOpen || campaign.status !== "closed";
      })
      .sort(([idA, campaignA], [idB, campaignB]) => {
        if (campaignA.name < campaignB.name) {
          return -1;
        }
        if (campaignA.name > campaignB.name) {
          return 1;
        }
        return 0;
      });
  };

  const filteredCampaigns = useMemo(
    () => sortAndFilterCampaigns(campaigns, showOpenCampaigns),
    [showOpenCampaigns, campaigns]
  );

  const handleSearchValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleConvertCandidates = (event: React.MouseEvent<unknown>) => {
    const allApproved = selected.length === 0;

    const convCandidates: CandidatesDict = {};

    filteredCandidates.forEach(([candidateId, candidate]) => {
      if (
        candidate.status !== "applicant" &&
        ((allApproved && candidate.status === "approved") ||
          (!allApproved && isSelected(candidateId)))
      ) {
        convCandidates[candidateId] = { ...candidate };
      }
    });

    setConvertCandidates(convCandidates);
    setConverDialogOpen(true);
  };

  const handleCloseConvertDialog = () => {
    setConverDialogOpen(false);
    fetchCandidates(selectedCampaign);
  };

  const handleExport = async () => {
    let cmp: string;
    switch (selectedCampaign) {
      case "all_campaigns":
        cmp = "all campaigns";
        break;
      case "no_campaign":
        cmp = "candidates without a campaign";
        break;
      default:
        cmp = campaigns[selectedCampaign].name;
        break;
    }

    const regInfos: RegistrationInfosDict = {};
    Object.values(registrationInfos).forEach((regInfo) => {
      regInfos[regInfo.candidateId] = regInfo;
    });

    // Convert candidates data to CSV format:
    const candidates = Object.fromEntries(visibleRows.map(([id, candidate]) => [id, candidate]));
    try {
      const csvData = generateCandidatesCSV(candidates, regInfos);
      const curentDate = new Date().toISOString().split("T")[0];
      // Create a blob from the CSV data and download:
      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = cmp + " - " + curentDate + ".csv"; // specify the file name
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } finally {
      const candidateIds = visibleRows.map(([id, candidate]) => id);
      let multiple = undefined;
      if (candidateIds.length > 1) {
        multiple = candidateIds.length + " candidates exported";
      }
      const logEntry: LogEntry = {
        collection: "candidates",
        documentId: multiple ? "multiple" : candidateIds[0],
        sponsorId: currentUser?.appUser?.sponsorId || "",
        operation: "export",
        changedFields: { candidates: multiple || candidateIds },
      };
      logEvent(logEntry);
    }
  };

  const getVerificationIcon = (verification: CandidateVerificaion | null) => {
    let verificationExired = false;
    if (verification && verification.expiryDate && !verification.verifiedOn) {
      verificationExired = isDateInThePast(verification.expiryDate);
    }

    if (verificationExired) {
      return (
        <Tooltip title="Verification expired">
          <IconButton size="small">
            <ErrorOutline color="error" />
          </IconButton>
        </Tooltip>
      );
    } else {
      return <></>;
    }
  };

  const handleDeleteSelected = async () => {
    if (
      window.confirm(
        `Are you sure you want to delete ${selected.length} candidate${
          selected.length === 1 ? "" : "s"
        }?`
      )
    ) {
      setProgressDialog({
        open: true,
        current: 0,
        total: selected.length,
        action: "delete",
      });

      // Process deletions one at a time to show progress
      selected.forEach(async (candidateId, index) => {
        await doDeleteCandidate(candidateId);
        setProgressDialog((prev) => ({ ...prev, current: index + 1 }));
      });

      setSelected([]);
      setProgressDialog((prev) => ({ ...prev, open: false, action: null }));
      await fetchCandidates(selectedCampaign);
    }
  };

  const calcScore = (candidate: Candidate) => {
    const campaign = campaigns[selectedCampaign];
    if (campaign && campaign.disqualifyIfNoPassport && candidate.has_passport === false) {
      return DISQUALIFY_NUM_VALUE;
    }

    if (candidate.surveyAnswers && campaign) {
      let score = calculateSurveyScore(candidate.surveyAnswers, RUSSIAN_QUESTIONS);
      console.log("language score: ", score);
      if (campaign?.language === "ru" && score > DISQUALIFY_NUM_VALUE) {
        const sc = calculateSurveyScore(candidate.surveyAnswers, ADVANCED_FORM_QUESTIONS);
        console.log("advanced score: ", sc);
        if (sc > DISQUALIFY_NUM_VALUE) {
          score += sc;
        } else {
          score = DISQUALIFY_NUM_VALUE;
        }
      }
      return score;
    } else {
      return 0;
    }
  };

  const handleTest = async () => {
    console.log("test");

    const token =
      "p.eyJ1IjogImI2NjRiYWUzLWIxOTktNGZiYy1iOTI0LTIzYTliMGFiNGM1YyIsICJpZCI6ICIyZDFhZWFiNS0yNWEzLTQwMTMtYjMwZC03ODRhNzMxNWQ0MDgiLCAiaG9zdCI6ICJldV9zaGFyZWQifQ.a8KtMkm8i-nTt2gFmrB9lZpCtur4u_WAReSkdpFl1TU";

    const del_url = "https://api.tinybird.co/v0/datasources/registrations/delete";
    const job_url = "https://api.tinybird.co/v0/jobs/";

    let c_cnt = 0;
    let c_deleted = 0;

    const regInfos: RegistrationInfosDict = await loadRegistrationInfos(
      currentUser?.appUser,
      "gQCQcl4ZVMCNCuHnrffS"
    );

    console.log("loaded reg infos: ", Object.keys(regInfos).length);

    // Parse the duplicate documents file
    const response = await fetch(duplicateDocumentsFile);
    const csvText = await response.text();

    const parsedData = parse(csvText, {
      header: true,
      delimiter: ",",
      skipEmptyLines: "greedy",
    });

    console.log("docFile: ", parsedData.data.length);

    for (let row of parsedData.data) {
      if (row === null || typeof row !== "object") {
        continue;
      }

      const dataRow = row as Record<string, string>;
      const id = dataRow["documentId"];

      // let url = new URL(`https://api.tinybird.co/v0/pipes/get_registrations_per_candidate.json`);
      // url.searchParams.append("campaignId", "gQCQcl4ZVMCNCuHnrffS");
      // url.searchParams.append("candidateId", id);

      // await new Promise((resolve) => setTimeout(resolve, 150));

      // const result = await fetch(url, {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // })
      //   .then((r) => r.json())
      //   .then((r) => r)
      //   .catch((e) => {
      //     console.error(`there is a problem running the query: ${e}`);
      //     return;
      //   });

      // if (!result.data) {
      //   console.error(`there is a problem running the query: ${result}`);
      // } else if (result.data.length > 1) {
      //   for (const row of result.data) {
      const documentId = id; // row.documentId;
      // const registrationInfoRef = doc(db, "registrations", documentId);
      // const registrationInfoSnapshot = await getDoc(registrationInfoRef);
      // if (!registrationInfoSnapshot.exists()) {
      if (!regInfos[id]) {
        const res = await fetch(del_url, {
          headers: {
            Authorization: "Bearer " + token,
          },
          method: "POST",
          body: new URLSearchParams({
            delete_condition: "documentId = '" + documentId + "'",
          }),
        });
        const res_json = await res.json();
        const jobId = res_json.job_id;

        c_deleted++;

        let status = "";
        while (status !== "done") {
          const res = await fetch(job_url + jobId, {
            headers: {
              Authorization: "Bearer " + token,
            },
          });
          const res_json = await res.json();
          status = res_json.status;
          if (status !== "done") {
            await new Promise((resolve) => setTimeout(resolve, 500));
          }
        }
      }

      //const registrationInfo = await loadRegistrationInfo(currentUser?.appUser, documentId);
      //   }
      //}
      c_cnt++;

      if (c_cnt % 5 === 0) {
        console.log(`cleared: ${c_cnt} candidate registrations, deleted: ${c_deleted}`);
      }
    }
    console.log("done");
    console.log(`cleared: ${c_cnt} candidate registrations, deleted: ${c_deleted}`);

    //await fetchCandidates(selectedCampaign);
  };

  const handleRecalculateScores = async () => {
    let candidatesToUpdate: CandidatesDict = {};

    if (selected.length === 0) {
      if (!window.confirm("Do you want to recalculate scores for all visible candidates?")) {
        return;
      }
      // Use all visible candidates
      visibleRows.forEach(([id, candidate]) => {
        candidatesToUpdate[id] = candidate;
      });
    } else {
      // Use selected candidates
      selected.forEach((id) => {
        if (candidates[id]) {
          candidatesToUpdate[id] = candidates[id];
        }
      });
    }

    setProgressDialog({
      open: true,
      current: 0,
      total: Object.keys(candidatesToUpdate).length,
      action: "progress",
    });

    let cnt = 0;
    for (const [candidateId, cand] of Object.entries(candidatesToUpdate)) {
      let score = 0;

      // Calculate score based on survey answers
      if (cand.surveyAnswers) {
        score = calcScore(cand);
      }

      console.log(
        `candidateId: ${candidateId}, old score: ${cand.surveyScore}, new score: ${score}`
      );

      if (score !== cand.surveyScore) {
        // Update cand with new score
        const updatedCandidate = {
          ...cand,
          surveyScore: score,
        };

        await saveCandidate(currentUser?.appUser, { id: candidateId, candidate: updatedCandidate });
      }
      cnt++;
      setProgressDialog((prev) => ({ ...prev, current: cnt }));
    }

    // Refresh the candidates list
    await fetchCandidates(selectedCampaign);
    setProgressDialog((prev) => ({ ...prev, open: false, action: null }));
    handleMoreClose();
  };

  const getFooter = () => {
    if (loading) {
      return (
        <TableRow>
          <TableCell align="center" colSpan={headCells.length + 1}>
            <CircularProgress size={40} sx={{ marginTop: "5rem" }} />
            <Typography variant="h6" sx={{ mt: 2 }}>
              Loading data...
            </Typography>
          </TableCell>
        </TableRow>
      );
    } else {
      return (
        <TableRow>
          <TableCell align="right" colSpan={headCells.length + 1}>
            {visibleRows.length + " Candidates"}
          </TableCell>
        </TableRow>
      );
    }
  };

  return (
    <TitledPage title="Candidates">
      <Box>
        <Box display={"flex"} sx={{ gap: 2 }}>
          <Button
            variant="outlined"
            color="primary"
            sx={{ marginTop: "8px", marginBottom: "4px" }}
            onClick={() => handleClickOpen()}
          >
            Add Candidate
          </Button>

          <Dialog
            open={passportDialog.open}
            onClose={handlePassportDialogClose}
            maxWidth="md"
            fullWidth
          >
            <DialogTitle sx={{ m: 0, p: 2 }}>
              Passport Image
              <IconButton
                aria-label="close"
                onClick={handlePassportDialogClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <img
                src={passportDialog.imageUrl}
                alt="Passport"
                style={{
                  width: "100%",
                  height: "auto",
                  maxHeight: "80vh",
                  objectFit: "contain",
                }}
              />
            </DialogContent>
          </Dialog>

          <AddCandidateDialog
            open={open}
            onClose={handleClose}
            onSave={handleSaveCandidate}
            candidate={editMode ? selectedCandidate || null : null}
            campaign={
              selectedCampaign &&
              selectedCampaign !== "all_campaigns" &&
              selectedCampaign !== "no_campaign"
                ? selectedCampaign
                : ""
            }
          />

          <ResetVerificationDialog
            candidates={resetVerificationCandidates}
            open={openResetVerificationDialog}
            onClose={() => setOpenResetVerificationDialog(false)}
          />
          <SendConfirmationCodeDialog
            candidates={sendConfirmationCandidates}
            open={openSendConfirmationDialog}
            onClose={() => setOpenSendConfirmationDialog(false)}
          />
          {hasRole(currentUser?.appUser, ["admin"]) && (
            <>
              <DocumentHistoryDialog
                open={openHistory}
                onClose={() => setOpenHistory(false)}
                documentId={historyDoc}
              />
              <Tooltip title="History">
                <span>
                  <IconButton
                    color="primary"
                    edge={"end"}
                    sx={{ marginTop: "8px", marginBottom: "4px" }}
                    disabled={!selectedCandidate}
                    onClick={() => handleShowHistory(selectedCandidate?.id || "")}
                  >
                    <InfoIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </>
          )}

          {/* Add Delete Selected button */}
          <Tooltip title="Delete selected candidates">
            <span>
              <IconButton
                color="primary"
                edge={"end"}
                sx={{ marginTop: "8px", marginBottom: "4px" }}
                disabled={selected.length === 0}
                onClick={handleDeleteSelected}
              >
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>

          <Tooltip title="Refresh candidates">
            <IconButton
              color="primary"
              edge={"end"}
              sx={{ marginTop: "8px", marginBottom: "4px", marginLeft: "-12px" }}
              onClick={refreshCandidates}
            >
              <Refresh />
            </IconButton>
          </Tooltip>

          {/* Add More menu button */}
          <Tooltip title="More actions">
            <IconButton
              color="primary"
              edge={"end"}
              sx={{ marginTop: "8px", marginBottom: "4px" }}
              onClick={handleMoreClick}
            >
              <MoreVertIcon />
            </IconButton>
          </Tooltip>

          {/* Add Menu component */}
          <Menu anchorEl={anchorEl} open={openMenu} onClose={handleMoreClose}>
            <MenuItem
              onClick={(e) => {
                handleConvertCandidates(e);
                handleMoreClose();
              }}
              disabled={visibleRows.length === 0}
            >
              <ListItemIcon>
                <AssignmentInd fontSize="small" color="primary" />
              </ListItemIcon>
              <ListItemText>
                {selected.length === 0
                  ? "Convert all approved candidates"
                  : "Convert selected candidates"}
              </ListItemText>
            </MenuItem>

            <MenuItem
              onClick={() => {
                handleResetVerifications();
                handleMoreClose();
              }}
              disabled={!(selectedCandidate || selected.length > 0)}
            >
              <ListItemIcon>
                <Restore fontSize="small" color="primary" />
              </ListItemIcon>
              <ListItemText>Reset Verifications</ListItemText>
            </MenuItem>

            <MenuItem
              onClick={() => {
                handleSendConfirmationCodes();
                handleMoreClose();
              }}
              disabled={!(selectedCandidate || selected.length > 0)}
            >
              <ListItemIcon>
                <Mail fontSize="small" color="primary" />
              </ListItemIcon>
              <ListItemText>Send Confirmation Codes</ListItemText>
            </MenuItem>

            <MenuItem onClick={handleRecalculateScores} disabled={visibleRows.length === 0}>
              <ListItemIcon>
                <Calculate fontSize="small" color="primary" />
              </ListItemIcon>
              <ListItemText>
                {selected.length === 0
                  ? "Recalculate all scores"
                  : `Recalculate scores (${selected.length})`}
              </ListItemText>
            </MenuItem>
            <Divider key="divider_recruiting_1" variant="inset" />

            <MenuItem
              onClick={() => {
                handleExport();
                handleMoreClose();
              }}
              disabled={visibleRows.length === 0}
            >
              <ListItemIcon>
                <SaveIcon fontSize="small" color="primary" />
              </ListItemIcon>
              <ListItemText>Export to CSV</ListItemText>
            </MenuItem>

            {/* <MenuItem onClick={handleTest} disabled={visibleRows.length === 0}>
              <ListItemIcon>
                <Calculate fontSize="small" color="primary" />
              </ListItemIcon>
              <ListItemText>
                {selected.length === 0
                  ? "Test all candidates"
                  : `Test candidates (${selected.length})`}
              </ListItemText>
            </MenuItem> */}
          </Menu>

          <ConvertCandidatesDialog
            candidates={convertCandidates}
            campaigns={campaigns}
            open={openConvertDialog}
            onClose={handleCloseConvertDialog}
          />

          <Divider orientation="vertical" flexItem sx={{ marginTop: "8px", marginBottom: "4px" }} />
          <TextField
            name={"campaign"}
            label={"Campaign"}
            size="small"
            select
            onChange={handleSelectCampaign}
            value={selectedCampaign || ""}
            required
            margin={"dense"}
            sx={{
              width: "600px",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Tooltip
                    title={showOpenCampaigns ? "Show all campaigns" : "Show active campaigns"}
                  >
                    <IconButton onClick={handleToggleShowopenCampaigns}>
                      {showOpenCampaigns ? <ToggleOn sx={{ color: "green" }} /> : <ToggleOff />}
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          >
            <MenuItem value="all_campaigns" key="all_campaigns">
              <Typography fontStyle={"italic"}>Show all campaigns</Typography>
            </MenuItem>
            {!currentUser?.appUser?.sponsorId && (
              <MenuItem value="no_campaign" key="no_campaign">
                <Typography fontStyle={"italic"}>Show candidates without a campaigns</Typography>
              </MenuItem>
            )}
            {Object.values(filteredCampaigns).map(([campaignId, campaign]) => {
              return (
                <MenuItem
                  value={campaignId}
                  key={campaignId}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {campaign.name}
                  {getCampaignChip(campaignId)}
                </MenuItem>
              );
            })}
          </TextField>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search..."
            value={searchValue}
            onChange={handleSearchValueChange}
            sx={{ minWidth: "250px", marginTop: "8px", marginBottom: "4px" }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            marginTop: "1rem",
            // minHeight: "400px",
            minHeight: "calc(100vh - 210px)",
            paddingBottom: "4px",
            position: "relative", // Add position relative to contain the absolute positioned overlay
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              flexBasis: "0",
              maxWidth: `calc(100% - 550px)`,
              marginRight: "8px",
              maxHeight: "calc(100vh - 230px)",
            }}
          >
            <TableVirtuoso
              data={visibleRows}
              context={{
                selectedId: selectedCandidate?.id || "",
                handleSelect: handleSelectCandidate,
              }}
              components={VirtuosoTableComponents}
              fixedHeaderContent={() => (
                <EnhancedTableHeadContents
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={visibleRows.length}
                  selectable={true}
                  headCells={headCells}
                />
              )}
              fixedFooterContent={getFooter}
              itemContent={(index, [candidateId, candidate]) => {
                const isItemSelected = isSelected(candidateId);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                        onChange={(event) => handleCheckRow(event, candidateId)}
                      />
                    </TableCell>
                    <TableCell scope="row" size="small">
                      {candidate.name}
                    </TableCell>
                    <TableCell size="small">{candidate.surname}</TableCell>
                    <TableCell size="small">{getPassportCell(candidate)}</TableCell>
                    <TableCell size="small">{getCandidateStatusChip(candidate.status)}</TableCell>
                    <TableCell size="small">
                      {candidate.surveyScore !== undefined
                        ? candidate.surveyScore === DISQUALIFY_NUM_VALUE
                          ? "DQ"
                          : candidate.surveyScore
                        : "-"}
                    </TableCell>
                    <TableCell size="small">
                      {getVerificationIcon(candidate.verification)}
                    </TableCell>
                    <TableCell align="right" size="small">
                      {candidate.applicantId && (
                        <Tooltip title="Go to Applicant">
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              navigate(`/applicants/${candidate.applicantId}`);
                            }}
                          >
                            <InputIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title="Edit candidate">
                        <IconButton
                          size="small"
                          onClick={() => {
                            handleEditCandidate({ id: candidateId, candidate });
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>{" "}
                      <Tooltip title="Delete">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDelete(candidateId || "");
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </>
                );
              }}
            />
          </Box>
          <Box
            id="details"
            sx={{ width: "48%", maxWidth: "550px", marginBottom: "4px", flexGrow: 1 }}
          >
            {displayCandidateDetails(selectedCandidate)}
          </Box>
        </Box>

        {/* Add this near your other dialogs */}
        <Dialog open={progressDialog.open} maxWidth="xs" fullWidth>
          <DialogTitle>
            {progressDialog.action === "delete" ? "Deleting Candidates" : "Recalculating Scores"}
          </DialogTitle>
          <DialogContent>
            <Box sx={{ width: "100%", mt: 2 }}>
              <LinearProgress
                variant="determinate"
                value={(progressDialog.current / progressDialog.total) * 100}
              />
              <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 1 }}>
                {`${progressDialog.current} of ${progressDialog.total} candidates ${
                  progressDialog.action === "delete" ? "deleted" : "processed"
                }`}
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setProgressDialog((prev) => ({ ...prev, open: false, action: null }))}
              disabled={progressDialog.current < progressDialog.total}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </TitledPage>
  );
};

export default CandidatesPage;
