import { EMPTY_COUNTRY } from "./commons";
import { Country } from "./commons";

export const NUM_RELATIVES = 4;

export const EMPTY_RELATIVE = {
  name: "",
  surname: "",
  nationality: { ...EMPTY_COUNTRY },
  relation: "",
  typeOfStay: "",
  passportNumber: "",
  note: "",
};

export const EMPTY_IMMERGENCY_CONTACT = {
  name: "",
  phone: "",
  relation: "",
};

export const RELATIVE_RELATION: Record<string, string> = {
  adoped_child: "Adopted child",
  brother: "Brother",
  brother_in_law: "Brother-in-law",
  child: "Child",
  child_spouce: "Child's spouse (daughter-in-law or son-in-law)",
  daughter: "Daughter",
  daughter_in_law: "Daughter-in-law",
  father: "Father",
  father_in_law: "Father-in-law",
  grandchild: "Grandchild",
  grandparent: "Grandparent",
  husband: "Husband",
  mother: "Mother",
  mother_in_law: "Mother-in-law",
  parent: "Parent",
  sister: "Sister",
  sister_in_law: "Sister-in-law",
  son: "Son",
  son_in_law: "Son-in-law",
  step_child: "Step-child",
  step_parents: "Step-parents",
  step_sibling: "Step-sister or brother",
  unmarried_partner: "Unmarried partner",
  wife: "Wife",
};

export interface Relative {
  name: string;
  surname: string;
  nationality: Country;
  relation: string;
  typeOfStay: string;
  passportNumber?: string;
  note?: string;
}

export interface RelativeError {
  name: string;
  surname: string;
  nationality: string;
  relation: string;
  typeOfStay: string;
  passportNumber?: string;
  note?: string;
}

export interface ImmergencyContact {
  name: string;
  phone: string;
  relation: string;
}
