import React from "react";
import { Grid, Divider, MenuItem, Typography, IconButton } from "@mui/material";
import { SelectCountryTranslated } from "../../../components/select-country";
import { RadioYesNo } from "../../../components/radio-button-yes-no";

import { Applicant, ApplicantErrors } from "../../../types/applicants";
import { EMPTY_COUNTRY } from "../../../types/commons";
import { Country } from "../../../types/commons";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CustomTextField } from "../../../components/custom-textfield";
import { FormattedMessage } from "react-intl";
import { ordinalNumbers } from "../../../utils/utils";
import { NUM_TRAVEL_DESTINATIONS, NUM_TRAVEL_UK } from "../../../types/travels";
import DeleteIcon from "@mui/icons-material/Delete";

interface PageTravelInformationProps {
  applicant: Applicant;
  errors: ApplicantErrors;
  disableTravelAbroad: boolean;
  disableTravelUK: boolean;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onCountryChange: (event: any, newValue: Country | null) => void;
  onTravelCountryChange: (event: any, newValue: Country | null) => void;
  onInputTravelChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDeleteTravel: (type: string, index: number) => void;
}
export const PageTravelInformation: React.FC<PageTravelInformationProps> = ({
  applicant,
  errors,
  disableTravelAbroad,
  disableTravelUK,
  onInputChange,
  onCountryChange,
  onTravelCountryChange,
  onInputTravelChange,
  onDeleteTravel,
}) => {
  console.log("pageTravelInformation");
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container spacing={2} padding={2}>
      <Grid item xs={12}>
        <Divider textAlign="left">
          <FormattedMessage id="divider_uk_status" defaultMessage="UK Status" />
        </Divider>
      </Grid>

      <Grid item xs={10} md={6}>
        <RadioYesNo
          id="UK_insurance"
          error={!!errors.UK_insurance}
          helperText={errors.UK_insurance}
          value={applicant.UK_insurance}
          label="Do you have a National Insurance Number in UK?"
          onChange={onInputChange}
          autoFocus
        />
      </Grid>
      <Grid item xs={10} md={6}>
        <CustomTextField
          name="UK_insurance_number"
          id="UK_insurance_number"
          label="National Insurance number"
          error={!!errors.UK_insurance_number}
          helperText={errors.UK_insurance_number}
          value={applicant.UK_insurance_number}
          disabled={!applicant.UK_insurance}
          onChange={onInputChange}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      </Grid>

      <Grid item xs={10} md={6}>
        <RadioYesNo
          id="visited_UK_doctor"
          error={!!errors.visited_UK_doctor}
          helperText={errors.visited_UK_doctor}
          value={applicant.visited_UK_doctor}
          label="Have you ever visited a doctor in UK?"
          onChange={onInputChange}
        />
      </Grid>

      <Grid item xs={10} md={6}>
        <RadioYesNo
          id="granted_UK_visa"
          error={!!errors.granted_UK_visa}
          helperText={errors.granted_UK_visa}
          value={applicant.granted_UK_visa}
          label="Have you ever been granted a UK visa?"
          onChange={onInputChange}
        />
      </Grid>

      <Grid item xs={10} md={6}>
        <RadioYesNo
          id="refused_visa"
          error={!!errors.refused_visa}
          helperText={errors.refused_visa}
          value={applicant.refused_visa}
          label="Have you ever been refused a visa for the UK?"
          onChange={onInputChange}
        />
      </Grid>

      <Grid item xs={10} md={6}>
        <RadioYesNo
          id="refused_entry"
          error={!!errors.refused_entry}
          helperText={errors.refused_entry}
          value={applicant.refused_entry}
          label="Have you been refused entry on arrival to the UK?"
          onChange={onInputChange}
        />
      </Grid>

      <Grid item xs={10} md={6}>
        <RadioYesNo
          id="has_worked_illegally"
          error={!!errors.has_worked_illegally}
          helperText={errors.has_worked_illegally}
          value={applicant.has_worked_illegally}
          label="Have you ever worked illegally in UK?"
          onChange={onInputChange}
        />
      </Grid>

      <Grid item xs={10} md={6}>
        <RadioYesNo
          id="required_to_leave_UK"
          error={!!errors.required_to_leave_UK}
          helperText={errors.required_to_leave_UK}
          value={applicant.required_to_leave_UK}
          label="Have you ever been deported‚ removed or otherwise required to leave the UK?"
          onChange={onInputChange}
        />
      </Grid>

      <Grid item xs={10} md={6}>
        <RadioYesNo
          id="has_applied_for_visa"
          error={!!errors.has_applied_for_visa}
          helperText={errors.has_applied_for_visa}
          value={applicant.has_applied_for_visa}
          label="Have you made an application to the Home Office to remain in the UK in the last 10 years?"
          onChange={onInputChange}
        />
      </Grid>

      <Grid item xs={12}>
        <Divider textAlign="left">
          <FormattedMessage id="divider_travel_UK" defaultMessage="Travel to UK" />
        </Divider>
      </Grid>

      <Grid item xs={12}>
        <RadioYesNo
          id="has_travelled_to_UK"
          value={applicant.has_travelled_to_UK}
          label="Have you ever travelled to the UK before?"
          onChange={onInputTravelChange}
          error={!!errors.has_travelled_to_UK}
          helperText={errors.has_travelled_to_UK}
        />
      </Grid>

      {/* UK travel details */}
      {!disableTravelUK && (
        <Grid item xs={12} md={12}>
          <Typography>
            <FormattedMessage
              id="travel_uk_hint"
              defaultMessage="Name your 3 most recent travels to the UK."
            />
          </Typography>
        </Grid>
      )}
      {!disableTravelUK &&
        ordinalNumbers(NUM_TRAVEL_UK).map((value, i) => {
          return (
            <React.Fragment key={"uk_travel_" + i}>
              <Grid item xs={12} md={3}>
                <CustomTextField
                  name={"traveluk_" + i + "_from"}
                  label={value + " travel start date"}
                  error={
                    !!(
                      errors.travel_details_UK &&
                      errors.travel_details_UK[i] &&
                      errors.travel_details_UK[i].from
                    )
                  }
                  helperText={
                    errors.travel_details_UK &&
                    errors.travel_details_UK[i] &&
                    errors.travel_details_UK[i].from
                  }
                  type="date"
                  value={
                    (applicant.travel_details_UK &&
                      applicant.travel_details_UK[i] &&
                      applicant.travel_details_UK[i].from) ||
                    ""
                  }
                  onChange={onInputTravelChange}
                  InputLabelProps={{ shrink: true }}
                  disabled={disableTravelUK}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <CustomTextField
                  name={"traveluk_" + i + "_to"}
                  label={value + " travel end date"}
                  error={
                    !!(
                      errors.travel_details_UK &&
                      errors.travel_details_UK[i] &&
                      errors.travel_details_UK[i].to
                    )
                  }
                  helperText={
                    errors.travel_details_UK &&
                    errors.travel_details_UK[i] &&
                    errors.travel_details_UK[i].to
                  }
                  type="date"
                  value={
                    (applicant.travel_details_UK &&
                      applicant.travel_details_UK[i] &&
                      applicant.travel_details_UK[i].to) ||
                    ""
                  }
                  onChange={onInputTravelChange}
                  InputLabelProps={{ shrink: true }}
                  disabled={disableTravelUK}
                  fullWidth
                />
              </Grid>
              <Grid item xs={11} md={5}>
                <CustomTextField
                  name={"traveluk_" + i + "_reason"}
                  label={"Reason for the " + value + " travel"}
                  select
                  error={
                    !!(
                      errors.travel_details_UK &&
                      errors.travel_details_UK[i] &&
                      errors.travel_details_UK[i].reason
                    )
                  }
                  helperText={
                    errors.travel_details_UK &&
                    errors.travel_details_UK[i] &&
                    errors.travel_details_UK[i].reason
                  }
                  value={
                    (applicant.travel_details_UK &&
                      applicant.travel_details_UK[i] &&
                      applicant.travel_details_UK[i].reason) ||
                    ""
                  }
                  onChange={onInputTravelChange}
                  required
                  fullWidth
                >
                  <MenuItem value="Work">
                    <FormattedMessage id="travel_reason_work" defaultMessage="Work" />
                  </MenuItem>
                  <MenuItem value="Tourism">
                    <FormattedMessage id="travel_reason_tourism" defaultMessage="Tourism" />
                  </MenuItem>
                  <MenuItem value="Study">
                    <FormattedMessage id="travel_reason_study" defaultMessage="Study" />
                  </MenuItem>
                  <MenuItem value="Transit">
                    <FormattedMessage id="travel_reason_transit" defaultMessage="Transit" />
                  </MenuItem>
                </CustomTextField>
              </Grid>
              <Grid item xs={1} md={1} container alignItems="center">
                <IconButton
                  aria-label="delete"
                  size="large"
                  onClick={() => {
                    onDeleteTravel("uk", i);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>

              {smallScreen && i < 3 && (
                <Grid item xs={12}>
                  <Divider light></Divider>
                </Grid>
              )}
            </React.Fragment>
          );
        })}

      <Grid item xs={12}>
        <Divider textAlign="left">
          <FormattedMessage id="divider_travel_abroad" defaultMessage="Travel abroad" />
        </Divider>
      </Grid>
      <Grid item xs={10} md={12}>
        <RadioYesNo
          id="refused_visa_abroad"
          error={!!errors.refused_visa_abroad}
          helperText={errors.refused_visa_abroad}
          value={applicant.refused_visa_abroad}
          label="Have you ever been refused a visa for any country?"
          onChange={onInputChange}
        />
      </Grid>
      <Grid item xs={2} md={6} />

      <Grid item xs={12}>
        <RadioYesNo
          id="deported_abroad"
          error={!!errors.deported_abroad}
          helperText={errors.deported_abroad}
          value={applicant.deported_abroad}
          label="Have you ever been deported‚ removed or otherwise required to leave another country?"
          onChange={onInputChange}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTextField
          name="deported_abroad_details"
          label="If you have been deported‚ removed or otherwise required to leave another country, please give more details"
          error={!!errors.deported_abroad_details}
          helperText={errors.deported_abroad_details}
          value={applicant.deported_abroad_details}
          onChange={onInputChange}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      </Grid>

      {/* -------- Travel Destinations ---------*/}
      <Grid item xs={12}>
        <RadioYesNo
          id="has_travelled_abroad"
          value={applicant.has_travelled_abroad}
          label="Have you travelled to any other country beside UK in the last 10 years?"
          error={!!errors.has_travelled_abroad}
          helperText={errors.has_travelled_abroad}
          onChange={onInputTravelChange}
        />
      </Grid>

      {/*travel details abroad*/}
      {!disableTravelAbroad &&
        ordinalNumbers(NUM_TRAVEL_DESTINATIONS).map((value, i) => {
          return (
            <React.Fragment key={"abroad_travel_" + i}>
              <Grid item xs={12} md={4}>
                <CustomTextField
                  name={"travelabroad_" + i + "_from"}
                  label={value + " travel start date"}
                  error={
                    !!(
                      errors.travel_details_abroad &&
                      errors.travel_details_abroad[i] &&
                      errors.travel_details_abroad[i].from
                    )
                  }
                  helperText={
                    errors.travel_details_abroad &&
                    errors.travel_details_abroad[i] &&
                    errors.travel_details_abroad[i].from
                  }
                  type="date"
                  value={
                    (applicant.travel_details_abroad &&
                      applicant.travel_details_abroad[i] &&
                      applicant.travel_details_abroad[i].from) ||
                    ""
                  }
                  onChange={onInputTravelChange}
                  InputLabelProps={{ shrink: true }}
                  disabled={disableTravelAbroad}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <CustomTextField
                  name={"travelabroad_" + i + "_to"}
                  label={value + " travel end date"}
                  error={
                    !!(
                      errors.travel_details_abroad &&
                      errors.travel_details_abroad[i] &&
                      errors.travel_details_abroad[i].to
                    )
                  }
                  helperText={
                    errors.travel_details_abroad &&
                    errors.travel_details_abroad[i] &&
                    errors.travel_details_abroad[i].to
                  }
                  type="date"
                  value={
                    (applicant.travel_details_abroad &&
                      applicant.travel_details_abroad[i] &&
                      applicant.travel_details_abroad[i].to) ||
                    ""
                  }
                  onChange={onInputTravelChange}
                  InputLabelProps={{ shrink: true }}
                  disabled={disableTravelAbroad}
                  fullWidth
                />
              </Grid>

              {!smallScreen && <Grid item md={4}></Grid>}

              <Grid item xs={12} md={4}>
                <SelectCountryTranslated
                  id={"destination_" + i}
                  label={value + " destination"}
                  error={
                    !!(
                      errors.travel_details_abroad &&
                      errors.travel_details_abroad[i] &&
                      errors.travel_details_abroad[i].destination
                    )
                  }
                  helperText={
                    errors.travel_details_abroad &&
                    errors.travel_details_abroad[i] &&
                    errors.travel_details_abroad[i].destination
                  }
                  value={
                    applicant &&
                    applicant.travel_details_abroad &&
                    applicant.travel_details_abroad[i]
                      ? applicant.travel_details_abroad[i].destination
                      : EMPTY_COUNTRY
                  }
                  onChange={onTravelCountryChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomTextField
                  name={"travelabroad_" + i + "_reason"}
                  label={"Reason for the " + value + " travel"}
                  select
                  error={
                    !!(
                      errors.travel_details_abroad &&
                      errors.travel_details_abroad[i] &&
                      errors.travel_details_abroad[i].reason
                    )
                  }
                  helperText={
                    errors.travel_details_abroad &&
                    errors.travel_details_abroad[i] &&
                    errors.travel_details_abroad[i].reason
                  }
                  value={
                    (applicant.travel_details_abroad &&
                      applicant.travel_details_abroad[i] &&
                      applicant.travel_details_abroad[i].reason) ||
                    ""
                  }
                  onChange={onInputTravelChange}
                  required
                  fullWidth
                >
                  <MenuItem value="Work">
                    <FormattedMessage id="travel_reason_work" defaultMessage="Work" />
                  </MenuItem>
                  <MenuItem value="Tourism">
                    <FormattedMessage id="travel_reason_tourism" defaultMessage="Tourism" />
                  </MenuItem>
                  <MenuItem value="Study">
                    <FormattedMessage id="travel_reason_study" defaultMessage="Study" />
                  </MenuItem>
                </CustomTextField>
              </Grid>
              <Grid item xs={1} md={1} container alignItems="center">
                <IconButton
                  aria-label="delete"
                  size="large"
                  onClick={() => {
                    onDeleteTravel("abroad", i);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>

              {i < 4 &&
                (smallScreen ? (
                  <Grid item xs={12}>
                    <Divider light></Divider>
                  </Grid>
                ) : (
                  <Grid item xs={8}>
                    <Divider light></Divider>
                  </Grid>
                ))}
              {!smallScreen && <Grid item md={4}></Grid>}
            </React.Fragment>
          );
        })}
    </Grid>
  );
};
