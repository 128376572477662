import { Grid, Divider } from "@mui/material";
import { MenuItem } from "@mui/material";
import { RadioYesNo } from "../../../components/radio-button-yes-no";
import { CustomTextField } from "../../../components/custom-textfield";

import { Applicant, ApplicantErrors } from "../../../types/applicants";
import { FormattedMessage } from "react-intl";

interface PageAdditionalInformationProps {
  applicant: Applicant;
  errors: ApplicantErrors;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
export const PageAdditionalInformation: React.FC<PageAdditionalInformationProps> = ({
  applicant,
  errors,
  onInputChange,
}) => {
  return (
    <Grid container spacing={2} padding={2}>
      <Grid item xs={12}>
        <Divider textAlign="left">
          <FormattedMessage
            id="divider_additional_information"
            defaultMessage="Additional Information"
          />
        </Divider>
      </Grid>

      <Grid item xs={12} md={6}>
        <RadioYesNo
          id="convictions"
          error={!!errors.convictions}
          helperText={errors.convictions}
          value={applicant.convictions}
          label="Do you have any criminal convictions in any country (including traffic offences)?"
          onChange={onInputChange}
          autoFocus
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <RadioYesNo
          id="war_crimes"
          error={!!errors.war_crimes}
          helperText={errors.war_crimes}
          value={applicant.war_crimes}
          label="In times of either peace or war have you‚ ever been involved in‚ or suspected of involvement in war crime? "
          onChange={onInputChange}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <RadioYesNo
          id="terrorist_org_member"
          error={!!errors.terrorist_org_member}
          helperText={errors.terrorist_org_member}
          value={applicant.terrorist_org_member}
          label="Have you ever been involved in‚ supported‚ or encouraged terrosist organisations"
          onChange={onInputChange}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <RadioYesNo
          id="not_good_character"
          error={!!errors.not_good_character}
          helperText={errors.not_good_character}
          value={applicant.not_good_character}
          label="Have you engaged in any other activities that might indicate that you may not be considered a person of good character?"
          onChange={onInputChange}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider textAlign="left"></Divider>
      </Grid>
      <Grid item xs={12}>
        <CustomTextField
          name="application_additional_information"
          label="Is there any other information you wish to be considered as part of your application?"
          error={!!errors.application_additional_information}
          helperText={errors.application_additional_information}
          value={applicant.application_additional_information}
          onChange={onInputChange}
          fullWidth
        />
      </Grid>

      <Grid item xs={12}>
        <CustomTextField
          name="work_experience"
          label="Please provide details about your working experience"
          error={!!errors.work_experience}
          helperText={errors.work_experience}
          value={applicant.work_experience}
          onChange={onInputChange}
          fullWidth
        />
      </Grid>

      <Grid item xs={12}>
        <CustomTextField
          name="has_health_issues"
          label="If you have any health problems/allergies, please describe"
          error={!!errors.has_health_issues}
          helperText={errors.has_health_issues}
          value={applicant.has_health_issues}
          onChange={onInputChange}
          fullWidth
        />
      </Grid>

      <Grid item xs={10} md={4}>
        <CustomTextField
          name="english_level"
          label="What is your level of English"
          select
          error={!!errors.english_level}
          helperText={errors.english_level}
          value={applicant.english_level}
          onChange={onInputChange}
          required
          fullWidth
        >
          <MenuItem value="None">
            <FormattedMessage id="language_level_none" defaultMessage="None" />
          </MenuItem>
          <MenuItem value="Beginner">
            <FormattedMessage id="language_level_beginner" defaultMessage="Beginner" />
          </MenuItem>
          <MenuItem value="Intermediate">
            <FormattedMessage id="language_level_intermediate" defaultMessage="Intermediate" />
          </MenuItem>
          <MenuItem value="Advanced">
            <FormattedMessage id="language_level_advanced" defaultMessage="Advanced" />
          </MenuItem>
        </CustomTextField>
      </Grid>

      <Grid item xs={2} md={2} />

      <Grid item xs={10} md={6}>
        <RadioYesNo
          id="has_driving_license"
          error={!!errors.has_driving_license}
          helperText={errors.has_driving_license}
          value={applicant.has_driving_license}
          label="Do you hold а driving license?"
          onChange={onInputChange}
        />
      </Grid>
    </Grid>
  );
};
