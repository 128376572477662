import {
  Timestamp,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  setDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import {
  Season,
  SeasonDoc,
  SeasonFB,
  SeasonsDict,
  WorkOrder,
  WorkOrderDoc,
  WorkOrderFB,
  WorkOrdersDict,
} from "../types/orders";
import { AppUser } from "../types/users";
import db from "../types/firebase";
import { fbTimestampToDate } from "../utils/utils";

export const getOrderFromFB = (order: WorkOrderFB): WorkOrder => {
  const newOrder: WorkOrder = {
    name: order.name,
    sponsorId: order.sponsorId,
    employerId: order.employerId,
    seasonId: order.seasonId,
    status: order.status,
    start_date: fbTimestampToDate(order.start_date),
    demand: order.demand,
  };

  order.end_date && (newOrder.end_date = fbTimestampToDate(order.end_date));

  order.agentId && (newOrder.agentId = order.agentId);

  order.createdAt && (newOrder.createdAt = order.createdAt);
  order.createdBy && (newOrder.createdBy = order.createdBy);
  order.updatedAt && (newOrder.updatedAt = order.updatedAt);
  order.updatedBy && (newOrder.updatedBy = order.updatedBy);

  return newOrder;
};

const getFBOrder = (order: WorkOrder): WorkOrderFB => {
  const newOrder: WorkOrderFB = {
    name: order.name,
    sponsorId: order.sponsorId,
    employerId: order.employerId,
    seasonId: order.seasonId,
    status: order.status,
    start_date: Timestamp.fromDate(new Date(order.start_date)),
    demand: order.demand,
  };

  order.end_date && (newOrder.end_date = Timestamp.fromDate(new Date(order.end_date)));

  order.agentId && (newOrder.agentId = order.agentId);

  order.createdAt && (newOrder.createdAt = order.createdAt);
  order.createdBy && (newOrder.createdBy = order.createdBy);
  order.updatedAt && (newOrder.updatedAt = order.updatedAt);
  order.updatedBy && (newOrder.updatedBy = order.updatedBy);

  return newOrder;
};

export const loadWorkOrders = async (
  user: AppUser | null | undefined,
  loadActiveOnly: boolean = false
) => {
  console.log("loading orders....");
  const sponsorId = user && user.sponsorId ? user.sponsorId : "";

  let workOrdersRef;
  let workOrdersSnapshot;
  const workOrdersDict: WorkOrdersDict = {};

  // NOTE: Agents seem not to make sense in work orders anymore
  //const agentId = user && user.agentId ? user.agentId : "";
  // if (agentId !== "") {
  //   workOrdersRef = query(collection(db, "orders"), where("agentId", "==", agentId));
  // } else
  if (sponsorId) {
    workOrdersRef = query(collection(db, "orders"), where("sponsorId", "==", sponsorId));
  } else {
    workOrdersRef = collection(db, "orders");
  }

  if (loadActiveOnly) {
    workOrdersRef = query(workOrdersRef, where("status", "==", "open"));
  }

  workOrdersSnapshot = await getDocs(workOrdersRef);

  workOrdersSnapshot.forEach((doc) => {
    const workOrder = getOrderFromFB(doc.data() as WorkOrderFB);
    workOrdersDict[doc.id] = workOrder;
  });

  return workOrdersDict;
};

export const storeWorkOrder = async (
  user: AppUser | null | undefined,
  workOrder: WorkOrderDoc | null,
  addIfNew: boolean = false
) => {
  console.log("storing workOrder: ");
  console.log(workOrder);
  if (!(workOrder && workOrder.workOrder)) {
    return null;
  }

  const appDoc: WorkOrderDoc = { ...workOrder };
  if (appDoc.id) {
    const docRef = doc(db, "orders", appDoc.id);
    const workOrderSnapshot = await getDoc(docRef);
    // Update the document
    if (workOrderSnapshot) {
      appDoc.workOrder.updatedAt = new Date().toISOString();
      appDoc.workOrder.updatedBy = user ? user.id : "anonymous";
      await setDoc(docRef, getFBOrder(appDoc.workOrder));
      console.log("updated work order:", appDoc);
    } else return null;
  } else if (addIfNew) {
    appDoc.workOrder.createdAt = new Date().toISOString();
    appDoc.workOrder.createdBy = user ? user.id : "anonymous";
    appDoc.id = (await addDoc(collection(db, "orders"), getFBOrder(appDoc.workOrder))).id;
    console.log("created work order:", appDoc.id);
  }

  return appDoc;
};

export const deleteWorkOrder = async (workOrderId: string) => {
  return deleteDoc(doc(db, "orders", workOrderId));
};

export const updateBatchWorkOrders = async (
  user: AppUser | null | undefined,
  workOrders: string[],
  data: any
) => {
  console.log("batch updating " + workOrders.length + " work orders....");

  data.updatedAt = new Date().toISOString();
  data.updatedBy = user ? user.id : "anonymous";
  const batch = writeBatch(db);

  workOrders.forEach((woId) => {
    const workOrderRef = doc(db, "orders", woId);
    if (workOrderRef) batch.update(workOrderRef, data);
  });

  await batch.commit();
};

const getSeasonFromFB = (season: SeasonFB): Season => {
  const newSeason: Season = {
    name: season.name,
    start_date: fbTimestampToDate(season.start_date),
  };

  season.end_date && (newSeason.end_date = fbTimestampToDate(season.end_date));

  season.createdAt && (newSeason.createdAt = season.createdAt);
  season.createdBy && (newSeason.createdBy = season.createdBy);
  season.updatedAt && (newSeason.updatedAt = season.updatedAt);
  season.updatedBy && (newSeason.updatedBy = season.updatedBy);

  return newSeason;
};

const getFBSeason = (season: Season): SeasonFB => {
  const newSeason: SeasonFB = {
    name: season.name,
    start_date: Timestamp.fromDate(new Date(season.start_date)),
  };

  season.end_date && (newSeason.end_date = Timestamp.fromDate(new Date(season.end_date)));

  season.createdAt && (newSeason.createdAt = season.createdAt);
  season.createdBy && (newSeason.createdBy = season.createdBy);
  season.updatedAt && (newSeason.updatedAt = season.updatedAt);
  season.updatedBy && (newSeason.updatedBy = season.updatedBy);

  return newSeason;
};

export const loadSeasons = async (user: AppUser | null | undefined) => {
  if (!user) return {};

  const seasonsRef = collection(db, "seasons");
  const seasonsSnapshot = await getDocs(seasonsRef);
  const seasons: SeasonsDict = {};

  seasonsSnapshot.forEach((doc) => {
    const season = getSeasonFromFB(doc.data() as SeasonFB);
    seasons[doc.id] = season;
  });

  return seasons;
};

export const storeSeason = async (
  user: AppUser | null | undefined,
  season: SeasonDoc | null,
  addIfNew: boolean = false
) => {
  if (!(season && season.season)) {
    return null;
  }

  const appDoc: SeasonDoc = { ...season };
  if (appDoc.id) {
    const docRef = doc(db, "seasons", appDoc.id);
    const seasonSnapshot = await getDoc(docRef);
    // Update the document
    if (seasonSnapshot) {
      appDoc.season.updatedAt = new Date().toISOString();
      appDoc.season.updatedBy = user ? user.id : "anonymous";
      await setDoc(docRef, getFBSeason(appDoc.season));
      console.log("updated season:", appDoc.id);
    } else return null;
  } else if (addIfNew) {
    appDoc.season.createdAt = new Date().toISOString();
    appDoc.season.createdBy = user ? user.id : "anonymous";
    appDoc.id = (await addDoc(collection(db, "seasons"), getFBSeason(appDoc.season))).id;
    console.log("created season:", appDoc.id);
  }

  return appDoc;
};

export const deleteSeason = async (
  user: AppUser | null | undefined,
  seasonId: string
): Promise<string> => {
  if (!seasonId) return "no-season";
  if (!user || !user.role.includes("admin")) {
    return "insufficient-permissions";
  }

  const collections = ["orders"];
  for (let collectionName of collections) {
    const collectionRef = collection(db, collectionName);
    const q = query(collectionRef, where("seasonId", "==", seasonId), limit(1));
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      console.log(`Found document with seasonId ${seasonId} in ${collectionName}`);
      return "season-in-use";
    }
  }

  await deleteDoc(doc(db, "seasons", seasonId));
  return "success";
};
