import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SponsorDoc } from "../../types/sponsors";
import { messages, SupportedLanguages } from "../../languages/languages";
import {
  generateConfirmationCode,
  loadCampaign,
  loadCandidateByVerificationCode,
  saveCandidate,
} from "../../data-functions/recruiting_api";
import { loadSponsor } from "../../data-functions/system-data_api";
import { FormattedMessage, IntlProvider } from "react-intl";
import { Box, Button, Card, Container, Typography, CircularProgress, Alert } from "@mui/material";
import { sendEmailVerificationEmail } from "../../utils/candidate-utils";
import { CampaignDoc, CandidateDoc } from "../../types/recruiting";
import { getServerTime } from "../../data-functions/common-api";
//import gwLogo from "../../ressources/global workforce_thin font.png";

type ParamTypes = {
  cid: string;
};

const ACTIVE_STEP_EMPTY = -1;
const ACTIVE_STEP_REGISTERED = 0;
const ACTIVE_STEP_REGISTRATION_ALREADY_CONFIRMED = 2;
const ACTIVE_STEP_EXPIRED_CANDIDATE = 3;
const ACTIVE_STEP_INVALID_CANDIDATE = 4;

type LoadingState = "loading" | "success" | "error";

export const VerifyRegistrationPage = () => {
  const { cid } = useParams<ParamTypes>();
  const [sponsor, setSponsor] = useState<SponsorDoc | null>(null);
  const [language, setLanguage] = useState<SupportedLanguages>("en");
  const [candidate, setCandidate] = useState<CandidateDoc | null>(null);
  const [campaign, setCampaign] = useState<CampaignDoc | null>(null);
  const [activeStep, setActiveStep] = useState<number>(ACTIVE_STEP_EMPTY);
  const [loadingState, setLoadingState] = useState<LoadingState>("loading");
  const [error, setError] = useState<string | null>(null);

  const fetchData = useCallback(async (candidateId: string | undefined) => {
    if (!candidateId) {
      setLoadingState("error");
      setError("No candidate ID provided");
      setActiveStep(ACTIVE_STEP_EXPIRED_CANDIDATE);
      return;
    }

    try {
      setLoadingState("loading");

      // Load candidate
      const candidateDoc = await loadCandidateByVerificationCode(candidateId);
      if (!candidateDoc) {
        setLoadingState("error");
        setError("Candidate not found");
        setActiveStep(ACTIVE_STEP_INVALID_CANDIDATE);
        return;
      }

      // Load sponsor and campaign in parallel
      const [sponsorDoc, campaignDoc] = await Promise.all([
        loadSponsor(candidateDoc.candidate.sponsorId),
        loadCampaign(candidateDoc.candidate.campaignId || ""),
      ]);

      // Update all state at once to prevent multiple re-renders
      setCandidate(candidateDoc);
      setSponsor(sponsorDoc || null);
      setCampaign(campaignDoc);

      // Update language if needed
      if (campaignDoc?.campaign?.language) {
        setLanguage(campaignDoc.campaign.language as SupportedLanguages);
      }

      // Handle verification status
      if (candidateDoc.candidate.verification) {
        if (candidateDoc.candidate.verification.verifiedOn) {
          setActiveStep(ACTIVE_STEP_REGISTRATION_ALREADY_CONFIRMED);
        } else {
          try {
            const serverTime = await getServerTime();
            const confirmationCode = await generateConfirmationCode(campaignDoc?.id || "");

            const updatedCandidate: CandidateDoc = {
              ...candidateDoc,
              candidate: {
                ...candidateDoc.candidate,
                verification: {
                  ...candidateDoc.candidate.verification,
                  verifiedOn: serverTime.iso,
                  confirmationCode,
                },
                status: campaignDoc?.campaign?.inviteAutomatically ? "invited" : "verified",
              },
            };

            await saveCandidate(null, updatedCandidate, false);
            setCandidate(updatedCandidate);
            await resendConfirmationMail(updatedCandidate, campaignDoc);
            setActiveStep(ACTIVE_STEP_REGISTERED);
          } catch (err) {
            console.error("Error updating candidate:", err);
            setError("Failed to update candidate verification");
            setLoadingState("error");
            return;
          }
        }
      } else {
        setActiveStep(ACTIVE_STEP_INVALID_CANDIDATE);
      }

      setLoadingState("success");
    } catch (err) {
      console.error("Error fetching data:", err);
      setError("Failed to load data");
      setLoadingState("error");
      setActiveStep(ACTIVE_STEP_INVALID_CANDIDATE);
    }
  }, []);

  useEffect(() => {
    fetchData(cid);
  }, [cid, fetchData]);

  const resendConfirmationMail = async (
    candidateDoc: CandidateDoc | null,
    campaignDoc: CampaignDoc | null
  ) => {
    if (!candidateDoc) return;

    const templateId = campaignDoc?.campaign.inviteAutomatically
      ? "candidate_confirmation"
      : "candidate_confirmation_no_invitation";

    await sendEmailVerificationEmail([candidateDoc.id], templateId);
  };

  const getRegisteredCandidate = () => {
    return (
      <Box padding={"3rem"} paddingTop={"1rem"}>
        <Typography variant="h5" sx={{ marginBottom: "1rem", textAlign: "center" }}>
          <FormattedMessage
            id="register_registration_confirmed_title"
            defaultMessage="Congratulations! Your registration has been confirmed!"
          />
        </Typography>
        <Typography variant="h5" sx={{ marginBottom: "1rem", textAlign: "center" }}>
          <FormattedMessage
            id="register_registration_confirmed_email"
            defaultMessage="You will receive an email with the confirmation."
          />
        </Typography>
        {campaign?.campaign.inviteAutomatically && (
          <Typography variant="h5" sx={{ marginBottom: "1rem", textAlign: "center" }}>
            <FormattedMessage
              id="register_registration_confirmed_email_2"
              defaultMessage="Please bring the email with you to the interview!"
            />
          </Typography>
        )}
      </Box>
    );
  };

  const getRegistrationAlreadyConfirmed = () => {
    return (
      <Box
        padding={"3rem"}
        paddingTop={"1rem"}
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap={2}
      >
        <Typography variant="h5" sx={{ marginBottom: "1rem", textAlign: "center" }}>
          <FormattedMessage
            id="register_regitration_already_confirmed_title"
            defaultMessage="Your registration has been already confirmed!"
          />
        </Typography>
        {campaign?.campaign.inviteAutomatically && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              resendConfirmationMail(candidate, campaign);
            }}
          >
            <FormattedMessage
              id="register_resend_confirmation_button"
              defaultMessage="Resend Confirmation Mail"
            />
          </Button>
        )}
      </Box>
    );
  };

  const getInvalidCandidate = () => {
    return (
      <Box padding={"3rem"} paddingTop={"1rem"}>
        <Typography variant="h5" sx={{ marginBottom: "1rem", textAlign: "center" }}>
          <FormattedMessage
            id="register_candidate_invalid_title"
            defaultMessage="The confirmation link you were provided is invalid!"
          />
        </Typography>
      </Box>
    );
  };

  const getExpiredCandidate = () => {
    return (
      <Box padding={"3rem"} paddingTop={"1rem"}>
        <Typography variant="h5" sx={{ marginBottom: "1rem", textAlign: "center" }}>
          <FormattedMessage
            id="register_confirmation_expired_title"
            defaultMessage="This link has already expired! You need to make a new registration!"
          />
        </Typography>
      </Box>
    );
  };

  const getEmpty = () => {
    return (
      <Box padding={"3rem"} paddingTop={"1rem"}>
        <Typography variant="h5" sx={{ marginBottom: "1rem", textAlign: "center" }}>
          <FormattedMessage id="register_empty_title" defaultMessage="Seasonal work in the UK" />
        </Typography>
      </Box>
    );
  };

  const getActivePage = () => {
    switch (activeStep) {
      case ACTIVE_STEP_REGISTERED:
        return getRegisteredCandidate();
      case ACTIVE_STEP_REGISTRATION_ALREADY_CONFIRMED:
        return getRegistrationAlreadyConfirmed();
      case ACTIVE_STEP_INVALID_CANDIDATE:
        return getInvalidCandidate();
      case ACTIVE_STEP_EXPIRED_CANDIDATE:
        return getExpiredCandidate();
      case ACTIVE_STEP_EMPTY:
        return getEmpty();
      default:
        return getEmpty();
    }
  };

  if (loadingState === "loading") {
    return (
      <Container maxWidth="md" sx={{ marginTop: "2rem", marginBottom: "2rem" }}>
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (loadingState === "error" && error) {
    return (
      <Container maxWidth="md" sx={{ marginTop: "2rem", marginBottom: "2rem" }}>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <IntlProvider locale={language} messages={messages[language]}>
      <Container maxWidth="md" sx={{ marginTop: "2rem", marginBottom: "2rem" }}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <img
            alt="Operator Logo"
            src={sponsor ? sponsor.sponsor.logo : ""}
            style={{ maxHeight: "200px", width: "auto" }}
          />
        </Box>
        <Card sx={{ marginTop: "1rem", padding: "1rem" }}>{getActivePage()}</Card>
      </Container>
    </IntlProvider>
  );
};
