import { Question } from "../../types/surveys";

export const INTERVIEW_QUESTIONS: Question[] = [
  {
    id: "qid_0029",
    type: "radio_yes_no",
    label: "Do you speak English?",
    required: true,
    mdSize: 1,
  },
  {
    id: "qid_0030",
    type: "toggle",
    label: "If yes, at what level?",
    options: [
      { id: "qid_0030_01", label: "Basic", value: "basic" },
      { id: "qid_0030_02", label: "Intermediate", value: "intermediate" },
      { id: "qid_0030_03", label: "Advanced", value: "advanced" },
    ],
    required: false,
    mdSize: 1,
  },
  {
    id: "qid_0026",
    type: "radio_yes_no",
    label: "Have you worked in the UK before?",
    required: true,
    mdSize: 1,
  },
  {
    id: "qid_0027",
    type: "select",
    label: "If yes, through which operator?",
    options: [
      { id: "qid_0027_01", label: "HOPS Group", value: "hops" },
      { id: "qid_0027_02", label: "ProForce", value: "proforce" },
      { id: "qid_0027_03", label: "Fruitful", value: "fruitful" },
      { id: "qid_0027_04", label: "Concordia", value: "concordia" },
      { id: "qid_0027_05", label: "AgriHR", value: "agrihr" },
      { id: "qid_0027_06", label: "Other", value: "other" },
    ],
    required: false,
    mdSize: 1,
  },
  {
    id: "qid_0028",
    type: "text",
    label: "If yes, in which year?",
    required: true,
    mdSize: 1,
  },
  {
    id: "qid_00P1",
    type: "placeholder",
    label: "",
    mdSize: 1,
    required: false,
  },

  {
    id: "qid_0031",
    type: "radio_yes_no",
    label: "Do you have a higher education?",
    required: true,
    mdSize: 1,
  },
  {
    id: "qid_0034",
    type: "radio_yes_no",
    label: "Are you willing to be filmed during the interview?",
    required: true,
    mdSize: 1,
  },
  {
    id: "qid_0001",
    type: "radio_yes_no",
    label: "Do you have any previous experience in agriculture?",
    mdSize: 1,
    required: false,
  },
  {
    id: "qid_00P2",
    type: "placeholder",
    label: "",
    mdSize: 1,
    required: false,
  },
  {
    id: "qid_0002",
    type: "multiline",
    label: "Which crops do you have experience growing/picking?",
    mdSize: 1,
    required: false,
  },
  {
    id: "qid_0003",
    type: "multiline",
    label: "Preference for the type of crops to be picked?",
    mdSize: 1,
    required: false,
  },
  {
    id: "qid_0004",
    type: "radio_yes_no",
    label: "Have you ever been issued an UK visa before?",
    mdSize: 1,
    required: false,
  },
  {
    id: "qid_0005",
    type: "multiline",
    label: "If yes, what type of visa and when did it expire?",
    mdSize: 1,
    required: false,
  },
  {
    id: "qid_0006",
    type: "radio_yes_no",
    label: "Do you have a driving license?",
    mdSize: 1,
    required: false,
  },
  {
    id: "qid_0007",
    type: "select_multiple",
    label: "Hold a license for",
    options: [
      { id: "qid_0007_01", label: "Driving a tractor", value: "tractor" },
      { id: "qid_0007_02", label: "Driving a forklift", value: "forklift" },
      { id: "qid_0007_03", label: "Driving a spayer", value: "spayer" },
    ],
    mdSize: 1,
    required: false,
  },
  {
    id: "qid_0008",
    type: "multiline",
    label: "Have you worked in other countries? If so, in which countries and what type of work?",
    mdSize: 2,
    required: false,
  },
  {
    id: "qid_0009",
    type: "radio_yes_no",
    label: "Do you suffer from any serious illnesses?",
    mdSize: 1,
    required: false,
  },
  {
    id: "qid_0010",
    type: "text",
    label: "If YES, please specify:",
    mdSize: 1,
    required: false,
  },
  {
    id: "qid_0011",
    type: "radio_yes_no",
    label: "Does this disease require medication?",
    mdSize: 1,
    required: false,
  },
  {
    id: "qid_0012",
    type: "text",
    label: "If YES, please specify:",
    mdSize: 1,
    required: false,
  },
  {
    id: "qid_0013",
    type: "radio_yes_no",
    label: "Do you have allergies?",
    mdSize: 1,
    required: false,
  },
  {
    id: "qid_0014",
    type: "text",
    label: "If YES, please specify:",
    mdSize: 1,
    required: false,
  },
  {
    id: "qid_0015",
    type: "radio_yes_no",
    label: "Do you suffer from color blindness?",
    mdSize: 1,
    required: false,
  },
  {
    id: "qid_0016",
    type: "radio_yes_no",
    label: "Are you physically fit and able to work long hours in all kinds of weather?",
    mdSize: 1,
    required: false,
  },
  {
    id: "qid_0023",
    type: "radio_yes_no",
    label: "Are you afraid of heights (for mushroom pickers)?",
    mdSize: 1,
    required: false,
  },
  {
    id: "qid_0017",
    type: "toggle",
    label: "For how long can you stay in the UK?",
    options: [
      { id: "qid_0017_01", label: "3 Months", value: "3" },
      { id: "qid_0017_02", label: "4 Months", value: "4" },
      { id: "qid_0017_03", label: "5 Months", value: "5" },
      { id: "qid_0017_04", label: "6 Months", value: "6" },
    ],
    mdSize: 1,
    required: false,
  },
  {
    id: "qid_0018",
    type: "radio_yes_no",
    label: "Would you work 40 plus hours a week?",
    mdSize: 1,
    required: false,
  },
  {
    id: "qid_0019",
    type: "text",
    label: "Do you have a preferred start date?",
    mdSize: 1,
    required: false,
  },
  {
    id: "qid_0020",
    type: "multiline",
    label: "What's the best part of working on a farm?",
    mdSize: 2,
    required: false,
  },
  {
    id: "qid_0021",
    type: "radio_yes_no",
    label:
      "Do you have enough funds to cover the cost of visa fee, TB test, round-trip plane ticket and pocket money until you get your first salary?",
    mdSize: 1,
    required: false,
  },
  {
    id: "qid_0022",
    type: "toggle",
    label: "How do you prefer to be paid - by piecerate or by timework?",
    options: [
      { id: "qid_0022_01", label: "Piece rate", value: "piece" },
      { id: "qid_0022_02", label: "Time work", value: "time" },
    ],
    mdSize: 1,
    required: false,
  },

  {
    id: "qid_0024",
    type: "multiline",
    label:
      "What amount of money do you expect to earn within a full work period of 6 months, and what amount of savings would you be happy with?",
    mdSize: 1,
    required: false,
  },
  {
    id: "qid_0025",
    type: "toggle",
    label: "Do you prefer day or night shifts?",
    options: [
      { id: "qid_0025_01", label: "Day", value: "day" },
      { id: "qid_0025_02", label: "Night", value: "night" },
    ],
    mdSize: 1,
    required: false,
  },
];

export const RUSSIAN_QUESTIONS: Question[] = [
  {
    id: "qid_0032",
    type: "radio_yes_no",
    label: "Do you speak and read Russian?",
    mdSize: 1,
    required: true,
    score: {
      true: 0,
      false: "disqualify",
    },
  },
  {
    id: "qid_0033",
    type: "toggle",
    label: "If yes, at what level?",
    options: [
      { id: "qid_0033_01", label: "Basic", value: "basic" },
      { id: "qid_0033_02", label: "Intermediate", value: "intermediate" },
      { id: "qid_0033_03", label: "Advanced", value: "advanced" },
    ],
    mdSize: 1,
    required: false,
    score: {
      basic: 0,
      intermediate: 1,
      advanced: 2,
    },
  },
];

export const ADVANCED_FORM_QUESTIONS: Question[] = [
  {
    id: "qid_0029",
    type: "radio_yes_no",
    label: "Do you speak English?",
    required: true,
    mdSize: 1,
  },
  {
    id: "qid_0030",
    type: "toggle",
    label: "If yes, at what level?",
    options: [
      { id: "qid_0030_01", label: "Basic", value: "basic" },
      { id: "qid_0030_02", label: "Intermediate", value: "intermediate" },
      { id: "qid_0030_03", label: "Advanced", value: "advanced" },
    ],
    required: false,
    mdSize: 1,
    score: {
      basic: 0,
      intermediate: 0,
      advanced: 2,
    },
  },
  {
    id: "qid_0026",
    type: "radio_yes_no",
    label: "Have you worked in the UK before?",
    required: true,
    mdSize: 1,
    score: {
      true: 1,
      false: 0,
    },
  },
  {
    id: "qid_0027",
    type: "select",
    label: "If yes, through which operator?",
    options: [
      { id: "qid_0027_00", label: "None", value: "none" },
      { id: "qid_0027_01", label: "HOPS Group", value: "hops" },
      { id: "qid_0027_02", label: "ProForce", value: "proforce" },
      { id: "qid_0027_03", label: "Fruitful", value: "fruitful" },
      { id: "qid_0027_04", label: "Concordia", value: "concordia" },
      { id: "qid_0027_05", label: "AgriHR", value: "agrihr" },
      { id: "qid_0027_06", label: "Other", value: "other" },
    ],
    required: false,
    mdSize: 1,
    score: {
      none: 0,
      hops: -1,
      proforce: -2,
      fruitful: -2,
      concordia: -2,
      agrihr: -2,
      other: -2,
    },
  },
  {
    id: "qid_0028",
    type: "text",
    label: "If yes, in which year?",
    required: false,
    mdSize: 1,
  },

  {
    id: "qid_0031",
    type: "radio_yes_no",
    label: "Do you have a higher education?",
    required: true,
    mdSize: 1,
  },
  {
    id: "qid_0006",
    type: "radio_yes_no",
    label: "Do you have a driving license?",
    required: true,
    mdSize: 1,
    score: {
      true: 1,
      false: 0,
    },
  },
  {
    id: "qid_0007",
    type: "select_multiple",
    label: "Hold a license for",
    options: [
      { id: "qid_0007_01", label: "Driving a tractor", value: "tractor" },
      { id: "qid_0007_02", label: "Driving a forklift", value: "forklift" },
      { id: "qid_0007_03", label: "Driving a spayer", value: "spayer" },
    ],
    required: false,
    mdSize: 1,
    score: {
      qid_0007_01: 1,
      qid_0007_02: 1,
      qid_0007_03: 1,
    },
  },
  {
    id: "qid_0034",
    type: "radio_yes_no",
    label: "Are you willing to be filmed during the interview?",
    required: true,
    mdSize: 1,
    score: {
      true: 0,
      false: "disqualify",
    },
  },
];
