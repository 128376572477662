import { Divider, Grid, Typography } from "@mui/material";
import { Box, IconButton, Tooltip } from "@mui/material";
import { Card, CardHeader, CardContent } from "@mui/material";

import { Input as InputIcon, OpenInNew } from "@mui/icons-material";

import { ApplicantDoc } from "../../types/applicants";
import { VisaApplication } from "../../types/visa-application";
import { applicantFullName } from "../../utils/applicant-utils";
import { useNavigate } from "react-router-dom";
import { Sponsor } from "../../types/sponsors";
import { Agent } from "../../types/agents";
import { VirtualPresentation } from "../../types/virtual-presentations";

interface ApplicationDetailsProps {
  application: VisaApplication;
  applicant: ApplicantDoc;
  sponsor?: Sponsor;
  agent?: Agent;
  presentation?: VirtualPresentation;
}

export const ApplicationDetails = ({
  application,
  applicant,
  sponsor,
  agent,
  presentation,
}: ApplicationDetailsProps) => {
  const navigate = useNavigate();

  const person = applicant.applicant;

  const getGenderLabel = (gender: string | undefined) => {
    if (!gender) return "n/a";
    switch (gender) {
      case "M":
        return "Male";
      case "F":
        return "Female";
      case "D":
        return "Diverse";
      default:
        return "n/a";
    }
  };

  return (
    <Card>
      <CardHeader
        title={
          <>
            {`${applicantFullName(person)} (${person.email})`}
            <Tooltip title="Go to Applicant">
              <IconButton
                size="small"
                onClick={(e) => {
                  navigate(`/applicants/${applicant.id}`);
                }}
                sx={{ color: "white", paddingLeft: "1rem" }}
              >
                <InputIcon />
              </IconButton>
            </Tooltip>
          </>
        }
      ></CardHeader>
      <CardContent>
        <Grid container spacing={2} padding={2}>
          <Grid item xs={12} md={4} lg={2}>
            <Typography variant="button" sx={{ fontWeight: "bold" }}>
              Sponsor:
            </Typography>
            <Typography>{sponsor ? sponsor.name : "n/a"}</Typography>
          </Grid>
          <Grid item xs={12} md={8} lg={4}>
            <Typography variant="button" sx={{ fontWeight: "bold" }}>
              Agent:
            </Typography>
            <Typography>{agent ? agent.company : "n/a"}</Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <Typography variant="button" sx={{ fontWeight: "bold" }}>
              Visa Type:
            </Typography>
            <Typography>{application.type.label}</Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <Typography variant="button" sx={{ fontWeight: "bold" }}>
              Alt. starting date:
            </Typography>
            <Typography>
              {application.alternate_start_date ? application.alternate_start_date : "n/a"}
            </Typography>
          </Grid>

          <Grid item xs={12} md={4} lg={2}>
            <Typography variant="button" sx={{ fontWeight: "bold" }}>
              CoS number:
            </Typography>
            <Typography>{application.cos ? application.cos : "n/a"}</Typography>
          </Grid>
          {/* 2nd line -------------------------- */}
          <Grid item xs={6} md={4} lg={2}>
            <Typography variant="button" sx={{ fontWeight: "bold" }}>
              Survey expiry date:
            </Typography>
            <Typography>
              {application.dataForm_expiry_date ? application.dataForm_expiry_date : "n/a"}
            </Typography>
          </Grid>
          <Grid item xs={6} md={4} lg={4}>
            <Typography variant="button" sx={{ fontWeight: "bold" }}>
              Survey completed:
            </Typography>
            <Typography>
              {application.dataForm_completed ? application.dataForm_completed : "-"}
            </Typography>
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <Typography variant="button" sx={{ fontWeight: "bold" }}>
              Privacy policy accepted:
            </Typography>
            <Typography>{application.privacy_policy_accepted ? "yes" : "no"}</Typography>
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <Typography variant="button" sx={{ fontWeight: "bold" }}>
              Sufficient funds:
            </Typography>
            <Typography>{application.sufficient_funds ? "yes" : "no"}</Typography>
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <Typography variant="button" sx={{ fontWeight: "bold" }}>
              GWF number:
            </Typography>
            <Typography>{application.gwf ? application.gwf : "n/a"}</Typography>
          </Grid>
          {/* 3rd line -------------------------- */}

          <Grid item xs={12}>
            <Typography variant="button" sx={{ fontWeight: "bold" }}>
              Visa application link:
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography>
                {application.visa_application_link ? application.visa_application_link : "n/a"}
              </Typography>
              {application.visa_application_link && (
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => window.open(application.visa_application_link, "_blank")}
                  sx={{ ml: 1 }}
                >
                  <OpenInNew fontSize="small" />
                </IconButton>
              )}
            </Box>
          </Grid>
          {/* 4rd line -------------------------- */}
          <Grid item xs={12} lg={3}>
            <Typography variant="button" sx={{ fontWeight: "bold" }}>
              Virtual Presentation:
            </Typography>
            <Typography>{presentation ? presentation.name : "Not Assigned"}</Typography>
          </Grid>
          {application.virtualPresentation?.virtualPresentationId && (
            <>
              <Grid item xs={6} lg={2}>
                <Typography variant="button" sx={{ fontWeight: "bold" }}>
                  Presentation expiry date:
                </Typography>
                <Typography>
                  {application.virtualPresentation.expiry_date
                    ? application.virtualPresentation.expiry_date
                    : "n/a"}
                </Typography>
              </Grid>
              <Grid item xs={6} lg={3}>
                <Typography variant="button" sx={{ fontWeight: "bold" }}>
                  Presentation completed:
                </Typography>
                <Typography>
                  {application.virtualPresentation.completedDate
                    ? application.virtualPresentation.completedDate
                    : "-"}
                </Typography>
              </Grid>
            </>
          )}
          {!application.virtualPresentation?.virtualPresentationId && (
            <Grid item xs={6} lg={9}></Grid>
          )}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <Typography variant="button" sx={{ fontWeight: "bold" }}>
              Name:
            </Typography>
            <Typography>{person.name || "n/a"}</Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <Typography variant="button" sx={{ fontWeight: "bold" }}>
              Surname:
            </Typography>
            <Typography>{person.surname || "n/a"}</Typography>
          </Grid>

          <Grid item xs={12} md={4} lg={2}>
            <Typography variant="button" sx={{ fontWeight: "bold" }}>
              Nationality:
            </Typography>
            <Typography>{person.nationality?.label || "n/a"}</Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <Typography variant="button" sx={{ fontWeight: "bold" }}>
              Date of birth:
            </Typography>
            <Typography>{person.birth_date || "n/a"}</Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <Typography variant="button" sx={{ fontWeight: "bold" }}>
              Place of birth:
            </Typography>
            <Typography>{person.birth_place || "n/a"}</Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <Typography variant="button" sx={{ fontWeight: "bold" }}>
              Country of birth:
            </Typography>
            <Typography>{person.birth_country?.label || "n/a"}</Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <Typography variant="button" sx={{ fontWeight: "bold" }}>
              Gender:
            </Typography>
            <Typography>{getGenderLabel(person.gender)}</Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <Typography variant="button" sx={{ fontWeight: "bold" }}>
              Passport number:
            </Typography>
            <Typography>{person.passport ? person.passport.id : "n/a"}</Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <Typography variant="button" sx={{ fontWeight: "bold" }}>
              Passport issue date:
            </Typography>
            <Typography>{person.passport ? person.passport.issue_date : "n/a"}</Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <Typography variant="button" sx={{ fontWeight: "bold" }}>
              Passport expiry date:
            </Typography>
            <Typography>{person.passport ? person.passport.expiry_date : "n/a"}</Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <Typography variant="button" sx={{ fontWeight: "bold" }}>
              Place of issue:
            </Typography>
            <Typography>{person.passport ? person.passport.issuing_place : "n/a"}</Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <Typography variant="button" sx={{ fontWeight: "bold" }}>
              Country of issue:
            </Typography>
            <Typography>
              {person.passport?.issuing_country ? person.passport.issuing_country?.label : "n/a"}
            </Typography>
          </Grid>
          <Grid item xs={12} md={8} lg={4}>
            <Typography variant="button" sx={{ fontWeight: "bold" }}>
              Permanent address:
            </Typography>
            <Typography>{person.residency_street || "n/a"}</Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <Typography variant="button" sx={{ fontWeight: "bold" }}>
              City:
            </Typography>
            <Typography>{person.residency_city || "n/a"}</Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <Typography variant="button" sx={{ fontWeight: "bold" }}>
              Country:
            </Typography>
            <Typography>
              {person.residency_country ? person.residency_country?.label : "n/a"}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <Typography variant="button" sx={{ fontWeight: "bold" }}>
              Region:
            </Typography>
            <Typography>{person.residency_province || "n/a"}</Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <Typography variant="button" sx={{ fontWeight: "bold" }}>
              Postcode:
            </Typography>
            <Typography>{person.residency_zip || "n/a"}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
