/**
 * Represents the possible types of questions that can be used in a survey.
 * - text: Single line text input
 * - number: Numeric input
 * - date: Date picker
 * - boolean: True/false input
 * - multiline: Multi-line text input
 * - radio: Single selection from multiple options
 * - radio_yes_no: Specialized radio for yes/no questions
 * - select: Dropdown single selection
 * - select_multiple: Dropdown multiple selection
 * - toggle: On/off switch
 * - placeholder: Non-interactive text display
 * - country: Country selector
 */
export type QuestionType =
  | "text"
  | "number"
  | "date"
  | "boolean"
  | "multiline"
  | "radio"
  | "radio_yes_no"
  | "select"
  | "select_multiple"
  | "toggle"
  | "placeholder"
  | "country";

/**
 * Represents a choice option for questions that have multiple options
 * (e.g., radio, select, select_multiple)
 * a score of -1 means that the whole survey is valued as 0%
 */
export interface QuestionChoice {
  /** Unique identifier for the choice */
  id: string;
  /** Display text for the choice */
  label: string;
  /** Value to be stored when this choice is selected */
  value: string;
  /** Whether the question is a disqualifying question */
  disqualifyIfValue?: string;
}

/**
 * Represents a user's answer to a question
 */
export type QuestionAnswer = Record<string, string | Record<string, string>>;

export type DisqualifyValue = "disqualify";
export const DISQUALIFY_NUM_VALUE = -9999999;
export type SpecialAnswerValue = "_any_answer_" | "_no_answer_" | "_all_answers_";

/**
 * Represents a survey question with its properties and configuration
 * a score of -1 means that the whole survey is valued as 0%
 */
export interface Question {
  /** Unique identifier for the question */
  id: string;
  /** The type of question, determining how it will be displayed and answered */
  type: QuestionType;
  /** The question text displayed to the user */
  label: string;
  /** Whether an answer is required for this question */
  required: boolean;
  /** Custom error message to display when validation fails */
  errorMessage?: string;
  /** Available choices for questions with multiple options */
  options?: QuestionChoice[];
  /** Material Design grid size for layout purposes */
  mdSize?: number;
  /** Optional numerical score associated with the question */
  score?: Record<string | SpecialAnswerValue, number | DisqualifyValue>;
}

export type QuestionsDict = Record<string, Question>;
export type QuestionAnswersDict = Record<string, string | Record<string, string>>;
