import { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";

import { gwfMainBlue } from "../../theme";
import checked_background from "../../ressources/checked_background2.png";

import {
  EMPTY_VIRTUAL_PRESENTATION,
  VirtualPresentationDoc,
  VirtualPresentationSlide,
} from "../../types/virtual-presentations";
import { DraggableList } from "../../components/vp-thumbnail-list";
import { AddToQueue, Delete } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import _, { isEqual } from "lodash";
import ImageResizor from "image-resizor";
import { AuthContext } from "../../components/auth-provider";
import { SponsorsDict } from "../../types/sponsors";
import { loadSponsors } from "../../data-functions/system-data_api";

interface VirtualPresentationDialogProps {
  presentation: VirtualPresentationDoc | null;
  open: boolean;
  onClose: () => void;
  onSave: (presentation: VirtualPresentationDoc) => void;
}

const EMPTY_VP_DOC = { id: "", presentation: { ...EMPTY_VIRTUAL_PRESENTATION } };

const VirtualPresentationDialog = ({
  presentation: intialPresentation,
  open,
  onClose,
  onSave,
}: VirtualPresentationDialogProps) => {
  const [sponsors, setSponsors] = useState<SponsorsDict>({});
  const [presentation, setPresentation] = useState<VirtualPresentationDoc>({ ...EMPTY_VP_DOC });
  const [thumbnailListId, setThumbnailListId] = useState<string>("slide-list");
  const [selectedSlide, setSelectedSlide] = useState<VirtualPresentationSlide | null>(null);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [errors, setErrors] = useState<{ name: string; sponsor: string }>({
    name: "",
    sponsor: "",
  });
  const [slideErrors, setSlideErrors] = useState<{
    description: string;
    image: string;
  }>({
    description: "",
    image: "",
  });

  const { currentUser } = useContext(AuthContext)!;

  const fetchSponsors = async () => {
    const sponsorsDict: SponsorsDict = await loadSponsors(currentUser?.appUser);
    setSponsors(sponsorsDict);
  };

  useEffect(() => {
    fetchSponsors();
  }, []);

  useEffect(() => {
    const newPresentation = _.cloneDeep(intialPresentation || EMPTY_VP_DOC);
    newPresentation.presentation.sponsorId = currentUser?.appUser?.sponsorId || "";
    setThumbnailListId("slide-list");
    setPresentation(newPresentation);

    // if (intialPresentation) setPresentation({ ...intialPresentation });
    // else setPresentation(null);
  }, [intialPresentation]);

  useEffect(() => {
    if (!open) {
      setErrors({ name: "", sponsor: "" });
      setSlideErrors({ description: "", image: "" });
      setSelectedSlide(null);
      const newPresentation = { ...EMPTY_VP_DOC };
      newPresentation.presentation.sponsorId = currentUser?.appUser?.sponsorId || "";
      setPresentation(newPresentation);
    }
  }, [open]);

  /*  dialog functions */
  const validateSlide = () => {
    let errors = { description: "", image: "" };
    let isValid = true;

    if (!selectedSlide) {
      return false;
    }

    if (!selectedSlide.description) {
      errors.description = "Description is required";
      isValid = false;
    }

    if (!selectedSlide.url && !selectedSlide.localImage) {
      errors.image = "Image is required";
      isValid = false;
    }

    setSlideErrors(errors);
    return isValid;
  };

  const validatePresentation = () => {
    let errors = { name: "", sponsor: "" };
    let isValid = true;

    if (!presentation) {
      console.log("presentation is null");
      return false;
    }

    if (!presentation.presentation.name) {
      errors.name = "Name is required";
      console.log("errors.name: ", errors.name);
      isValid = false;
    }

    if (!presentation.presentation.sponsorId) {
      errors.sponsor = "Sponsor is required";
      console.log("presentation: ", presentation);
      console.log("errors.sponsor: ", errors.sponsor);
      isValid = false;
    }

    console.log("isValid: ", isValid);
    setErrors(errors);
    return isValid;
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //    if (event.target.name === "expiry_date") {
    console.log("input changed: Presentation: ", presentation);

    if (presentation) {
      const newPresentation: VirtualPresentationDoc = {
        ...presentation,
        presentation: {
          ...presentation.presentation,
          [event.target.name]: event.target.value,
        },
      };
      console.log("input changed: newPresentation: ", newPresentation);
      setPresentation(newPresentation);
    }
  };

  const handleInputSlideChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedSlide && event.target.name) {
      const key = event.target.name as keyof VirtualPresentationSlide;
      const newSlide = { ...selectedSlide };
      newSlide[key] = event.target.value;
      setSelectedSlide(newSlide);
    }
  };

  const handleAddSlide = async () => {
    if (presentation) {
      const newSlide: VirtualPresentationSlide = {
        id: uuidv4(),
        url: "",
        storageRef: "",
        description: "Slide " + (presentation.presentation.slides.length + 1),
      };
      const newPresentation: VirtualPresentationDoc = {
        ...presentation,
      };
      newPresentation.presentation.slides.push(newSlide);
      setPresentation(newPresentation);
      setSelectedSlide(newSlide);
      console.log("handleAddSlide: newPresentation: ", newPresentation);
    }
  };

  const handleRemoveSelectedSlide = async () => {
    if (presentation && selectedSlide) {
      const conf = window.confirm("Are you sure you want to remove the selected slide?");
      if (!conf) {
        return;
      }

      const newPresentation: VirtualPresentationDoc = {
        ...presentation,
      };
      newPresentation.presentation.slides = newPresentation.presentation.slides.filter(
        (slide) => slide.id !== selectedSlide.id
      );
      setPresentation(newPresentation);
      setSelectedSlide(null);
    }
  };

  const handleSaveSlide = async () => {
    if (!validateSlide()) {
      return;
    }

    if (presentation && selectedSlide) {
      const newPresentation: VirtualPresentationDoc = {
        ...presentation,
      };

      const slideIndex = newPresentation.presentation.slides.findIndex(
        (slide) => slide.id === selectedSlide.id
      );

      if (slideIndex >= 0) {
        newPresentation.presentation.slides[slideIndex] = { ...selectedSlide };
      }

      setPresentation(newPresentation);
      console.log("handleSaveSlide: newPresentation: ", newPresentation);
    }
  };

  const handleCancelSlide = async () => {
    setSelectedSlide(null);
  };

  const handleSavePresentation = async () => {
    if (!validatePresentation()) {
      console.log("validatePresentation failed");
      console.log("errors: ", errors);
      return;
    }
    onSave(presentation);
  };

  const handleCancelPresentation = async () => {
    const conf = window.confirm("Are you sure you want to cancel the changes?");
    if (conf) {
      onClose();
    }
  };

  /*--------- Image Upload ----------- */
  const handleImageClick = (event: React.MouseEvent<HTMLImageElement>) => {
    console.log("open image select");
    console.log(fileInputRef.current);
    fileInputRef.current?.click();
    //setIsDialogOpen(true);
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file: File | null = event.target.files && event.target.files[0];
    console.log("file: ", file);

    if (file && selectedSlide) {
      const reader = new FileReader();

      reader.onload = async function (e: ProgressEvent<FileReader>) {
        if (e.target && e.target.result) {
          const newSlide = { ...selectedSlide };
          newSlide.localImage = e.target.result as string;
          newSlide.localExtension = file.name.split(".").pop() || "";

          let thumbnail = await new ImageResizor(file, {
            maxHeight: 150,
            maxWidth: 150,
            quality: 0.9,
          }).init();
          if (thumbnail) {
            newSlide.localThumbnail = thumbnail.toDataURL();
          } else {
            newSlide.localThumbnail = "";
          }

          setSelectedSlide(newSlide);
        }
      };

      reader.readAsDataURL(file);
    }
    if (event.target) {
      event.target.value = "";
    }
  };

  // // a little function to help us with reordering the result
  function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
    console.log("reordering......");
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }

  const onDragEnd = (event: any) => {
    // dropped outside the list
    const { source } = event.operation;

    if (event.canceled || !presentation?.presentation.slides) {
      return;
    }

    const items = reorder(
      presentation?.presentation.slides || [],
      source.sortable.initialIndex,
      source.sortable.previousIndex
    );

    const newPresentation: VirtualPresentationDoc = {
      ...presentation,
    };
    newPresentation.presentation.slides = items;

    setPresentation(newPresentation);
  };

  const onSelectItem = (item: VirtualPresentationSlide) => {
    if (selectedSlide && selectedSlide.id !== item.id) {
      const originalSlide = presentation?.presentation.slides.find(
        (slide) => slide.id === selectedSlide.id
      );
      if (!isEqual(selectedSlide, originalSlide)) {
        const conf = window.confirm(
          "The selected slide has been changed! Press OK if you want to discard the changes."
        );
        if (!conf) {
          return;
        }
      }
    }
    const newSlide = presentation?.presentation.slides.find((slide) => slide.id === item.id);
    if (newSlide) {
      setSelectedSlide({ ...newSlide });
    } else {
      setSelectedSlide(null);
    }
  };

  return (
    <Dialog
      disableEscapeKeyDown={true}
      fullScreen
      maxWidth="lg"
      fullWidth
      open={open}
      onClose={onClose}
    >
      <DialogTitle bgcolor={gwfMainBlue} sx={{ color: "white" }}>
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleCancelPresentation}
          aria-label="close"
          sx={{ marginRight: "1rem" }}
        >
          <CloseIcon />
        </IconButton>
        Virtual Presentation Settings
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            margin: "1.5rem",
            marginTop: "2rem",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                error={!!errors.name}
                helperText={errors.name}
                autoFocus
                margin="dense"
                name="name"
                label="Name"
                type="text"
                fullWidth
                value={(presentation?.presentation && presentation.presentation.name) || ""}
                onChange={handleInputChange}
              />
            </Grid>
            {!currentUser?.appUser?.sponsorId && (
              <Grid item xs={12} md={6}>
                <TextField
                  name={"sponsorId"}
                  label={"Sponsor"}
                  select
                  error={!!errors.sponsor}
                  helperText={errors.sponsor}
                  onChange={handleInputChange}
                  value={(presentation?.presentation && presentation.presentation.sponsorId) || ""}
                  required
                  fullWidth
                  margin={"dense"}
                >
                  <MenuItem value="" key="no_sponsor">
                    <Typography fontStyle={"italic"}>no sponsor selected</Typography>
                  </MenuItem>
                  {Object.entries(sponsors).map(([sponsorId, sponsor]) => {
                    return (
                      <MenuItem value={sponsorId} key={sponsorId}>
                        {sponsor.name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
            )}
          </Grid>

          <Box sx={{ display: "flex", flexDirection: "row", marginTop: "1.5rem" }}>
            {/* ---------- Slides ---------- */}
            <Box
              sx={{
                flexGrow: 1,
                flexBasis: "0",
                maxWidth: `400px`,

                marginRight: "8px",
              }}
            >
              <Card sx={{ height: "100%" }}>
                <CardHeader title={"Slides"} />
                <Box
                  id="thumbnail-list"
                  sx={{
                    margin: "1rem",
                    overflowY: "auto", // Enable vertical scrolling
                    flexGrow: 1,
                    height: "600px",
                    //                      maxHeight: "calc(100% - 85px)",
                  }}
                >
                  <DraggableList
                    droppableId={thumbnailListId}
                    items={presentation?.presentation.slides || []}
                    selectedItem={selectedSlide?.id || null}
                    onDragEnd={onDragEnd}
                    onSelectItem={onSelectItem}
                  />
                </Box>
                <CardActions sx={{ padding: 2, borderTop: "1px solid lightgrey" }}>
                  <Box flexGrow={1}></Box>
                  <ButtonGroup variant="text" aria-label="Basic button group">
                    <Tooltip title="Add Slide">
                      <Button sx={{ float: "right" }} onClick={handleAddSlide}>
                        <AddToQueue />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Remove Slide">
                      <Button sx={{ float: "right" }} onClick={handleRemoveSelectedSlide}>
                        <Delete />
                      </Button>
                    </Tooltip>
                  </ButtonGroup>
                </CardActions>
              </Card>
            </Box>

            {/* ---------- Slide Preview ---------- */}
            <Box sx={{ flexGrow: 1, flexBasis: "0", width: "100%" }}>
              <Card>
                <CardHeader title={"Slide Preview"} />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "600px",
                    padding: "16px",
                  }}
                >
                  {!selectedSlide && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      Select a slide to view
                    </div>
                  )}
                  {selectedSlide && (
                    <>
                      <Box padding={"16px"}>
                        <TextField
                          error={!!slideErrors.description}
                          helperText={slideErrors.description}
                          autoFocus
                          margin="dense"
                          name="description"
                          label="Slide Description"
                          type="text"
                          sx={{ width: "550px" }}
                          value={selectedSlide && selectedSlide.description}
                          onChange={handleInputSlideChange}
                        />
                      </Box>
                      <Box padding={"0 16px"}>
                        <Divider textAlign="left">Slide image</Divider>
                      </Box>
                      <Box display="flex" justifyContent="center" padding={"8px 16px"}>
                        <Box
                          position="relative"
                          display="inline-block"
                          onClick={handleImageClick}
                          sx={{
                            backgroundImage: `url(${checked_background})`,
                            backgroundRepeat: "repeat",
                            height: "450px",
                            border: "1px solid lightgrey",
                            cursor: "pointer",
                          }}
                        >
                          {(selectedSlide.localImage || selectedSlide.url) && (
                            <img
                              src={selectedSlide.localImage || selectedSlide.url}
                              //onClick={handleImageClick}
                              alt="Sponsor"
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100%", // Prevent y-axis overflow
                                objectFit: "contain",
                                cursor: "pointer",
                              }}
                            />
                          )}
                          {!selectedSlide.localImage && !selectedSlide.url && (
                            <div
                              style={{
                                width: "600px",
                                maxWidth: "100%",
                                height: "100%",
                                cursor: "pointer",
                              }}
                            ></div>
                          )}
                          <Box
                            position="absolute"
                            bottom={0}
                            width="100%"
                            height="50px"
                            bgcolor="rgba(0, 0, 0, 0.5)"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            gap="10px"
                            sx={{ cursor: "pointer" }}
                            //onClick={handleImageClick}
                          >
                            <EditIcon sx={{ color: "white" }} />
                            <Typography variant="body2" color="white">
                              Click to change the slide image
                            </Typography>
                          </Box>
                          {slideErrors.image && (
                            <Typography
                              variant="body2"
                              color="error"
                              sx={{
                                position: "absolute",
                                top: "100%",
                                mt: 1,
                                width: "100%",
                                textAlign: "left",
                                margin: "3px 0 0 14px",
                              }}
                            >
                              {slideErrors.image}
                            </Typography>
                          )}
                        </Box>

                        <input
                          type="file"
                          style={{ display: "none" }}
                          onChange={handleImageChange}
                          ref={fileInputRef}
                        />
                      </Box>
                    </>
                  )}
                </Box>
                {selectedSlide && (
                  <CardActions sx={{ padding: 2 }}>
                    <Box flexGrow={1}></Box>
                    <Button variant="outlined" onClick={handleCancelSlide} sx={{ mr: 1 }}>
                      Cancel
                    </Button>
                    <Button variant="contained" onClick={handleSaveSlide} sx={{ mr: 2 }}>
                      Save Slide
                    </Button>
                  </CardActions>
                )}
              </Card>
            </Box>
          </Box>
        </Box>
        <DialogActions sx={{ borderTop: "1px solid lightgrey", paddingTop: "16px" }}>
          <Button variant="outlined" onClick={handleCancelPresentation} sx={{ mr: 1 }}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSavePresentation} sx={{ mr: 2 }}>
            Save Presentation
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default VirtualPresentationDialog;
