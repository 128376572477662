import { Child, EMPTY_CHILD } from "../../types/persons";
import { EMPTY_TRAVEL, TravelDetails } from "../../types/travels";
import { findCountry, formatDateToddMMYYYY, parseCSVDate } from "../utils";

export const APPLICATION_MAPPING: Record<string, string> = {
  employer: "farm",
  order: "order no",
  order_start_date: "order start date",
};

export const CSV_APPLICANT_MAPPING: Record<string, string> = {
  name: "name",
  surname: "surname",
  "maiden name": "maiden_name",
  phone: "phone",
  email: "email",
  gender: "gender",
  "passport no": "passport.id",
  "issuing authority": "passport.issuing_place",
  "marital status": "marital_status",
  "UK insurance number": "UK_insurance_number",
  "relatives in UK - name": "relatives_UK.name",
  "relatives in UK - surname": "relatives_UK.surname",
  "relatives in UK - relation": "relatives_UK.relation",
  "relatives in UK - type of stay": "relatives_UK.typeOfStay",
  "place of birth": "birth_place",
  "residential address": "residency_street",
  city: "residency_city",
  region: "residency_province",
  "post code": "residency_zip",
  "residency ownership": "residency_ownership",

  "immergency contact - full name": "immergency_contact.name",
  "immergency contact - phone": "immergency_contact.phone",
  "immergency contact - relation": "immergency_contact.relation",

  "mother - name": "mother.name",
  "mother - surname": "mother.surname",
  "mother - place of birth": "mother.birthPlace",

  "father - name": "father.name",
  "father - surname": "father.surname",
  "father - place of birth": "father.birthPlace",

  "partner - name": "partner.name",
  "partner - surname": "partner.surname",

  "deported abroad - details": "deported_abroad_details",

  "application additional information": "application_additional_information",

  "work experience": "work_experience",
  "english level": "english_level",
  "health issues - details": "has_health_issues",
  "preferred language": "preferredLanguage",
};

/*

travel Uk 1 - start date
travel Uk 1 - end date
travel Uk 1 - reason

travel abroad 1 - country
travel abroad 1 - start date
travel abroad 1 - end date
travel abroad 1 - reason

*/

export const CSV_MAPPING_COUNTRY_PROPERTIES: Record<string, string> = {
  nationality: "nationality",
  "additional nationality": "additional_nationality",
  "issuing country": "passport.issuing_country",
  "country of birth": "birth_country",
  "country of residence": "residency_country",
  "father - country of birth": "father.birthCountry",
  "father - nationality": "father.nationality",
  "mother - country of birth": "mother.birthCountry",
  "mother - nationality": "mother.nationality",
  "relatives in UK - nationality": "relatives_UK.nationality",
  "partner - nationality": "partner.nationality",
};

export const CSV_MAPPING_DATE_PROPERTIES: Record<string, string> = {
  "date of birth": "birth_date",
  "date of issue": "passport.issue_date",
  "date of expiry": "passport.expiry_date",
  "father - date of birth": "father.birthday",
  "mother - date of birth": "mother.birthday",
  "residency ownership since": "residency_date_since",
  "partner - date of birth": "partner.birthday",
};

export const CSV_MAPPING_BOOLEAN_PROPERTIES: Record<string, string> = {
  "UK insurance": "UK_insurance",
  "visited UK doctor": "visited_UK_doctor",
  "has biometric passport": "biometric_pass",
  "has children": "has_children",
  "has relatives in UK": "has_relatives_in_UK",
  "granted UK visa": "granted_UK_visa",
  "has travelled to UK": "has_travelled_to_UK",
  "has worked illegally": "has_worked_illegally",
  "required to leave UK": "required_to_leave_UK",
  "refused UK visa": "refused_visa",
  "refused UK entry": "refused_entry",
  "has applied to remain in UK": "has_applied_for_visa",
  "has travelled abroad": "has_travelled_abroad",
  "refused visa abroad": "refused_visa_abroad",
  "deported abroad": "deported_abroad",
  "war crimes": "war_crimes",
  "has convictions": "convictions",
  "terrorist org member": "terrorist_org_member",
  "not good character": "not_good_character",
  "children travelling": "children_travelling",
  "has driving license": "has_driving_license",
  "parents unknown": "parents_unknown",
  "partner - lives home": "partner.livesHome",
};

export const parseCountry = (country: string) => {
  return {
    code: country,
    name: "",
  };
};

export const parseChildrenFromCSV = (prefix: string, row: Record<string, string>) => {
  const children: Child[] = [];

  for (let i = 1; i <= 3; i++) {
    const child = { ...EMPTY_CHILD };
    const name = row[`${prefix} ${i} - name`];
    if (name) {
      child.name = name.trim();
      child.surname = row[`${prefix} ${i} - surname`].trim();
      child.birthday = formatDateToddMMYYYY(
        parseCSVDate(row[`${prefix} ${i} - date of birth`]),
        "-"
      );
      child.livesHome = row[`${prefix} ${i} - lives home`].trim().toLocaleLowerCase() === "yes";
      children.push(child);
    } else {
      break;
    }
  }

  return children;
};

export const parseTravelToUK = (row: Record<string, string>) => {
  const travelToUK: TravelDetails[] = [];
  for (let i = 1; i <= 3; i++) {
    const travel = { ...EMPTY_TRAVEL };
    if (row[`travel Uk ${i} - start date`]) {
      travel.from = formatDateToddMMYYYY(parseCSVDate(row[`travel Uk ${i} - start date`]), "-");
      travel.to = formatDateToddMMYYYY(parseCSVDate(row[`travel Uk ${i} - end date`]), "-");
      travel.reason = row[`travel Uk ${i} - reason`];
      travelToUK.push(travel);
    }
  }
  return travelToUK;
};

export const parseTravelAbroad = (row: Record<string, string>) => {
  const travelAbroad: TravelDetails[] = [];
  for (let i = 1; i <= 3; i++) {
    const travel = { ...EMPTY_TRAVEL };
    const country = findCountry(row[`travel abroad ${i} - country`]);
    if (country) {
      travel.destination = country;
      travel.from = formatDateToddMMYYYY(parseCSVDate(row[`travel abroad ${i} - start date`]), "-");
      travel.to = formatDateToddMMYYYY(parseCSVDate(row[`travel abroad ${i} - end date`]), "-");
      travel.reason = row[`travel abroad ${i} - reason`];
      travelAbroad.push(travel);
    }
  }
  return travelAbroad;
};
