const bg = {
  // ------------ General variables ------------
  radio_yes: "Да",
  radio_no: "Не",
  radio_undefined: "Моля, изберете поне една опция!",
  travel_reason_work: "Работа",
  travel_reason_tourism: "Туризъм",
  travel_reason_study: "Обучение",
  travel_reason_transit: "Транзит",

  button_next: "Следваща",
  button_back: "Предишна",
  button_finish: "Завърши",
  button_register: "Регистрация",
  buttone_start: "Започни",
  button_continue: "Продължи",

  // ------------ Relatives relationship ------------
  relative_reason_none: "изеберете една опция",
  relative_reason_adoped_child: "Адоптирано дете",
  relative_reason_brother: "Брат",
  relative_reason_brother_in_law: "Девер",
  relative_reason_child: "Дете",
  relative_reason_child_spouce: "Снаха / Зет",
  relative_reason_daughter: "Дъщеря",
  relative_reason_daughter_in_law: "Снаха",
  relative_reason_father: "Баща",
  relative_reason_father_in_law: "Свекър",
  relative_reason_grandchild: "Внук",
  relative_reason_grandparent: "Баба / Дядо",
  relative_reason_husband: "Съпруг",
  relative_reason_mother: "Майка",
  relative_reason_mother_in_law: "Свекърва",
  relative_reason_parent: "Родител",
  relative_reason_sister: "Сестра",
  relative_reason_sister_in_law: "Балдза",
  relative_reason_son: "Син",
  relative_reason_son_in_law: "Зет",
  relative_reason_step_child: "Доведено дете",
  relative_reason_step_parents: "Доведени родители",
  relative_reason_step_sibling: "Доведен/а брат / сестра",
  relative_reason_unmarried_partner: "Неженен партньор",
  relative_reason_wife: "Съпруга",

  // ------------ Relatives Type of Stay ------------
  тypeofstay_none: "изберете една опция",
  typeofstay_temporary: "Временна виза",
  typeofstay_permanently: "С постоянно пребиваване",
  typeofstay_other: "Няма виза и не е с постояяно пребиваване",
  typeofstay_cannotcontact: "Не мога да се свържя с тях",

  // ------------ Steps ------------
  step_0: "Начало",
  step_1: "Обща Информация",
  step_2: "Фамилия",
  step_3: "Пътувания",
  step_4: "Допълнителна Информация",
  step_5: "Край",
  step_text_finished: "Всички данни са попълнени - готови сте!",

  // ------------ Dividers ------------
  divider_general_information: "Обща информация",
  divider_birth_information: "Рожденна информация",
  divider_passport_information: "Паспортна информация",
  divider_residency_information: "Домашен адрес",
  divider_phone_information: "Телефон",
  //
  divider_marital_status: "Семейно положение",
  divider_children: "Деца",
  divider_partner_details: "Данни за партньора",
  divider_parents: "Данни за родителите",
  divider_mother_details: "Данни за майката",
  divider_father_details: "Данни за бащата",
  divider_immergency_contact: "Лице за спешен контакт",
  divider_additional_information: "Допълнителни данни",
  divider_relatives_UK: "Роднини във Великобритания",
  //
  divider_uk_status: "Информация за Великобритания",
  divider_travel_UK: "Пътувания до Великобритания",
  divider_travel_abroad: "Задгранични пътувания",
  divider_final_provisions: "Финални разпоредби",
  divider_data_privacy: "Защита на личните данни",
  divider_sufficient_funds: "Достатъчно средства",

  // ------------ Errors ------------
  email_error: "Моля, въведете валиден имейл адрес",
  name_error: "Името е задължително и трябва да съдържа само букви!",
  surname_error: "Фамилното име е задължително и трябва да съдържа само букви!",
  maiden_name_error: "Майчиното име трябва да съдържа само букви!",
  gender_error: "Полът е задължителен!",
  birth_date_error: "Изисква се дата на раждане, която трябва да е поне 17 години назад.",
  birth_place_error: "Мястото на раждане е задължително!",
  pass_id_error: "Номера на паспорт е задължителен и трябва да съдържа само букви и цифри!",
  pass_issuing_place_error: "Мястото на издаване е задължително и трябва да съдържа само букви!",
  pass_issue_date_error: "Датата на издаване на паспорта е задължителна, и трябва да е в миналото.",
  pass_expiry_date_error:
    "Датата на изтичане на паспорта е задължителна, и трябва да е в бъдещето!",
  biometric_pass_error: "Моля, изберете какъв тип ви е паспорта!",
  residency_street_error: "Домашният адрес е задължителен!",
  residency_province_error: "Областта трябва да съдържа само букви!",
  residency_city_error: "Домашният адрес е задължителен и трябва да съдържа само букви!",
  residency_zip_error: "Пощенският код е задължителен!",
  residency_date_since_error: "Датата на нанасяне е задължителна и трябва да е в миналото!",
  nationality_error: "Данните за гражданство за задължителни!",
  additional_nationality_error: "",
  birth_country_error: "Държавата на раждане е задължителна!",
  pass_issuing_country_error: "Държавата на издаване е задължителна!",
  residency_country_error: "Държавата на живеене е задължителна!",
  residency_ownership_error: "Моля, изберете подходящият статус за това жилище!",
  residency_ownership_other_error: "Моля, посочете отношението си към жилището на латиница!",
  phone_error: "Телефонният номер е задължителен и трябва да съдържа само цифри!",

  // ------------ Page Errors ------------
  invalid_questionnaire_id: "Невалиден номер на анкета!",
  invalid_questionnaire_expired:
    "Времето за попълване на анкетата изтече! За повече информация, моля свържете се с вашият агент или спонсор!",
  invalid_presentation_id: "Невалиден номер на презентация!",
  invalid_presentation_expired:
    "Вашата презентация изтече! За повече информация, моля свържете се с вашият агент или спонсор!",
  invalid_presentation_completed: "Вие вече сте изгледали тази презентация!",

  // ------------- Welcome Page -------------
  welcome_title: "Работа във Великобритания с виза",
  welcome_hint1:
    "Моля, посочете отговорите на въпросите на ЛАТИНИЦА. Въпросите, означени със звезда (*) са задължителни.",
  welcome_hint2:
    "Нашата услуга е БЕЗПЛАТНА! Моля, не плащайте на никого! Таксата за виза е 298 британски лири (приблизително 363 EUR/ 386 USD) и такса TLS /за определени държави/ се заплаща с дебитна или кредитна карта към Британската визова служба UKVI. Ние нямаме агенти! Кандидатства се директно!",
  welcome_verify_email:
    "Моля, въведете вашия имейл адрес, за да продължите с попълването на данните!",

  // ------------- General Information Page -------------
  name: "Име",
  surname: "Фамилия",
  maiden_name: "Майчино име (ако имета)",
  gender: "Пол",
  sex_male: "Мъж",
  sex_female: "Жена",
  sex_diverse: "Друг",
  birth_date: "Дата на раждане",
  birth_place: "Място на раждане (Град)",
  pass_id: "Номер на паспорт",
  pass_issuing_place: "Град на издаване",
  pass_issue_date: "Дата на издаване",
  pass_expiry_date: "Дата на изтичане",
  full_address: "Домашен адрес (улица, №, Град)",
  residency_street: "Домашен адрес (улица, №, етаж, апартамент)",
  residency_city: "Домашен адрес (Град/Село)",
  residency_province: "Област / Регион",
  residency_zip: "Пощенски код",
  residency_date_since: "От кога живеете на този адрес?",
  pass_biometric: "Имате ли паспосрт с биометрични данни?",
  nationality: "Националност",
  additional_nationality: "Имате ли друга националност?",
  birth_country: "Държава на раждане",
  pass_issuing_country: "Държава издател",
  residency_country: "Домашен адрес (Държава)",
  ownership_own: "Принадлжи ми",
  ownership_rent: "Под наем е",
  ownership_other: "Друго",
  residency_ownership: "Ваше ли е жилището?",
  residency_ownership_other: "Моля, оточнете",
  phone: "Номер на телефона",

  // ------------- Family Information Page -------------
  marital_status: "Семейно положение",
  marital_status_error: "Семейноto положение е задължително!",
  marital_status_single: "Неженен (неомъжена)",
  marital_status_married: "Женен (омъжена)",
  marital_status_divorsed: "Разведен (разведена)",
  has_children: "Имате ли деца?",
  has_children_error: "Необходими са данните на поне едно дете!",
  child_0_name: "Името на първото дете",
  child_1_name: "Името на второто дете",
  child_2_name: "Името на третото дете",
  child_3_name: "Името на четвъртото дете",
  child_0_surname: "Фамилното име на първото дете",
  child_1_surname: "Фамилното име на второто дете",
  child_2_surname: "Фамилното име на третото дете",
  child_3_surname: "Фамилното име на четвъртото дете",
  child_0_birthday: "Дата на раждане на първото дете",
  child_1_birthday: "Дата на раждане на второто дете",
  child_2_birthday: "Дата на раждане на третото дете",
  child_3_birthday: "Дата на раждане на четвъртото дете",
  child_0_lives_home: "Живее ли при вас",
  child_1_lives_home: "Живее ли при вас",
  child_2_lives_home: "Живее ли при вас",
  child_3_lives_home: "Живее ли при вас",
  //
  child_0_name_error: "Името на детето е задължително и трябва да съдържа само букви!",
  child_1_name_error: "Името на детето е задължително и трябва да съдържа само букви!",
  child_2_name_error: "Името на детето е задължително и трябва да съдържа само букви!",
  child_3_name_error: "Името на детето е задължително и трябва да съдържа само букви!",
  child_0_surname_error: "Името на детето е задължително и трябва да съдържа само букви!",
  child_1_surname_error: "Името на детето е задължително и трябва да съдържа само букви!",
  child_2_surname_error: "Името на детето е задължително и трябва да съдържа само букви!",
  child_3_surname_error: "Името на детето е задължително и трябва да съдържа само букви!",
  child_0_birthday_error: "Необходимо е да посочите датата на раждане на детето!",
  child_1_birthday_error: "Необходимо е да посочите датата на раждане на детето!",
  child_2_birthday_error: "Необходимо е да посочите датата на раждане на детето!",
  child_3_birthday_error: "Необходимо е да посочите датата на раждане на детето!",
  //
  partner_name: "Име",
  partner_name_error: "Името е задължително и трябва да съдържа само букви!",
  partner_surname: "Фамилия",
  partner_surname_error: "Фамилното име е задължително и трябва да съдържа само букви!",
  partner_nationality: "Националност",
  partner_nationality_error: "Данните за гражданство за задължителни!",
  partner_birthday: "Дата на раждане",
  partner_birthday_error: "Изисква се дата на раждане, която трябва да е поне 17 години назад.",
  partner_birth_place: "Място на раждане (Град)",
  partner_birth_place_error: "Мястото на раждане е задължително!",
  partner_birth_country: "Държава на раждане",
  partner_birth_country_error: "Държавата на раждане е задължителна!",
  partner_livesHome: "С вас ли живее партьорът ви?",
  partner_livesHome_error: "Моля изберете подходящата опция!",
  //
  parents_unknown: "Не мога да предоставя данни за родителите си",
  //
  mother_name: "Име",
  mother_name_error: "Името е задължително и трябва да съдържа само букви!",
  mother_surname: "Фамилия",
  mother_surname_error: "Фамилното име е задължително и трябва да съдържа само букви!",
  mother_nationality: "Националност",
  mother_nationality_error: "Данните за гражданство за задължителни!",
  mother_birthday: "Дата на раждане",
  mother_birthday_error: "Изисква се дата на раждане, която трябва да е поне 17 години назад.",
  mother_birthPlace: "Място на раждане (Град)",
  mother_birthPlace_error: "Мястото на раждане е задължително!",
  mother_birthCountry: "Държава на раждане",
  mother_birthCountry_error: "Държавата на раждане е задължителна!",
  //
  father_name: "Име",
  father_name_error: "Името е задължително и трябва да съдържа само букви!",
  father_surname: "Фамилия",
  father_surname_error: "Фамилното име е задължително и трябва да съдържа само букви!",
  father_nationality: "Националност",
  father_nationality_error: "Данните за гражданство за задължителни!",
  father_birthday: "Дата на раждане",
  father_birthday_error: "Изисква се дата на раждане, която трябва да е поне 17 години назад.",
  father_birthPlace: "Място на раждане (Град)",
  father_birthPlace_error: "Мястото на раждане е задължително!",
  father_birthCountry: "Държава на раждане",
  father_birthCountry_error: "Държавата на раждане е задължителна!",
  //
  immergency_contact_name: "Пълните имена на лицето за контакт",
  immergency_contact_name_error:
    "Името на спешния контакт е задължително и трябва да съдържа само букви!",
  immergency_contact_phone: "Телефонен номер",
  immergency_contact_phone_error:
    "Телефонният номер е задължителен и трябва да съдържа само цифри!",
  immergency_contact_relation: "Каква е връзката ви с вашия контакт?",
  immergency_contact_relation_error: "Връзката е задължителна!",
  immergency_contact_relation_relatives: "Роднина",
  immergency_contact_relation_parent: "Родител",
  immergency_contact_relation_friend: "Приятел",
  //
  children_travelling: "Деца ще пътуват ли с вас?",
  //
  has_relatives_in_UK: "Имате ли роднини във Великобритания?",
  has_relatives_in_UK_error: "Данните за поне един роднина трябва да бъдат въведени!",

  relative_0_name: "Име на първият роднина",
  relative_1_name: "Име на вторият роднина",
  relative_2_name: "Име на третият роднина",
  relative_3_name: "Име на четвъртият роднина",

  relative_0_surname: "Фамилията на първият роднина",
  relative_1_surname: "Фамилията на вторият роднина",
  relative_2_surname: "Фамилията на третият роднина",
  relative_3_surname: "Фамилията на четвъртият роднина",

  relative_0_nationality: "Националността на първият роднина",
  relative_1_nationality: "Националността на вторият роднина",
  relative_2_nationality: "Националността на третият роднина",
  relative_3_nationality: "Националността на четвъртият роднина",

  relative_0_relation: "Връзката ви с първият роднина",
  relative_1_relation: "Връзката ви с вторият роднина",
  relative_2_relation: "Връзката ви с третият роднина",
  relative_3_relation: "Връзката ви с четвъртият роднина",

  relative_0_typeofstay: "Статута на престой на първият роднина",
  relative_1_typeofstay: "Статута на престой на вторият роднина",
  relative_2_typeofstay: "Статута на престой на третият роднина",
  relative_3_typeofstay: "Статута на престой на четвъртият роднина",

  relative_0_passportNumber: "Номерът на паспорта на първият роднина",
  relative_1_passportNumber: "Номерът на паспорта на вторият роднина",
  relative_2_passportNumber: "Номерът на паспорта на третият роднина",
  relative_3_passportNumber: "Номерът на паспорта на четвъртият роднина",

  relative_0_note: "Подробен опис на ситуацията",
  relative_1_note: "Подробен опис на ситуацията",
  relative_2_note: "Подробен опис на ситуацията",
  relative_3_note: "Подробен опис на ситуацията",

  relative_0_name_error: "Името е задължително и трябва да съдържа само букви!",
  relative_1_name_error: "Името е задължително и трябва да съдържа само букви!",
  relative_2_name_error: "Името е задължително и трябва да съдържа само букви!",
  relative_3_name_error: "Името е задължително и трябва да съдържа само букви!",

  relative_0_surname_error: "Фамилното име е задължително и трябва да съдържа само букви!",
  relative_1_surname_error: "Фамилното име е задължително и трябва да съдържа само букви!",
  relative_2_surname_error: "Фамилното име е задължително и трябва да съдържа само букви!",
  relative_3_surname_error: "Фамилното име е задължително и трябва да съдържа само букви!",

  relative_0_nationality_error: "Данните за гражданство за задължителни!",
  relative_1_nationality_error: "Данните за гражданство за задължителни!",
  relative_2_nationality_error: "Данните за гражданство за задължителни!",
  relative_3_nationality_error: "Данните за гражданство за задължителни!",

  relative_0_relation_error: "Изберете, връзката си със роднината!",
  relative_1_relation_error: "Изберете, връзката си със роднината!",
  relative_2_relation_error: "Изберете, връзката си със роднината!",
  relative_3_relation_error: "Изберете, връзката си със роднината!",

  relative_0_typeofstay_error: "Изберете, статута на престой!",
  relative_1_typeofstay_error: "Изберете, статута на престой!",
  relative_2_typeofstay_error: "Изберете, статута на престой!",
  relative_3_typeofstay_error: "Изберете, статута на престой!",

  relative_0_passportNumber_error: "Номерът на паспорта е задължителен!",
  relative_1_passportNumber_error: "Номерът на паспорта е задължителен!",
  relative_2_passportNumber_error: "Номерът на паспорта е задължителен!",
  relative_3_passportNumber_error: "Номерът на паспорта е задължителен!",

  relative_0_note_error: "Подробният опис е задължителен!",
  relative_1_note_error: "Подробният опис е задължителен!",
  relative_2_note_error: "Подробният опис е задължителен!",
  relative_3_note_error: "Подробният опис е задължителен!",

  // ------------- Travel Information Page -------------
  UK_insurance: "Притежавате ли Национален осигурителен номер от Великобритания?",
  UK_insurance_number: "Национален осигурителен номер",
  UK_insurance_number_error: "Моля, въведете вашият Национален осигурителен номер!",
  visited_UK_doctor: "Посещавали ли сте лекар във Великобритания?",
  granted_UK_visa: "Издавана ли ви е виза за Великобритания?",
  refused_visa: "Отказвана ли ви е виза за Великобритания преди?",
  refused_entry: "Отказвано ли ви е да влезете във Великобритания?",
  has_worked_illegally: "Работили ли сте нелегално във Великобритания?",
  required_to_leave_UK: "Депортирани ли сте от Великобритания?",
  has_applied_for_visa:
    "Подавали ли сте документи за статут или да пребивавате във Великобритания в последните 10 години?",
  //
  has_travelled_to_UK: "Пътували ли сте до Великобритания преди?",
  has_travelled_to_UK_error: "Трябва да се въведе поне едно пътуване!",
  travel_uk_hint: "Въведете последните си 3 пътувания до Велокобритания.",
  //
  traveluk_0_from: "Началната дата на първото пътуване",
  traveluk_1_from: "Началната дата на второто пътуване",
  traveluk_2_from: "Началната дата на третото пътуване",
  traveluk_3_from: "Началната дата на четвъртото пътуване",

  traveluk_0_to: "Крайна дата на първото пътуване",
  traveluk_1_to: "Крайна дата на второто пътуване",
  traveluk_2_to: "Крайна дата на третото пътуване",
  traveluk_3_to: "Крайна дата на четвъртото пътуване",

  traveluk_0_reason: "Причина за първото пътуване",
  traveluk_1_reason: "Причина за второто пътуване",
  traveluk_2_reason: "Причина за третото пътуване",
  traveluk_3_reason: "Причина за четвъртото пътуване",

  traveluk_0_from_error:
    "Необходимо е да посочите началната дата на престоя си във Великобритания!",
  traveluk_1_from_error:
    "Необходимо е да посочите началната дата на престоя си във Великобритания!",
  traveluk_2_from_error:
    "Необходимо е да посочите началната дата на престоя си във Великобритания!",
  traveluk_3_from_error:
    "Необходимо е да посочите началната дата на престоя си във Великобритания!",

  traveluk_0_to_error: "Необходимо е да посочите крайната дата на престоя си във Великобритания!",
  traveluk_1_to_error: "Необходимо е да посочите крайната дата на престоя си във Великобритания!",
  traveluk_2_to_error: "Необходимо е да посочите крайната дата на престоя си във Великобритания!",
  traveluk_3_to_error: "Необходимо е да посочите крайната дата на престоя си във Великобритания!",

  traveluk_0_reason_error: "Необходимо е да посочите причината за престоя си във Великобритания!",
  traveluk_1_reason_error: "Необходимо е да посочите причината за престоя си във Великобритания!",
  traveluk_2_reason_error: "Необходимо е да посочите причината за престоя си във Великобритания!",
  traveluk_3_reason_error: "Необходимо е да посочите причината за престоя си във Великобритания!",

  //
  refused_visa_abroad: "Отказвана ли ви е виза за някоя държава?",
  deported_abroad: "Депортирани ли сте от някоя държава?",
  deported_abroad_details: "Ако сте били депортирани от друга държава, моля посочете подробности",
  deported_abroad_details_error:
    'Ако отговорите с "да" на предишния въпрос, трябва да предоставите подробна информация!',
  //
  has_travelled_abroad: "Пътували ли сте в чужбина освен Великобритания през последните 10 години?",
  has_travelled_abroad_error: "Трябва да се въведе поне едно пътуване!",
  destination_0: "Първата държава",
  destination_1: "Втората държава",
  destination_2: "Третата държава",
  destination_3: "Четвъртата държава",
  destination_4: "Петата държава",
  travelabroad_0_from: "Началната дата на първото пътуване",
  travelabroad_1_from: "Началната дата на второто пътуване",
  travelabroad_2_from: "Началната дата на третото пътуване",
  travelabroad_3_from: "Началната дата на четвъртото пътуване",
  travelabroad_4_from: "Началната дата на петото пътуване",
  travelabroad_0_to: "Крайната дата на първото пътуване",
  travelabroad_1_to: "Крайната дата на второто пътуване",
  travelabroad_2_to: "Крайната дата на третото пътуване",
  travelabroad_3_to: "Крайната дата на четвъртото пътуване",
  travelabroad_4_to: "Крайната дата на петото пътуване",

  travelabroad_0_reason: "Причина за първото пътуване",
  travelabroad_1_reason: "Причина за второто пътуване",
  travelabroad_2_reason: "Причина за третото пътуване",
  travelabroad_3_reason: "Причина за четвъртото пътуване",
  travelabroad_4_reason: "Причина за петото пътуване",

  destination_0_error:
    "Необходимо е да посочите страната, в която сте пребивавали! За Великобритания, моля използвайте предишната секция.",
  destination_1_error:
    "Необходимо е да посочите страната, в която сте пребивавали! За Великобритания, моля използвайте предишната секция.",
  destination_2_error:
    "Необходимо е да посочите страната, в която сте пребивавали! За Великобритания, моля използвайте предишната секция.",
  destination_3_error:
    "Необходимо е да посочите страната, в която сте пребивавали! За Великобритания, моля използвайте предишната секция.",
  destination_4_error:
    "Необходимо е да посочите страната, в която сте пребивавали! За Великобритания, моля използвайте предишната секция.",
  travelabroad_0_from_error: "Необходимо е да посочите началната дата на престоя си в миналото!",
  travelabroad_1_from_error: "Необходимо е да посочите началната дата на престоя си в миналото!",
  travelabroad_2_from_error: "Необходимо е да посочите началната дата на престоя си в миналото!",
  travelabroad_3_from_error: "Необходимо е да посочите началната дата на престоя си в миналото!",
  travelabroad_4_from_error: "Необходимо е да посочите началната дата на престоя си в миналото!",
  travelabroad_0_to_error: "Необходимо е да посочите крайната дата на престоя си в миналото!",
  travelabroad_1_to_error: "Необходимо е да посочите крайната дата на престоя си в миналото!",
  travelabroad_2_to_error: "Необходимо е да посочите крайната дата на престоя си в миналото!",
  travelabroad_3_to_error: "Необходимо е да посочите крайната дата на престоя си в миналото!",
  travelabroad_4_to_error: "Необходимо е да посочите крайната дата на престоя си в миналото!",
  travelabroad_0_reason_error: "Необходимо е да посочите причината за престоя си зад граница!",
  travelabroad_1_reason_error: "Необходимо е да посочите причината за престоя си зад граница!",
  travelabroad_2_reason_error: "Необходимо е да посочите причината за престоя си зад граница!",
  travelabroad_3_reason_error: "Необходимо е да посочите причината за престоя си зад граница!",
  travelabroad_4_reason_error: "Необходимо е да посочите причината за престоя си зад граница!",

  // ------------- Additional Information Page -------------
  convictions: "Осъждан ли си сте? (включително и за пътно-транспортни нарушения)",
  war_crimes: "Участвали ли сте във военен конфликт?",
  terrorist_org_member: "Участвали/подкрепяли ли сте терористични организации",
  not_good_character:
    "Участвали ли сте в дейности, които могат да дадат негативно впечатление за вас?",
  application_additional_information:
    "Искате ли да предоставите някаква допълнителна информация като част от тази кандидатура?",
  application_additional_information_error: "Моля, използвайте само латински букви",
  work_experience: "Моля, опишете какъв работен опит имате",
  work_experience_error: "Моля, използвайте само латински букви",
  has_health_issues: "Имате ли здравословни проблеми/aлергии? (Моля, опишете)",
  has_health_issues_error: "Моля, използвайте само латински букви",
  english_level: "Ниво на английски",
  english_level_error: "Моля, посочете нивото си на английски!",
  language_level_none: "Никакво",
  language_level_beginner: "Начално",
  language_level_intermediate: "Средно",
  language_level_advanced: "Напреднал",
  has_driving_license: "Притежавате ли шофьорска книжка?",
  has_driving_license_error: "Моля, посочете дали имате шофьорска книжка!",
  //
  privacy_hint:
    "Защо са ни необходими Вашите лични данни и как ще ги обработваме? " +
    "Global Workforce ООД е администратор на Вашите лични данни " +
    "и ги обработва съгласно Общия регламент за защита на личните данни (GDPR). " +
    "Събирането на Вашите лични данни е необходимо във връзка с кандидатурата " +
    "Ви за участие в наши програми. Тук можете да прочетете подробна информация " +
    "за нашата Политика за поверителност: ",
  privacy_policy:
    "Потвърждавам, че съм се запознал/а подробно, разбирам " +
    "и се съгласявам с Политиката за поверителност",
  privacy_policy_error:
    "Моля, приемете политиката за поверителност, " +
    "за да можем да продължим работата по вашата кандидатура!",

  //
  sufficient_funds:
    "Декларирам, че разполагам с достатъчно средства за покриване на разходите " +
    "за теста за туберкулоза, визата и пътуването до и от Обединеното кралство.",
  sufficient_funds_error: "Моля, потвърдете наличието на средства!",

  // ------------- Candidate Registration Page -------------
  register_finished_title: "Благодаим ви за регистрацията!",
  register_finished_email:
    "Ще получите имейл за потвърждение на регистрацията си. " +
    "За да завършите регистрацията, натиснете линк-а в полученият имейл.",
  register_finished_hint1:
    "Имате {hours} часа, да потвърдите имейла си. " +
    "Без това, вашата регистрация ще бъде изтрита и вие няма да можете да се явите на интервю!",
  register_finished_hint2:
    "Ако не сте получили имейл за потвърждение, моля проверете папката за спам.",
  register_welcome_title: "Работа във Великобритания с виза",
  register_welcome_hint1:
    "Добре дошли в регистрацията за работа във Великобритания на фирма {sponsor}. " +
    "Тук можете да подадете заявление за участие в Програмата сезонни работни места във Великобритания.",
  register_welcome_hint2:
    "Моля, бъдете готови да предоставите вашето име, имейл адрес и телефонен номер.",
  register_disclaimer_text:
    "Работата по програмата сезонни работа във Великобритания е напълно безплатна. Бъдете внимателни към мошенниците и търговските компании, които предлагат работа за пари. Ако платите на някого за помощ с вашето заявление, загубвате шанса си да участвате в програмата сезонни работни места.",
  register_register_title: "Работа във Великобритания с виза",
  register_register_hint1:
    "Тук можете да се регистрирате. Моля, попълнете имената си, имейл адреса и телефонен номер.",
  email: "Имейл адрес",
  has_passport: "Имате ли задграничен паспорт?",
  passportId: "Номер на паспортa",
  passportId_error: "Номерът на паспорта е нвалиден!",

  email_suggestion: "Натисни да замениш с {email}",
  email_provider_url: "Моля, използвайте имейл адрес от провайдера {emailProviderUrl}",

  register_campaign_invalid_title: "Предоставеният ви линк за регистрация е невалиден!",
  register_campaign_expired_title: "Кампанията все още не е отворена!",

  register_registration_confirmed_title: "Благодарим Ви! Регистрацията ви е потвърдена!",
  register_registration_confirmed_email: "Ще получите имейл с потвърждение.",
  register_registration_confirmed_email_2: "Моля, носете имейла със себе си на интервюто!",
  register_regitration_already_confirmed_title: "Регистрацията ви вече е потвърдена!",
  register_candidate_invalid_title: "Предоставената ви връзка за потвърждение е невалидна!",
  register_confirmation_expired_title:
    "Тази връзка вече е изтекла! Трябва да направите нова регистрация!",
  register_empty_title: "",
  register_resend_confirmation_button: "Изпрати отново имейлa за потвърждение",

  // ------------- Candidate Registration Page -------------
  vp_welcome_tax: "Добре дошли в презентацията за работа във Великобритания",
  vp_title_hint1: "Тук можете да се запознаете с всички детайли за работата във Великобритания.",
  vp_title_hint2:
    "Моля, изгледайте презентацията и накрая потвърдете, " +
    "че сте я разгледали и сте запознати със съдържанието.",
  vp_sign_title: "Благодарим ви за вниманието!",
  vp_sign_hint1:
    "За да завършите презентацията, моля, потвърдете, " +
    "че сте се запознали с нейното съдържанието и след това натиснете да продължите.",
  vp_confirm_content:
    "Потвърждавам, че съм изгледал/а и съм се запознал/а със съдържанието на презентацията.",
  vp_confirm_content_error: "За завършване на процеса е необходимо е да потвърдите",
  vp_end_title: "Поздравления, успешно сте завършили презентацията!",
  vp_end_hint1: "Можете да затворите този прозорец.",

  region: "Област",
  region_error: "Моля, изберете вашата област",
  no_region: "Изберете област",

  // ------------- Interview Questions Page -------------
  qid_0032: "Знаете ли български език?",
  qid_0032_error: "Моля, посочете дали знаете български език!",
  qid_0033: "На какъв ниво знаете български език?",
  qid_0033_error: "Моля, посочете на какво ниво знаете български език!",
  qid_0033_01: "Основно",
  qid_0033_02: "Средно",
  qid_0033_03: "Напреднало",
  qid_0029: "Знаете ли английски език?",
  qid_0029_error: "Моля, посочете дали знаете английски език!",
  qid_0030: "На какво ниво знаете английски език?",
  qid_0030_error: "Моля, посочете на какво ниво знаете английски език!",
  qid_0030_01: "Основно",
  qid_0030_02: "Средно",
  qid_0030_03: "Напреднало",
  qid_0026: "Работили ли сте в Обединеното кралство преди?",
  qid_0026_error: "Моля, посочете дали сте работали в Обединеното кралство преди!",
  qid_0027: "Ако да, то през коя компания?",
  qid_0027_error: "Моля, посочете през коя компания сте работали в Обединеното кралство!",
  qid_0027_01: "HOPS Group",
  qid_0027_02: "ProForce",
  qid_0027_03: "Fruitful",
  qid_0027_04: "Concordia",
  qid_0027_05: "AgriHR",
  qid_0027_06: "Друга",
  qid_0028: "Ако да, то в кой година?",
  qid_0028_error: "Моля, посочете в кой година сте работали в Обединеното кралство!",
  qid_0031: "Имате ли висше образование?",
  qid_0031_error: "Моля, посочете дали имате висше образование!",
  qid_0006: "Имате ли шофьорска книжка?",
  qid_0006_error: "Моля, посочете дали имате шофьорска книжка!",
  qid_0007: "Имате ли удостоверение за управление на транспортно средство?",
  qid_0007_error: "Моля, посочете дали имате удостоверение за управление на транспортно средство!",
  qid_0007_01: "Трактор",
  qid_0007_02: "Мотокар",
  qid_0007_03: "Спецтехника",
  qid_0034: "Съгласявате ли се на заснемане по време на интервюто?",
  qid_0034_error: "Моля, посочете дали сте съгласни на заснемане по време на интервюто!",
};

export default bg;
