import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { SponsorDoc } from "../../types/sponsors";
import { FormattedMessage, IntlProvider } from "react-intl";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
  MenuItem,
  Typography,
} from "@mui/material";
import { messages, SupportedLanguages } from "../../languages/languages";
import { CustomTextField } from "../../components/custom-textfield";
import {
  CampaignDoc,
  Candidate,
  CandidateDoc,
  CandidateErrors,
  EMPTY_CANDIDATE_DOC,
  EMPTY_CANDIDATE_ERRORS,
  RegistrationInfo,
  VERIFICATION_EXPIRY_HOURS,
} from "../../types/recruiting";
import { isEMailValid, isNameValid, isPhoneValid, urlAppendCacheBuster } from "../../utils/utils";
import {
  Circle,
  CircleOutlined,
  WarningAmber as WarningIcon,
  UploadFile,
} from "@mui/icons-material";
import { RadioYesNo } from "../../components/radio-button-yes-no";
import {
  loadCampaign,
  loadCandidates,
  saveCandidate,
  storeRegistrationInfo,
} from "../../data-functions/recruiting_api";
import { loadRegions, loadSponsor } from "../../data-functions/system-data_api";
import emailSpellChecker from "@zootools/email-spell-checker";
import { v4 as uuidv4 } from "uuid";

import { calculateSurveyScore, sendEmailVerificationEmail } from "../../utils/candidate-utils";
import { ADVANCED_FORM_QUESTIONS, RUSSIAN_QUESTIONS } from "./interview-questions";
import QuestionComponent from "../../components/question-component";
import { DISQUALIFY_NUM_VALUE, Question } from "../../types/surveys";
import { Regions } from "../../types/commons";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import { storage } from "../../types/firebase";
import { LoadingButton } from "@mui/lab";

declare global {
  interface Window {
    mrz_worker: any;
  }
}

type ParamTypes = {
  cid: string;
};

const hint_text =
  "Why do we need your personal data and how are we going to use it? " +
  "By submitting this form you agree that Global Workforce is an " +
  "administrator of personal data and as such will process it in accordance with GDPR " +
  "- EU General Data Protection Regulation 2016/679. " +
  "The collection of your personal data is necessary in connection with your application " +
  "for our programmes. Please read more about our Privacy Policy:";
const privacy_policy =
  "I agree to the Terms and Conditions of the Privacy Policy of Global Workforce.";

const ACTIVE_STEP_EMPTY = -1;
const ACTIVE_STEP_REGISTER = 0;
const ACTIVE_STEP_FINISHED = 1;
const ACTIVE_STEP_INVALID_CAMPAIGN = 2;
const ACTIVE_STEP_EXPIRED_CAMPAIGN = 3;
// const ACTIVE_STEP_SCAN_PASSPORT = 4;
const ACTIVE_STEP_WELCOME = 5;
const ACTIVE_STEP_DISCLAIMER = 6;
const ACTIVE_STEP_ADVANCED_FORM = 7;
const ACTIVE_STEP_UPLOAD_PASSPORT = 8;

export const RegisterCandidate = () => {
  const { cid } = useParams<ParamTypes>();
  const [campaign, setCampaign] = useState<CampaignDoc | null>(null);
  const [sponsor, setSponsor] = useState<SponsorDoc | null>(null);
  const [language, setLanguage] = useState<SupportedLanguages>("en");
  const [candidate, setCandidate] = useState<CandidateDoc>({ ...EMPTY_CANDIDATE_DOC });
  const [regions, setRegions] = useState<Regions | null>(null);
  const [activeStep, setActiveStep] = useState<number>(ACTIVE_STEP_EMPTY);
  const [errors, setErrors] = useState<CandidateErrors>({ ...EMPTY_CANDIDATE_ERRORS });
  const [emailSuggestion, setEmailSuggestion] = useState<string>("");
  const [registrationInfo, setRegistrationInfo] = useState<RegistrationInfo | null>(null);
  const [answerErrors, setAnswerErrors] = useState<Record<string, string>>({});

  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [imageFileExtension, setImageFileExtension] = useState<string>("");

  const registerInProgress = useRef<boolean>(false);
  const ipFetchInitiated = useRef(false);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const fetchSponsor = async () => {
      if (cid) {
        const campaignDoc = await loadCampaign(cid);
        let newCampaign;
        if (campaignDoc) {
          setCampaign(campaignDoc);
          newCampaign = campaignDoc.campaign;
        } else {
          setCampaign(null);
          console.error("Campaign ID: " + cid + " was not found");
          setActiveStep(ACTIVE_STEP_INVALID_CAMPAIGN);
          return;
        }

        const sponsor = await loadSponsor(newCampaign.sponsorId);

        if (sponsor) {
          setSponsor(sponsor);
        }

        if (newCampaign.language && newCampaign.language !== language) {
          setLanguage(newCampaign.language as SupportedLanguages);
        }

        if (newCampaign.status === "open") {
          setActiveStep(ACTIVE_STEP_WELCOME);
        } else {
          setActiveStep(ACTIVE_STEP_EXPIRED_CAMPAIGN);
        }

        if (newCampaign.includeRegion && newCampaign.country) {
          const regions = await loadRegions(newCampaign.country);
          setRegions(regions);
        }
      } else {
        console.error("Campaign ID: " + cid + " was not found");
        setActiveStep(ACTIVE_STEP_INVALID_CAMPAIGN);
      }
    };

    const fetchIpAddress = async () => {
      fetch("https://api4.ipify.org?format=json")
        .then(
          (res) => res.json(),
          (err) => console.error("Error fetching IP address:", err)
        )
        .then(
          (data) => {
            let deviceId = localStorage.getItem("gwf_deviceId");
            if (!deviceId) {
              deviceId = uuidv4();
              localStorage.setItem("gwf_deviceId", deviceId);
            }

            const rInfo: RegistrationInfo = {
              sponsorId: sponsor?.id || "",
              candidateId: "",
              campaignId: "",
              browserAgent: navigator.userAgent,
              ip4Address: data.ip,
              ip6Address: "",
              deviceId: deviceId,
              createdAt: new Date().toISOString(),
            };
            console.log("IP data:", rInfo);
            setRegistrationInfo(rInfo);
          },
          (err) => console.error("Error fetching IP address:", err)
        );
    };

    if (!ipFetchInitiated.current) {
      ipFetchInitiated.current = true;
      fetchIpAddress();
    }

    fetchSponsor();
    setEmailSuggestion("");
  }, [cid]);

  // const handleLoadPassport = async () => {
  // Create an input element to select a file
  // const input = document.createElement("input");
  // input.type = "file";
  // input.accept = "image/*";
  // // Add an event listener to handle the file selection
  // input.onchange = async (event) => {
  //   const file = (event.target as HTMLInputElement).files?.[0];
  //   if (file) {
  //     try {
  //       const reader = new FileReader();
  //       reader.onload = function (e: ProgressEvent<FileReader>) {
  //         console.log("reader onload", e.target);
  //         console.log("worker", worker);
  //         if (e.target && e.target.result && worker) {
  //           console.log("posting message");
  //           worker.postMessage({
  //             cmd: "process",
  //             image: e.target.result,
  //           });
  //         }
  //       };
  //       console.log("reading file");
  //       reader.readAsDataURL(file);
  //     } catch (error) {
  //       console.error("Error scanning passport:", error);
  //     }
  //   }
  // };
  // // Trigger the file input click event
  // input.click();
  // };

  const getQuestionValue = (qid: string) => {
    const answer = candidate?.candidate?.surveyAnswers?.[qid];

    return answer === undefined ? undefined : answer;
  };

  const handleAnswerChange = (
    event: React.ChangeEvent<HTMLInputElement> | any,
    question?: Question,
    value?: any
  ) => {
    if (candidate?.candidate && question) {
      const key = question.id;
      let newInterviewAnswers = { ...candidate.candidate.surveyAnswers };
      if (!newInterviewAnswers) {
        newInterviewAnswers = {};
      }

      newInterviewAnswers[key] = value;
      setCandidate({
        ...candidate,
        candidate: { ...candidate.candidate, surveyAnswers: newInterviewAnswers },
      });

      let score = calculateSurveyScore(newInterviewAnswers, ADVANCED_FORM_QUESTIONS);
      if (campaign?.campaign.language === "ru" && score > DISQUALIFY_NUM_VALUE) {
        const sc = calculateSurveyScore(newInterviewAnswers, RUSSIAN_QUESTIONS);
        if (sc > DISQUALIFY_NUM_VALUE) {
          score += sc;
        } else {
          score = DISQUALIFY_NUM_VALUE;
        }
      }
    }
  };

  const handleUploadPassportImage = async (imageId: string) => {
    let downloadURL: string = "";

    try {
      const imageName = imageId ? imageId : uuidv4();

      const fileName =
        "candidates/" + campaign?.id + "/" + imageName + "." + imageFileExtension || "jpg";
      const storageRef = ref(storage, fileName);
      // Define metadata with Cache-Control
      const metadata = {
        cacheControl: "public, max-age=31536000", // Example: 1 year
      };

      await uploadString(storageRef, imageSrc || "", "data_url", metadata);

      // Step 3: Get download URL
      downloadURL = await getDownloadURL(storageRef);
      return urlAppendCacheBuster(downloadURL);
    } catch (err) {
      console.error("Error uploading passport image:", err);
    } finally {
      return downloadURL;
    }
  };

  const validateInput = async (candidate: Candidate) => {
    let newErrors: CandidateErrors = { ...EMPTY_CANDIDATE_ERRORS };
    let newAnswerErrors: Record<string, string> = {};
    let formIsValid = true;

    if (!(candidate.name && isNameValid(candidate.name))) {
      newErrors.name = "Name is required and should only contain alphabetic characters";
      formIsValid = false;
    }

    if (!(candidate.surname && isNameValid(candidate.surname))) {
      newErrors.surname = "Surname is required and should only contain alphabetic characters";
      formIsValid = false;
    }

    if (!isPhoneValid(candidate.phone)) {
      newErrors.phone = "Phone number is required and should only contain numbers!";
      formIsValid = false;
    }

    if (!candidate.privacy_policy_accepted) {
      formIsValid = false;
      newErrors.privacy_policy_accepted =
        "Please, accept the privacy policy, so that we can continue with your application!";
    }

    console.log("candidate.birth_date: ", candidate.birth_date);
    if (!candidate.birth_date) {
      formIsValid = false;
      newErrors.birth_date = "Please, enter your date of birth";
    }

    if (candidate.email && isEMailValid(candidate.email)) {
      if (campaign) {
        if (campaign.campaign.restrictEmailProvider) {
          const mailProvider = candidate.email.split("@")[1];
          if (mailProvider !== campaign.campaign.emailProviderUrl) {
            newErrors.email =
              "Please, use the email address from the email provider " +
              campaign.campaign.emailProviderUrl;
            newErrors.emailDomain = newErrors.email;
            formIsValid = false;
          }
        }
        if (formIsValid) {
          const duplCandidates = await loadCandidates(null, campaign.id, {
            email: { value: candidate.email.trim(), operator: "==" },
          });

          const candidateFound = Object.keys(duplCandidates).length > 0;

          if (candidateFound) {
            newErrors.email = "A candidate with this email address already exists";
            formIsValid = false;
          }
        }
      }
    } else {
      newErrors.email = "Please enter a valid email address";
      formIsValid = false;
    }

    if (candidate.has_passport && (!candidate.passportId || candidate.passportId.length <= 3)) {
      newErrors.passportId = "Please, enter a valid passport number";
      formIsValid = false;
    }

    if (campaign?.campaign.includeRegion && !candidate.region) {
      newErrors.region = "Please, select a region";
      formIsValid = false;
    }

    if (activeStep === ACTIVE_STEP_ADVANCED_FORM) {
      const advancedFormQuestions = ADVANCED_FORM_QUESTIONS.filter((question) => question.required);
      for (const question of advancedFormQuestions) {
        const value = getQuestionValue(question.id);

        if (!value) {
          newAnswerErrors[question.id] = "This field is required";
          formIsValid = false;
        }
      }

      if (campaign?.campaign.language === "ru") {
        const russianQuestions = RUSSIAN_QUESTIONS.filter((question) => question.required);
        for (const question of russianQuestions) {
          const value = getQuestionValue(question.id);
          if (!value) {
            newAnswerErrors[question.id] = "This field is required";
            formIsValid = false;
          }
        }
      }
    }

    setErrors(newErrors);
    setAnswerErrors(newAnswerErrors);
    return formIsValid;
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const key = event.target.name;

    let newCandidate;

    if (key === "privacy_policy") {
      newCandidate = {
        ...candidate,
        candidate: { ...candidate.candidate, privacy_policy_accepted: event.target.checked },
      };
    } else if (key === "has_passport") {
      newCandidate = {
        ...candidate,
        candidate: { ...candidate.candidate, has_passport: event.target.value === "true" },
      };
    } else if (key === "passportId") {
      newCandidate = {
        ...candidate,
        candidate: { ...candidate.candidate, passportId: event.target.value },
      };
      newCandidate.candidate.has_passport = event.target.value.length > 0;
    } else {
      newCandidate = {
        ...candidate,
        candidate: { ...candidate.candidate, [key]: event.target.value },
      };
    }

    setCandidate(newCandidate);
  };

  const handleRegionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newCandidate = { ...candidate };
    const region = regions?.regions.find((r) => r.code === event.target.value);
    if (region) {
      newCandidate.candidate.region = region;
    } else {
      if (newCandidate.candidate.region !== undefined) {
        delete newCandidate.candidate.region;
      }
    }
    setCandidate(newCandidate);
    console.log("newCandidate: ", newCandidate);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    // Your logic here
    console.log("Input field lost focus:", event.target.value);
    const email = event.target.value;
    if (email) {
      const suggestedEmail = emailSpellChecker.run({
        email,
      });
      if (suggestedEmail) {
        // Make the warning container visible,

        // and add warning text
        setEmailSuggestion(suggestedEmail.full);
      } else {
        // Else, hide the warning container
        setEmailSuggestion("");
      }
    }
  };

  const calcScore = (newCandidate: Candidate) => {
    if (
      campaign &&
      campaign.campaign.disqualifyIfNoPassport &&
      newCandidate.has_passport === false
    ) {
      return DISQUALIFY_NUM_VALUE;
    }

    if (newCandidate.surveyAnswers && campaign) {
      let score = calculateSurveyScore(newCandidate.surveyAnswers, RUSSIAN_QUESTIONS);
      if (campaign?.campaign.language === "ru" && score > DISQUALIFY_NUM_VALUE) {
        const sc = calculateSurveyScore(newCandidate.surveyAnswers, ADVANCED_FORM_QUESTIONS);
        if (sc > DISQUALIFY_NUM_VALUE) {
          score += sc;
        } else {
          score = DISQUALIFY_NUM_VALUE;
        }
      }
      return score;
    } else {
      return 0;
    }
  };

  const handleSave = async () => {
    if (registerInProgress.current === true) {
      console.log("registering in progress");
      return;
    }

    registerInProgress.current = true;

    const newCandidate = { ...candidate.candidate };
    newCandidate.name = newCandidate.name.trim();
    newCandidate.surname = newCandidate.surname.trim();
    newCandidate.email = newCandidate.email.toLowerCase().trim();
    newCandidate.phone = newCandidate.phone.trim();
    if (newCandidate.passportId) {
      newCandidate.passportId = newCandidate.passportId.trim();
    }
    newCandidate.preferredLanguage = language;
    newCandidate.sponsorId = sponsor ? sponsor.id : "";

    const isValid = await validateInput(newCandidate);
    if (!isValid) {
      registerInProgress.current = false;
      return;
    }

    if (campaign && campaign.id) {
      const expiryHours =
        (campaign.campaign.verificationExpiryHours || VERIFICATION_EXPIRY_HOURS) * 60 * 60 * 1000;
      newCandidate.campaignId = campaign.id;
      newCandidate.agentId = campaign.campaign.agentId || "";
      newCandidate.verification = {
        verificationCode: uuidv4(),
        expiryDate: new Date(Date.now() + expiryHours).toISOString(),
      };

      if (newCandidate.surveyAnswers) {
        const score = calcScore(newCandidate);
        newCandidate.surveyScore = score;
        if (score === DISQUALIFY_NUM_VALUE) {
          newCandidate.status = "disqualified";
        }
      }

      let candidateId = "";
      try {
        const candDoc = await saveCandidate(null, { id: "", candidate: newCandidate }, true);
        console.log("saved candDoc: ", candDoc);
        if (candDoc) {
          candidateId = candDoc.id;
          if (registrationInfo) {
            registrationInfo.candidateId = candDoc.id;
            registrationInfo.campaignId = campaign.id;
            registrationInfo.sponsorId = sponsor ? sponsor.id : "";
            console.log("storing registration info", registrationInfo);
            try {
              const regInfoDoc = await storeRegistrationInfo(
                null,
                { id: "", registrationInfo },
                true
              );
              if (regInfoDoc && regInfoDoc.id) {
                console.log("registration info stored", regInfoDoc);
              } else {
                console.log("registration info not stored");
              }
            } catch (err) {
              console.error("Error storing registration info:", err);
            }
          } else {
            console.log("no registration info to store");
          }

          const templateId = campaign.campaign.inviteAutomatically
            ? "candidate_verification"
            : "candidate_verification_no_invitation";
          try {
            await sendEmailVerificationEmail([candDoc.id], templateId);
          } catch (err) {
            console.error("Error sending email verification email:", err);
          }
          setCandidate(candDoc);
        }
      } catch (err) {
        registerInProgress.current = false;
        console.error(err);
        return;
      }

      if (campaign?.campaign.requirePassportScan && imageSrc) {
        console.log("uploading passport image");
        try {
          const imageUrl = await handleUploadPassportImage(candidateId);
          console.log("image URL from handleUploadPassportImage: ", imageUrl);
          if (imageUrl) {
            await saveCandidate(
              null,
              { id: candidateId, candidate: { ...newCandidate, imageUrl } },
              false
            );
          }
        } catch (err) {
          console.error("Error uploading passport image:", err);
        } finally {
          setImageSrc(null);
        }
      }
      setActiveStep(ACTIVE_STEP_FINISHED);
    }

    registerInProgress.current = false;
  };

  const handleNext = async () => {
    if (!candidate.candidate.privacy_policy_accepted) {
      setErrors({
        ...errors,
        privacy_policy_accepted:
          "Please, accept the privacy policy, so that we can continue with your application!",
      });
      return;
    }

    switch (activeStep) {
      case ACTIVE_STEP_WELCOME:
        if (campaign && campaign.campaign.showPaymentsDisclaimer) {
          setActiveStep(ACTIVE_STEP_DISCLAIMER);
        } else {
          setActiveStep(ACTIVE_STEP_REGISTER);
        }
        break;
      case ACTIVE_STEP_DISCLAIMER:
        setActiveStep(ACTIVE_STEP_REGISTER);
        break;
      case ACTIVE_STEP_REGISTER:
        {
          const isValid = await validateInput(candidate.candidate);
          if (isValid) {
            setErrors({ ...EMPTY_CANDIDATE_ERRORS });
            setAnswerErrors({});
          } else {
            return;
          }

          if (campaign && campaign.campaign.showAdvancedForm) {
            setActiveStep(ACTIVE_STEP_ADVANCED_FORM);
          } else if (campaign && campaign.campaign.requirePassportScan) {
            setActiveStep(ACTIVE_STEP_UPLOAD_PASSPORT);
          } else {
            handleSave();
          }
        }
        break;
      case ACTIVE_STEP_ADVANCED_FORM:
        console.log("ACTIVE_STEP_ADVANCED_FORM -> Next");
        {
          const isValid = await validateInput(candidate.candidate);
          if (isValid) {
            setErrors({ ...EMPTY_CANDIDATE_ERRORS });
          } else {
            return;
          }
          if (campaign && campaign.campaign.requirePassportScan) {
            setActiveStep(ACTIVE_STEP_UPLOAD_PASSPORT);
          } else {
            handleSave();
          }
        }
        break;
    }
  };

  const getFinished = () => {
    const hours = campaign?.campaign.verificationExpiryHours || VERIFICATION_EXPIRY_HOURS;
    return (
      <Box padding={"3rem"} paddingTop={"1rem"}>
        <Typography variant="h5" sx={{ marginBottom: "1rem" }}>
          <FormattedMessage
            id="register_finished_title"
            defaultMessage="Thank you for registering!"
          />
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: "1rem" }}>
          <FormattedMessage
            id="register_finished_email"
            defaultMessage="We have sent you a link to confirm your email! To finish the registration process, please click on the link in the email."
          />
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: "1rem" }}>
          <FormattedMessage
            id="register_finished_hint1"
            values={{ hours }}
            defaultMessage={
              "You have {hours} hours to confirm your email address! " +
              "Without confirmation, your registration will be deleted."
            }
          />
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: "1rem" }}>
          <FormattedMessage
            id="register_finished_hint2"
            defaultMessage="If you haven't received our email, check your spam folder."
          />
        </Typography>
      </Box>
    );
  };

  const getWelcomePage = () => {
    return (
      <Box
        sx={{
          padding: {
            xs: "1rem",
            md: "3rem",
          },
          paddingTop: "1rem",
        }}
      >
        <Typography variant="h5" sx={{ marginBottom: "1rem", textAlign: "center" }}>
          <FormattedMessage
            id="register_welcome_title"
            defaultMessage="Working in the United Kingdom with a visa"
          />
        </Typography>
        <Typography>
          <FormattedMessage
            id="register_welcome_hint1"
            defaultMessage="Welcome to the {sponsor} registration page. Here you can register for our interview process."
            values={{ sponsor: sponsor?.sponsor.name }}
          />
        </Typography>
        <Typography sx={{ marginBottom: "1rem", marginTop: "1rem" }}>
          <FormattedMessage
            id="register_welcome_hint2"
            defaultMessage="Please, be ready to provide your name, surname, passport number, email address and telephone number."
          />
        </Typography>

        <Grid container spacing={2} marginTop={"1rem"} marginBottom={"1rem"}>
          {/* ---------- Privacy Policy ----------*/}
          <Grid item xs={12}>
            <Box>
              <Typography>
                <FormattedMessage id="privacy_hint" defaultMessage={hint_text} />

                <Link
                  href="https://www.hopslaboursolutions.com/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.hopslaboursolutions.com/privacy-policy
                </Link>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <FormControl error={!!errors.privacy_policy_accepted}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="privacy_policy"
                    size="medium"
                    checkedIcon={<Circle />}
                    icon={<CircleOutlined />}
                    onChange={handleInputChange}
                  />
                }
                label={<FormattedMessage id="privacy_policy" defaultMessage={privacy_policy} />}
              />
              <FormHelperText>
                {errors.privacy_policy_accepted && (
                  <FormattedMessage
                    id="privacy_policy_error"
                    defaultMessage={errors.privacy_policy_accepted}
                  />
                )}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Button variant="contained" onClick={handleNext}>
                <FormattedMessage id="button_next" defaultMessage={"Next"} />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const getDisclaimer = () => {
    return (
      <Box
        sx={{
          padding: {
            xs: "1rem",
            md: "3rem",
          },
          paddingTop: "1rem",
        }}
      >
        <Typography variant="h5" sx={{ marginBottom: "1rem", textAlign: "center" }}>
          <FormattedMessage
            id="register_welcome_title"
            defaultMessage="Working in the United Kingdom with a visa"
          />
        </Typography>

        <Grid container marginTop={"2rem"} marginBottom={"1rem"} gap={3} alignItems="center">
          <Grid
            item
            xs={12}
            md={1}
            sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
          >
            <WarningIcon color="warning" sx={{ fontSize: "4rem" }} />
          </Grid>
          <Grid item xs={12} md={10} sx={{ justifyContent: "center" }}>
            <Typography>
              <FormattedMessage
                id="register_disclaimer_text"
                defaultMessage="Employment under the Seasonal Worker Programme is completely free of charge. Beware of scammers and commercial companies that offer employment for money. If you pay someone to help you with your application, you will lose your chance to participate in the Seasonal Worker Programme. "
              />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Button variant="contained" onClick={handleNext}>
                <FormattedMessage id="button_next" defaultMessage={"Next"} />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const getForm = () => {
    return (
      <Box
        sx={{
          padding: {
            xs: "0rem",
            md: "3rem",
          },
          paddingTop: "1rem",
        }}
      >
        <Typography variant="h5" sx={{ marginBottom: "1rem", textAlign: "center" }}>
          <FormattedMessage
            id="register_register_title"
            defaultMessage="Working in the United Kingdom with a visa"
          />
        </Typography>
        <Typography sx={{ marginBottom: "1rem" }}>
          <FormattedMessage
            id="register_register_hint1"
            defaultMessage="You can register with your names, email address and telephone number."
          />
        </Typography>

        {/* ---------- Data Form ---------- */}
        <Grid container spacing={2} marginTop={"1rem"} marginBottom={"1rem"}>
          {/* <Grid item xs={12}>
            <Button variant="contained" onClick={handleLoadPassport}>
              load passport
            </Button>
          </Grid> */}

          <Grid item xs={12} md={6}>
            <CustomTextField
              error={!!errors.name}
              helperText={errors.name}
              autoFocus
              margin="dense"
              name="name"
              label="Name"
              type="text"
              fullWidth
              required
              value={candidate.candidate.name || ""}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              error={!!errors.surname}
              helperText={errors.surname}
              margin="dense"
              name="surname"
              label="Surname"
              type="text"
              fullWidth
              required
              value={candidate.candidate.surname || ""}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              error={!!errors.email}
              helperText={errors.email}
              margin="dense"
              name="email"
              label="Email Address"
              type="email"
              fullWidth
              required
              value={candidate.candidate.email || ""}
              onChange={handleInputChange}
              onBlur={handleBlur}
            />
            {emailSuggestion && (
              <Chip
                color="warning"
                label={
                  <FormattedMessage
                    id="email_suggestion"
                    defaultMessage={`Press to use {email} instead`}
                    values={{ email: emailSuggestion }}
                  />
                }
                size={"medium"}
                variant={"outlined"}
                onClick={() => {
                  setCandidate({
                    ...candidate,
                    candidate: { ...candidate.candidate, email: emailSuggestion },
                  });
                  setEmailSuggestion("");
                }}
                onDelete={() => {
                  setEmailSuggestion("");
                }}
                sx={{
                  width: "100%",
                  marginTop: "0.5rem",
                  "& .MuiChip-deleteIcon": {
                    marginLeft: "auto",
                  },
                  height: "auto",
                  minHeight: "2rem",
                  "& .MuiChip-label": {
                    display: "block",
                    whiteSpace: "normal",
                  },
                }}
              />
            )}
            {campaign?.campaign.emailProviderUrl && (
              <Box display="flex" alignItems="center" gap={1}>
                <WarningIcon color="warning" />
                <Typography
                  variant="body2"
                  sx={{
                    marginTop: "0.5rem",
                    fontSize: "0.8rem",
                    color: errors.emailDomain ? "error.main" : "inherit",
                  }}
                >
                  <FormattedMessage
                    id="email_provider_url"
                    defaultMessage="Please, use the email address from the email provider {emailProviderUrl}"
                    values={{ emailProviderUrl: campaign.campaign.emailProviderUrl }}
                  />
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              name="phone"
              label="Phone number"
              error={!!errors.phone}
              helperText={errors.phone}
              type="tel"
              value={candidate.candidate.phone || ""}
              onChange={handleInputChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              name="birth_date"
              label="Date of birth"
              error={!!errors.birth_date}
              helperText={errors.birth_date}
              type="date"
              value={candidate.candidate.birth_date || ""}
              onChange={handleInputChange}
              fullWidth
              required
            />
          </Grid>
          {campaign?.campaign.includeRegion && regions && (
            <Grid item xs={12} md={6}>
              <CustomTextField
                error={!!errors.region}
                helperText={errors.region}
                margin="dense"
                name="region"
                fullWidth
                select
                value={candidate.candidate.region?.code || ""}
                onChange={handleRegionChange}
                label="Region"
              >
                <MenuItem value="no_region">
                  <FormattedMessage id="no_region" defaultMessage="Select a region" />
                </MenuItem>
                {regions.regions.map((region) => (
                  <MenuItem key={region.code} value={region.code}>
                    {region.label}
                  </MenuItem>
                ))}
              </CustomTextField>
            </Grid>
          )}

          {/* ---------- Passport ---------- */}
          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item xs={12}>
                <RadioYesNo
                  id="has_passport"
                  value={candidate.candidate.has_passport}
                  label="Do you have a travel passport?"
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={11}>
                <CustomTextField
                  error={!!errors.passportId}
                  helperText={errors.passportId}
                  margin="dense"
                  name="passportId"
                  label="Passport Number"
                  type="text"
                  fullWidth
                  disabled={!candidate.candidate.has_passport}
                  value={candidate.candidate.passportId || ""}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" alignItems="center">
              {!(campaign?.campaign.showAdvancedForm || campaign?.campaign.requirePassportScan) && (
                <Button variant="contained" onClick={handleSave}>
                  <FormattedMessage id="button_register" defaultMessage={"Register"} />
                </Button>
              )}
              {campaign &&
                (campaign.campaign.showAdvancedForm || campaign.campaign.requirePassportScan) && (
                  <Button variant="contained" onClick={handleNext}>
                    <FormattedMessage id="button_next" defaultMessage={"Next"} />
                  </Button>
                )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const getAdvancedForm = () => {
    return (
      <Box
        sx={{
          padding: {
            xs: "0rem",
            md: "3rem",
          },
          paddingTop: "1rem",
        }}
      >
        <Typography variant="h5" sx={{ marginBottom: "1rem", textAlign: "center" }}>
          <FormattedMessage
            id="register_register_title"
            defaultMessage="Working in the United Kingdom with a visa"
          />
        </Typography>
        {/* <Typography sx={{ marginBottom: "1rem" }}>
          <FormattedMessage
            id="register_register_hint1"
            defaultMessage="You can register with your names, email address and telephone number."
          />
        </Typography> */}

        {/* ---------- Data Form ---------- */}
        <Grid container spacing={2} marginTop={"1rem"} marginBottom={"1rem"}>
          {campaign?.campaign.language === "ru" &&
            RUSSIAN_QUESTIONS.map((question) => (
              <Grid item xs={12} md={(question.mdSize || 1) * 6} key={"ge_" + question.id}>
                <QuestionComponent
                  question={question}
                  error={!!answerErrors[question.id]}
                  helperText={answerErrors[question.id]}
                  value={getQuestionValue(question.id)}
                  onChange={handleAnswerChange}
                  translated={true}
                />
              </Grid>
            ))}
          {ADVANCED_FORM_QUESTIONS.map((question) => (
            <Grid item xs={12} md={(question.mdSize || 1) * 6} key={"ge_" + question.id}>
              <QuestionComponent
                question={question}
                helperText={answerErrors[question.id]}
                error={!!answerErrors[question.id]}
                value={getQuestionValue(question.id)}
                onChange={handleAnswerChange}
                translated={true}
              />
            </Grid>
          ))}
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" alignItems="center">
              {!campaign?.campaign.requirePassportScan && (
                <LoadingButton
                  loading={registerInProgress.current}
                  variant="contained"
                  onClick={handleSave}
                  disabled={registerInProgress.current}
                  sx={{ mt: 2 }}
                >
                  <FormattedMessage id="button_register" defaultMessage="Register" />
                </LoadingButton>
              )}
              {campaign && campaign.campaign.requirePassportScan && (
                <Button variant="contained" onClick={handleNext}>
                  <FormattedMessage id="button_next" defaultMessage={"Next"} />
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const getUploadPassport = () => {
    return (
      <Box padding={{ xs: "0rem", md: "3rem" }} paddingTop={"1rem"}>
        <Typography variant="h5" sx={{ marginBottom: "1rem", textAlign: "center" }}>
          <FormattedMessage id="register_upload_passport_title" defaultMessage="Upload Image" />
        </Typography>

        <Typography variant="body1" sx={{ marginBottom: "2rem", textAlign: "center" }}>
          <FormattedMessage
            id="register_upload_passport_instructions"
            defaultMessage="Please upload a clear photo of yourself ин а пасспорт формат on a light background."
          />
        </Typography>

        <Typography variant="body1" sx={{ marginBottom: "2rem", textAlign: "center" }}>
          <FormattedMessage
            id="register_upload_passport_instructions2"
            defaultMessage="Upload a jpeg or jpg image maximum 5MB."
          />
        </Typography>

        {imageSrc && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: 3,
            }}
          >
            <Card
              sx={{
                maxWidth: 400,
                width: "100%",
                position: "relative",
              }}
            >
              <img
                src={imageSrc}
                alt="Passport preview"
                style={{
                  width: "100%",
                  height: "auto",
                  display: "block",
                }}
              />
            </Card>
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Button variant="outlined" component="label" startIcon={<UploadFile />}>
            <FormattedMessage
              id="register_upload_passport_button"
              defaultMessage="Choose Passport Image"
            />
            <input
              ref={fileInputRef}
              type="file"
              hidden
              accept="image/jpeg,image/jpg"
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) {
                  // Check if file type is allowed
                  const allowedTypes = ["image/jpeg", "image/jpg"];
                  if (!allowedTypes.includes(file.type)) {
                    alert("Please upload only JPEG/JPG images");
                    if (fileInputRef.current) {
                      fileInputRef.current.value = "";
                    }
                    return;
                  }

                  const reader = new FileReader();
                  reader.onload = (e) => {
                    const result = e.target?.result as string;
                    setImageSrc(result);
                    setImageFileExtension(file.name.split(".").pop() || "jpg");
                  };
                  reader.readAsDataURL(file);
                }
              }}
            />
          </Button>

          {/* {imageSrc && (
            <Button
              variant="outlined"
              color="error"
              onClick={() => {
                setImageSrc(null);
                setImageFileExtension("");
                if (fileInputRef.current) {
                  fileInputRef.current.value = "";
                }
              }}
            >
              <FormattedMessage
                id="register_upload_passport_remove"
                defaultMessage="Remove Image"
              />
            </Button>
          )} */}

          <LoadingButton
            loading={registerInProgress.current}
            variant="contained"
            onClick={handleSave}
            disabled={!imageSrc || registerInProgress.current}
            sx={{ mt: 2 }}
          >
            <FormattedMessage id="button_register" defaultMessage="Register" />
          </LoadingButton>
        </Box>
      </Box>
    );
  };

  const getInvalidCampaign = () => {
    return (
      <Box padding={"3rem"} paddingTop={"1rem"}>
        <Typography variant="h5" color="error" sx={{ marginBottom: "1rem", textAlign: "center" }}>
          <FormattedMessage
            id="register_campaign_invalid_title"
            defaultMessage="The regitration link you have been provided is invalid!"
          />
        </Typography>
      </Box>
    );
  };

  const getExpiredCampaign = () => {
    return (
      <Box padding={"3rem"} paddingTop={"1rem"}>
        <Typography variant="h5" color="error" sx={{ marginBottom: "1rem", textAlign: "center" }}>
          <FormattedMessage
            id="register_campaign_expired_title"
            defaultMessage="This campaign has alread expired and does not accept registrations anymore!"
          />
        </Typography>
      </Box>
    );
  };

  const getEmpty = () => {
    return (
      <Box padding={"3rem"} paddingTop={"1rem"}>
        <Typography variant="h5" sx={{ marginBottom: "1rem", textAlign: "center" }}>
          <FormattedMessage id="register_empty_title" defaultMessage="Seasonal work in the UK" />
        </Typography>
      </Box>
    );
  };

  const getActivePage = () => {
    switch (activeStep) {
      case ACTIVE_STEP_WELCOME:
        return getWelcomePage();
      case ACTIVE_STEP_DISCLAIMER:
        return getDisclaimer();
      // case ACTIVE_STEP_SCAN_PASSPORT:
      //   return getScanPassport();
      case ACTIVE_STEP_REGISTER:
        return getForm();
      case ACTIVE_STEP_ADVANCED_FORM:
        return getAdvancedForm();
      case ACTIVE_STEP_UPLOAD_PASSPORT:
        return getUploadPassport();
      case ACTIVE_STEP_FINISHED:
        return getFinished();
      case ACTIVE_STEP_INVALID_CAMPAIGN:
        return getInvalidCampaign();
      case ACTIVE_STEP_EXPIRED_CAMPAIGN:
        return getExpiredCampaign();
      case ACTIVE_STEP_EMPTY:
        return getEmpty();
      default:
        return getEmpty();
    }
  };

  return (
    <IntlProvider locale={language} messages={messages[language]}>
      <Container maxWidth="md" sx={{ marginTop: "2rem", marginBottom: "2rem" }}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <img
            src={sponsor ? sponsor.sponsor.logo : ""}
            alt="operatorlogo"
            style={{ maxHeight: "200px", width: "auto" }}
          />
        </Box>
        <Card sx={{ marginTop: "1rem", padding: "1rem" }}>{getActivePage()}</Card>
      </Container>
    </IntlProvider>
  );
};
