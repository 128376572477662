import {
  CollectionReference,
  Query,
  addDoc,
  collection,
  deleteDoc,
  doc,
  documentId,
  getCountFromServer,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import {
  CampaignDoc,
  CampaignsDict,
  Candidate,
  Candidate_FB,
  CandidateDoc,
  CandidatesDict,
  RecruitingCampaign,
  RegistrationInfo,
  RegistrationInfoDoc,
  RegistrationInfosDict,
} from "../types/recruiting";
import db from "../types/firebase";
import { AppUser } from "../types/users";
import { Filter } from "../types/commons";
import { applyFilter } from "../utils/db-utils";
import { getCandidateFromFB, setCandidateToFB } from "../utils/candidate-utils";
import { getServerTime } from "./common-api";
import { generatePassword } from "../utils/utils";

export const loadCandidate = async (candidateId: string) => {
  let loadedCandidate = null;

  if (candidateId) {
    const docRef = doc(db, "candidates", candidateId);
    const candidateSnapshot = await getDoc(docRef);
    if (candidateSnapshot) {
      loadedCandidate = {
        id: candidateSnapshot.id,
        candidate: getCandidateFromFB(candidateSnapshot.data() as Candidate_FB),
      };
    }
  }
  return loadedCandidate;
};

export const loadCandidateByVerificationCode = async (verificationCode: string) => {
  let loadedCandidate = null;

  if (verificationCode) {
    const candidatesRef = query(
      collection(db, "candidates"),
      where("verification.verificationCode", "==", verificationCode)
    );
    const candidateSnapshot = await getDocs(candidatesRef);

    if (candidateSnapshot && candidateSnapshot.size > 0) {
      loadedCandidate = {
        id: candidateSnapshot.docs[0].id,
        candidate: getCandidateFromFB(candidateSnapshot.docs[0].data() as Candidate_FB),
      };
    }
  }
  return loadedCandidate;
};

export const loadCandidateByConfirmationCode = async (
  confirmationCode: string,
  campaignId: string
) => {
  let loadedCandidate = null;

  if (confirmationCode) {
    const candidatesRef = query(
      collection(db, "candidates"),
      where("verification.confirmationCode", "==", confirmationCode),
      where("campaignId", "==", campaignId)
    );
    const candidateSnapshot = await getDocs(candidatesRef);

    if (candidateSnapshot && candidateSnapshot.size > 0) {
      loadedCandidate = {
        id: candidateSnapshot.docs[0].id,
        candidate: getCandidateFromFB(candidateSnapshot.docs[0].data() as Candidate_FB),
      };
    }
  }
  return loadedCandidate;
};

export const saveCandidate = async (
  user: AppUser | null | undefined,
  candidate: CandidateDoc,
  createIfNoId: boolean = false
) => {
  console.log("saveCandidate", candidate.id);
  const appDoc = { ...candidate };
  const fbDoc = setCandidateToFB(appDoc.candidate);
  const serverTime = await getServerTime();

  if (appDoc.id) {
    const docRef = doc(db, "candidates", appDoc.id);
    const candidateSnapshot = await getDoc(docRef);
    // Update the document

    if (candidateSnapshot) {
      appDoc.candidate.updatedAt = serverTime.iso;
      appDoc.candidate.updatedBy = user ? user.id : "anonymous";

      fbDoc.updatedAt = appDoc.candidate.updatedAt;
      fbDoc.updatedBy = appDoc.candidate.updatedBy;

      await setDoc(docRef, fbDoc);

      console.log("updated candidate:", appDoc.id);
    } else {
      console.log("no document found with this id");
      return null;
    }
  } else if (createIfNoId) {
    appDoc.candidate.createdAt = serverTime.iso;
    appDoc.candidate.createdBy = user ? user.id : "anonymous";

    fbDoc.createdAt = appDoc.candidate.createdAt;
    fbDoc.createdBy = appDoc.candidate.createdBy;

    appDoc.id = (await addDoc(collection(db, "candidates"), fbDoc)).id;
    console.log("created candidate:", appDoc.id);
  }

  return appDoc;
};

export const deleteCandidate = async (candidateId: string) => {
  return deleteDoc(doc(db, "candidates", candidateId));
};

export const loadCandidates = async (
  user: AppUser | null | undefined,
  campaignId: string,
  filter: Filter = {},
  cLimit: number = 0
) => {
  let candidatesRef: Query | CollectionReference;
  const candidatesDict: CandidatesDict = {};

  const sponsorId = user && user.sponsorId ? user.sponsorId : "";
  const agentId = user && user.agentId ? user.agentId : "";

  // const campaignIds: string[] = Object.keys(campaigns).filter((id) => {
  //   let res = sponsorId ? campaigns[id].sponsorId === sponsorId : true;
  //   res &&= agentId ? campaigns[id].agentId === agentId : true;
  //   return res;
  // });

  if (campaignId === "no_campaign") {
    candidatesRef = query(collection(db, "candidates"), where("campaignId", "==", ""));
  } else if (campaignId === "all_campaigns") {
    candidatesRef = query(collection(db, "candidates"));
  } else {
    candidatesRef = query(collection(db, "candidates"), where("campaignId", "==", campaignId));
  }

  if (sponsorId !== "") {
    candidatesRef = query(candidatesRef, where("sponsorId", "==", sponsorId));
  }

  if (agentId !== "") {
    candidatesRef = query(candidatesRef, where("agentId", "==", agentId));
  }

  candidatesRef = applyFilter(candidatesRef, filter, cLimit);

  const snapshot = await getDocs(candidatesRef);

  snapshot.forEach((doc) => {
    candidatesDict[doc.id] = getCandidateFromFB(doc.data() as Candidate_FB);
  });

  // if (campaignIds.length > 0) {
  //   const chunks = chunkArray(campaignIds, 30);
  //   for (const chunk of chunks) {
  //     candidatesRef = query(collection(db, "candidates"), where("campaignId", "in", chunk));
  //     candidatesRef = applyFilter(candidatesRef, filter, cLimit);

  //     const snapshot = await getDocs(candidatesRef);

  //     snapshot.forEach((doc) => {
  //       candidatesDict[doc.id] = getCandidateFromFB(doc.data() as Candidate_FB);
  //       if (cLimit > 0 && Object.keys(candidatesDict).length === cLimit) {
  //         console.log("loaded " + Object.keys(candidatesDict).length + " candidates");
  //         return candidatesDict;
  //       }
  //     });
  //   }
  // } else {
  //   if (sponsorId === "") {
  //     candidatesRef = query(collection(db, "candidates"), where("campaignId", "==", ""));
  //     candidatesRef = applyFilter(candidatesRef, filter, cLimit);
  //     const candidatesSnapshot = await getDocs(candidatesRef);

  //     console.log("loaded " + candidatesSnapshot.size + " candidates");

  //     candidatesSnapshot.forEach((doc) => {
  //       const candidate = getCandidateFromFB(doc.data() as Candidate_FB);
  //       candidatesDict[doc.id] = candidate;
  //     });
  //   } else return {};
  // }

  return candidatesDict;
};

export const loadCampaignNumbers = async (campaignId: string) => {
  const campaignRef = collection(db, "candidates");
  const q = query(campaignRef, where("campaignId", "==", campaignId));
  const snapshot = await getCountFromServer(q);

  return snapshot.data().count; // Returns the document count
};

export const loadCampaign = async (campaignId: string) => {
  let loadedCampaign = null;

  if (campaignId) {
    const docRef = doc(db, "campaigns", campaignId);
    const campaignSnapshot = await getDoc(docRef);
    if (campaignSnapshot) {
      loadedCampaign = {
        id: campaignSnapshot.id,
        campaign: campaignSnapshot.data() as RecruitingCampaign,
      };
    }
  }
  return loadedCampaign;
};

export const loadCampaigns = async (
  user: AppUser | null | undefined,
  filter: Filter = {},
  cLimit: number = 0
) => {
  const sponsorId = user && user.sponsorId ? user.sponsorId : "";
  const agentId = user && user.agentId ? user.agentId : "";

  let campaignsRef: Query | CollectionReference;
  let campaignsSnapshot;
  const campaignsDict: CampaignsDict = {};

  if (agentId) {
    campaignsRef = query(collection(db, "campaigns"), where("agentId", "==", agentId));
  } else if (sponsorId) {
    campaignsRef = query(collection(db, "campaigns"), where("sponsorId", "==", sponsorId));
  } else {
    campaignsRef = collection(db, "campaigns");
  }

  campaignsRef = applyFilter(campaignsRef, filter, cLimit);

  campaignsSnapshot = await getDocs(campaignsRef);

  campaignsSnapshot.forEach((doc) => {
    const campaign = doc.data() as RecruitingCampaign;
    campaignsDict[doc.id] = campaign;
  });

  return campaignsDict;
};

export const saveCampaign = async (
  user: AppUser | null | undefined,
  campaign: CampaignDoc,
  createIfNoId: boolean = false
) => {
  const appDoc: CampaignDoc = { ...campaign };
  if (appDoc.id) {
    const docRef = doc(db, "campaigns", appDoc.id);
    const campaignSnapshot = await getDoc(docRef);
    // Update the document
    if (campaignSnapshot) {
      appDoc.campaign.updatedAt = new Date().toISOString();
      appDoc.campaign.updatedBy = user ? user.id : "anonymous";

      await setDoc(docRef, appDoc.campaign);
      console.log("updated campaign:", appDoc.id);
    } else return null;
  } else if (createIfNoId) {
    appDoc.campaign.createdAt = new Date().toISOString();
    appDoc.campaign.createdBy = user ? user.id : "anonymous";
    appDoc.id = (await addDoc(collection(db, "campaigns"), appDoc.campaign)).id;
    console.log("created campaign:", appDoc.id);
  }

  return appDoc;
};

export const deleteCampaign = async (campaignId: string) => {
  return deleteDoc(doc(db, "campaigns", campaignId));
};

export const loadCampaigsByApplicantEmail = async (email: string) => {
  if (!email) {
    return [];
  }

  // lod the candidates by email
  const candidatesRef = query(collection(db, "candidates"), where("email", "==", email));
  const candidatesSnapshot = await getDocs(candidatesRef);
  const campaignIds = candidatesSnapshot.docs.map((doc) => {
    return (doc.data() as Candidate).campaignId;
  });

  let campaigns: string[] = [];
  // lod the campaigns by campaignId
  if (campaignIds.length > 0) {
    const campaignsRef = query(collection(db, "campaigns"), where(documentId(), "in", campaignIds));
    const campaignsSnapshot = await getDocs(campaignsRef);
    campaigns = campaignsSnapshot.docs.map((doc) => (doc.data() as RecruitingCampaign).name);
  }
  return campaigns;
};

export const loadRegistrationInfos = async (
  user: AppUser | null | undefined,
  campaignId: string
) => {
  // const sponsorId = user && user.sponsorId ? user.sponsorId : "";
  let registrationRef: Query | CollectionReference;
  const registrationInfosDict: RegistrationInfosDict = {};

  // if (sponsorId) {
  //   registrationRef = query(collection(db, "registrations"), where("sponsorId", "==", sponsorId));
  // } else
  // {
  registrationRef = collection(db, "registrations");
  // }

  if (campaignId)
    if (campaignId === "no_campaign") {
      registrationRef = query(registrationRef, where("campaignId", "==", ""));
    } else if (campaignId === "all_campaigns") {
      registrationRef = query(registrationRef);
    } else {
      registrationRef = query(registrationRef, where("campaignId", "==", campaignId));
    }

  const snapshot = await getDocs(registrationRef);
  snapshot.forEach((doc) => {
    const registrationInfo = doc.data() as RegistrationInfo;
    registrationInfosDict[doc.id] = registrationInfo;
  });

  return registrationInfosDict;
};

export const loadRegistrationInfo = async (
  user: AppUser | null | undefined,
  registrationInfoId: string
) => {
  const registrationInfoRef = doc(db, "registrations", registrationInfoId);
  const registrationInfoSnapshot = await getDoc(registrationInfoRef);
  return registrationInfoSnapshot.exists()
    ? (registrationInfoSnapshot.data() as RegistrationInfo)
    : null;
};

export const loadRegistrationInfoByCandidateId = async (
  user: AppUser | null | undefined,
  candidateId: string
) => {
  const registrationInfoRef = query(
    collection(db, "registrations"),
    where("candidateId", "==", candidateId)
  );
  const registrationInfoSnapshot = await getDocs(registrationInfoRef);
  return registrationInfoSnapshot.docs.map((doc) => doc.data() as RegistrationInfo);
};

export const storeRegistrationInfo = async (
  user: AppUser | null | undefined,
  registrationInfo: RegistrationInfoDoc,
  createIfNoId: boolean = false
) => {
  const appDoc = { ...registrationInfo };

  if (appDoc.id) {
    const docRef = doc(db, "registrations", appDoc.id);
    const registrationInfoSnapshot = await getDoc(docRef);
    // Update the document

    if (registrationInfoSnapshot) {
      await setDoc(docRef, appDoc.registrationInfo);

      console.log("updated registration info:", appDoc.id);
    } else {
      console.log("no document found with this id");
      return null;
    }
  } else if (createIfNoId) {
    const serverTime = await getServerTime();
    console.log("serverTime: ", serverTime);
    appDoc.registrationInfo.createdAt = serverTime.iso;

    const regInfoRef = await addDoc(collection(db, "registrations"), appDoc.registrationInfo);
    appDoc.id = regInfoRef.id;
    console.log("created registration info:", appDoc);
  }

  return appDoc;
};

export const deleteRegistrationInfo = async (registrationInfoId: string) => {
  return deleteDoc(doc(db, "registrations", registrationInfoId));
};

export const deleteCandiateRegistrationInfo = async (candidateId: string) => {
  const registrationInfoRef = query(
    collection(db, "registrations"),
    where("candidateId", "==", candidateId)
  );
  const registrationInfoSnapshot = await getDocs(registrationInfoRef);

  const deletePromises = registrationInfoSnapshot.docs.map((doc) => deleteDoc(doc.ref));
  return Promise.all(deletePromises);
};

export const getCandidatesWithDuplicateIPs = async (
  user: AppUser | null | undefined,
  campaignId: string,
  candidateId: string,
  ipAddress: string
) => {
  const sponsorId = user && user.sponsorId ? user.sponsorId : "";

  let candidatesRef = query(
    collection(db, "registrations"),
    where("campaignId", "==", campaignId),
    where("candidateId", "!=", candidateId),
    where("ip4Address", "==", ipAddress)
  );

  if (sponsorId !== "") {
    candidatesRef = query(candidatesRef, where("sponsorId", "==", sponsorId));
  }

  const snapshot = await getDocs(candidatesRef);
  const candidates: RegistrationInfo[] = snapshot.docs.map((doc) => doc.data() as RegistrationInfo);

  return candidates;
};

export const generateConfirmationCode = async (campaignId: string) => {
  let code = "";
  if (campaignId) {
    let candidatesDict: CandidatesDict = {};
    do {
      code = generatePassword(6, false, false).toUpperCase();
      candidatesDict = await loadCandidates(null, campaignId, {
        "verification.confirmationCode": { value: code, operator: "==" },
      });
    } while (Object.keys(candidatesDict).length > 0);
    return code;
  }
  console.log("generated code: ", code);
  return code;
};
