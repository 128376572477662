import React, { useContext, useEffect, useMemo, useState } from "react";

import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Container,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { TitledPage } from "../../components/titled-page";
import {
  CampaignsDict,
  Candidate,
  CandidateErrors,
  CandidateStatus,
  CandidatesDict,
  EMPTY_CANDIDATE_ERRORS,
  RegistrationInfo,
} from "../../types/recruiting";
import { AuthContext } from "../../components/auth-provider";
import header_Image from "../../ressources/global workforce_thin font.png";

import { candidateFullName } from "../../utils/applicant-utils";
import { RadioYesNo } from "../../components/radio-button-yes-no";
import { getCandidateStatusLabel } from "../../utils/candidate-utils";
import {
  isConfirmationCodeValid,
  isEMailValid,
  isNameValid,
  isPhoneValid,
} from "../../utils/utils";
import {
  getCandidatesWithDuplicateIPs,
  loadCampaigns,
  loadCandidateByConfirmationCode,
  loadCandidates,
  loadRegistrationInfoByCandidateId,
  saveCandidate,
} from "../../data-functions/recruiting_api";
import { loadSponsor } from "../../data-functions/system-data_api";
import QuestionComponent from "../../components/question-component";
import { Question, QuestionAnswersDict } from "../../types/surveys";
import { INTERVIEW_QUESTIONS, RUSSIAN_QUESTIONS } from "./interview-questions";
import { CustomTextField } from "../../components/custom-textfield";

interface CandidateOptionType {
  inputValue?: string;
  id: string;
  name: string;
  email: string;
}

interface Registrations {
  numRegistrations: number;
  numRejections: number;
  numDnI: number;
}

const EMPTY_REGISTRATIONS = {
  numRegistrations: 0,
  numRejections: 0,
  numDnI: 0,
};

const ACTIVE_STEP_SELECT_CAMPAIGN = 0;
const ACTIVE_STEP_SELECT_CANDIDATE = 1;
const ACTIVE_STEP_INTERVIEW_QUESTIONS = 2;
const ACTIVE_STEP_FINISHED_INTERVIEW = 3;

const PageInterviews: React.FC = () => {
  const [campaigns, setCampaigns] = useState<CampaignsDict>({});
  const [selectedCampaignId, setSelectedCampaignId] = useState<string>("");
  const [activeStep, setActiveStep] = useState<number>(ACTIVE_STEP_SELECT_CAMPAIGN);
  const [sponsorId, setSponsorId] = useState("");
  const [sponsorLogo, setSponsorLogo] = useState<string | null>(null);

  const [selectedCandidate, setSelectedCandidate] = useState<CandidateOptionType | null>(null);
  const [interviewAnswers, setInterviewAnswers] = useState<QuestionAnswersDict>({});
  const [candidates, setCandidates] = useState<CandidatesDict>({});
  const [candidateOptions, setCandidateOptions] = useState<CandidateOptionType[]>([]);
  const [numCampaigns, setNumCampaigns] = useState<Registrations>({ ...EMPTY_REGISTRATIONS });
  const [errors, setErrors] = useState<CandidateErrors>({ ...EMPTY_CANDIDATE_ERRORS });
  const [confirmation_code_invalid, setConfirmationCodeInvalid] = useState<boolean>(false);
  const [confirmationCode, setConfirmationCode] = useState<string>("");

  const [duplicateCandidates, setDuplicateCandidates] = useState<RegistrationInfo[]>([]);
  const [registrationInfo, setRegistrationInfo] = useState<RegistrationInfo | null>(null);
  const { currentUser } = useContext(AuthContext)!;

  const fetchCampaigns = async () => {
    const campaignsDict: CampaignsDict = await loadCampaigns(currentUser?.appUser, {
      status: { value: ["open", "closed_registration"], operator: "in" },
    });

    setCampaigns(campaignsDict);
  };

  const fetchCandidates = async () => {
    console.log("selected campaign: " + selectedCampaignId);
    if (selectedCampaignId) {
      const candidatesDict: CandidatesDict = await loadCandidates(
        currentUser?.appUser,
        selectedCampaignId,
        {
          status: { value: ["invited", "interviewed", "approved", "rejected"], operator: "in" },
        }
      );

      Object.entries(candidatesDict).forEach(([candidateId, candidate]) => {
        if (["visa", "DnI"].includes(candidate.status || "")) {
          delete candidatesDict[candidateId];
        }
      });

      const newOptions: CandidateOptionType[] = [];

      // Assuming candidatesDict is an object with 'id' as key and 'name' as value
      let sortedCandidates = Object.entries(candidatesDict).sort((a, b) => {
        let nameA = candidateFullName(a[1]).toUpperCase(); // ignore upper and lowercase
        let nameB = candidateFullName(b[1]).toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0; // names must be equal
      });

      sortedCandidates.forEach((candidate) => {
        newOptions.push({
          id: candidate[0], // id is the key in the original object
          name: `${candidateFullName(candidate[1])} (${candidate[1].email})`, // name is the value in the original object
          email: candidate[1].email,
        });
      });

      // newOptions.push({
      //   id: doc.id,
      //   name: candidateFullName(candidate),
      // });

      console.log("loaded " + Object.keys(candidatesDict).length + " candidates");

      setCandidates(candidatesDict);
      setCandidateOptions(newOptions);
      setConfirmationCode("");
    }
  };

  useEffect(() => {
    fetchCampaigns();
    setSponsorId(currentUser?.appUser?.sponsorId || "");
    setConfirmationCode("");
  }, []);

  useEffect(() => {
    setActiveStep(ACTIVE_STEP_SELECT_CAMPAIGN);
    setSelectedCampaignId("");
    setSelectedCandidate(null);
    setDuplicateCandidates([]);
    setConfirmationCode("");
    fetchCampaigns();
    setSponsorId(currentUser?.appUser?.sponsorId || "");
  }, [currentUser]);

  useEffect(() => {
    const fetchLogo = async () => {
      let logo = null;
      if (sponsorId) {
        const sponsor = await loadSponsor(sponsorId);
        if (sponsor) {
          logo = sponsor.sponsor.logo;
        }
      }
      setSponsorLogo(logo || null);
    };

    fetchLogo();
  }, [sponsorId]);

  useEffect(() => {
    const fetchNumCandidates = async () => {
      const numRegistrations = { ...EMPTY_REGISTRATIONS };

      console.log("fetching num candidates for: " + selectedCandidate?.email);
      if (selectedCandidate && selectedCandidate.email) {
        const numCandiates = await loadCandidates(currentUser?.appUser, "all_campaigns", {
          email: { value: selectedCandidate.email, operator: "==" },
        });
        console.log("numCandidates", numCandiates);

        if (numCandiates) {
          numRegistrations.numRegistrations = Object.keys(numCandiates).length;
          Object.entries(numCandiates).forEach(([id, candidate]) => {
            if (candidate.status === "rejected") {
              numRegistrations.numRejections++;
            } else if (candidate.status === "DnI") {
              numRegistrations.numDnI++;
            }
          });
        }
      }
      setNumCampaigns(numRegistrations);
    };

    let duplicateCandidates: RegistrationInfo[] = [];

    const fetchDuplicateCandidates = async () => {
      if (!selectedCandidate?.id) return;
      const regInfo = await loadRegistrationInfoByCandidateId(
        currentUser?.appUser,
        selectedCandidate?.id
      );

      if (regInfo?.[0]?.ip4Address) {
        duplicateCandidates = await getCandidatesWithDuplicateIPs(
          currentUser?.appUser,
          selectedCampaignId,
          selectedCandidate?.id,
          regInfo?.[0]?.ip4Address
        );
      }

      setRegistrationInfo(regInfo?.[0] || null);
      setDuplicateCandidates(duplicateCandidates);
    };

    // check if the selected candidate has already applied
    fetchNumCandidates();
    fetchDuplicateCandidates();
    setConfirmationCode("");
    if (selectedCandidate && candidates[selectedCandidate.id]) {
      console.log(
        "setting interview answers for: " + selectedCandidate.id,
        candidates[selectedCandidate.id].surveyAnswers
      );
      setInterviewAnswers(candidates[selectedCandidate.id].surveyAnswers || {});
    }
  }, [selectedCandidate]);

  const onInputComboChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    console.log("setting campaign: " + value);
    setSelectedCampaignId(value || "");
    setSelectedCandidate(null);
    setDuplicateCandidates([]);
    setNumCampaigns({ ...EMPTY_REGISTRATIONS });
  };

  const onConfirmationCodeChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("setting confirmation code: " + event.target.value);
    let code = event.target.value.toUpperCase();
    if (code.length > 6) {
      code = code.substring(0, 6);
    }

    setConfirmationCode(code);
    const isCodeValid = isConfirmationCodeValid(code);
    if (code.length > 0 && !isCodeValid) {
      setConfirmationCodeInvalid(true);
      return;
    }

    if (isCodeValid && code.length === 6) {
      const candidateId = Object.keys(candidates).find(
        (key) => candidates[key].verification?.confirmationCode === code
      );
      if (candidateId) {
        setSelectedCandidate({
          id: candidateId,
          name: candidateFullName(candidates[candidateId]),
          email: candidates[candidateId].email,
        });
        setActiveStep(ACTIVE_STEP_INTERVIEW_QUESTIONS);
      } else {
        const newCandidate = await loadCandidateByConfirmationCode(code, selectedCampaignId);
        if (newCandidate) {
          setSelectedCandidate({
            id: newCandidate.id,
            name: candidateFullName(newCandidate.candidate),
            email: newCandidate.candidate.email,
          });
          setCandidates({ ...candidates, [newCandidate.id]: newCandidate.candidate });
          setActiveStep(ACTIVE_STEP_INTERVIEW_QUESTIONS);
          setConfirmationCodeInvalid(false);
        } else {
          setConfirmationCodeInvalid(true);
        }
      }
    }
  };

  const handleContinue = async () => {
    let newStep;
    switch (activeStep) {
      case ACTIVE_STEP_SELECT_CAMPAIGN:
        newStep = ACTIVE_STEP_SELECT_CANDIDATE;
        break;
      default:
        newStep = ACTIVE_STEP_SELECT_CAMPAIGN;
    }

    if (newStep === ACTIVE_STEP_SELECT_CANDIDATE) await fetchCandidates();
    setActiveStep(newStep);
  };

  const handleCandidateChange = (event: any, value: CandidateOptionType | null) => {
    if (value && typeof value === "string") {
    } else {
      console.log("selecting candidate");
      console.log(value);
      setSelectedCandidate(value);
      setActiveStep(ACTIVE_STEP_INTERVIEW_QUESTIONS);
    }
  };

  const handleAnswerChange = (
    event: React.ChangeEvent<HTMLInputElement> | any,
    question?: Question,
    value?: any
  ) => {
    console.log("handleAnswerChange");
    console.log("question: ", question);
    console.log("value: ", value);

    if (selectedCandidate && interviewAnswers && question) {
      const key = question.id;
      let newInterviewAnswers = { ...interviewAnswers };
      if (!newInterviewAnswers) {
        newInterviewAnswers = {};
      }

      newInterviewAnswers[key] = value;
      console.log("newInterviewAnswers: ", newInterviewAnswers);
      setInterviewAnswers(newInterviewAnswers);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //    if (event.target.name === "expiry_date") {

    if (selectedCandidate && candidates[selectedCandidate.id]) {
      const key = event.target.name as keyof Candidate;
      let newCandidate: Candidate = {
        ...candidates[selectedCandidate.id],
      };

      if (key.startsWith("qid_")) {
        if (!newCandidate.surveyAnswers) {
          newCandidate.surveyAnswers = {};
        }
        if (key.startsWith("qid_0007")) {
          newCandidate.surveyAnswers[key] = event.target.checked ? "true" : "false";
        } else {
          newCandidate.surveyAnswers[key] = event.target.value;
        }
      } else {
        newCandidate = { ...newCandidate, [key]: event.target.value };
      }

      const newCandidates = { ...candidates };
      newCandidates[selectedCandidate.id] = newCandidate;
      setCandidates(newCandidates);
    }
  };

  const validate = () => {
    let formIsValid = true;
    if (!selectedCandidate) return formIsValid;

    let newErrors: CandidateErrors = { ...EMPTY_CANDIDATE_ERRORS };

    const candidate = candidates[selectedCandidate.id];

    if (!(candidate.name && isNameValid(candidate.name))) {
      newErrors.name = "Name is required and should only contain alphabetic characters";
      formIsValid = false;
    }

    if (!(candidate.surname && isNameValid(candidate.surname))) {
      newErrors.surname = "Surname is required and should only contain alphabetic characters";
      formIsValid = false;
    }

    if (!isPhoneValid(candidate.phone)) {
      newErrors.phone = "Phone number is required and should only contain numbers!";
      formIsValid = false;
    }

    // if (!candidate.privacy_policy_accepted) {
    //   formIsValid = false;
    //   newErrors.privacy_policy_accepted =
    //     "Please, accept the privacy policy, so that we can continue with your application!";
    // }

    if (!(candidate.email && isEMailValid(candidate.email))) {
      newErrors.email = "Please enter a valid email address";
      formIsValid = false;
    }
    setErrors(newErrors);
    return formIsValid;
  };

  const saveCandidateData = async (status: CandidateStatus) => {
    if (!(selectedCandidate && selectedCandidate.id)) {
      return;
    }

    if (!validate()) {
      return;
    }

    // Check if document exists
    if (candidates[selectedCandidate.id]) {
      // Update the candidate
      const newCandiate = {
        id: selectedCandidate.id,
        candidate: { ...candidates[selectedCandidate.id] },
      };
      newCandiate.candidate.status = status;
      newCandiate.candidate.interviewer =
        currentUser?.appUser?.name + " " + currentUser?.appUser?.surname;
      newCandiate.candidate.interviewerId = currentUser?.appUser?.id;
      newCandiate.candidate.surveyAnswers = interviewAnswers;
      await saveCandidate(currentUser?.appUser, newCandiate, true);
      candidates[selectedCandidate.id] = newCandiate.candidate;
      setCandidates(candidates);
      setActiveStep(3);
      setSelectedCandidate(null);
      setConfirmationCode("");
      setNumCampaigns({ ...EMPTY_REGISTRATIONS });
    } else {
      console.log(`No document found for candidate: ${selectedCandidate.id}`);
    }
  };

  const handleApproveCandidate = async () => {
    saveCandidateData("approved");
  };

  const handleRejectCandidate = async () => {
    saveCandidateData("rejected");
  };

  const handleSaveCandidate = async () => {
    saveCandidateData("interviewed");
  };

  const getCardColor = (status: CandidateStatus | undefined | null) => {
    switch (status) {
      case "approved":
        return "success";

      case "rejected":
        return "error";

      case "DnI":
        return "error";

      default:
        return "primary";
    }
  };

  const sortedCampaigns = useMemo(() => {
    return Object.entries(campaigns).sort(([idA, campaignA], [idB, campaignB]) => {
      if (campaignA.name < campaignB.name) {
        return -1;
      }
      if (campaignA.name > campaignB.name) {
        return 1;
      }
      return 0;
    });
  }, [campaigns]);

  const getQuestionValue = (qid: string) => {
    const cid = selectedCandidate?.id;
    if (!(cid && interviewAnswers)) return undefined;

    const answer = interviewAnswers?.[qid];

    return answer === undefined ? undefined : answer;
  };

  const getInterviewQuestions = () => {
    return (
      selectedCandidate &&
      candidates[selectedCandidate.id] && (
        <Grid item xs={12}>
          <Card>
            <CardHeader
              action={getCandidateStatusLabel(candidates[selectedCandidate.id].status)}
              title={"Candidate Details"}
              sx={{
                backgroundColor: (theme) =>
                  theme.palette[getCardColor(candidates[selectedCandidate.id].status)].main,
                color: (theme) =>
                  theme.palette[getCardColor(candidates[selectedCandidate.id].status)].contrastText,
              }}
            />
            <CardContent>
              <Grid container spacing={2} padding={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    error={!!errors.name}
                    helperText={errors.name}
                    autoFocus
                    margin="dense"
                    name="name"
                    label="Name"
                    type="text"
                    fullWidth
                    value={selectedCandidate && candidates[selectedCandidate.id].name}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    error={!!errors.surname}
                    helperText={errors.surname}
                    margin="dense"
                    name="surname"
                    label="Surname"
                    type="text"
                    fullWidth
                    value={(selectedCandidate && candidates[selectedCandidate.id].surname) || ""}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    error={!!errors.email}
                    helperText={errors.email}
                    margin="dense"
                    name="email"
                    label="Email Address"
                    type="email"
                    fullWidth
                    value={(selectedCandidate && candidates[selectedCandidate.id].email) || ""}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="phone"
                    label="Phone number"
                    error={!!errors.phone}
                    helperText={errors.phone}
                    type="tel"
                    value={(selectedCandidate && candidates[selectedCandidate.id].phone) || ""}
                    onChange={handleInputChange}
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="birth_date"
                    label="Date of birth"
                    error={!!errors.birth_date}
                    helperText={errors.birth_date}
                    type="date"
                    value={(selectedCandidate && candidates[selectedCandidate.id].birth_date) || ""}
                    onChange={handleInputChange}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                {/* ---------- Passport ---------- */}
                <Grid item xs={12} md={6}>
                  <Grid container>
                    <Grid item xs={12}>
                      <RadioYesNo
                        id="has_passport"
                        value={!!selectedCandidate && candidates[selectedCandidate.id].has_passport}
                        label="Do you have a travel passport?"
                        translated={false}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={11}>
                      <TextField
                        error={!!errors.passportId}
                        helperText={errors.passportId}
                        margin="dense"
                        name="passportId"
                        label="Passport Number"
                        type="text"
                        fullWidth
                        disabled={
                          !(selectedCandidate && candidates[selectedCandidate.id].has_passport)
                        }
                        value={
                          (selectedCandidate && candidates[selectedCandidate.id].passportId) || ""
                        }
                        onChange={handleInputChange}
                      />
                    </Grid>
                  </Grid>{" "}
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>

                {selectedCampaignId &&
                  campaigns[selectedCampaignId].language === "ru" &&
                  RUSSIAN_QUESTIONS.map((question) => (
                    <Grid item xs={12} md={(question.mdSize || 1) * 6} key={"ge_" + question.id}>
                      <QuestionComponent
                        question={question}
                        error={false}
                        value={getQuestionValue(question.id)}
                        onChange={handleAnswerChange}
                        translated={false}
                      />
                    </Grid>
                  ))}

                {INTERVIEW_QUESTIONS.map((question) => (
                  <Grid item xs={12} md={(question.mdSize || 1) * 6} key={"ge_" + question.id}>
                    <QuestionComponent
                      question={question}
                      error={false}
                      value={getQuestionValue(question.id)}
                      onChange={handleAnswerChange}
                      translated={false}
                    />
                  </Grid>
                ))}

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12}>
                  <CustomTextField
                    name="note"
                    label="Interviewer notes"
                    // error={!!errors.phone}
                    // helperText={errors.phone}

                    value={(selectedCandidate && candidates[selectedCandidate.id].note) || ""}
                    onChange={handleInputChange}
                    margin="dense"
                    fullWidth
                    multiline
                    rows={4}
                    translated={false}
                    externalLabel={true}
                  />
                </Grid>

                {/* Grid Container   */}
              </Grid>
            </CardContent>

            {/*---------- Form Action Buttons ----------*/}
            <CardActions sx={{ padding: 2 }}>
              <Box flexGrow={1}></Box>

              <Button
                variant="contained"
                color="success"
                onClick={handleApproveCandidate}
                sx={{ mr: 2 }}
              >
                Approve
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={handleRejectCandidate}
                sx={{ mr: 2 }}
              >
                Reject
              </Button>
              <Button variant="contained" onClick={handleSaveCandidate} sx={{ mr: 2 }}>
                Decide Later
              </Button>
            </CardActions>
          </Card>
        </Grid>
      )
    );
  };

  const getInterviewForm = () => {
    const candidateName =
      selectedCandidate && candidates[selectedCandidate.id]
        ? candidateFullName(candidates[selectedCandidate?.id])
        : "The candidate ";
    switch (activeStep) {
      case ACTIVE_STEP_FINISHED_INTERVIEW:
        return (
          <Grid item xs={12}>
            <Typography>
              {candidateName} has been saved successfully. Select another candidate to interview.
            </Typography>
          </Grid>
        );

      case ACTIVE_STEP_INTERVIEW_QUESTIONS:
        return getInterviewQuestions();
    }
  };

  const getForm = () => {
    switch (activeStep) {
      // Select a Campaign page
      case ACTIVE_STEP_SELECT_CAMPAIGN:
        return (
          <Container maxWidth="md" sx={{ marginTop: "2rem", marginBottom: "2rem" }}>
            <Card sx={{ marginTop: "1rem", padding: "1rem" }}>
              <Box display="flex" justifyContent="center" alignItems="center" paddingTop={2}>
                <CardMedia
                  component="img"
                  //alt="green iguana"
                  //height="140"
                  image={sponsorLogo || header_Image}
                  sx={{
                    marginY: "1rem",
                    maxHeight: "200px",
                    width: "auto",
                    maxWidth: "75%",
                  }}
                />
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ margin: "2rem 3rem" }}
              >
                <Grid container direction={"column"} alignContent={"center"}>
                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ marginBottom: "1rem" }}>
                      Select the campaign, you want to work on
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name={"campaignId"}
                      label={"Campaign"}
                      select
                      onChange={onInputComboChange}
                      value={selectedCampaignId || ""}
                      required
                      fullWidth
                      margin={"dense"}
                    >
                      {Object.values(sortedCampaigns).map(([campaignId, campaign]) => {
                        return (
                          <MenuItem value={campaignId} key={campaignId}>
                            {campaign.name}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ marginTop: "3rem" }}
                    >
                      <Button
                        variant="contained"
                        disabled={!selectedCampaignId}
                        onClick={handleContinue}
                      >
                        Continue
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Container>
        );
      // Interview Page (steps 1 to 3)
      default:
        return (
          <Box display={"flex"} sx={{ width: "100%", gap: 2, maxWidth: "900px", margin: "auto" }}>
            <Grid container alignContent={"start"} gap={2}>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="start"
                  alignItems="start"
                  sx={{ marginBottom: "2rem" }}
                >
                  <Button variant="contained" startIcon={<ArrowBack />} onClick={handleContinue}>
                    Select a new campaign
                  </Button>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Typography variant="body1" sx={{ marginBottom: "4px" }}>
                      Enter the confirmation code...
                    </Typography>
                    <TextField
                      error={confirmation_code_invalid}
                      name={"confirmation_code"}
                      label={"Confirmation Code"}
                      value={confirmationCode}
                      fullWidth
                      margin={"dense"}
                      onChange={onConfirmationCodeChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={8}>
                    <Typography variant="body1" sx={{ marginBottom: "4px" }}>
                      Or select the candidate, you want to interview
                    </Typography>
                    <Autocomplete
                      id="candidate-combo-box"
                      fullWidth
                      sx={{ marginTop: "0", marginBottom: "0" }}
                      options={candidateOptions}
                      getOptionKey={(option) => option.id}
                      getOptionLabel={(option) => {
                        // e.g. value selected with enter, right from the input
                        if (option && typeof option === "string") {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return `${option.name}`;
                      }}
                      value={selectedCandidate}
                      onChange={handleCandidateChange}
                      filterSelectedOptions
                      filterOptions={(options, { inputValue }) => {
                        const newOptions = options.filter((option) =>
                          option.name.toLowerCase().includes(inputValue.toLowerCase())
                        );
                        console.log("inputValue: " + inputValue);
                        console.log("number of options: " + newOptions.length);
                        return newOptions;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      isOptionEqualToValue={(
                        option: CandidateOptionType,
                        value: CandidateOptionType
                      ) => {
                        return option.id === value.id;
                      }}
                      renderOption={(props, option) => <li {...props}>{option.name}</li>}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Candidate"
                          variant="outlined"
                          margin={"dense"}
                          //   error={!!errors.applicant}
                          //   helperText={errors.applicant}
                          required
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {numCampaigns.numRegistrations > 1 && (
                <Grid item xs={12}>
                  <Alert severity={numCampaigns.numDnI > 0 ? "error" : "info"}>
                    {`This email address / candidate has already been registered in ${
                      numCampaigns.numRegistrations - 1
                    } other campaigns. There are ${numCampaigns.numRejections} rejections and ${
                      numCampaigns.numDnI
                    } Do Not Invites.`}
                  </Alert>
                </Grid>
              )}
              {duplicateCandidates.length > 0 && (
                <Grid item xs={12}>
                  <Alert severity="warning">
                    {`${duplicateCandidates.length} other candidates have registered from the same IP address (${duplicateCandidates[0].ip4Address}).`}
                  </Alert>
                </Grid>
              )}
              {getInterviewForm()}
            </Grid>
          </Box>
        );
    }
  };

  return (
    <TitledPage title="Interview Form">
      <Box>{getForm()}</Box>
    </TitledPage>
  );
};

export default PageInterviews;
