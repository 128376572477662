import { Grid, Divider, MenuItem } from "@mui/material";
import { SelectCountryTranslated } from "../../../components/select-country";
import { RadioYesNo } from "../../../components/radio-button-yes-no";
import { CustomTextField } from "../../../components/custom-textfield";

import { Applicant, ApplicantErrors } from "../../../types/applicants";
import { EMPTY_COUNTRY } from "../../../types/commons";
import { Country } from "../../../types/commons";
import { FormattedMessage } from "react-intl";
// import AddressAutocomplete from "../../../components/address-autocomplete";
// import { applicantFullAdress } from "../../../utils/utils";

interface PageGeneralInformationProps {
  applicant: Applicant;
  errors: ApplicantErrors;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onAddressChange: (event: any, newValue: string | null) => void;
  onCountryChange: (event: any, newValue: Country | null) => void;
}
export const PageGeneralInformation: React.FC<PageGeneralInformationProps> = ({
  applicant,
  errors,
  onInputChange,
  onAddressChange,
  onCountryChange,
}) => {
  return (
    <Grid container spacing={2} padding={2}>
      <Grid item xs={12}>
        <Divider textAlign="left">
          <FormattedMessage id="divider_general_information" defaultMessage="General Information" />
        </Divider>
      </Grid>
      <Grid item xs={12} md={4}>
        <CustomTextField
          name="name"
          label="Name"
          error={!!errors.name}
          helperText={errors.name}
          autoFocus
          type="text"
          value={applicant.name}
          onChange={onInputChange}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <CustomTextField
          name="surname"
          label="Surname"
          error={!!errors.surname}
          helperText={errors.surname}
          type="text"
          value={applicant.surname}
          onChange={onInputChange}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <CustomTextField
          name="maiden_name"
          label="Maiden name (if available)"
          error={!!errors.maiden_name}
          helperText={errors.maiden_name}
          type="text"
          value={applicant && applicant.maiden_name}
          onChange={onInputChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <SelectCountryTranslated
          id="nationality"
          label="Nationality"
          error={!!errors.nationality}
          helperText={errors.nationality}
          value={
            applicant && applicant.nationality && applicant.nationality.code
              ? applicant.nationality
              : EMPTY_COUNTRY
          }
          onChange={onCountryChange}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <SelectCountryTranslated
          id="additional_nationality"
          label="Additional Nationality"
          error={!!errors.additional_nationality}
          helperText={errors.additional_nationality}
          value={
            applicant && applicant.additional_nationality && applicant.additional_nationality.code
              ? applicant.additional_nationality
              : EMPTY_COUNTRY
          }
          onChange={onCountryChange}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <CustomTextField
          name="gender"
          label="Gender"
          select
          error={!!errors.gender}
          helperText={errors.gender}
          value={applicant && applicant.gender ? applicant.gender : ""}
          onChange={onInputChange}
          sx={{ width: "160px" }}
          required
        >
          <MenuItem value="M">
            <FormattedMessage id="sex_male" defaultMessage="Male" />
          </MenuItem>
          <MenuItem value="F">
            <FormattedMessage id="sex_female" defaultMessage="Female" />
          </MenuItem>
          <MenuItem value="D">
            <FormattedMessage id="sex_diverse" defaultMessage="Diverse" />
          </MenuItem>
        </CustomTextField>
      </Grid>
      <Grid item xs={12}>
        <Divider textAlign="left">
          <FormattedMessage id="divider_birth_information" defaultMessage="Birth Information" />
        </Divider>
      </Grid>
      <Grid item xs={12} md={4}>
        <CustomTextField
          name="birth_date"
          label="Date of Birth"
          error={!!errors.birth_date}
          helperText={errors.birth_date}
          type="date"
          value={applicant.birth_date}
          onChange={onInputChange}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <CustomTextField
          name="birth_place"
          label="Place of Birth"
          error={!!errors.birth_place}
          helperText={errors.birth_place}
          type="text"
          value={applicant.birth_place}
          onChange={onInputChange}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <SelectCountryTranslated
          id="birth_country"
          label="Country of Birth"
          error={!!errors.birth_country}
          helperText={errors.birth_country}
          value={
            applicant && applicant.birth_country && applicant.birth_country.code
              ? applicant.birth_country
              : EMPTY_COUNTRY
          }
          onChange={onCountryChange}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <Divider textAlign="left">
          <FormattedMessage
            id="divider_passport_information"
            defaultMessage="Passport Information"
          />
        </Divider>
      </Grid>
      {/*Passport */}
      <Grid item xs={12} md={4}>
        <CustomTextField
          name="pass_id"
          label="Passport ID"
          error={!!errors.passport?.id}
          helperText={errors.passport?.id}
          type="text"
          value={applicant.passport && applicant.passport.id}
          onChange={onInputChange}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <CustomTextField
          name="pass_issuing_place"
          label="Place of Issuing (City)"
          error={!!errors.passport?.issuing_place}
          helperText={errors.passport?.issuing_place}
          type="text"
          value={applicant.passport && applicant.passport.issuing_place}
          onChange={onInputChange}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <SelectCountryTranslated
          id="pass_issuing_country"
          label="Issuing Authority (Country)"
          error={!!errors.passport?.issuing_country}
          helperText={errors.passport?.issuing_country}
          value={
            applicant &&
            applicant.passport &&
            applicant.passport.issuing_country &&
            applicant.passport.issuing_country
              ? applicant.passport.issuing_country
              : EMPTY_COUNTRY
          }
          onChange={onCountryChange}
          required
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <CustomTextField
          name="pass_issue_date"
          label="Date of Issue"
          error={!!errors.passport?.issue_date}
          helperText={errors.passport?.issue_date}
          type="date"
          value={applicant.passport && applicant.passport.issue_date}
          onChange={onInputChange}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <CustomTextField
          name="pass_expiry_date"
          label="Expiry Date"
          error={!!errors.passport?.expiry_date}
          helperText={errors.passport?.expiry_date}
          type="date"
          value={applicant.passport && applicant.passport.expiry_date}
          onChange={onInputChange}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={12} md={8}>
        {/* biometric passport */}
        <RadioYesNo
          id="pass_biometric"
          error={!!errors.passport?.is_biometric}
          helperText={errors.passport?.is_biometric}
          value={applicant && applicant.passport && applicant.passport.is_biometric}
          label="Do you have a biometric passport?"
          onChange={onInputChange}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider textAlign="left">
          <FormattedMessage id="divider_residency_information" defaultMessage="Current Address" />
        </Divider>
      </Grid>
      {/* ---------- Address ----------*/}
      {/* <Grid item xs={12}>
        <AddressAutocomplete
          id="full_address"
          label="Full address"
          autocompleteType="full_address"
          country={applicant && applicant.residency_country ? applicant.residency_country.code : ""}
          value={applicantFullAdress(applicant)}
          onChange={onAddressChange}
          required
        />
      </Grid>
 */}
      <Grid item xs={12} md={4}>
        <SelectCountryTranslated
          id="residency_country"
          label="Country"
          error={!!errors.residency_country}
          helperText={errors.residency_country}
          value={
            applicant && applicant.residency_country ? applicant.residency_country : EMPTY_COUNTRY
          }
          onChange={onCountryChange}
          required
        />
      </Grid>
      <Grid item xs={12} md={4}>
        {/* <AddressAutocomplete
          id="residency_city"
          label="City"
          autocompleteType="city"
          country={applicant && applicant.residency_country ? applicant.residency_country.code : ""}
          error={!!errors.residency_city}
          helperText={errors.residency_city}
          value={applicant.residency_city}
          onChange={onAddressChange}
          required
        /> */}
        <CustomTextField
          name="residency_city"
          label="City"
          error={!!errors.residency_city}
          helperText={errors.residency_city}
          type="text"
          value={applicant.residency_city}
          onChange={onInputChange}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <CustomTextField
          name="residency_zip"
          label="Postal Code "
          error={!!errors.residency_zip}
          helperText={errors.residency_zip}
          type="text"
          value={applicant.residency_zip}
          onChange={onInputChange}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <CustomTextField
          name="residency_province"
          label="Province / Region"
          error={!!errors.residency_province}
          helperText={errors.residency_province}
          type="text"
          value={applicant.residency_province}
          onChange={onInputChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={8}>
        <CustomTextField
          name="residency_street"
          label="Street / Number / Floor / Apt."
          error={!!errors.residency_street}
          helperText={errors.residency_street}
          type="text"
          value={applicant.residency_street}
          onChange={onInputChange}
          fullWidth
          required
        />
        {/* <AddressAutocomplete
          id="residency_street"
          label="Street / Number / Floor / Apt."
          autocompleteType="street_address"
          country={applicant && applicant.residency_country ? applicant.residency_country.code : ""}
          error={!!errors.residency_street}
          helperText={errors.residency_street}
          value={applicant.residency_street}
          onChange={onAddressChange}
          required
        /> */}
      </Grid>
      <Grid item xs={12} md={4}>
        <CustomTextField
          name="residency_date_since"
          label="Since when do you live at this address?"
          error={!!errors.residency_date_since}
          helperText={errors.residency_date_since}
          type="date"
          value={applicant.residency_date_since}
          onChange={onInputChange}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <CustomTextField
          name="residency_ownership"
          label="Ownership status"
          select
          error={!!errors.residency_ownership}
          helperText={errors.residency_ownership}
          value={applicant && applicant.residency_ownership ? applicant.residency_ownership : ""}
          onChange={onInputChange}
          required
          fullWidth
        >
          <MenuItem value="own">
            <FormattedMessage id="ownership_own" defaultMessage="Оwn it" />
          </MenuItem>
          <MenuItem value="rent">
            <FormattedMessage id="ownership_rent" defaultMessage="Rent it" />
          </MenuItem>
          <MenuItem value="other">
            <FormattedMessage id="ownership_other" defaultMessage="Other" />
          </MenuItem>
        </CustomTextField>
      </Grid>
      {applicant && applicant.residency_ownership === "other" && (
        <Grid item xs={12}>
          <CustomTextField
            name="residency_ownership_other"
            label="Please specify"
            error={!!errors.residency_ownership_other}
            helperText={errors.residency_ownership_other}
            value={applicant.residency_ownership_other}
            onChange={onInputChange}
            fullWidth
            required
          />
        </Grid>
      )}

      {/* Phone number */}
      <Grid item xs={12}>
        <Divider textAlign="left">
          <FormattedMessage id="divider_phone_information" defaultMessage="Phone number" />
        </Divider>
      </Grid>
      <Grid item xs={12} md={4}>
        <CustomTextField
          name="phone"
          label="Phone number"
          error={!!errors.phone}
          helperText={errors.phone}
          type="tel"
          value={applicant.phone || ""}
          onChange={onInputChange}
          fullWidth
          required
        />
      </Grid>
    </Grid>
  );
};
