import { TestScore, TestVerdict } from "../../types/recruiting";

export const CSV_TEST_SCORE_EMAIL_FIELD = "E-mail";

export const CSV_CANDIDATE_MAPPING: Record<string, string> = {
  name: "name",
  surname: "surname",
  "maiden name": "maiden_name",
  phone: "phone",
  email: "email",
  "passport no": "passportId",
  region: "region",
  note: "note",
  interviewer: "interviewer",
  "preferred language": "preferredLanguage",
};

export const CSV_CANDIDATE_MAPPING_DATE_PROPERTIES: Record<string, string> = {
  "date of birth": "birth_date",
  "date of issue": "passport.issue_date",
  "date of expiry": "passport.expiry_date",
  "father - date of birth": "father.birthday",
  "mother - date of birth": "mother.birthday",
  "residency ownership since": "residency_date_since",
  "partner - date of birth": "partner.birthday",
};

export const CSV_CANDIDATE_MAPPING_BOOLEAN_PROPERTIES: Record<string, string> = {
  "privacy policy accepted": "privacy_policy_accepted",
  "has passport": "has_passport",
};

export const CSV_CANDIDATE_MAPPING_COUNTRY_PROPERTIES: Record<string, string> = {
  nationality: "country",
};

export const parseTestScore = (row: Record<string, string>) => {
  const startDate = row["Дата начала"] ? parseDateTime(row["Дата начала"]) : "";
  const finished = row["Завершен"] === "Да";
  const score = row["Балл"] ? parseInt(row["Балл"]) : 0;
  const maxScore = row["Макс"] ? parseInt(row["Макс"]) : 0;
  const scorePercentage = row["(%)"] ? parseFloat(row["(%)"].replace("%", "") || "0") : 0;
  const credibility = row["Прокторинг - Оценка доверия"]
    ? parseInt(row["Прокторинг - Оценка доверия"])
    : 0;
  let verdict: TestVerdict = "none";
  switch (row["Прокторинг - Заключение"] || "none") {
    case "Одобрено":
      verdict = "pass";
      break;
    case "Отклонено":
      verdict = "fail";
      break;
    default:
      verdict = "none";
  }

  const testScore: TestScore = {
    startDate,
    finished,
    score,
    maxScore,
    scorePercentage,
    credibility,
    verdict,
  };

  return testScore;
};

const parseDateTime = (date: string) => {
  if (!date) {
    return "";
  }
  try {
    // Remove extra spaces. this is a hack to fix the inaccurate input data
    const trimmedDate = date.trim().replace("  ", " ");
    // Split date and time parts
    const [datePart, timePart] = trimmedDate.split(" ");

    // Split date components (DD.MM.YYYY)
    const [day, month, year] = datePart.trim().split(".").map(Number);

    // Split time components (HH:mm:ss)
    const [hours, minutes, seconds] = timePart.trim().split(":").map(Number);

    // Check if any of the components are NaN or invalid
    if (
      isNaN(day) ||
      isNaN(month) ||
      isNaN(year) ||
      isNaN(hours) ||
      isNaN(minutes) ||
      isNaN(seconds) ||
      month < 1 ||
      month > 12 ||
      day < 1 ||
      day > 31 ||
      hours < 0 ||
      hours > 23 ||
      minutes < 0 ||
      minutes > 59 ||
      seconds < 0 ||
      seconds > 59
    ) {
      return "";
    }

    // JavaScript months are 0-based, so we subtract 1 from the month
    return new Date(year, month - 1, day, hours, minutes, seconds).toISOString();
  } catch (error) {
    return "";
  }
};
