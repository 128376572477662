import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Email as SendIcon } from "@mui/icons-material";
import { useEffect, useMemo, useState } from "react";
import { APPLICATION_STATUS } from "../../types/visa-application";
import { AgentsDict } from "../../types/agents";
import { SeasonsDict, WorkOrdersDict } from "../../types/orders";
import { VirtualPresentationsDict } from "../../types/virtual-presentations";

export interface BatchUpdateProps {
  agentId?: string;
  seasonId?: string;
  workOrderId?: string;
  status?: string;
  dataForm_expiry_date?: string;
  virtualPresentationId?: string;
  expiry_date?: string;
  sendPresentationLink?: boolean;
}

interface ApplicationsBatchUpdateDialogProps {
  open: boolean;
  onClose: () => void;
  onBatchUpdate: (values: BatchUpdateProps) => void;
  onSendDataFormLink: (template: string) => void;
  numberOfSelected: number;
  initialValues: BatchUpdateProps;
  agents: AgentsDict;
  workOrders: WorkOrdersDict;
  seasons: SeasonsDict;
  virtualPresentations: VirtualPresentationsDict;
}

const ApplicationsBatchUpdateDialog = ({
  open,
  onClose,
  onBatchUpdate,
  onSendDataFormLink,
  numberOfSelected,
  initialValues,
  agents,
  workOrders,
  seasons,
  virtualPresentations,
}: ApplicationsBatchUpdateDialogProps) => {
  const [values, setValues] = useState<BatchUpdateProps>({ ...initialValues });
  const [template, setTemplate] = useState<string>("data_form");

  useEffect(() => {
    setValues({ ...initialValues });
    setErrors({ expiry_date: "" });
  }, [initialValues]);

  const [errors, setErrors] = useState<{ expiry_date: string }>({ expiry_date: "" });

  const validate = () => {
    let valid = true;
    const newErrors = { expiry_date: "" };

    if (values.virtualPresentationId) {
      if (values.expiry_date) {
        if (
          values.expiry_date !== initialValues.expiry_date &&
          new Date(values.expiry_date) < new Date()
        ) {
          newErrors.expiry_date = "Expiration date must be in the future";
          valid = false;
        }
      } else {
        newErrors.expiry_date = "Expiration date is required";
        valid = false;
      }
    }

    setErrors(newErrors);
    return valid;
  };

  const handleBatchUpdate = async () => {
    if (!validate()) {
      return;
    }

    const newValues = { ...values };
    if (values.agentId === "multiple" || values.agentId === initialValues.agentId) {
      delete newValues.agentId;
    }
    if (values.seasonId === "multiple" || values.seasonId === initialValues.seasonId) {
      delete newValues.seasonId;
    }
    if (values.workOrderId === "multiple" || values.workOrderId === initialValues.workOrderId) {
      delete newValues.workOrderId;
    }
    if (values.status === "multiple" || values.status === initialValues.status) {
      delete newValues.status;
    }
    if (
      values.dataForm_expiry_date === "multiple" ||
      values.dataForm_expiry_date === initialValues.dataForm_expiry_date
    ) {
      delete newValues.dataForm_expiry_date;
    }
    if (
      values.virtualPresentationId === "multiple" ||
      values.virtualPresentationId === initialValues.virtualPresentationId
    ) {
      delete newValues.virtualPresentationId;
    }

    onBatchUpdate(newValues);
    handleClose();
  };

  const handleClose = () => {
    //        setErrors({ ...EMPTY_ERRORS });
    onClose();
  };

  const handleInputComboChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const newValues: BatchUpdateProps = {
      ...values,
    };
    switch (event.target.name) {
      case "agentId":
        newValues.agentId = value;
        break;

      case "seasonId":
        newValues.seasonId = value;
        if (value && values.workOrderId) {
          if (workOrders[values.workOrderId].seasonId !== value) {
            newValues.workOrderId = "";
          }
        }
        break;
      case "workOrderId":
        if (value && values.seasonId && workOrders[value].seasonId !== values.seasonId) {
          return;
        }
        if (!values.seasonId) {
          newValues.seasonId = workOrders[value].seasonId;
        }
        newValues.workOrderId = value;
        break;

      case "status":
        newValues.status = value;
        break;

      case "virtualPresentationId":
        newValues.virtualPresentationId = value;
        break;

      case "template":
        setTemplate(value);
        return; // exit here to avoid setting the rest of the values

      default:
        break;
    }

    setValues(newValues);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.name) return;

    const value = event.target.value;
    const newValues: BatchUpdateProps = {
      ...values,
    };

    const key = event.target.name;
    switch (key) {
      case "expiry_date":
        newValues.expiry_date = value;
        break;

      case "dataForm_expiry_date":
        newValues.dataForm_expiry_date = value;
        break;

      default:
        // newApplication.application[key] = event.target.value;
        break;
    }

    setValues(newValues);
  };

  const handleSendLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValues: BatchUpdateProps = { ...values };
    newValues.sendPresentationLink = event.target.checked;
    setValues(newValues);
  };

  const sortAndFilterOrders = (fOrders: WorkOrdersDict, season: string | undefined) => {
    const filteredOrders = Object.entries(fOrders)
      .filter(([orderId, order]) => {
        if (season && season !== "multiple" && order.seasonId !== season) {
          return false;
        }

        return true;
      })
      .sort(([idA, orderA], [idB, orderB]) => {
        if (orderA.name < orderB.name) {
          return -1;
        }
        if (orderA.name > orderB.name) {
          return 1;
        }
        return 0;
      });

    return filteredOrders;
  };

  const sortedOrders = useMemo(
    () => sortAndFilterOrders(workOrders, values.seasonId),
    [workOrders, values.seasonId]
  );

  const sortedSeasons = useMemo(
    () => Object.entries(seasons).sort(([, a], [, b]) => a.name.localeCompare(b.name)),
    [seasons]
  );

  const sortedPresentations = useMemo(
    () => Object.entries(virtualPresentations).sort((a, b) => (a[1].name > b[1].name ? 1 : -1)),
    [virtualPresentations]
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"md"} fullWidth>
      <DialogTitle>Batch Update Applications</DialogTitle>
      <DialogContent>
        <DialogContentText>Changing {numberOfSelected} applications:</DialogContentText>
        <Grid container spacing={2} padding={2}>
          <Grid item xs={12}>
            <Divider textAlign="left">
              <Typography id="divider_update_properties">Update application properties</Typography>
            </Divider>
          </Grid>
          {/* ----------- Agent ----------- */}
          <Grid item xs={12}>
            <TextField
              name={"agentId"}
              label={"Agent"}
              select
              //error={!!errors.agent}
              //helperText={errors.agent}
              onChange={handleInputComboChange}
              value={values.agentId !== undefined ? values.agentId : "multiple"}
              fullWidth
              margin={"dense"}
            >
              {initialValues.agentId === "multiple" && (
                <MenuItem value="multiple" key="multiple_agents">
                  <Typography fontStyle={"italic"}>multiple agents selected</Typography>
                </MenuItem>
              )}

              <MenuItem value="" key="no_agent">
                <Typography>No agent selected</Typography>
              </MenuItem>

              {Object.entries(agents).map(([agentId, agent], index) => {
                return (
                  <MenuItem value={agentId} key={agentId}>
                    {agent.company}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>

          {/* ----------- Season & Work Orders ----------- */}
          <Grid item xs={12} md={3}>
            <TextField
              name={"seasonId"}
              label={"Season"}
              select
              onChange={handleInputComboChange}
              value={values.seasonId !== undefined ? values.seasonId : "multiple"}
              fullWidth
              margin={"dense"}
            >
              {initialValues.seasonId === "multiple" && (
                <MenuItem value="multiple" key="multiple_seasons">
                  <Typography fontStyle={"italic"}>Multiple seasons selected</Typography>
                </MenuItem>
              )}
              {/* <MenuItem value="" key="no_season">
                <Typography fontStyle={"italic"}>No season selected</Typography>
              </MenuItem> */}
              {sortedSeasons.map(([seasonId, season]) => {
                return (
                  <MenuItem value={seasonId} key={seasonId}>
                    {season.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              name={"workOrderId"}
              label={"Work Order"}
              select
              onChange={handleInputComboChange}
              value={values.workOrderId || ""}
              fullWidth
              margin={"dense"}
            >
              {initialValues.workOrderId === "multiple" && (
                <MenuItem value="multiple" key="multiple_orders">
                  <Typography fontStyle={"italic"}>Multiple work orders selected</Typography>
                </MenuItem>
              )}
              <MenuItem value="" key="no_order">
                <Typography>No order selected</Typography>
              </MenuItem>

              {sortedOrders.map(([orderId, order]) => {
                return (
                  <MenuItem value={orderId} key={orderId}>
                    {order.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
          {/* ----------- Status ----------- */}
          <Grid item xs={12} md={3}>
            <TextField
              name="status"
              label="Application status"
              select
              //              error={!!errors.status}
              //            helperText={errors.status}
              onChange={handleInputComboChange}
              value={values.status !== undefined ? values.status : "new"}
              fullWidth
              margin={"dense"}
            >
              {initialValues.status === "multiple" && (
                <MenuItem value="multiple" key="no_status">
                  <Typography fontStyle={"italic"}>Multiple values</Typography>
                </MenuItem>
              )}
              {Object.entries(APPLICATION_STATUS).map(([key, vtype]) => {
                return (
                  <MenuItem value={vtype.key} key={key}>
                    {vtype.label}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>

          {/* ----------- Data Form Expiration Date ----------- */}
          <Grid item xs={12} md={4}>
            <TextField
              name="dataForm_expiry_date"
              label="Data form expiration date"
              type="date"
              value={values.dataForm_expiry_date || ""}
              onChange={handleInputChange}
              margin={"dense"}
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
            />
          </Grid>

          {/* ----------- Presentation ----------- */}
          <Grid item xs={12}>
            <Divider> Assign a virtual presentation </Divider>
          </Grid>
          <Grid item xs={12} md={8}>
            <TextField
              name={"virtualPresentationId"}
              label={"Virtual Presentation"}
              select
              onChange={handleInputComboChange}
              value={values.virtualPresentationId || ""}
              required
              fullWidth
              margin={"dense"}
            >
              {initialValues.virtualPresentationId === "multiple" && (
                <MenuItem value="multiple" key="multiple_presentations">
                  <Typography fontStyle={"italic"}>multiple presentations selected</Typography>
                </MenuItem>
              )}
              <MenuItem value="" key="no_presentation">
                <Typography fontStyle={"italic"}>no presentation selected</Typography>
              </MenuItem>
              {Object.values(sortedPresentations).map(([presentationId, presentation]) => {
                return (
                  <MenuItem value={presentationId} key={presentationId}>
                    {presentation.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              name="expiry_date"
              label="Virtual presentation expiration date"
              error={!!errors.expiry_date}
              helperText={errors.expiry_date}
              type="date"
              value={values.expiry_date || ""}
              disabled={
                !values.virtualPresentationId || values.virtualPresentationId === "multiple"
              }
              onChange={handleInputChange}
              margin={"dense"}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                sx={{ marginTop: "16px" }}
                control={
                  <Switch
                    onChange={handleSendLinkChange}
                    checked={values.sendPresentationLink!!}
                    name="sendLink"
                  />
                }
                label="Send the candidates a link to their presentation"
              />
            </FormGroup>
          </Grid>

          {/* ----------- Email Templates ----------- */}
          <Grid item xs={12}>
            <Divider textAlign="left">
              <Typography id="divider_send_data_form_link">Send emails</Typography>
            </Divider>
          </Grid>
          <Grid item xs={6}>
            <TextField
              name={"template"}
              label={"Email template"}
              select
              onChange={handleInputComboChange}
              value={template}
              fullWidth
              margin={"dense"}
            >
              <MenuItem value="data_form" key="data_form">
                Survey Link
              </MenuItem>
              <MenuItem value="hops_gateway_portal" key="hops_gateway_portal">
                Sponsor Portal Instructions
              </MenuItem>
              <MenuItem value="data_form_instructions" key="data_form_instructions">
                Visa Form Instructions
              </MenuItem>
              <MenuItem value="visa_payment" key="visa_payment">
                Visa Payment Reminder
              </MenuItem>
            </TextField>
          </Grid>
          <Grid container alignItems="center" item xs={4}>
            <Button
              variant="contained"
              startIcon={<SendIcon />}
              onClick={() => {
                onSendDataFormLink(template);
              }}
            >
              Send Email
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleBatchUpdate}>Update</Button>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApplicationsBatchUpdateDialog;
